import React, {useState} from 'react';
import Page from "../../styled/Page";
import {ItemDetail} from "./ItemDetail";
import {AtlasBanner} from "./AtlasBanner";
import Header from "../../component/Header/Header";
import Drops from "../../data/items";
import season from "../../system/season";
import Tabs from "../../component/Tabs";
import {
    AtlasBlockDiv,
    AtlasColumnDiv,
    AtlasColumnOverlayDiv,
    AtlasContainerDiv,
    AtlasHeaderDiv,
    AtlasItemRollUpDiv,
    AtlasShadowDiv
} from "./AtlasStyle";
import {PageTypes} from "../../system/types";

// Retrieves the items for a provided season
const SeasonItems = (season, history) => {
    return Drops[season].map((item,i) => {
        const itemIndex = (i * 4) + i;
        return (
            <ItemDetail
                key={item.itemId}
                history={history}
                size={'small'}
                item={item}
                index={itemIndex}
                slotMargin={0}
            />
        )
    });
};

// The view for each season
const SeasonRollUp = (history) => {
    const rows = ['autumn','winter','spring','summer'].map((s,i) => (
        <AtlasColumnDiv
            key={s}
            active={s === season.current}
        >
            {i%2===0 &&
                <AtlasColumnOverlayDiv />
            }
            <AtlasHeaderDiv active={s === season.current}>{s.toUpperCase()}</AtlasHeaderDiv>
            <AtlasItemRollUpDiv>{SeasonItems(s,history)}</AtlasItemRollUpDiv>
        </AtlasColumnDiv>
    ));
    return (
        <div style={{display: 'flex',flexDirection: 'row'}}>
            {rows}
        </div>
    )
};
// The view for all seasons
const AllSeasonRollUp = ({history,activeTab}) => {
    const numberColumns = 4;
    const itemsPerColumn = Drops.all[activeTab].length / numberColumns;
    const rows = [];
    let offset = 0;
    // Each column should display items
    for (let i=0; i < numberColumns; i++) {
        // The offset must be determined for each column
        const columnDrops = Drops.all[activeTab].slice(offset,itemsPerColumn+offset);
        // The offset must be updated for the subsequent items
        offset = offset + itemsPerColumn;

        rows.push(
            <AtlasColumnDiv>
                <AtlasItemRollUpDiv>
                    {
                        columnDrops.map((item,index) => {
                            const itemIndex = (i * numberColumns) + index;
                            return (
                                <ItemDetail
                                    index={itemIndex}
                                    history={history}
                                    key={item.itemId}
                                    size={'medium'}
                                    item={item}
                                    slotMargin={0}
                                />
                            )
                        })
                    }
                </AtlasItemRollUpDiv>
            </AtlasColumnDiv>
        );
    }
    return (
        <div
            key={activeTab}
            style={{display: 'flex',flexDirection: 'row'}}
        >
            {rows}
        </div>
    );
};

// The final composed view
const sections = ['ability','cap','skin','consumable','other'];
export const Atlas = ({ history }) => {
    const [activeTab,setActiveTab] = useState(sections[0]);

    return (
        <Page className={'animated SlideInRight'}>
            <Header closeRoute={PageTypes.MAIN} title="Game Items"/>
            <AtlasContainerDiv
                className={'styleDarkScrollSquare'}
            >
                <AtlasBanner />
                <AtlasShadowDiv>
                    {SeasonRollUp(history)}
                </AtlasShadowDiv>
                <AtlasShadowDiv style={{margin: '5em 0'}}>
                    <Tabs
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        sections={sections}
                    />
                    <AtlasBlockDiv />
                    <AllSeasonRollUp
                        history={history}
                        activeTab={activeTab}
                    />
                </AtlasShadowDiv>
            </AtlasContainerDiv>
        </Page>
    )
};