import styled from 'styled-components';

export const BadgesDiv = styled.div`
    color: white;
    height: 4em;
    margin: 0 .5em;
    display: flex;
    align-items: center;
    div {
        margin: 0 .125em;
    }
    div:first-child {
        margin-left: 0;
    }
    div:last-child {
        margin-right: 0;
    }
`;