import {TabDiv, TabsContainerDiv} from "../styled/Tabs";
import React from "react";

const Tabs = ({sections,activeTab,setActiveTab}) => {

    return (
        <TabsContainerDiv>
            {
                sections.map((tab) => {
                    return (
                        <TabDiv
                            tabIndex={0}
                            key={tab}
                            active={activeTab === tab}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.toUpperCase()}
                        </TabDiv>
                    )
                })
            }
        </TabsContainerDiv>
    )
};

export default Tabs;