import Proton from 'proton-engine';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';

export function createOrbParticles(room, id, team, teamIndex) {
    let orbEmitter = new Proton.Emitter();
    orbEmitter.rate = new Proton.Rate(4, updatePerFrame);
    orbEmitter.addInitialize(new Proton.Mass(1));
    orbEmitter.addInitialize(new Proton.Radius(30, 50));
    orbEmitter.addInitialize(new Proton.Life(0.2, 0.4));
    orbEmitter.addBehaviour(new Proton.RandomDrift(5, 5, 0.05));
    orbEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-1, 1), new Proton.Span(0, 360), 'polar')
    );
    orbEmitter.addBehaviour(new Proton.Color('007700', 'aaffaa'));
    orbEmitter.addBehaviour(new Proton.Gravity(-1));
    orbEmitter.addBehaviour(new Proton.Scale(1, 1.5));
    orbEmitter.addBehaviour(new Proton.Alpha(0.8, 0));
    orbEmitter.damping = 0.04;
    orbEmitter.emit();
    orbEmitter.p.x = 0;
    orbEmitter.p.y = 0;

	let playerEmitter = new Proton.Emitter();
	playerEmitter.rate = new Proton.Rate(8, updatePerFrame);
	playerEmitter.addInitialize(new Proton.Mass(1));
	playerEmitter.addInitialize(new Proton.Radius(5, 15));
	playerEmitter.addInitialize(new Proton.Life(1, 2));
	playerEmitter.addBehaviour(new Proton.RandomDrift(5, 5, 0.05));
	playerEmitter.addInitialize(
		new Proton.Velocity(new Proton.Span(-2, 2), new Proton.Span(0, 360), 'polar')
	);
	playerEmitter.addBehaviour(new Proton.Color('00ff00', 'aaffaa'));
	playerEmitter.addBehaviour(new Proton.Gravity(-2));
	playerEmitter.addBehaviour(new Proton.Scale(1, 1.5));
	playerEmitter.addBehaviour(new Proton.Alpha(0.8, 0));
	playerEmitter.damping = 0.04;
	playerEmitter.emit();
	playerEmitter.p.x = 0;
	playerEmitter.p.y = 0;



    protonBackground.addEmitter(orbEmitter);
    protonBackground.addEmitter(playerEmitter);
    let interval = setInterval(() => {
        let projectile = room.projectiles.filter((projectile) => projectile.id === id)[0];
        if (projectile) {
			orbEmitter.p.x = projectile.position.x;
			orbEmitter.p.y = projectile.position.y;
			// orbEmitter.addInitialize(
			// 	new Proton.Position(
			// 		new Proton.CircleZone(projectile.position.x, projectile.position.y, 40)
			// 	)
			// );
			let player = room.teams[team][teamIndex];
			playerEmitter.p.x = player.position.x;
			playerEmitter.p.y = player.position.y;
        } else {
			orbEmitter.rate = new Proton.Rate(0,1000);
			playerEmitter.rate = new Proton.Rate(0,1000);
            setTimeout(() => {
                protonBackground.removeEmitter(orbEmitter);
                protonBackground.removeEmitter(playerEmitter);
            }, 2000);
            clearInterval(interval);
        }
    }, 1000 / 60);
}
