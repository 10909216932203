import {createAction} from "@reduxjs/toolkit";


export const updateSquad = createAction('UPDATE_SQUAD');

export const updateSquadStatus = createAction('UPDATE_SQUAD_STATUS');

// The player has been invited to join a squad. The invite is appended to the players invites.
export const squadInviteReceived = createAction('SQUAD_INVITE_RECEIVED');
// The receiving player has responded with a decision to join
export const squadResponse = createAction('SQUAD_RESPONSE');
export const squadRequestLeave = createAction('SQUAD_REQUEST_LEAVE');
// This event exclusively fires from a response. Making it an informed state manipulation.
export const squadLeave = createAction('SQUAD_LEAVE');