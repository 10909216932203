import {imageAssets} from "../../data/images";

const CriticalShot = (lobby, context) => {
    lobby.ballCritPositions.forEach((bc, index) => {
        let ball = lobby.ball;
        let angle = Math.atan2(ball.body.velocity.y, ball.body.velocity.x);
        let bcHeight = bc.size * 2.65;
        let bcWidth = bc.size * 2.65;
        context.translate(ball.body.position.x, ball.body.position.y);
        context.rotate(angle);
        let x = imageAssets['Other']['Ball Crit'][bc.frame].outline;
        bc.tickCounter++;
        if (bc.tickCounter == bc.frameDuration) {
            bc.tickCounter = 0;
            bc.frame++;
            if (bc.frame == bc.totalFrames - 1) {
                lobby.ballCritPositions.splice(index, 1);
            }
        }
        context.drawImage(x, -bcHeight / 2, -bcWidth / 2, bcWidth, bcHeight);
        context.rotate(-angle);
        context.translate(-ball.body.position.x, -ball.body.position.y);
    });
};

export default CriticalShot;