import React from "react";
import {modalEnable} from "../../../redux/modules/modal/modalActions";
import palette from "../../../styled/Palette";
import {definitionStats, mapStats} from "../../../data/stats";
import styled from "styled-components";

export const LootStatsLi = styled.li`
    padding: .5em;
    display: flex;
    width: 100%;
    color: ${palette.base4};
`;
export const LootStatsUl = styled.ul`
    display: flex;
    flex-direction: column;
    margin: .5em;
    flex-grow: 1;
`;
export let LootStatsData;

const ModalLootStats = (dispatch,theme) => {

    LootStatsData = (
        <LootStatsUl>
            {Object.keys(definitionStats).map(statName => {
                return (
                    <LootStatsLi key={statName}>
                        <div style={{fontWeight: 'bold', flex: 1}}>{mapStats(statName,'label')}</div>
                        <div style={{display: 'flex', fontSize: '.75em'}}>{definitionStats[statName]}</div>
                    </LootStatsLi>
                )
            })}
        </LootStatsUl>
    );

    dispatch(modalEnable({
        body: 'LootStats',
        title: "ABOUT ITEM POWER",
    }));
};

export default ModalLootStats;