import React, {useState} from "react";
import {useSelector} from "react-redux";
import {
    LootItemsInfoDiv
} from "./LootItemsInfoStyle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StatsIconDiv} from "../../LootStats/LootStats";
import {useIcon} from "../../../../data/icon";
import palette from "../../../../styled/Palette";
import InfoBanner from "../../../../component/InfoBanner/InfoBanner";
import {WatchBodyContentDiv} from "../../../Watch/WatchStyle";
import TextUnderlineHover from "../../../../styled/TextUnderlineHover";
import {PageTypes} from "../../../../system/types";

/**
 * This component was built to improve the user experience for new users.
 */
const LootItemsInfo = ({category,history}) => {
    const faces = useSelector(state => state.self.items.face);
    const theme = useSelector(state => state.theme);
    const [numberUnlockedFaces] = useState(faces.filter(face => face.locked === 0).length);

    if (numberUnlockedFaces > 3) return null;

    if (category === 'face') {
        return (
            <div style={{margin: '.5em 1em 0 1em'}}>
                <InfoBanner
                    title={'Item Cache'}
                    body={
                        <div>
                            Here is your personal inventory. Unlock faces by
                            &thinsp;
                            <TextUnderlineHover
                                theme={theme}
                                bold={true}
                                onClick={() => {
                                    history.push(PageTypes.FEATS);
                                }}
                            >
                                earning achievements
                            </TextUnderlineHover>
                            &thinsp;. Other items can be found after
                            &thinsp;
                            <TextUnderlineHover
                                theme={theme}
                                bold={true}
                                onClick={() => {
                                    history.push({pathname: PageTypes.GAME_SELECT, state: { mode: 'casual'}});
                                }}
                            >
                                completing matches
                            </TextUnderlineHover>
                            &thinsp;.
                             Players with access to
                            &thinsp;
                            <TextUnderlineHover
                                theme={theme}
                                bold={true}
                                onClick={() => {
                                    history.push(PageTypes.LEARN);
                                }}
                            >
                                premium
                            </TextUnderlineHover>
                            &thinsp;
                            receive better drop rates as well as other benefits like exclusive rare items.
                        </div>
                    }
                />
            </div>
        )
    }
    return null;
};

export default LootItemsInfo;