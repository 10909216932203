import React from "react";

const changes = [
    {
        version: '0.11.6',
        title: 'Standard Patch',
        date: '3/10/2023',
        notes: [
            `Refactored the Fortune wheel page.`
        ]
    },
    {
        version: '0.11.5',
        title: 'Standard Patch',
        date: '3/2/2023',
        notes: [
            `Added a dialogue tree for the event when a player purchases premium.`,
        ]
    },
    {
        version: '0.11.4',
        title: 'Standard Patch',
        date: '2/25/2023',
        notes: [
            `Fixed inability to escape the tutorial once started.`,
            `To simplify joining a match for new users, guests can no longer specify which game mode to join.`,
        ]
    },
    {
        version: '0.11.3',
        title: 'Fixes common game crash scenarios',
        date: '2/21/2023',
        summary: `By capturing game crash scenarios through analytics we have identified and fixed some of the most common experienced game stopping bugs.`,
    },
    {
        version: '0.11.2',
        title: 'Slime news ticker and minor enhancements',
        date: '2/15/2023',
        notes: [
            `Added a news ticker to keep our inhabitants informed of the latest breaking world information.`,
            `The squad interface has been updated to support many more players.`,
            `Creating a new outfit now clones the existing outfit for convenience.`,
            `Failing to connect to a game server should now send the user back to the main screen.`,
            `Visiting the website on mobile now displays messaging to direct users to a desktop computer.`,
        ],
    },
    {
        version: '0.11.1',
        title: 'Balancing and bug fixes',
        date: '6/15/2022',
        summary: `On 5/6/2022 we saw a record 1,192 unique user visits! Now here are some minor fixes.`,
        notes: [
            `Added indicators to match chat when a player from the active match receives a drop.`,
            `Balanced the hoops average game duration with other modes by doubling the size of the basket. `,
            `Fixed the chat censoring option.`,
            `Fixed private games inaccurately displaying coins earned.`,
            `Fixed costs not being displayed on market items.`,
            `Fixed passive abilities inaccurately rendering on the score board.`,
        ],
        technical: [
            `Upgrade to MatterJS version 18 resulted in a 40% performance boost on the physics engine.`,
        ]
    },
    {
        version: '0.11.0',
        title: 'Ability Items, Seasonal Drops, Moderators, and much more!',
        date: '4/30/2022',
        summary: `We are pleased to bring you one of our largest content releases! This update notably adds ability based accessory items into the mix. Many are quite rare but if you are fortunate enough to get your slimy nubs on them, they can be quite beneficial. This update marks a big progress milestone. Now that we have a solid feature set we will begin to invest in exposure in an effort to grow the userbase. Our peak number of visitors was 602, on 3/11/2022! Thanks to everyone for your undying patience and support. 🎉`,
        notes: [
            `Added 28 ability based items into the drop pool.`,
            `Added potions and other consumables into the drop pool.`,
            `Added a system so that players can switch between abilities during a match.`,
            `Added a seasonal system which influences the game in various ways.`,
            `Added a game items screen which details all the possible items that can be acquired.`,
            `Added a game and chat ban system so that player moderators can help regulate the community.`,
            `Added a component to display the number of all connected users and guests.`,
            `Added expandable lists to the Chat screen in order to separate friends by status.`,
            `Added an information page detailing some of the key game mechanics.`,
            `Added a rudimentary tutorial to help new users with controls.`,
            `Added an element on the loot screen that displays a comparison between the equipped and hovered item.`,
            `Added a context message to matches that will inform players why they have phased out while goal tending.`,
            `Added floor and background synchronization between users during a match.`,
            `Fixed an issue where ratings were not correctly being reported on the Watch screen.`,
        ],
        technical: [
            `The client and server are now sharing some files. This makes the project more maintainable with less duplicate code!`,
            `We migrated to styled-components because emotionjs does not have great support online.`,
            `Improved the performance on the loot screen by fixing our web worker implementation.`,
        ]
    },
    {
        version: '0.10.0',
        title: 'Quality of life',
        date: '10/26/2021',
        summary: `Lately our focus has been on trying to orchestrate our development and product team to gear up for a successful steam release. We are planning to grow our team slightly and continue to push out new content. For now here are some long due fixes.`,
        notes: [
            `Improved the present purchase experience.`,
            `Added some floor textures. We will expand on this later.`,
            `Added a default face because faceless slime was jarring.`,
            `Fixed an issue where character control buttons began in the pressed state.`,
            `Fixed an issue where emotes had stopped working.`,
        ]
    },
    {
        version: '0.9.0',
        title: 'Gamepad support, Wide-screen, and more.',
        date: '6/28/2021',
        notes: [
            `Added the ability to navigate the interface with a gamepad.`,
            `Added slots to show players equipped items in the profile page.`,
            `Added viewing support for extra wide screen users.`,
            `Added an indicator on loot items to show which item is currently equipped.`,
            `Added an improved keyboard controls diagram.`,
            `Fixed an issue where the game would remain active when on a fullscreen page.`,
            `Fixed an issue where the game would crash on firefox instead of displaying a 'Switch to Chrome' message.`,
        ],
        technical: [
            `Any gamepad device configured with Xinput is now supported.`
        ]
    },
    {
        version: '0.8.0',
        title: 'Optimizations',
        date: '5/24/2021',
        notes: [
            `Initial game download is up to 4x faster!`,
            `Added username restrictions on bad words during account creation.`,
            `Added better feedback for sign-in failures.`,
            `Added history retrieval for the public chat.`,
            `Fixed an issue where the match clock was not always reporting accurately`,
            `The watch screen now features only the best few matches.`,
            `The cards featured on the market screen got a slight make over.`,
            `New loot items now appear at the front of the list, for easy access.`,
        ],
        technical: [
            `75% reduction in static sound file sizes. 160MB down to 40MB.`,
            `75% reduction in static image file sizes. 8MB down to 2MB.`,
            `Adjusted versioning in order to better follow semantic standards`,
        ]
    },
    {
        version: '0.7.0',
        title: 'Server Regions, BIG Performance Enhancements, and more.',
        date: '5/8/2021',
        notes: [
            `Added dynamic regional support which recommends the lowest latency servers.`,
            `Added functionality for users to override the preferred game server selection.`,
            `Added functionality for users to optionally rate server performance following matches.`,
            `Added steam friends menu, with squad invite support, to the steam version of the game.`,
            `Added steam wallet integration for purchases through the platform.`,
            `Achievement unlocks are now being displayed.`,
            `Loot screen item filters now persist for each individual category.`,
            `The Match Selection screen now displays the users current divisions.`,
            `Fixed an issue where the informational bubbles would not open the associated modal.`
        ],
        technical: [
            `Implemented cache invalidation following patches, to make sure users have the latest content available.`,
            `Enhanced our content delivery system by sharing static files from edge locations.`,
            `Offloaded downloads of static files from master server to help ensure availability.`,
            `The Loot screen now uses virtualization in order to remove pagination needs.`,
            `We now use web workers in order to offload the work of generating our images onto another thread.`,
            `We now only support Chrome browser, as web workers are a limited experimental technology.`,
        ]
    },
    {
        version: '0.6.0',
        title: 'Resilience Update',
        date: '3/18/2021',
        notes: [
            `Added a header to better indicate which loot items are being displayed.`,
            `Added a cooldown between queue requests to inhibit server flooding.`,
            `Fixed a critical memory leak that often occurred on the Win screen.`,
            `Fixed a critical issue where all cash shop purchases were disabled.`,
            `Fixed an issue where subsequent radio tracks did not begin if the radio interface was not mounted.`,
            `Fixed an issue where selling an equipped item was not removing the item from the avatar.`,
            `Fixed an issue where newly acquired items were not being displayed on the Loot screen.`,
        ],
        technical: [
            `Created a staging server for testing builds before live deployments.`,
            `Migrated some server code to typescript to enhance reliability.`,
            `We have made significant progress implementing Steam platform friend invites, achievements, and purchasing systems`,
        ]
    },
    {
        version: '0.5.0',
        title: 'We Fix Stuff',
        date: '2/26/2021',
        notes: [
            `Added tooltips to the items on the Loot screen.`,
            `Added an animation to show coins being earned following a match win.`,
            `Added a button to toggle ability mode on the Private Match screen.`,
            `Users are now sorted by rating on the Chat screen.`,
            `Main Screen had some elements shifted around and refactored.`,
            `Fixed an issue where the Watch screen was not rendering optimized Slime avatars.`,
            `Fixed an issue where the Account Recovery screen was not working properly.`,
            `Fixed an issue where the rating bar was sometimes not properly filling.`,
            `Fixed an issue where the rematch timer was sometimes inaccurate.`,
            `Fixed an issue where the rematch timer was not properly reset after previous rematches.`,
            `Fixed an issue where paintless avatars would not render outfit items on the interface.`,
            `Fixed an issue where cancelled matches would not gracefully return players to the Main screen.`,
        ],
    },
    {
        version: '0.4.0',
        title: 'Regression Changes',
        date: '2/20/2021',
        notes: [
            `The Bug Report feature is now operational for processing issues.`,
            `Fixed an issue where a squad of one player could enter a private match.`,
            `Fixed an issue where the rank up screen was not displaying.`,
            `Fixed an issue where the drop screen would not let users continue.`,
            `Fixed an issue where a tied overtime game did not lead to the score screen.`,
            `Fixed an issue where the slime portrait was not displaying birth colors properly.`,
            `Fixed an issue where "opponent failed to join" if triggered, continued to display for proceeding matches.`,
            `Fixed an issue where players could trigger multiple private match requests successively.`,
            `Fixed an issue where players would sometimes duplicate on the private match screen.`,
        ],
    },
    {
        version: '0.3.0',
        title: 'Delivery Pipeline',
        date: '2/16/2021',
        notes: [
            `Slime avatars have been added to the Feats and Watch screens.`,
            `The squad interface was updated to include avatars in order to be more visually friendly.`,
            `The radio now includes a back button due to popular request.`,
            `When hovering items on the loot screen, the bar graph will now display the difference in change for each stat.`,
            `Fixed an issue where the queue did not always prioritize matching players.`,
            `Fixed an issue where the countdown sound could still be heard after leaving a match.`,
        ],
        technical: [
            `We now have the ability to test live builds on a staging server.`,
            `We now have the ability to instantly deploy version updates to steam.`,
        ]
    },
    {
        version: '0.2.0',
        title: 'Outfit Presets',
        date: '1/13/2021',
        notes: [
            `Premium users can now save different outfits on the loot screen.`,
            `Adjusted the experience for selling an item on the loot screen.`,
            `Fixed an issue where google had blacklisted our emails used for password recovery.`,
            `Radio can now be enabled on the the game screen under the match options cog.`,
            `Fixed an issue where sold items would still appear to be in the inventory.`,
            `Fixed an issue where the game crashed when returning to the slots screen after navigating away without claiming a prize.`,
            `Many other less notable fixes happened here and more made up the v0.9.1 release.`,
        ],
        technical: [
            `Implemented CRACO in order to have better flexibility with babel/webpack/electron.`,
            `Fixed hot refresh capabilities.`,
        ]
    },
    {
        version: '0.1.0',
        title: 'Enormous Refactoring Journey',
        date: '10/26/2020',
        summary: `We have been implementing an enormous number of changes, mostly under the hood. Since much of what we have previously deployed was a first-go pass on systems, many features needed improvement. You can see from the previously listed updates some artifacts of that work. Every single feature has been given a lift to some extent. Before we add anything new we need to make sure that nothing seems too clunky. Let's get into some details.`,
        notes: [
            `Career Ratio on the Feats page was corrected to only reflect ranked matches`,
            `Double Jump was added as a modifier option`,
            `Bots work together in teams by designating sections of the court they are responsible for`,
            `Bots difficulty scaling based on individual user skill level`,
            `Bots queue for all game sizes, up to 4 versus 4`,
            `Bots now wear a variety of outfits`,
            `Market now has a restocking phase when loading an entirely new batch of items`,
            `Market items are now marked as sold when purchased by a user, rather than disappearing`,
            `Application performance greatly improved`,
        ],
        technical: [
            `Integrated Google Analytics providing us with detailed traffic information`,
            `Transitioned from Gulp task runner into only leveraging Node Package Manager`,
            `Transitioned from SASS technology to using styled components with EmotionJS`,
            `Transitioned entirely from class-based components into a functional paradigm`,
            `Transitioned from using entirely local state into global storage with React Context`,
            `Transitioned into Redux as a global storage mechanism to replace React Context`,
            `Implemented a websocket middleware to manage events outside of the React scope`,
            `Began working to create a desktop version of the application with ElectronJS`,
        ]
    },
];

export default changes;
