
const HOOP_TALL = 10;
const HOOP_DIAMETER = 130;
const RIM_WIDTH = 15;

export const ballInitialState = {
    position: {
        x: -100,
        y: -100
    },
    latestPosition: {
        x: -100,
        y: -100,
    },
    lastPosition: {
        x: -100,
        y: -100,
    },
    circleRadius: 20,
    radius: 20,
};

export default {
    abilityEffects: [],
    contextMessages: [],
    bets: [],
    modifiers: {
        'Center Wall': {
            status: null
        }
    },
    private: null,
    p1Score: 0,
    p2Score: 0,
    ranked: null,
    roomId: 'lobby',
    results: null,
    sport: null,
    status: null,
    spectators: null,
    timeUntilStart: null,
    timeUntilNextMatchStart: null,
    server: {
        name: 'default slime server',
        region: 'slimeville',
        provider: 'slime hosting llc',
        userRated: false
    },
    sessionId: null,
    teams: {
        teamA: [],
        teamB: []
    },
    teamSize: null,
    timeLeft: null,
    timeLeftTimestamp: null,
    view: {
        // Which components are being rendered
        options: false,
        chat: false,
        rules: true,
    },
};