import React  from "react";
import styled from 'styled-components';
import DialogueTutorial from "../component/Dialogue/type/tutorial";

const TutorialContainerDiv = styled.div`
        border-left: 1px solid black;
        flex-direction: column;
        height: 100%;
        display: flex,
        justify-content: center,
        align-items: center,
`;
const TutorialPage = ({ history }) => (
    <TutorialContainerDiv>
        <DialogueTutorial
            history={history}
        />
    </TutorialContainerDiv>
);

export default TutorialPage;