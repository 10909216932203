import React from 'react';
import { getDivision } from '../../data/rating';
import { color } from '../../data/format';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import palette from "../../styled/Palette";
import {PageTypes} from "../../system/types";

let IconClose = require('react-icons/lib/fa/close');

const Players = ({team,sport, history}) => {
    const readyDiv = (player) => {
        let readyFlag;
        if (history.location.pathname === PageTypes.WIN) {
            readyFlag = player.rematch;
        } else if (history.location.pathname === PageTypes.RULES)  {
            readyFlag = player.ready;
        }

        const iconDiv = () => {
            if (!player.active) {
                return (<FontAwesomeIcon icon={['fas', 'door-open']} />);
            }
            if (!readyFlag) {
                return (<div><IconClose/></div>)
            } else {
                return (<FontAwesomeIcon icon={['fas', 'check']} />);
            }
        };

        return (
            <div className="animated bounceIn"
                 style={{alignItems: 'center', marginRight: '10px', display: 'flex'}}
            >
                {player.active && readyFlag && 'READY'}
                {!player.active && 'GONE'}
                &nbsp;
                <div>{ iconDiv() }</div>
            </div>
        )
    };

    return team.map((player, index) => {

        // I would think that the player would always have a rating, even if it's zero
        // so this check may be not necessary
        const rankImage = (player.rating) ? getDivision(player.rating).image : getDivision(0).image;

        return (
            <div
                key={index}
                style={{
                    minHeight: '20px',
                    flexGrow: 1,
                    backgroundColor: (index % 2 === 1 ? palette.base3 : palette.base1),
                    fontSize: '14px',
                    marginBottom: '1px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: color(player.colorHex,'light'),
                    width: '100%'
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: '50px',
                            padding: '10px',
                            borderRight: `1px solid ${palette.base5}`,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <img
                            alt="player rank"
                            src={rankImage}
                            style={{ width: '29px' }}
                        />
                    </div>
                    <div style={{alignItems: 'center', display: 'flex', marginLeft: '10px'}}>
                        {player.colorName}
                    </div>
                </div>
                <div style={{justifyContent: 'center', display: 'flex'}}>
                    { readyDiv(player) }
                </div>
            </div>
        );
    });
};

export default Players;