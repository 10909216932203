import styled from 'styled-components';
import palette from '../../styled/Palette';

export const SunburstDiv = styled.div`
    z-index: -1;
    opacity: .1;
    position: absolute;
    overflow: hidden;
    background: #fff;
    b {
        display: block;
        width: 0;
        height: 0;
        border-width: 9vw 125vw;
        margin: -18vw 0 0 0;
        border-color: transparent ${palette.base4};
        border-style: solid;
    }
    b:nth-of-type(1) { transform:rotate(20deg);  transform:rotate(20deg);  }
    b:nth-of-type(2) { transform:rotate(40deg);  transform:rotate(40deg);  }
    b:nth-of-type(3) { transform:rotate(60deg);  transform:rotate(60deg);  }
    b:nth-of-type(4) { transform:rotate(80deg);  transform:rotate(80deg);  }
    b:nth-of-type(5) { transform:rotate(100deg); transform:rotate(100deg); }
    b:nth-of-type(6) { transform:rotate(120deg); transform:rotate(120deg); }
    b:nth-of-type(7) { transform:rotate(140deg); transform:rotate(140deg); }
    b:nth-of-type(8) { transform:rotate(160deg); transform:rotate(160deg); }
    b:nth-of-type(9) { transform:rotate(180deg); transform:rotate(180deg); }
`;

export const OuterDiv = styled.div`
    margin-top: 100vw;
    height: 100vw;
`;
