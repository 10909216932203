import React from 'react';
import { initializeSocketMatch } from '../../redux/middleware/wsActions';
import { toggleUserInput } from "../../data/generic/generic";
import {drawerToggle, updateLoadingStatus} from "../../redux/modules/socket/socketActions";
import {match} from "../modules/match/matchActions";
import { updateLoadingState
} from "../../redux/modules/socket/socketActions";
import {selfAddNewItem, selfAddItem} from "../modules/self/selfActions";
import {settingChatBubble} from "../modules/setting/settingActions";
import {loadingTypes} from "../modules/socket/socketInitialState";

export const createSocket = (socketInfo) => {
    return (dispatch) => {
        dispatch(initializeSocketMatch(socketInfo));
        dispatch(match({
            type: 'MATCH',
            payload: {
                roomId: socketInfo.roomId,
                sessionId: socketInfo.sessionId
            }
        }));
    }
};

/**
 * This function only fires for match observers.
 */
export const observeMatch = (roomId, joinUrl) => {
    return (dispatch) => {
        dispatch(drawerToggle({open: false}));

        dispatch(updateLoadingStatus(loadingTypes.joiningMatch));

        // Transition to the loading screen
        dispatch(updateLoadingState({
            type: 'UPDATE_LOADING_STATE',
            payload: true
        }));
        // Give the overlay a chance to fill before swapping rooms
        setTimeout(() => {
            dispatch(drawerToggle({
                type: 'DRAWER_TOGGLE',
                view: null
            }));
            dispatch(updateLoadingState({
                type: 'UPDATE_LOADING_STATE',
                payload: false
            }));

            dispatch(
                createSocket({
                    joinUrl: joinUrl,
                    roomId: roomId,
                    sessionId: 'spectator',
                })
            );
            toggleUserInput(false);

        }, 1000); //todo: this timeout should be bound to a variable representing the fade time
    }
};

export const itemDrop = (message) => {
    return (dispatch) => {

        message.items.forEach(item => {
            // Only master message 'loginSuccess' updates the has attribute,
            // so here we update the state for the active session.

            // NOTE: Check for category attribute because some items from server appear to be missing this flag
            if (item?.category === 'Chat Bubble') {
                dispatch(settingChatBubble({has: true}));
            }

            // Some items that drop don't go in the inventory, like coins or tickets.
            const itemGoesInInventory = (
                ['unlock','luck','market','mtx'].includes(item?.type)) &&
                ['paint','cap','skin','face','accessory'].includes(item?.category.toLowerCase());

            if (itemGoesInInventory) {
                // Updates the inventory with the item
                dispatch(selfAddItem([item]));
            }
            // Updates the drop array with the item
            dispatch(selfAddNewItem(item));
        });
    }
};