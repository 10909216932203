import {useIcon} from "../../../data/icon";
import styled from 'styled-components';
import React, {useState} from "react";
import {selfIsCaptain} from "../../../data/selectors";
import palette from "../../../styled/Palette";
import {squadRequestLeave} from "../../../redux/modules/squad/squadActions";
import {requestSquadKickPlayer, requestSquadPromoteUser} from "../../../system/endpoints/master";
import {color} from "../../../data/format";

const SquadOptionsDropdownUserUl = styled.ul`
    position: absolute;
    top: 1.8em;
    left: 0;
    width: 100%;
    max-height: 20em;
    border: 1px solid ${palette.midnightBlue};
    background-color: ${palette.base0};
    z-index: 5;
`;
const SquadOptionsDropdownUserLi = styled.li`
    display: flex;
    width: 100%;
    padding: .5em;
`;

const SquadOptionsDropdown = ({squad, self}) => {
    const [dropdownExpanded, setDropdownExpanded] = useState(false);

    return (
        <div style={{position: 'relative'}}>
            <button
                tabIndex={0}
                onClick={() => setDropdownExpanded(!dropdownExpanded)}
                style={{
                    textAlign: 'left',
                    width: '15em',
                    border: `1px solid ${palette.midnightBlue}`,
                    padding: '5px',
                    color: palette.base4,
                    backgroundColor: palette.wetAsphalt
                }}>
                {selfIsCaptain(squad, self)
                    ? <>{useIcon('lead')} Manage Players</>
                    : 'View Players'
                }
            </button>
            {dropdownExpanded &&
                <SquadOptionsDropdownUserUl className="styleDarkScrollSquare">
                {
                    squad.current.map((user) => (
                        <SquadOptionsDropdownUserLi style={{color: color(user.outfit.paint.colorHex,'light')}}>
                            <div style={{flexGrow: 1}}>
                                <div style={{display: 'flex', flexGrow: 1 }}>
                                    {user.name}
                                </div>
                                <div>

                                </div>
                            </div>
                            <>
                                <div>
                                    {user.captain
                                        ? <>{useIcon('lead')}</>
                                        : (
                                            <div
                                                className="buttonZoom"
                                                onClick={() => requestSquadPromoteUser(user.name)}
                                                style={{paddingRight: '.2em'}}
                                            >
                                                {selfIsCaptain(squad, self) && useIcon('promote')}
                                            </div>
                                        )
                                    }
                                </div>
                                {selfIsCaptain(squad, self) &&
                                <div className="buttonZoom" onClick={() => requestSquadKickPlayer(user.name)}>
                                    {useIcon('kill')}
                                </div>
                                }
                            </>
                        </SquadOptionsDropdownUserLi>
                    ))
                }
            </SquadOptionsDropdownUserUl>
            }
        </div>
    );
};

export default SquadOptionsDropdown;