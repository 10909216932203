import {BetsCloseButton, OverlayHeaderDiv} from "./BetsOverlay/BetsOverlayStyle";
import React from "react";
let IconClose = require('react-icons/lib/fa/close');

export const BetHeader = ({setShowOverlay}) => {
    return (
        <OverlayHeaderDiv>
            <div style={{fontFamily: 'Montserrat', marginLeft: '1em', marginTop: '.5em', fontWeight: 'bold'}}>
                WAGE COINS ON MATCH OUTCOME
            </div>
            <BetsCloseButton>
                <IconClose
                    tabIndex={0}
                    onClick={() => setShowOverlay(false)} className="buttonZoom"/>
            </BetsCloseButton>
        </OverlayHeaderDiv>
    )
};
export default BetHeader;