import {createReducer} from "@reduxjs/toolkit";
import socketInitialState from "./slotsInitialState";
import {ItemCategoryTypes} from "../../../system/types";

const slotsReducers = createReducer(socketInitialState, {
    UPDATE_POSSIBLE_ITEMS: (state, action) => {
        // The server does not provide a matching name attribute for coins or tickets
        // This override allows us to target the on-client data store
        state.possibleItems = action.payload.map(item => {
            switch (item.category.toLowerCase()) {
                case ItemCategoryTypes.COINS: {
                    item.name = 'Slime Coin';
                    break;
                }
                case ItemCategoryTypes.TICKETS: {
                    item.name = 'Ticket';
                    break;
                }
            }
            return item;
        });
    },
    UPDATE_FINAL_ITEM: (state, action) => {
        if (action.payload && action.payload.category) action.payload.category = action.payload.category.toLowerCase();
        state.finalItem = action.payload;
    },
});

export default slotsReducers;