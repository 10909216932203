
export const FontTypes = {
    size: {
        HUGE: '4em',
        LARGE: '3em',
        BIG: '2.5em',
        NORMAL: '1.75em',
        FINE: '1.25em',
        SMALL: '1em',
        SMALLER: '.8em',
        SOSMALL: '.6em'
    },
    family: {
        TITLE: 'Modak',
        FUN: 'Indie Flower',
        READABLE: 'Ubuntu',
        OVERHEAD: 'Gochi+Hand',
        A: 'Lobster',
        B: 'Montserrat',
        C: 'Pacifico',
        D: 'Fondamento',
        E: 'Luckiest+guy',
        F: 'VT323',
    }
};