import palette from "../../../styled/Palette";
import Icon from "../../../data/icon";
import styled from 'styled-components';
import {matchUpdateServerMetadata} from "../../../redux/modules/match/matchActions";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import React from "react";
import {playSound} from "../../../data/sound";
import { masterSocket } from "../../../redux/middleware/wsMaster";
import {HighscoresFindUserInput} from "../../../page/Highscores/HighscoresStyle";

export const ModalServerFeedbackDiv = styled.div`
    padding: 1em;
    display: flex;
    justify-content: space-around;
    width: 100%;
`;
export const ModalServerFeedbackButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    svg {
        font-size: 5em;
    }
`;

export let ServerFeedbackData;

const ModalServerFeedback = (dispatch, server) => {
    const feedbackOptions = [
        {
            color: palette.pomegranate,
            icon: Icon('tired'),
        },
        {
            color: palette.carrot,
            icon: Icon('sad-cry'),
        },
        {
            color: palette.sunFlower,
            icon: Icon('meh'),
        },
        {
            color: palette.peterRiver,
            icon: Icon('laugh'),
        },
        {
            color: palette.emerald,
            icon: Icon('grin-hearts'),
        }
    ];
    const handleSubmitFeedback = (index) => {
        // Rate the server
        masterSocket.send(JSON.stringify({
            'event': 'rateServer',
            'region': server.region,
            'alias': server.alias,
            'provider': server.provider,
            'rating': index+1,
        }));
        // Reset the metadata since we have left the server
        dispatch(matchUpdateServerMetadata({
            userRated: true,
            region: null,
            provider: null,
            alias: null,
        }));

        dispatch(modalDisable());
        playSound('groupInviteAccept',1);

        if (index === 0) {
            // Refresh the users server list if they seem to be having a bad time
            masterSocket.send(JSON.stringify({
                'event': 'getAvailableServers',
            }));
        }
    };

    ServerFeedbackData =  (
        <ModalServerFeedbackDiv>
            {
                feedbackOptions.map((option,index) => {
                    return (
                        <ModalServerFeedbackButton
                            tabIndex={0}
                            onClick={() => handleSubmitFeedback(index)}
                            className="buttonZoom modalOption"
                            style={{color: option.color}}
                        >
                            {option.icon}
                        </ModalServerFeedbackButton>
                    )
                })
            }
        </ModalServerFeedbackDiv>
    );

    dispatch(modalEnable({
        title: 'RATE THE MATCH SERVERS PERFORMANCE',
        escapable: true,
        userReject: () => {
            playSound('screenOpen', 1);
            dispatch(matchUpdateServerMetadata({
                region: null,
                provider: null,
                alias: null,
            }));
        },
        body: 'ServerFeedback',
        noOptions: true,
    }));
};

export default ModalServerFeedback;