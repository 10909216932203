import palette from "../../../styled/Palette";
import styled from 'styled-components';
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import React from "react";
import Tippy from "@tippyjs/react";
import Tooltip from "../../../styled/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";
import {
    socketCooldown,
    socketServerRemoveOverride,
    socketUpdateAcceptableServers
} from "../../../redux/modules/socket/socketActions";
import {masterSocket} from "../../../redux/middleware/wsMaster";
import {HighscoresFindUserInput} from "../../../page/Highscores/HighscoresStyle";
import {FONT_NORMAL} from "../../../data/font";
import {useIcon} from "../../../data/icon";

const TableContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const TableHeaderDiv = styled.div`
    display: flex;
    font-size: 1.25em;
    > div {
        background-color: ${palette.belizeHole};
    }
`;
const TableCellDiv = styled.div`
    display: flex;
    margin: 1px;
    width: 10rem;
    padding: .5rem;
    font-size: .8em;
    background-color: ${props => props.override ? palette.wetAsphalt : ''} !important;
`;
const TableRowDiv = styled.div`
    display: flex;
    > div {
        background-color: ${palette.midnightBlue};
    }
`;
const ServerTableRow = ({acceptableServer,serverIndex, dispatch}) => {
    const {serverName, region, provider, latency, acceptable, override} = acceptableServer;
    // override can be either null or bool
    const isOverride = typeof override === 'boolean';
    // If an override is not set, use the default preferred setting
    let isChecked = isOverride ? override : acceptable;

    return (
        <TableRowDiv>
            <TableCellDiv style={{width: '15rem'}}>{region}</TableCellDiv>
            <TableCellDiv style={{width: '15rem'}}>{serverName}</TableCellDiv>
            <TableCellDiv style={{width: '15rem'}}>{provider}</TableCellDiv>
            <TableCellDiv>{latency}ms</TableCellDiv>
            <TableCellDiv>{acceptable ? 'Yes' : 'No'}</TableCellDiv>
            <TableCellDiv
                override={isOverride}
            >
                <div style={{flex: 1}}>
                    <input
                        className={'modalOption'}
                        type='checkBox'
                        checked={isChecked}
                        onChange={() => {
                            dispatch(socketUpdateAcceptableServers(serverIndex));
                        }}
                        style={{transform: 'scale(1.5)', display: 'inline'}}
                    />
                </div>
                {isOverride &&
                <div
                    tabIndex={0}
                    onClick={() => dispatch(socketServerRemoveOverride(serverIndex))} className="buttonZoom">
                    <FontAwesomeIcon style={{fontSize: '1.25em', transform: 'rotate(25deg)'}} icon={['fas', 'thumbtack']} />
                </div>
                }
            </TableCellDiv>
        </TableRowDiv>
    )
};

export let ServerSelectData;
export let ServerSelectOptionData;

const NoneContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    font-size: ${FONT_NORMAL};
    color: ${palette.base4};
`;

const ModalServerSelect = (dispatch,acceptableServers,cooldown) => {
    const anyServerIsEnabled = acceptableServers.map(server => typeof server.override === 'boolean' ? server.override : server.acceptable).includes(true);
    const handleServerRefresh = () => {
        dispatch(socketCooldown('servers'));
        masterSocket.send(JSON.stringify({
            'event': 'getAvailableServers',
        }));
    };

    ServerSelectData = (
        <TableContainerDiv>
            <TableHeaderDiv>
                <TableCellDiv style={{width: '15rem'}}>Region</TableCellDiv>
                <TableCellDiv style={{width: '15rem'}}>Alias</TableCellDiv>
                <TableCellDiv style={{width: '15rem'}}>
                    Provider
                </TableCellDiv>
                <TableCellDiv>Latency</TableCellDiv>
                <TableCellDiv style={{display: 'flex'}}>
                    Preferred
                </TableCellDiv>
                <TableCellDiv>Use Server</TableCellDiv>
            </TableHeaderDiv>
            <div className="styleDarkScrollSquare" style={{maxHeight: '15em'}}>
                {acceptableServers.length > 0 ?
                    acceptableServers.map((acceptableServer,serverIndex) => (
                        <ServerTableRow
                            key={acceptableServer.serverName}
                            acceptableServer={acceptableServer}
                            serverIndex={serverIndex}
                            dispatch={dispatch}
                        />
                    ))
                    :
                    <NoneContainerDiv>All game servers are currently offline</NoneContainerDiv>
                }
            </div>
            <div style={{color: palette.base4, display: 'flex', justifyContent: 'flex-end', margin: '1em', fontSize: '.75em'}}>
                {anyServerIsEnabled ?
                    <>Select the <FontAwesomeIcon style={{margin: '0 .5em'}} icon={['fas', 'thumbtack']} /> to remove a custom setting</>
                    :
                    <>At least one server must be enabled to continue</>
                }

            </div>
        </TableContainerDiv>
    );
    ServerSelectOptionData = (
        <Button
            modalOption={true}
            isDisabled={!cooldown.servers}
            history={history}
            click={() => handleServerRefresh()}
            styles={{width: STANDARD_BUTTON_WIDTH}}
            title={'Ping Servers'}
            icon={<FontAwesomeIcon style={{marginRight: '10px', width: '25px'}} icon={['fas','sync']}/>} />
    );

    dispatch(modalEnable({
        disabled: !anyServerIsEnabled,
        title: 'Game Server Availability',
        body: 'ServerSelect',
        option: 'ServerSelectOption'
    }))
};

export default ModalServerSelect;