import React, {useEffect} from 'react';
import styled from 'styled-components';
import palette from "../../styled/Palette";

export const TextareaArea = styled.textarea`
    border: 2px solid ${palette.peterRiver};
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    color: ${palette.base4};
`;
export const TextareaLimitDiv = styled.div`
    position: absolute;
    bottom: -2em;
    right: .5em;
    color: ${palette.belizeHole};
    font-weight: bold;
`;
const TextareaLimited = ({ rows, cols, limit, setCharacters, placeholder }) => {
  const [content, setContent] = React.useState('');

  useEffect(() => {
      setCharacters(content);
  }, [content]);

  const reachedCharacterLimit = content.length > limit;
  return (
      <div
          style={{position: 'relative', width: '100%', height: '100%'}}>
          <TextareaArea
              tabIndex={0}
              rows={rows}
              cols={cols}
              onChange={event => setContent(event.target.value)}
              placeholder={placeholder}
          />
          <TextareaLimitDiv>
              <span style={{color: reachedCharacterLimit ? palette.pomegranate : palette.peterRiver}}>{content.length}</span> / {limit}
          </TextareaLimitDiv>
      </div>
  );
};
export default TextareaLimited;