import { faCentos } from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import tinycolor from 'tinycolor2';
import { colorContrast } from './data/format';
import { imageAssets } from './data/images';

/**
 * Changes the color of a canvas pixel by pixel
 */
export const changeColorCanvas = (asset, colorRgb, invert) => {
    const canvas = new OffscreenCanvas(asset.width || 360, asset.height || 200);
    let context = canvas.getContext('2d');

    context.drawImage(asset, 0, 0);

    let imageData = context.getImageData(0, 0, asset.width, asset.height);
    let pixelArray = imageData.data;
    let length = pixelArray.length / 4; // 4 containers - red, green, blue and alpha
    for (let i = 0; i < length; i++) {
        let index = 4 * i;
        let r = pixelArray[index];
        let g = pixelArray[++index];
        let b = pixelArray[++index];
        let a = pixelArray[++index];
        if (a != 0) {
            pixelArray[index - 3] = colorRgb.r;
            pixelArray[index - 2] = colorRgb.g;
            pixelArray[index - 1] = colorRgb.b;
        }
    }
    context.putImageData(imageData, 0, 0);

    if (invert) {
        invertCanvas(canvas, context, asset.width, asset.height);
    }

    return canvas;
};

/**
 * Creates a canvas object with the drawing of the colorized player item
 */
export const createPlayerItemImage = (asset, colorRgb, invert) => {
    const canvas = new OffscreenCanvas(asset.outline.width, asset.outline.height);
    let context = canvas.getContext('2d');

    context.drawImage(asset.base, 0, 0);
    let imageData = context.getImageData(0, 0, asset.base.width, asset.base.height);
    let pixelArray = imageData.data;
    let length = pixelArray.length / 4; // 4 containers - red, green, blue and alpha
    for (let i = 0; i < length; i++) {
        let index = 4 * i;
        let r = pixelArray[index];
        let g = pixelArray[++index];
        let b = pixelArray[++index];
        let a = pixelArray[++index];
        if (a != 0) {
            pixelArray[index - 3] = colorRgb.r;
            pixelArray[index - 2] = colorRgb.g;
            pixelArray[index - 1] = colorRgb.b;
        }
    }
    context.putImageData(imageData, 0, 0);
    context.drawImage(asset.outline, 0, 0);

    if (invert) {
        invertCanvas(canvas, context, asset.base.width, asset.base.height);
    }

    return canvas;
};

/**
 * Creates a canvas object with the drawing of the colorized player item
 */
export const createPlayerFaceImages = (faces, invert) => {
    let newFaces = {};
    if (invert) {
        for (let emote in faces) {
            let image = faces[emote];
            const canvas = new OffscreenCanvas(image.width, image.height);
            let context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);
            invertCanvas(canvas, context, image.width, image.height);
            newFaces[emote] = canvas;
        }
    }
    else{
        newFaces = faces;
    }
    return newFaces;
};

/**
 * Inverts the color of the canvas
 */
export const invertCanvas = (canvas, context, width, height) => {
    let imageData = context.getImageData(0, 0, width, height);
    let pixelArray = imageData.data;
    let length = pixelArray.length / 4;
    context.globalAlpha = 1;
    context.clearRect(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < length; i++) {
        let index = 4 * i;
        let r = pixelArray[index];
        let g = pixelArray[++index];
        let b = pixelArray[++index];
        let a = pixelArray[++index];
        if (a != 0) {
            pixelArray[index - 3] = Math.abs(r - 255);
            pixelArray[index - 2] = Math.abs(g - 255);
            pixelArray[index - 1] = Math.abs(b - 255);
        }
    }
    context.putImageData(imageData, 0, 0);
};

/**
 *  Return a canvas with inverted colors
 */
export const invertItemAsset = (asset) => {
	let canvas = new OffscreenCanvas(asset.width, asset.height);
	let height = asset.height;
	let width = asset.width;
	let context = canvas.getContext('2d');
	context.drawImage(asset,0,0);
    let imageData = context.getImageData(0, 0, width, height);
    let pixelArray = imageData.data;
    let length = pixelArray.length / 4;
    context.globalAlpha = 1;
    context.clearRect(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < length; i++) {
        let index = 4 * i;
        let r = pixelArray[index];
        let g = pixelArray[++index];
        let b = pixelArray[++index];
        let a = pixelArray[++index];
        if (a != 0) {
            pixelArray[index - 3] = Math.abs(r - 255);
            pixelArray[index - 2] = Math.abs(g - 255);
            pixelArray[index - 1] = Math.abs(b - 255);
        }
    }
    context.putImageData(imageData, 0, 0);
	return canvas;
};

/**
 * Generate the canvas based on the color and other metadata for a given item.
 */
const generateSlimePortrait = (outfit, individualItem, assets) => {
    if (individualItem) {
        outfit = {
            face: {
                name: 'Default',
                category: 'Face',
            },
            skin: null,
            cap: null,
            paint: null,
            accessory: null,
        };

        if (individualItem.category === 'cap') {
            outfit.cap = individualItem;
        }
        if (individualItem.category === 'skin') {
            outfit.skin = individualItem;
        }
    }

    let baseSlime = (() => {
        let color;

        if (!individualItem) {
            color = tinycolor(outfit.paint.colorHex).toRgb();
        } else {
            color = tinycolor(colorContrast(individualItem.colorHex)).toRgb();
        }

        let baseAsset = assets.Other.Slime.base;
        let baseImage = changeColorCanvas(baseAsset, color);
        const canvas = new OffscreenCanvas(360, 200);
        let context = canvas.getContext('2d');
        context.drawImage(baseImage, 0, 0);
        return canvas.transferToImageBitmap();
    })();
    let skin = (() => {
        let baseImage = null;
        let outline = null;
        if (outfit.skin) {
            let color = tinycolor(outfit.skin.colorHex).toRgb();
            let asset = assets.Skin[outfit.skin.name];
            baseImage = changeColorCanvas(asset.base, color);
            outline = assets.Skin[outfit.skin.name].outline;
            const canvas = new OffscreenCanvas(360, 200);
            let context = canvas.getContext('2d');
            context.drawImage(baseImage, 0, 0);
            context.drawImage(outline, 0, 0);
            return canvas.transferToImageBitmap();
        } else {
            return null;
        }
    })();
    let cap = (() => {
        let baseImage = null;
        let outline = null;
        if (outfit.cap) {
            let color = tinycolor(outfit.cap.colorHex).toRgb();
            let asset = assets.Cap[outfit.cap.name];
            baseImage = changeColorCanvas(asset.base, color);
            outline = asset.outline;
            const canvas = new OffscreenCanvas(asset.outline.width, asset.outline.height);
            let context = canvas.getContext('2d');
            context.drawImage(baseImage, 0, 0);
            context.drawImage(outline, 0, 0);
            return canvas.transferToImageBitmap();
        } else {
            return null;
        }
    })();

    let baseOutlineSlime = assets.Other.Slime.outline;
    let texture = assets.Other.Slime.texture;
    let yOffset = 130; //160
    let xOffset = 100; // 0
    const canvas = new OffscreenCanvas(360 + xOffset, 200 + yOffset);
    let context = canvas.getContext('2d');
    context.drawImage(baseSlime, xOffset / 2, yOffset);
    if (skin) context.drawImage(skin, xOffset / 2, yOffset);
    context.drawImage(texture, xOffset / 2, yOffset);
    context.drawImage(baseOutlineSlime, xOffset / 2 - 2, yOffset - 2);
    // Draw Face
    if (outfit.face) {
        let face = assets.Face[outfit.face.name]['idle'];
        let faceWidth = 155;
        let faceHeight = 155;
        let offset = 0.2;
        context.drawImage(
            face,
            180 - faceWidth / 2 + 360 * offset + xOffset / 2,
            85 - faceHeight / 2 + 20 + yOffset,
            faceWidth,
            faceHeight
        );
    }
    // Draw Cap
    if (cap) {
        let spriteWidth = assets.Cap[outfit.cap.name].outline.width;
        let spriteHeight = assets.Cap[outfit.cap.name].outline.height;
        let spriteYOffset = 1 - assets.Cap[outfit.cap.name].yMargin;
        let spriteXOffset = assets.Cap[outfit.cap.name].xMargin;
        context.drawImage(
            cap,
            180 - spriteWidth / 2 + xOffset / 2 + spriteWidth * spriteXOffset,
            yOffset - spriteHeight + spriteHeight * spriteYOffset,
            spriteWidth,
            spriteHeight
        );
    }

    // Web workers can not transport a canvas so we convert it to a transferable payload

    return canvas.transferToImageBitmap();
};

export default generateSlimePortrait;
