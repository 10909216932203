import palette from "../../styled/Palette";
import matchScope from "../../data/matchScope";

const Cooldown = (lobby, context, team, teamIndex, serverTime, percentageOfFrame, theme) => {
    if (team == null || teamIndex == null) return;
    let me = lobby.teams[team][teamIndex];
    if (me) {
        let activeAbility = me.activeAbility;
        let ability = me.abilities[activeAbility];
        if (ability) {
            context.lineWidth = 4;
            let sizeOffset = me.stats.size * me.scale * 100;
            let capOffset = me.outfit.cap ? 60 : 20;

            let lerpX = ((me.position.x - me.lastPosition.x) * percentageOfFrame) + me.position.x;
            let lerpY = -sizeOffset - capOffset + ((me.position.y - me.lastPosition.y) * percentageOfFrame) + me.position.y;

            // Mutate type to be clear whether if the ability is a consumable
            if (ability.type === 'cooldown' && ability.consumable) {
                ability.type = 'consumable';
            }

            context.textAlign = 'center';
            context.font = '30px Modak';
            context.textBaseline = 'middle';
            context.fillStyle = palette.belizeHole;
            context.strokeStyle = palette.midnightBlue;
            switch (ability.type) {
                case 'mana': {
                    context.beginPath();
                    //context.rect(playerPositionX, playerPositionY, 4, 100);
                    context.fillStyle = palette.peterRiver;
                    const height = 30;
                    const width = 10;
                    const fillVal = Math.min(Math.max(ability.mana / ability.totalMana, 0), 1);
                    const playerCenter = lerpX - (width/2);
                    context.fillRect(playerCenter,lerpY+ (height - (fillVal * height)), width, fillVal * height);
                    context.lineWidth = 1;
                    context.strokeRect(playerCenter,lerpY,width,height);
                    break;
                }
                case 'cooldown': {
                    const timeLeftTilNextUse = Math.ceil((ability.coolDownTime - (serverTime - ability.lastUsed ?? 0)) / 1000);
                    const displayTime = timeLeftTilNextUse;

                    let txt;
                    if (displayTime < 1) {
                        txt = '⋆';
                    } else {
                        txt = displayTime;
                    }
                    context.lineWidth = 7;
                    context.strokeText(txt, lerpX, lerpY);
                    context.lineWidth = 1;
                    context.fillText(txt, lerpX, lerpY);
                    break;
                }
                case 'consumable': {
                    let txt = ability.quantity;
                    // setup these to match your needs
                    context.miterLimit = 2;
                    context.lineJoin = 'circle';
                    // draw an outline, then filled
                    context.lineWidth = 7;
                    context.strokeText(txt, lerpX, lerpY);
                    context.lineWidth = 1;
                    context.fillText(txt, lerpX, lerpY);
                    break;
                }
            }
        }
    }
};

export default Cooldown;
