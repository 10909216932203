import React, {useEffect, useRef, useState} from "react";
import {UserBannerDiv, UserBannerIconDiv, UserBannerNameDiv, UserBannerOptionsDiv} from "../UserBannerStyle";
import Icon from "../../../../data/icon";
import palette from "../../../../styled/Palette";
import {requestRemoveFriend, requestSquadInvite} from "../../../../system/endpoints/master";
import {playSound} from "../../../../data/sound";
import {toggleAllNodes} from "../../../../gamepad/controller";

const IconOffline = require('react-icons/lib/fa/circle-thin');
const IconOnline = require('react-icons/lib/fa/circle');

const getIcon = (player,type) => {
    if (player.roomId) {
        return Icon('sword');
    }
    if (type === 'online') {
        return <IconOnline/>;
    } else {
        return <IconOffline/>;
    }
};
const getColor = (player,type) => {
    if (player.roomId) {
        return palette.nephritis;
    }
    if (type === 'online') {
        return palette.nephritis;
    } else {
        return palette.belizeHole;
    }
};

const FriendUser = ({setWhisper,type,handleObserveMatch,history,selected,setSelected,refBanner,player}) => {
    const [deleting, setDeleting] = useState(false);
    const [options,setOptions] = useState([]);
    const refFirstOption = useRef();
    const refSecondOption = useRef();
    const refThirdOption = useRef();
    const refFourthOption = useRef();
    const refFifthOption = useRef();
    const refSixthOption = useRef();
    const refSeventhOption = useRef();
    const handleSelect = () => {
        // Show the sub-options for the banner
        setSelected(true);
        playSound('itemHover', 1);
    };
    const handleDeselect = () => {
        // Enable the banner as a selectable option
        refBanner.current.setAttribute("tabindex","0");
        // Show the sub-options for the banner
        setSelected(false);
        setDeleting(false);
    };
    const handleDeleting = () => {
        // Show the sub-options for the banner
        setDeleting(true);
    };
    const handleViewProfile = () => {
        history.push({
            pathname: '/feats',
            state: {
                username: player.username,
            }
        });
    };
    const handleRemoveFriend = () => {
        setDeleting(false);
        setSelected(false);
        requestRemoveFriend(player.username);
        toggleAllNodes(true);
    };
    const handleSquadInvite = (username) => {
        requestSquadInvite(username);
    };

    /**
     * Only make the sub-options selectable once a banner is selected
     */
    useEffect(() => {
        if (options.length > 0) {
            toggleAllNodes(false);
            // Make the sub-options selectable
            options.forEach((option,i) => {
                option.setAttribute("tabindex","0");
                if (i === options.length-1) {
                    options[0].focus();
                }
            });
        }
    }, [options]);

    /**
     * Watch the view for changes to update the options focus
     */
    useEffect(() => {
        const temp = [];
        if (refSeventhOption.current) temp.push(refSeventhOption.current);
        if (refThirdOption.current) temp.push(refThirdOption.current);
        if (refFifthOption.current) temp.push(refFifthOption.current);
        if (refSixthOption.current) temp.push(refSixthOption.current);
        if (refSecondOption.current) temp.push(refSecondOption.current);
        if (refFirstOption.current) temp.push(refFirstOption.current);
        if (refFourthOption.current) temp.push(refFourthOption.current);
        setOptions(temp);
    }, [selected,deleting]);

    /**
     * When no longer selecting the banner, permit access to other banners
     */
    useEffect(() => {
        if (!selected) {
            toggleAllNodes(true);
        }
    }, [selected]);

    return (
        <UserBannerDiv
            key={player.username}
            selected={selected}
            ref={refBanner}
            tabIndex={0}
            onClick={() => !selected && handleSelect(refBanner)}
        >
            {!selected ?
            <>
                <UserBannerIconDiv color={getColor(player,type)}>
                    {getIcon(player,type)}
                </UserBannerIconDiv>
                <UserBannerNameDiv>
                    {player.username}
                </UserBannerNameDiv>
            </>
            :
               <>
                   {!deleting ?
                       <UserBannerNameDiv>
                           {player.username}
                       </UserBannerNameDiv>
                       :
                       <div style={{display: 'flex', width: '100%'}}>
                           <div style={{display: 'flex', flexDirection: 'column'}}>
                               <div style={{color: palette.base4}}>Remove Friend?</div>
                               <UserBannerNameDiv>{player.username}</UserBannerNameDiv>
                           </div>
                           <UserBannerOptionsDiv>
                               <div
                                   onClick={() => handleDeselect()}
                                   ref={refSecondOption}
                                   tabIndex={0}
                                   className={'buttonZoom'}
                                   style={{color: palette.pumpkin}}
                               >
                                   {Icon('undo-alt')}
                               </div>
                               <div
                                   onClick={() => handleRemoveFriend()}
                                   tabIndex={0}
                                   ref={refFirstOption}
                                   className={'buttonZoom'}
                                   style={{color: palette.pomegranate, marginRight: '.5em'}}
                               >
                                   {Icon('trash-alt')}
                               </div>
                           </UserBannerOptionsDiv>
                       </div>
                   }
                   {!deleting &&
                   <UserBannerOptionsDiv>
                       {player.roomId &&
                       <div
                           style={{color: palette.nephritis}}
                           onClick={() => handleObserveMatch(player.roomId,player.joinUrl)}
                           className={'buttonZoom'}
                           tabIndex={0}
                           ref={refThirdOption}
                       >
                           {Icon('sword')}
                       </div>
                       }

                       {type === 'online' &&
                           <>
                               <div
                                   style={{color: palette.turquoise}}
                                   onClick={() => handleSquadInvite(player.username)}
                                   className={'buttonZoom'}
                                   tabIndex={0}
                                   ref={refSeventhOption}
                               >
                                   {Icon('user-friends')}
                               </div>
                               <div
                                   ref={refFifthOption}
                                   onClick={() => setWhisper(player.username)}
                                   className={'buttonZoom'}
                                   tabIndex={0}
                                   style={{color: palette.amethyst}}
                               >
                                   {Icon('comments-alt')}
                               </div>
                           </>
                       }
                       <div
                           ref={refSixthOption}
                           onClick={() => handleViewProfile()}
                           className={'buttonZoom'}
                           tabIndex={0}
                           style={{color: palette.pumpkin}}
                       >
                           {Icon('address-card')}
                       </div>
                       <div
                           onClick={() => handleDeleting()}
                           style={{color: palette.pomegranate}}
                           className={'buttonZoom'}
                           tabIndex={0}
                           ref={refFourthOption}
                       >
                           {Icon('skull')}
                       </div>
                   </UserBannerOptionsDiv>
                   }
               </>

            }
        </UserBannerDiv>
    )
};

export default FriendUser;