import React, {useEffect, useState} from 'react';
import loader from "../../../images/load2.svg";
import { playSound } from '../../../data/sound';
import palette from '../../../styled/Palette';
import workerGetPortrait from "../../../webworker/main";
import { imageAssets } from '../../../data/images';
import {profileScreenSlotTypes} from '../../../data/slotTypes';
import Tooltip from "../../../styled/Tooltip";
import Tippy from "@tippyjs/react";
import {useSpring} from "react-spring";
import {
    SlotsMount,
    EquiptSlotDiv,
    SlotIcon
} from '../../Loot/Equipt/EquiptStyle';
import {statsSortAlphabetical} from "../../../data/stats";
import ItemTooltip from "../../../component/Item/ItemTooltip";
import {useIcon} from "../../../data/icon";

const ProfileEquipped = ({profile, category}) => {
    const [capIcon, setCapIcon] = useState(null);
    const [skinIcon, setSkinIcon] = useState(null);

    const config = { tension: 300, friction: 15, duration: 0 };
    const initialStyles = { opacity: 0};
    const [spring, setSpring] = useSpring(() => initialStyles);

    function onHide0({ unmount }) {
        setSpring({
            ...initialStyles,
            onRest: unmount,
            config: { ...config, clamp: true }
        });
    }
    function onMount0() {
        setSpring({
            opacity: .95,
            onRest: () => {},
            config
        });
    }

    useEffect(() => {
        if (profile.outfit.cap) {
            workerGetPortrait(null, profile.outfit.cap).then(dataUrl => {
                setCapIcon(dataUrl);
            })
        }
        if (profile.outfit.skin) {
            workerGetPortrait(null, profile.outfit.skin).then(dataUrl => {
                setSkinIcon(dataUrl);
            })
        }
    }, [profile.outfit.cap, profile.outfit.skin]);

    const getIcon = (type) => {
        return (
            <SlotIcon color={category === type ? palette.base4 : palette.base6 }>
                {useIcon(type,{ fontSize: '2em'},'fal')}
            </SlotIcon>
        )
    };

    return (
        <SlotsMount style={{
            height: '7em',
            padding: '.5em .5em 0 .5em',
            width: '100%',
        }}>
            <div style={{display: 'flex', width: '100%', height: '100%'}}>
                {
                    profileScreenSlotTypes.map((slot) => {
                        const slotType = (slotName) => {
                            switch (slotName) {
                                case 'paint': {
                                    return (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', backgroundColor: profile.outfit.paint.colorHex }}>{getIcon(slotName)}</div>);
                                }
                                case 'cap': {
                                    return (<img style={{ zIndex: '3', verticalAlign: 'center', width: '90%' }} src={capIcon || loader} alt="Default paintbrush illustration" />);
                                }
                                case 'skin': {
                                    return (<img style={{ zIndex: '3', verticalAlign: 'center', width: '90%' }} src={skinIcon || loader} alt="slime illustration" />);
                                }
                                case 'face': {
                                    return (<img style={{transform: 'scale(.25)', zIndex: '3', verticalAlign: 'center' }} src={imageAssets.Face[profile.outfit.face.name].idle.src ? imageAssets.Face[profile.outfit.face.name].idle.src : loader} alt="Default paintbrush illustration" />);
                                }
                                case 'accessory': {
                                    return (<div style={{  transform: 'scale(.6)', zIndex: '3', verticalAlign: 'center', fontSize: '2em', color: 'white'}}><i className="fas fa-comment-alt"> </i></div>);
                                }
                            }
                        };

                        if (profile.outfit[slot]) {
                            return (
                                <Tippy
                                    key={slot}
                                    animation={true}
                                    onMount={onMount0}
                                    onHide={onHide0}
                                    hideOnClick={false}
                                    delay={[50, 0]}
                                    render={attrs => (
                                        <Tooltip
                                            tier={0}
                                            style={spring} {...attrs}>
                                            <ItemTooltip
                                                category={slot}
                                                item={profile.outfit[slot]}
                                                statsOrdered={statsSortAlphabetical(profile.outfit[slot].stats)}
                                            />
                                        </Tooltip>
                                    )}
                                >
                                    <EquiptSlotDiv
                                        tabIndex={0}
                                        key={slot}
                                        onMouseEnter={() => playSound('hoverItem', .2)}
                                    >
                                        { profile.outfit[slot] ? slotType(slot) : getIcon(slot)}
                                    </EquiptSlotDiv>
                                </Tippy>
                            )
                        }
                        return (
                            <EquiptSlotDiv
                                key={slot}
                                currentActive={category === slot}
                                onMouseEnter={() => playSound('hoverItem', .2)}
                            >
                                {getIcon(slot)}
                            </EquiptSlotDiv>
                        )
                    })
                }
            </div>
        </SlotsMount>
    )
};

export default ProfileEquipped;