import styled from 'styled-components';
import palette from '../../../styled/Palette';

export const RatingBarDiv = styled.div`
    font-family: Montserrat;
    display: flex;
    color: black;
    background-color: ${palette.midnightBlue};
    border: 1px solid black;
    flex-grow: 1;
    position: relative;
    font-size: 1em;
    align-items: center;
`;
export const RatingBarFillDiv = styled.div`
    align-self: normal;
    font-family: Montserrat;
    display: flex;
    background-color: ${palette.belizeHole};
    border: 2px solid ${palette.midnightBlue};
    width: ${props => props.width}%;
    border-radius: 4px;
`;
export const RatingBarInfoDiv = styled.div`
    color: ${palette.base4};
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 1em;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
`;