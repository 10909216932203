import React from "react";
import CashItem from "./CashItem";
import {cashShopData} from "../../data/items";
import palette from "../../styled/Palette";
import styled from 'styled-components';
import season from '../../system/season';

const NoItemsStyleDiv = styled.div`
    font-size: 2em;
    color: ${palette.base4};
    font-family: Luckiest Guy;
`;
const NoItemsDiv = () => {
    return (
        <NoItemsStyleDiv>
            No {season.current} seasonal items this year!
        </NoItemsStyleDiv>
    )
};

const CashItems = ({activeTab,setView}) => {
    const loadSeasonItems = () => {
        switch (season.current) {
            case 'autumn': return NoItemsDiv();
            case 'winter': return (
                <>
                    <CashItem
                        click={() => setView(cashShopData.seasonal.snowballPack)}
                        title={cashShopData.seasonal.snowballPack.name}
                        image={cashShopData.seasonal.snowballPack.image}
                        price={cashShopData.seasonal.snowballPack.price}
                        count={cashShopData.seasonal.snowballPack.count}
                    />
                </>
            );
            case 'spring': return NoItemsDiv();
            case 'summer': return NoItemsDiv();
        }
    };

    switch (activeTab) {
        case 'REGULARS': return (
            <>
                <CashItem
                    click={() => setView(cashShopData.regulars.chatBubble)}
                    title={'CHAT BUBBLE'}
                    image={cashShopData.regulars.chatBubble.image}
                    price={cashShopData.regulars.chatBubble.price}
                />
                <CashItem
                    click={() => setView(cashShopData.regulars.ticketPack)}
                    title={'TICKET PACK'}
                    image={cashShopData.regulars.ticketPack.image}
                    price={cashShopData.regulars.ticketPack.price}
                />
                <CashItem
                    click={() => setView(cashShopData.regulars.present)}
                    title={'PRESENT'}
                    image={cashShopData.regulars.present.image}
                    price={cashShopData.regulars.present.price}
                />
            </>
        );
        case 'SEASONAL': return loadSeasonItems();
        case 'POTIONS': return (
            <>
                <CashItem
                    click={() => setView(cashShopData.potions.parcel)}
                    title={'PARCEL'}
                    image={cashShopData.potions.parcel.image}
                    imageSize={'25%'}
                    price={cashShopData.potions.parcel.price}
                    count={cashShopData.potions.parcel.count}
                />
                <CashItem
                    click={() => setView(cashShopData.potions.bundle)}
                    title={'BUNDLE'}
                    image={cashShopData.potions.bundle.image}
                    imageSize={'35%'}
                    price={cashShopData.potions.bundle.price}
                    count={cashShopData.potions.bundle.count}
                />
                <CashItem
                    click={() => setView(cashShopData.potions.collection)}
                    title={'COLLECTION'}
                    image={cashShopData.potions.collection.image}
                    imageSize={'50%'}
                    price={cashShopData.potions.collection.price}
                    count={cashShopData.potions.collection.count}
                />
            </>
        )
    }
};

export default CashItems;
