import tinycolor from "tinycolor2";

export const timer = (timer) => {
    const hours   = Math.floor(timer / 3600);
    const minutes = Math.floor((timer - (hours * 3600)) / 60);
    const seconds = timer - (hours * 3600) - (minutes * 60);

    let display = '';
    if (hours === 0) {
        if (minutes !== 0) display = minutes + ' min';
        else display = seconds + ' sec';
    } else {
        display = hours + ' hour';
    }
    return display;
};

// This one show the whole time
export const timerGeneric = (timer) => {
    let minutes = Math.floor(timer / 60);
    let seconds = (timer - (minutes * 60));
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
};

// Runescape style short-hand
export const currency = (value) => {
    //https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript
    value = parseInt(value,10);
    const valueLength = value.toString().length;
    if (valueLength > 10) {
        return `${value.toString().slice(0,2)}B`
    } else if (valueLength > 9) {
        return `${value.toString().slice(0,1)}B`
    } else if (valueLength > 8) {
        return `${value.toString().slice(0,3)}M`
    } else if (valueLength > 7) {
        return `${value.toString().slice(0,2)}M`
    } else if (valueLength > 6) {
        return `${value.toString().slice(0,1)}M`
    } else if (valueLength === 6) {
        return `${value.toString().slice(0,3)}K`
    } if (valueLength === 5) {
        return `${value.toString().slice(0,2)}K`
    } else {
        return (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').substring(0,(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').length - 3);
    }
};

// *Exclusive of max
export const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
};

// Offset the lightness
export const color = (hex, offset) => {
    let uiColor = tinycolor(hex).toHsl();
    if (offset === 'light') return tinycolor.fromRatio({ h: uiColor.h, s: uiColor.s, l: .7 }).toHexString();
    if (offset === 'dim') return tinycolor.fromRatio({ h: uiColor.h, s: uiColor.s, l: .5 }).toHexString();
};

// Black or white is returned to contrast hex input
export const monoColor = (hex) => {
    let uiColor = tinycolor(hex).toHsl();
    let lightness = uiColor.l;
    if (lightness >= .5) return '#000';
    else return '#FFF';
};

/**
 * Retrieve an offset lightness based on the provided color.
 * If the color is very bright, return a darker color
 * If the color is very dark, return a brighter color
 */
export const colorContrast = (hex) => {
    let color = tinycolor(hex).toHsl();
    let lightness = color.l;
    if (lightness > .5) {
        lightness = lightness - .3;
    } else if (lightness <= .5) {
        lightness = lightness + .3;
    }
    return tinycolor.fromRatio({ h: color.h, s: color.s, l: lightness });
};

// Retrieve an offset saturation
export const colorSaturate = (hex) => {
    if (hex === '#808080') return 'white';
    let color = tinycolor(hex).toHsl();
    let saturation = color.s;
    let lightness = color.l;
    if (saturation > .8) {
        saturation = saturation - .3;
        lightness = lightness - .1;
    } else if (saturation <= .8) {
        saturation = saturation + .3;
        lightness = lightness + .2;
    }
    return tinycolor.fromRatio({ h: color.h, s: saturation, l: lightness }).toHexString();
};


