import React, {useEffect, useRef, useState} from "react";
import {UserBannerDiv, UserBannerIconDiv, UserBannerNameDiv, UserBannerOptionsDiv} from "../UserBannerStyle";
import Icon from "../../../../data/icon";
import palette from "../../../../styled/Palette";
import {requestAcceptFriend, requestRemoveFriend} from "../../../../system/endpoints/master";
import {playSound} from "../../../../data/sound";
import {toggleAllNodes} from "../../../../gamepad/controller";

const InviteReceived = ({player,setSelected,selected,refBanner}) => {
    const [options,setOptions] = useState([]);
    const refFirstOption = useRef();
    const refSecondOption = useRef();
    const handleSelect = (refBanner) => {
        // Disable the banner as a selectable option
        refBanner.current.setAttribute("tabindex","-1");
        // Show the sub-options for the banner
        setSelected(true);
        playSound('itemHover', 1);
    };

    /**
     * Only make the sub-options selectable once a banner is selected
     */
    useEffect(() => {
        if (options.length > 0) {
            toggleAllNodes(false);
            // Make the sub-options selectable
            options.forEach((option,i) => {
                option.setAttribute("tabindex","0");
                if (i === options.length-1) {
                    options[0].focus();
                }
            });
        }
    }, [options]);

    /**
     * Watch the view for changes to update the options focus
     */
    useEffect(() => {
        const temp = [];
        if (refFirstOption.current) temp.push(refFirstOption.current);
        if (refSecondOption.current) temp.push(refSecondOption.current);
        setOptions(temp);
    }, [selected]);

    /**
     * When no longer selecting the banner, permit access to other banners
     */
    useEffect(() => {
        if (!selected) {
            toggleAllNodes(true);
        }
    }, [selected]);

    return (
        <UserBannerDiv
            key={player.username}
            selected={selected}
            ref={refBanner}
            tabIndex={0}
            onClick={() => handleSelect(refBanner)}
        >
            {!selected ?
                    <>
                        <UserBannerIconDiv color={palette.sunFlower}>
                            {Icon('user-plus')}
                        </UserBannerIconDiv>
                        <UserBannerNameDiv>
                            {player.username}
                        </UserBannerNameDiv>
                    </>
                    :
                    <div style={{display: 'flex', width: '100%'}}>
                       <div style={{display: 'flex', flexDirection: 'column'}}>
                           <div style={{color: palette.base4}}>Add Friend?</div>
                           <UserBannerNameDiv>{player.username}</UserBannerNameDiv>
                       </div>
                        <UserBannerOptionsDiv>
                            <div
                                onClick={() => requestAcceptFriend(player.username)}
                                tabIndex={0}
                                ref={refFirstOption}
                                className={'buttonZoom'}
                                style={{color: palette.nephritis}}
                            >
                                {Icon('user-check')}
                            </div>
                            <div
                                ref={refSecondOption}
                                onClick={() => requestRemoveFriend(player.username)}
                                tabIndex={0}
                                className={'buttonZoom'}
                                style={{color: palette.pomegranate}}
                            >
                                {Icon('user-times')}
                            </div>
                        </UserBannerOptionsDiv>
                    </div>
            }
        </UserBannerDiv>
    )
};

export default InviteReceived;