import Proton from 'proton-engine';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';
import { bassGuitar } from '../../data/sound';

export function createMusicParticles(room, team, teamIndex) {
    let playNote = (index) => {
        let note = new Audio(bassGuitar[index]);
        note.play();
    };
    // let playNote = new Audio(bassGuitar[])
    // let musicSound = new Audio(getSound('wind').noise);
    // let dampener = getSound('wind').dampener;
    // let fadeOutTime = 1000;
    let fadeTime = null;
    // musicSound.currentTime = 0;
    // musicSound.volume = 1 * dampener;
    // musicSound.play();

    let musicControls = {
        up: false,
        down: false,
        left: false,
        right: false,
        shift: false,
        1: false,
        2: false,
        3: false,
        4: false,
    };

    let endParticles = () => {
        if (fadeTime === null) {
            fadeTime = Date.now();
            clearInterval(interval);
        }
    };
    let interval = setInterval(() => {
        let player = room.teams[team][teamIndex];
        if (player) {
            let activeAbility = player.activeAbility;
            let ability = player.abilities[activeAbility];
            if (activeAbility === 'music' && ability.active) {
                let playerControls = ability.musicControls;
				let controlNoteMap = {
					shift: 0,
					left: 1,
					down: 2,
					right: 3,
					up: 4,
					1: 5,
					2: 6,
					3: 7,
					4: 8
				}
                for (let control in musicControls) {
                    if (playerControls[control] != musicControls[control]) {
                        musicControls[control] = playerControls[control];
                        if (musicControls[control]) {
                            playNote(controlNoteMap[control]);
							let playerEmitter = new Proton.Emitter();
							playerEmitter.rate = new Proton.Rate(40, updatePerFrame);
							playerEmitter.addInitialize(new Proton.Mass(1));
							playerEmitter.addInitialize(new Proton.Radius(4, 8));
							playerEmitter.addInitialize(
								new Proton.Velocity(new Proton.Span(15, 15), new Proton.Span(-90, 90), 'polar')
							);
							playerEmitter.damping = 0;
							playerEmitter.addBehaviour(new Proton.RandomDrift(5, 0, 0.05));
							playerEmitter.addBehaviour(new Proton.Color('random'));
							playerEmitter.addBehaviour(new Proton.Scale(1));
							playerEmitter.addInitialize(new Proton.Life(.3));
							playerEmitter.addBehaviour(new Proton.Alpha(1, 0));
							let playerAttractionVector = new Proton.Vector(0, 0);
							playerEmitter.emit(updatePerFrame * 2,updatePerFrame + 1.5);
							protonBackground.addEmitter(playerEmitter);
							let player = room.teams[team][teamIndex];
							playerEmitter.p.x = player.position.x ;
							playerEmitter.p.y = player.position.y + (player.stats.size * 100 / 2);
                        }
                    }
                }
            } else {
                endParticles();
            }
        } else {
            endParticles();
        }
    }, 1000 / 60);
}
