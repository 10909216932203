import Button from "../../../styled/Button";
import {socketSignInPhase, updateLoginToast} from "../../../redux/modules/socket/socketActions";
import React, {useEffect, useRef} from "react";
import InputStandard from "../../../styled/Input";
import {isAppropriateUsername, isValidEmail, isValidUsername} from "../../../system/validation";
import {masterSocket} from "../../../redux/middleware/wsMaster";
import {requestOutfitDelete, requestRegister} from "../../../system/endpoints/master";

const FormNewUser = ({dispatch,theme,hideAlert}) => {
    const refEmail = useRef(null);
    const refPassword = useRef(null);
    const refUsername = useRef(null);

    const isValid = () => {
        if (!isValidUsername(refUsername.current.value)) {
            dispatch(updateLoginToast('Username may not contain spaces or special characters and must be at least four characters.'));
            return false;
        }
        if (!isAppropriateUsername(refUsername.current.value.toLowerCase())) {
            dispatch(updateLoginToast('Username Not Permitted'));
            return false;
        }
        if (!refPassword.current.value) {
            dispatch(updateLoginToast('Password Required'));
            return false;
        }
        if (!isValidEmail(refEmail.current.value) || refEmail.current.value.length < 5) {
            dispatch(updateLoginToast('Invalid Email'));
            return false;
        }
        return true;
    };

    const submitForm = () => {
        if (isValid()) {
            requestRegister(refUsername.current.value, refPassword.current.value, refEmail.current.value,);
        }
    };

    const handleKeyPress = (code) => {
        const pressEnter = code === 'Enter';
        if (pressEnter) {
            submitForm();
        }
    };

    // Generic configuration for handling a listener
    useEffect(() => {
        const keystrokeInstance = (e) => handleKeyPress(e.code);
        window.addEventListener('keydown', keystrokeInstance);
        return () => {
            window.removeEventListener('keydown', keystrokeInstance);
        };
    }, []);

    return (
        <>
            <div>
                <InputStandard
                    tabIndex={0}
                    id={'user'}
                    autoComplete="off"
                    ref={refUsername}
                    placeholder="Username"
                />
                <InputStandard
                    tabIndex={0}
                    id={'pass'}
                    autoComplete="off"
                    ref={refPassword}
                    placeholder="Password"
                    type="password"
                />
                <InputStandard
                    tabIndex={0}
                    autoComplete="off"
                    ref={refEmail}
                    placeholder="Email"
                />
            </div>
            <div style={{display: 'flex',marginTop: '2em'}}>
                <Button
                    click={() => submitForm()}
                    title={"Create"}
                    style={{backgroundColor: theme.interfaceColor}}> </Button>
                <li onClick={() => {hideAlert(); dispatch(socketSignInPhase('login'));}}>
                    <Button title={"Back"} type="button" className="buttonNeutral"> </Button>
                </li>
            </div>
        </>
    )
};

export default FormNewUser;