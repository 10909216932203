import {masterSocket} from "../../redux/middleware/wsMaster";
import {getBrowser, getOS} from "../../data/generic/generic";
import {userServerSelection} from "../queue";

const sendMaster = (msg) => {
    masterSocket.send(JSON.stringify(msg));
};

export const requestAcceptFriend = (username) => {
    sendMaster({
        'event': 'acceptFriend',
        'friendName': username
    });
};
export const requestAddFriend = (username) => {
    sendMaster({
        'event': 'friendRequest',
        'username': username,
    });
};
export const requestRemoveFriend = (username) => {
    sendMaster({
        'event': 'removeFriend',
        'friendName': username
    });
};
export const requestSquadInvite = (username) => {
    sendMaster({
        'event': 'squadInvite',
        'username': username,
    });
};

export const requestEmailRecovery = (email) => {
    sendMaster({
        'event': 'forgotCredentials',
        'email': email,
    });
};
export const requestSubmitReport = (type, message, viewport) => {
    sendMaster({
        event: 'issueReport',
        message: JSON.stringify({
            message: message,
            browser: getBrowser(),
            viewport: `${viewport.width}x${viewport.height}`
        }),
        // These properties are required because they have validation rules so we just shove stuff in message now.
        type,
        system: getOS(),
        browser: getBrowser(),
    });
};

export const requestQuickJoinQueue = (squad, acceptableServers) => {
    sendMaster({
        'event': 'joinQueue',
        "options": {
            'volley': {
                '1': squad.current.length <= 1,
                '2': squad.current.length <= 2,
                '3': squad.current.length <= 3,
                '4': squad.current.length <= 4
            },
            'soccer': {
                '1': squad.current.length <= 1,
                '2': squad.current.length <= 2,
                '3': squad.current.length <= 3,
                '4': squad.current.length <= 4
            },
            'hoops': {
                '1': squad.current.length <= 1,
                '2': squad.current.length <= 2,
                '3': squad.current.length <= 3,
                '4': squad.current.length <= 4
            },
        },
        'ranked': false,
        'ability': true,
        'acceptableServers': userServerSelection(acceptableServers)
    });
};
export const requestJoinQueue = (queueSettings, acceptableServers, settings) => {
    const { ranked, ability } = settings;

    sendMaster(  {
        event: "joinQueue",
        options: {
            volley: {
                1: queueSettings.volley1,
                2: queueSettings.volley2,
                3: queueSettings.volley3,
                4: queueSettings.volley4,
            },
            soccer: {
                1: queueSettings.soccer1,
                2: queueSettings.soccer2,
                3: queueSettings.soccer3,
                4: queueSettings.soccer4,
            },
            hoops: {
                1: queueSettings.hoops1,
                2: queueSettings.hoops2,
                3: queueSettings.hoops3,
                4: queueSettings.hoops4,
            },
        },
        ranked: ranked,
        ability: ability,
        acceptableServers: userServerSelection(acceptableServers),
    });
};
export const requestLeaveQueue = () => {
    sendMaster({
        event: "leaveQueue",
    });
};

export const requestSteamPurchase = (selectedCard) => {
    sendMaster({
        'event': 'initializeSteamPurchase',
        'item': selectedCard
    });
};
export const requestSignIn = ({username,password,steamFriendIds}) => {
    sendMaster({
        'event': 'login',
        'username': username,
        'password': password,
        'steamFriendIds': steamFriendIds
    });
};

export const requestBanUser = (username,banType,duration,reason) => {
    sendMaster({
        'event': 'banUser',
        username,
        banType,
        duration,
        reason
    });
};

export const requestEquipItem = (itemId) => {
    sendMaster({
        'event': 'equip',
        'userItemId': itemId,
    });
};
export const requestChangeLoadout = (userItemId,slot) => {
    sendMaster({
        'event': 'loadoutRequest',
        userItemId,
        slot
    });
};

export const requestCashShopItemToken = (itemCamelCaseName) => {
    sendMaster({
        'event': 'getToken',
        sku: itemCamelCaseName
    });
};

export const requestOutfitCreate = (newOutfitName) => {
    sendMaster({
        'event': 'createOutfit',
        name: newOutfitName,
    });
};
export const requestOutfitUpdate = (outfitId) => {
    sendMaster({
        'event': 'switchOutfit',
        outfitId: outfitId,
    });
};
export const requestOutfitDelete = (outfitActiveId) => {
    sendMaster({
        'event': 'deleteOutfit',
        outfitId: outfitActiveId,
    });
};

export const requestRegister = (username,password,email) => {
    sendMaster({
        'event': 'register',
        username,
        password,
        email,
    });
};

// User preferences
export const requestToggleChatBubble = (status) => {
    sendMaster({
        'event': 'toggleChatBubbleRequest',
        status,
    });
};

// Squad system
export const requestSquadPromoteUser = (name) => {
    sendMaster({
        'event': 'passSquad',
        'username': name,
    });
};
export const requestSquadKickPlayer = (name) => {
    sendMaster({
        'event': 'kickSquadPlayer',
        'username': name,
    });
};

export const requestSpinFortuneWheel = () => {
    sendMaster({
        event: 'spinWheel'
    });
};
