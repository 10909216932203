import React from 'react'
import FeedbackMount from "../../../component/SideNav/FeedbackMount";
import {useDispatch, useSelector} from 'react-redux';
import {friendsAlert} from "../../../redux/modules/friends/friendsActions";

export const SearchFeedback = ({friends}) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);

    const resetAlert = () => {
        setTimeout(() => dispatch(friendsAlert({status: null})), 2000);
    };

    if (friends.alert === 'success') {
        resetAlert();
        return <FeedbackMount
            className="animated fadeIn"
            alertValue="Friend invite sent"
        />;
    }
    if (friends.alert === 'fail') {
        resetAlert();
        return <FeedbackMount
            className="animated fadeIn"
            alertValue="Unknown Username"
        />;
    }
    return null;
};

export default SearchFeedback;
