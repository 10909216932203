import { badWords } from '../data/badWords';
import {getCensorLevel} from "../page/Options/CensorToggle";

// When a new message appears, scroll down. Unless you're viewing old messages.
export const stickFloor = () => {
    let chatWindow = document.getElementsByClassName('chatStickFloor')[0];
    if (chatWindow) {
        const bufferScroll = 100;
        const chatScrolledToBottom = chatWindow.scrollHeight - chatWindow.clientHeight <= chatWindow.scrollTop + bufferScroll;
        if (chatScrolledToBottom) {
            setTimeout(() => {
                chatWindow.scrollTop = chatWindow.scrollHeight - chatWindow.clientHeight
                //todo: You can notice the timeout delay, verify if needed
            }, 0);
        }
    }
};

// Used to show the latest chat on load
export const scrollBottom = () => {
    let elem = document.getElementsByClassName('chatStickFloor')[0];
    elem.scrollTop = elem.scrollHeight;
};

// Associate a listener to select and submit the chat input
export const getUserKeystroke = (keyEvent, banned, chatInput) => {
    const keyCode = keyEvent.keyCode;
    const userPressEnter = keyCode === 13;
    const userPressEscape = keyCode === 27;

    if (!banned && userPressEnter) {
        if (chatInput.value === '') {
            chatInput.focus();
        }
    }

    if (banned || userPressEscape) {
        keyEvent.target.value = '';
        chatInput.blur();
    }
};

// Filter the bad words
export const censor = (message) => {
    const level = getCensorLevel();

    // There should never be a missing message attribute but apparently there is sometimes
    if (!message) return '';

    let replacement = message.toUpperCase() === message ? 'SLIME' : 'slime';

    const replace = (word) => {
        message = message.replace(new RegExp(word,"gi"), replacement);
    };

    if (level !== 'disabled') {
        badWords.forEach(word => replace(word));
    }

    return message;

    /*
     Words that were dropped because they limit using other words:
         coon: racoon
     */
};

const formatRemainder = (seconds) => {
    const yearSeconds = 31540000;
    const daySeconds = 86400;
    const hourSeconds = 3600;
    const minuteSeconds = 60;

    if (seconds >= yearSeconds) {
        return `${(seconds/yearSeconds).toFixed(0)} year(s)`;
    }
    if (seconds >= daySeconds) {
        return `${(seconds/daySeconds).toFixed(0)} day(s)`;
    }
    if (seconds >= hourSeconds) {
        return `${(seconds/hourSeconds).toFixed(0)} hour(s)`;
    }
    return `${(seconds/minuteSeconds).toFixed(0)} minute(s)`;
};

// The message is delivered to the moderator user when a user was banned
// todo: It should probably be all mods or all users that can see this
// todo: We should have some sort of log system so we know whats going on
export const banChatMessage = (message) => {
    const seconds = message.duration * 60 * 60;
    const remainder = formatRemainder(seconds);

    return {
        msg: `User ${message.username} has been ${message.banType==='chat'?'chat':'game'} banned for ${remainder}.`,
        context: 'global',
        timestamp: Date.now(),
        ban: true
    };
};

// The message shown in the users chat input box
export const renderChatPlaceholder = (clientAutomatedChatBan,serverManualChatBan,duration) => {
    const remainingTimeSeconds = (duration - Date.now()) / 1000;

    if (serverManualChatBan) {
        return `You are chat banned for ${formatRemainder(remainingTimeSeconds)}.`;
    }
    if (clientAutomatedChatBan) {
        return 'Sometimes it is better to listen than to talk.';
    }
    return 'Say some stuff';
};