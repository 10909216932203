import {monoColor} from "../../data/format";
import React from "react";
import {mapStats, statsSortAlphabetical} from "../../data/stats";
import palette from "../../styled/Palette";


const CardBackBody = ({item, confirmPurchase, tierColor, coins,tierData,isFlipped}) => {
    let statsOrdered = null;
    if (item.stats) {
        statsOrdered = statsSortAlphabetical(item.stats);
    }

    if (confirmPurchase) {
        return (
            <div
                 style={{
                     display: 'flex',
                     justifyContent: 'center',
                     flexDirection: 'column',
                     alignItems: 'center',
                     pointerEvents: 'none',
                     fontSize: '2em',
                     color: item.category === 'paint' ? monoColor(item.colorHex): tierColor,
                     backgroundColor: item.category === 'paint' ? item.colorHex : '',
                     width: '100%', flexGrow: 1, padding: '1em'}}
            >
                <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'center'}}>
                    {coins < item.price && <div className="animated bounceIn">You Can't Afford This</div>}
                    {coins >= item.price && <div className="animated zoomIn" style={{fontWeight: 'bold', fontSize: '20px'}}>Are you sure?</div>}
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div>Coins</div>
                    <div>{coins}</div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div>Cost</div>
                    <div>{item.price}</div>
                </div>
                <div style={{width: '100%', height: '1px', backgroundColor: item.category === 'paint' ? monoColor(item.colorHex):tierColor}}> </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                    <div>{coins - item.price}</div>
                </div>
            </div>
        )
    } else {
        return (
            <div
                style={{display: 'flex', flexGrow: 1, overflowY: 'auto'}}
                className="styleDarkScrollSquare"
            >
               {item.category !== 'paint' &&
               <div
                   style={{width: '100%', margin: '1px 0'}}
               >
                   <ul
                       style={{color: palette.base2, display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}
                   >
                       {item.stats &&
                           Object.keys(statsOrdered).map((stat,index) => {
                               const display = mapStats(stat,'label');
                               return (
                                   <li
                                       key={index}
                                       style={{
                                           display: 'flex',
                                           flexGrow: 1,
                                           width: '100%',
                                           padding: '0 1em',
                                           justifyContent: 'space-between',
                                           alignItems: 'center'
                                       }}
                                   >
                                       <div style={{fontSize: '12px', color: item.stats[stat] > 0 ? palette.base4 : '' }}>
                                           {display}
                                       </div>
                                       <div
                                           style={{color: item.stats[stat] > 0 ? palette.sunFlower : palette.base2 , fontFamily: 'Montserrat' }}>
                                           {item.stats[stat] > 0 ? '+':null}{item.stats[stat]}
                                       </div>
                                   </li>
                               )
                           })
                       }
                   </ul>
               </div>
               }
               {item.category === 'paint' && isFlipped &&
                   <div style={{
                            color: monoColor(item.colorHex),
                            backgroundColor: item.colorHex,
                            flexGrow: 1, alignItems: 'center', justifyContent: 'center', position: 'relative', padding: '5px', margin: '2px'
                        }}
                        className="styleDarkScrollSquare"
                   >
                       <div className={"animated fadeIn "} style={{textAlign: 'left', display: 'flex', flexDirection: 'column'}}>
                           <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                               <span style={{fontFamily: 'Fondamento'}}>{item.adjective} -</span>
                               <span style={{fontSize: '12px'}}> {item.adjectiveDefinition}</span>
                           </div>
                           <div style={{
                                   fontSize: '16px',
                                   fontWeight: 'bold',
                               }}
                           >
                               <span style={{fontFamily: 'Fondamento'}}>{item.color} -</span>
                               <span style={{fontSize: '12px'}}> {item.colorDefinition}</span>
                           </div>
                       </div>
                   </div>
               }
           </div>
        )
    }
};

export default CardBackBody;

/*

                   {item.category !== 'paint' &&
                       <div
                           style={{
                           fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
                           color: tierColor,
                           width: '100%',
                           fontWeight: 'bold',
                            backgroundColor: palette.base6
                       }}
                   >
                       {tierData.name.toUpperCase()}
                   </div>}
 */