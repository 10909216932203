import styled from "styled-components";
import palette from "../../styled/Palette";
import {FONT_BIG, FONT_NORMAL, FONT_SMALLER} from "../../data/font";

export const LeftPanelDiv = styled.div`
    display: flex;
    width: 65em;
    flex-direction: column;
    border: solid ${palette.belizeHole};
    border-width: 0 2px 0 0;
`;

export const FortuneHeaderDiv = styled.div`
    color: ${palette.base4};
    background-color: ${palette.base0}
`;
export const FortuneHeaderItemDiv = styled.ul`
    background-color: ${palette.base5};
    width: 100%;
    font-size: ${FONT_SMALLER};
    text-align: left;
    border-bottom: 1px solid ${palette.belizeHole};
`;

export const FortuneBodyDiv = styled.div`
    flex-grow: 1;
    flex-direction: column;
    display: flex;
`;

export const FortuneFooterDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 7.5em;
    border: solid ${palette.belizeHole};
    border-width: 1px 0 0 0;
`;
export const SpinButton = styled.button`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${palette.base4};
    background-color: ${palette.midnightBlue};
    
    font-size: 1.25em;
    
    &:hover {
        background-color: ${palette.wetAsphalt};
    }
    :disabled {
        background-color: ${palette.base6};
    }
`;

export const CenteredDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`;