import styled from "styled-components";
import palette from "../../../styled/Palette";

export const ScoreboardPositionDiv = styled.div`
        margin-top: 1em;
    display: flex;
    flex-direction: column;
align-items: center;
`;
export const ScoreboardContainerDiv = styled.div`
    display: flex;
    align-items: stretch;
`;
export const ScoreboardCenterDiv = styled.div`
    width: 15em;
    height: 5em;
    display: flex;
    flex-direction: column;
    background-color: ${palette.base1};
`;
export const ScoreboardTimeDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    flex-grow: 1;
    font-weight: bold;
    color: ${palette.base4};
`;
export const ScoreboardScoreDiv = styled.div`
    background-color: ${palette.base3};
    height: 1.25em;
    color: ${palette.base4};
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const ScoreboardTeamItemDiv = styled.div`
    display: flex;
    background-color: ${palette.base1};
    flex-direction: column;
`;
export const ScoreboardTeamItemItemDiv = styled.div`
    flex-grow: 1;
    width: 4em;
    max-width: 4em;
    display: flex;
    justify-content: center;
`;
export const ScoreboardTeamItemContainerDiv = styled.div`
    position: relative;
    background-color: ${palette.base3};
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 4px solid ${palette.base1};
    padding: .5em;
    img {
        ${props => props.cooldown && 'filter: grayscale(1);'}
        width: 90%;
    };
    width: 2em;
    height: 3.75em;
`;
export const ScoreboardTeamItemColorContainerDiv = styled.div`
    height: 1.25em;
    width: 100%;
    background-color: ${palette.base3};
    display: flex;
`;
export const ScoreboardTeamItemColorDiv = styled.div`
    display: flex;
    flex-grow: 1;
    margin: .25em;
    border-radius: 2px;
    background-color: ${props => props.color};
`;
export const ScoreboardObjectiveDiv = styled.div`
    color: ${palette.base4};
    font-size: .75em;
    letter-spacing: .25em;
    margin-bottom: .25em;
`;
export const ScoreboardTeamItemInstanceCooldownDiv = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 3;
        background-color: rgba(0,0,0,.5);
        color: white;
        font-family: Luckiest Guy;
        font-size: 1em;
        width: 100%;
        height: 100%;
        opacity: ${props => props.cooldown ? 1:0};
`;