import React from "react";
import {CircleMountDiv} from "../style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DialoguePresent from "../../../component/Dialogue/type/present";
import DialoguePremium from "../../../component/Dialogue/type/premium";

const DropPremium = ({theme,username,history,handleReturn}) => (
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <div className="animated flipInX" >
            <CircleMountDiv style={{animationDelay: '1.5s'}} className="animationFlip">
                <div style={{zIndex: '3', marginTop: '.25em', verticalAlign: 'center', fontSize: '5em', color: theme.interfaceColorBright }}>
                    <FontAwesomeIcon icon={['fas', 'gem']} />
                </div>
            </CircleMountDiv>
        </div>
        <DialoguePremium
            handleReturn={handleReturn}
            history={history}
        />
    </div>
);

export default DropPremium;