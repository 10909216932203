import React, { useState } from 'react';
import {updateLoginToast} from "../../redux/modules/socket/socketActions";
import {useDispatch, useSelector} from "react-redux";
import FormSignIn from "./Form/FormSignIn";
import FormRecover from "./Form/FormRecover";
import FormReset from "./Form/FormReset";
import FormNewUser from "./Form/FormNewUser";

const SignInForms = () => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const { signInPhase } = useSelector(state => state.socket);
    const [validatedEmail,setValidatedEmail] = useState();

    const hideAlert = () => {
        dispatch(updateLoginToast(null));
    };

    const sharedProps = {
        dispatch,
        theme,
        hideAlert
    };

    switch (signInPhase) {
        case 'login': return <FormSignIn {...sharedProps} />;
        case 'recover': return <FormRecover {...sharedProps} validatedEmail={validatedEmail}/>;
        case 'reset': return <FormReset {...sharedProps} setValidatedEmail={setValidatedEmail}/>;
        case 'newUser': return <FormNewUser {...sharedProps} />;
    }
};

export default SignInForms;