import styled from 'styled-components';
import palette from '../../styled/Palette';
import {WATCH_MATCH_PANEL_SLOT_SIZE} from "../../data/globals";

export const WatchDiv = styled.div`
    
`;

export const MatchDiv = styled.div`
    width: 100%;
    height: ${WATCH_MATCH_PANEL_SLOT_SIZE}px;
    transition: all .25s ease;
    padding: 1em 2em 1em 1em;
    display: flex;
    border: 1px solid ${palette.base3};
    background-color: ${palette.base3};
    &:hover, :focus {
        cursor: pointer;
        background-color: ${palette.wetAsphalt} !important;
        border: 1px solid ${palette.belizeHole};
    }
`;

export const WatchHeaderDiv = styled.div`
    font-weight: bold;
    display: flex;
    text-align: left;
    background-color: ${palette.base0};
    justify-content: space-between;
    padding: .5em;
`;

const tableWidth = 5;
export const WatchHeaderContentDiv = styled.div`
    display: flex;
    padding: 1em 0;
    > div {
        padding: 0 1em;
        width: ${tableWidth}em;
    }
`;

export const WatchMatchInfoDiv = styled.div`
    align-items: center;
    display: flex;
    text-align: left;
    > div {
        padding: 0 1em;
        width: ${tableWidth}em;
   }
`;

export const WatchBodyDiv = styled.div`
    margin: 2em 0 4em 0;
    height: 100%;
    color: ${palette.base4};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    align-items: center;
`;
export const WatchBodyContentDiv = styled.div`
    width: 70em;
    height: 100%;
`;

export const SlimesDiv = styled.div`
    margin-left: 5em;
    flex-basis: 0;
    flex-grow: 1;
   justify-content: space-evenly;
   display: flex;
   align-items: center;
`;

export const SlimeContainerDiv = styled.div`
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    display: flex;
    height: 100%;
`;

export const VersusDiv = styled.div`
  font-family: Pacifico;
  font-size: 24px;
  color: ${palette.base2};
  padding: 0 10px;
`;
