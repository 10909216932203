import {imageAssets} from "../../../../data/images";

const Texture = (context,lerpX,lerpY,lerpAngle,size) => {
    let image = imageAssets.Other.Slime.texture;
    let outline = imageAssets.Other.Slime.outline;
    //let glow = player.glow;
    //alert(player.glow);
    context.translate(lerpX, lerpY);
    context.rotate(lerpAngle);
    //context.drawImage(glow, -364 / 2 * playerWidth / 2, -202 / 2 * playerHeight / 2 - Math.ceil(7 * playerHeight), 364 / 2 * playerWidth, 202 / 2 * playerHeight);

    try {
        //if (player.direction === 'left') {
        //	context.scale(-1, 1);
        //}
        context.drawImage(image, -image.width / 2 * size / 2, -image.height / 2 * size / 2 - Math.ceil(7 * size), image.width / 2 * size, image.height / 2 * size);
        context.drawImage(outline, -364 / 2 * size / 2, -202 / 2 * size / 2 - Math.ceil(7 * size), 364 / 2 * size, 202 / 2 * size);
        //if (player.direction === 'left') {
        //	context.scale(-1, 1);
        //}
    }
    catch (err) {
        console.log(err);
    }
    context.rotate(-lerpAngle);
    context.translate(-lerpX, -lerpY);
};

export default Texture;