import React, {useEffect, useState} from "react";
import {newsItems, NewsTickerDiv, NewsTickerItemDiv, NewsTickerWrapperDiv} from "./NewsTickerStyle";
import {shuffle} from "../../utility";

const NewsTicker = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        setNews(shuffle(newsItems));
    }, []);

    return (
        <NewsTickerWrapperDiv>
            <NewsTickerDiv>
                {news.map((item,index) => (
                    <NewsTickerItemDiv
                        // List is static, index as key is fine here.
                        key={index}
                    >
                        {item}
                    </NewsTickerItemDiv>
                ))}
            </NewsTickerDiv>
        </NewsTickerWrapperDiv>
    )
};

export default NewsTicker;