import { getRandomInt } from '../data/format';
import {masterSocket} from "../redux/middleware/wsMaster";

export const description = (name) => {
    let roll = getRandomInt(2);
    if (name === 'Cat') {
        if (roll === 0) return '^ↀᴥↀ^';
        if (roll === 1) return 'MEOW';
    }
    else if (name === 'Bird') {
        if (roll === 0) return 'CAW CAW';
        if (roll === 1) return 'This slime lives for seeds!';
    }
    else if (name === 'Doggo') {
        if (roll === 0) return "WOOF";
        if (roll === 1) return 'Doge Gang';
    }
    else if (name === 'Serpent') {
        if (roll === 0) return "Probably venomous";
        if (roll === 1) return 'SSSSSSSSSSSSSSSS';
    }
    else if (name === 'Good Boy') {
        if (roll === 0) return "You'd be lucky to see this face christmas morning";
        if (roll === 1) return "HINT - All animal faces are currently acquired through presents";
    }
    else if (name === 'Generic') {
        if (roll === 0) return "Looks firmiliar";
        if (roll === 1) return 'HINT - Am I a casual player? No.';
    }
    else if (name === 'Jerk') {
        if (roll === 0) return "Looks tough because he is";
        if (roll === 1) return 'HINT - You win some, you lose some';
    }
    else if (name === 'Limesqueeze') {
        if (roll === 0) return "It's creation was a mistake";
        if (roll === 1) return 'HINT - Unacceptable!';
    }
    else if (name === 'Cartoon') {
        if (roll === 0) return "The face of the top dog, and he knows it.";
        if (roll === 1) return 'HINT - Its all about the hot hand';
    }
    else if (name === 'Skeletal') {
        if (roll === 0) return "Forget about it, it's not happening";
        if (roll === 1) return 'HINT - Unlock this face or die trying';
    }
    else if (name === 'Mustachio') {
        if (roll === 0) return "Facial hair like this demands respect!";
        if (roll === 1) return 'HINT - Only time will tell';
    }
    else if (name === 'Standard') {
        if (roll === 0) return "The face of an eager slime yearning for greatness.";
        if (roll === 1) return 'HINT - 100 and its yours';
    }
    else if (name === 'Baby') {
        if (roll === 0) return "Where is this slime's mother?";
        if (roll === 1) return 'HINT - Play a game';
    }
    else if (name === 'Vintage') {
        if (roll === 0) return "From the glory days";
        if (roll === 1) return 'HINT - This one is a rare find';
    }
    else if (name === 'Seduction') {
        if (roll === 0) return "The most beautiful slime in the universe, some might say.";
        if (roll === 1) return 'HINT - Let her come to you';
    }
    else if (name === 'Cyclops') {
        if (roll === 0) return "The last of an ancient race of slimes.";
        if (roll === 1) return 'HINT - Some believe this slime is only a myth';
    }
    else if (name === 'Chat Bubble') {
        if (roll === 1) return 'HINT - You can buy this overhead text item from the cash shop.';
    }
};

export const equip = (items, item) => {
    if (item.userItemId) {
        masterSocket.send(JSON.stringify({
            event: 'equip',
            userItemId: item.userItemId,
        }));
    } else {
        // We do this separately because the userItemId exists in the users inventory, not the present payload.
        const index = items.face.findIndex(face => face.name === item.name);
        masterSocket.send(JSON.stringify({
            event: 'equip',
            userItemId: items.face[index].userItemId,
        }));
    }
};