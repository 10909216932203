import {SquadButtonButton, SquadFooterDiv, SquadUserDiv, SquadUsernameDiv} from "./SquadStyle";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {masterSocket} from "../../redux/middleware/wsMaster";
import {squadRequestLeave} from "../../redux/modules/squad/squadActions";
import {useDispatch} from "react-redux";
import HoverToggle from "../../hooks/hoverToggle";
import workerGetPortrait from "../../webworker/main";
import loader from "../../images/load2.svg";
import {selfIsCaptain} from "../../data/selectors";
import {useIcon} from "../../data/icon";

export const SquadButton = ({title, click, small, layoutFlex}) => {
    const iconMap = (title) => {
        switch (title) {
            case 'LEAD': return 'crown';
            case 'PROMOTE': return 'user-crown';
            case 'KICK': return 'skull';
            case 'LEAVE': return 'door-open';
            case 'INVITED': return 'paper-plane';
            case 'ACCEPT': return 'user-check';
            case 'DECLINE': return 'user-times';
        }
    };

    return (
        <SquadButtonButton
            tabIndex={0}
            onClick={click}
            small={small}
            layoutFlex={layoutFlex}
        >
            <div style={{padding: '.25em', fontSize: '1.5em'}}><FontAwesomeIcon icon={['fas', iconMap(title)]} /></div>
            <div>{title}</div>
        </SquadButtonButton>
    )
};

const SquadUser = ({pendingUser, user}) => {
    const [portrait,setPortrait] = useState(null);

    useEffect(() => {
        if (user.outfit) {
            workerGetPortrait(user.outfit).then(dataUrl => {
                setPortrait(dataUrl);
            });
        }
    }, [user.outfit]);

    return (
        <SquadUserDiv>
            <div style={{flexGrow: 1}}> </div>
            <SquadUsernameDiv colorHex={user.outfit ? user.outfit.paint.colorHex : 'white'}>
                {user.captain && <span style={{fontSize: '.5em'}}>{useIcon('lead')}</span>}
                {user.name}
            </SquadUsernameDiv>
            <div style={{opacity: pendingUser ? .25 : 1, zoom: '25%', width: '100%'}}>
                <img src={portrait || loader}/>
            </div>
        </SquadUserDiv>
    )
};

export default SquadUser;