import React from 'react';
import Players from '../../../component/Match/Players';

const Teams = ({teams,gameMode,history}) => {
    return (
        <div style={{borderTop: '1px solid black', display: 'flex', fontSize: '22px', color: '#8B8B8B', textAlign: 'left', justifyContent: 'flex-start'}}>
            {
                [teams.teamA,teams.teamB].map((team,index) => (
                    <div
                        key={index}
                        style={{flexBasis: 0, flexGrow: 1, display: 'flex', flexDirection: 'column', textOverflow: 'ellipsis'}}>
                        <Players
                            history={history}
                            sport={gameMode}
                            team={team}/>
                    </div>
                ))
            }

        </div>
    )
};
export default Teams;
