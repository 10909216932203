import styled from 'styled-components';
import palette from "../../styled/Palette";
import {shadow} from "../../data/globals";

export const ModalDiv = styled.div`
 z-index: 1000;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 position: absolute;
 display: flex;
 justify-content: center;
 align-items: center;
 background-color: rgba(0,0,0,.75);
 backdrop-filter: blur(4px);
`;

export const ModalContentDiv = styled.div`
    margin-top: -150px;
    width: 50em;
    max-height: 40em;
    display: flex;
    flex-direction: column;
    color: white;
    border: 1px solid black;
`;

export const BodyDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    position: relative;
    
    background-color: ${palette.wetAsphalt};
    
    flex-grow: 1;
    text-align: left;
`;

export const TitleDiv = styled.div`
    color: ${palette.base4};
    background-color: ${palette.base7};
    position: relative;
    display: flex;
    padding: 20px;
`;