import React from 'react';
import palette from '../../../styled/Palette';
import {playSound} from "../../../data/sound";

let IconClose = require('react-icons/lib/fa/close');
let IconCheck = require('react-icons/lib/fa/check');

const BetMaker = ({betAffordable,opponentValue,theme,betAmount,makeBet,betsWholeNumbers,updateBetProbability}) => {
    if (betAmount < 1) return null;

    const handleSubmit = () => {
        if (!betAffordable) {
            playSound('playerJoinFailed',1);
            return;
        }

        if (betsWholeNumbers) {
            makeBet();
        } else {
            updateBetProbability();
        }
    };

    return (
        <div
            className="animated fadeIn"
            style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'stretch' }}
        >
            <div style={{display: 'flex', justifyContent: 'space-between', width: '50%', padding: '10px', borderRight: '1px solid #2A2A2A', textAlign: 'left', fontSize: '20px', backgroundColor: palette.base0}}>
                <div style={{display: 'flex', flexDirection: 'column', color: theme.interfaceColor}}>
                    YOU<br/>
                    THEM
                </div>
                <div style={{width: '100%', textAlign: 'right'}}>
                    <div style={{color: theme.interfaceColor}}  className="flexTruncate">{parseFloat(betAmount).toFixed(2)}</div>
                    <div style={{color: theme.interfaceColor}}  className="flexTruncate">{parseFloat(opponentValue).toFixed(2)}</div>
                </div>
            </div>
            <div
                tabIndex={0}
                onClick={() => handleSubmit()}
                className="buttonGeneric"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center', padding: '10px', width: '50%'}}>
                <div>
                    {betsWholeNumbers && betAffordable ?
                        <IconCheck className="animated bounceIn"
                                   style={{color: palette.nephritis, cursor: 'pointer', fontSize: '25px', margin: '0 15px'}} />
                        :
                        <IconClose className="animated bounceIn"
                                   style={{color: palette.pomegranate, cursor: 'pointer', fontSize: '25px', margin: '0 15px'}} />
                    }
                </div>
                {betAffordable &&
                    <div style={{whiteSpace: 'noWrap'}}>
                        {betsWholeNumbers ?
                            'PLACE BET'
                            :
                            'ROUND ODDS'
                        }
                    </div>
                }

            </div>
        </div>
    )
};

export default BetMaker;