import styled from 'styled-components';

export const SignInTitle = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

export const SignInSlime = styled.div`
  color: ${props => props.color};
  font-size: 6em;
  font-family: Modak;
`;

export const SignInLol = styled.div`
  font-size: 3em;
  font-family: Indie Flower;
  margin-left: .25em;
`;