import {createReducer} from "@reduxjs/toolkit";
import queueInitialState from "./queueInitialState";
import {playSound} from "../../../data/sound";

const queueReducers = createReducer(queueInitialState, {
    QUEUE_MATCH: (state, action) => {
        if (action.payload === true) {
            state.wait = Date.now();
            playSound('joinQueue', 1);
        }
        if (action.payload === false) {
            state.wait = 0;
        }
        state.active = action.payload;
    },
    QUEUE_COOLDOWN: (state, action) => {
        state.cooldown = action.payload;
    },

    SET_PREFERENCE_GAME_MODES: (state, action) => {
        const { queueSettings, mode } = action.payload;

        state.preference = {
        ...queueSettings,
            [mode]: !queueSettings[mode]
        };
    },
});

export default queueReducers;