import styled from 'styled-components';
import palette from "../../styled/Palette";

export const ConsoleClosedButton = styled.button`
    position: absolute;
    bottom: .5em;
    right: .5em;
    font-size: 2em;
    color: ${palette.emerald};
    z-index: 99999;
`;

export const ConsoleDiv = styled.div`
    z-index: 99999;
    background-color: white;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    right: 1em;
    bottom: 1em;
    position: absolute;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const ConsoleHeaderDiv = styled.div`
    font-weight: bold;
    background-color: ${palette.midnightBlue};
    padding: .5em;
    border-bottom: 1px solid black;
    display: flex;
    color: white;
`;

export const ConsoleTextAreaDiv = styled.textarea`
    display: flex;
    flex-grow: 1;
    color: white;
`;
export const ConsoleClearButton = styled.button`
    padding: .25em;
    background-color: ${palette.nephritis};
    font-weight: bold;
    font-size: 1.5em;
    flex-grow: 1;
`;

export const ConsoleSendButton = styled.button`
    flex-grow: 1;
    padding: .25em;
    background-color: ${palette.emerald};
    font-weight: bold;
    font-size: 1.5em;
`;
export const ConsoleReceivedDiv = styled.div`
    width: 50%;
    background-color: black;
    color: ${palette.amethyst};
`;