import React, {useEffect, useState} from "react";
import palette from "../../../styled/Palette";
import Item from "../../../component/Item/Item";
import {useSelector} from "react-redux";
import MatchClock from "../../../component/Match/MatchClock";
import {sandbox} from "../../../system/physics/physics.js";
import styled from 'styled-components';
import matchScope from '../../../data/matchScope';
import {
    ScoreboardPositionDiv,
    ScoreboardTeamItemDiv,
    ScoreboardTeamItemItemDiv,
    ScoreboardTeamItemContainerDiv,
    ScoreboardTeamItemColorContainerDiv,
    ScoreboardTeamItemColorDiv,
    ScoreboardObjectiveDiv,
    ScoreboardContainerDiv,
    ScoreboardCenterDiv,
    ScoreboardTimeDiv,
    ScoreboardScoreDiv,
    ScoreboardTeamItemInstanceCooldownDiv
} from './ScoreStyle';
import {getAccessoryByAbility, getAccessoryData} from "../../../data/items";

const ManaContainerDiv = styled.div`
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
`;
const ManaDiv = styled.div`
    background-color: rgba(52,152,219,.5);
    height: ${props => props.remaining}%;
    width: 100%;
    position: absolute;
    z-index: 4;
`;
const ScoreboardTeamItemInstanceMana = ({remaining}) => {
    return (
        <ManaContainerDiv>
            <ManaDiv remaining={remaining} />
        </ManaContainerDiv>
    )
};
const ScoreboardTeamItemInstanceCooldown = ({remainingMS}) => {
    return (
        <ScoreboardTeamItemInstanceCooldownDiv cooldown={remainingMS > 0}>
            {Math.ceil(remainingMS /1000)}
        </ScoreboardTeamItemInstanceCooldownDiv>
    )
};
const ScoreboardTeamItemInstance = ({player,playerAbility,index,color}) => {
    return (
        <ScoreboardTeamItemDiv key={player.username}>
            <ScoreboardTeamItemItemDiv>
                <ScoreboardTeamItemContainerDiv>
                    <Item
                        key={playerAbility.name}
                        item={{
                            type: 'mtx',
                            name: playerAbility.name,
                            category: 'accessory',
                            // Append the client data for the item
                            ...getAccessoryByAbility(playerAbility.name)
                        }}
                        index={index}
                    />
                    {playerAbility.type === 'mana' && <ScoreboardTeamItemInstanceMana remaining={playerAbility.mana/playerAbility.totalMana *100} />}
                    {playerAbility.type === 'cooldown' && <ScoreboardTeamItemInstanceCooldown remainingMS={playerAbility.cooldown - (matchScope.serverTime ?? Date.now())}/>}
                </ScoreboardTeamItemContainerDiv>
            </ScoreboardTeamItemItemDiv>
            <ScoreboardTeamItemColorContainerDiv>
                <ScoreboardTeamItemColorDiv color={color} />
            </ScoreboardTeamItemColorContainerDiv>
        </ScoreboardTeamItemDiv>
    )
};
const ScoreboardTeamItems = ({team, abilities,gameMetadataProvider}) => {
    const teamItems = {teamA:[...abilities['teamA']],teamB:[...abilities['teamB']]};
    const teamSizeDifference = teamItems['teamA'].length - teamItems['teamB'].length;
    if (teamSizeDifference > 0) {
        for (let i=0; i < teamSizeDifference; i++) {
            teamItems['teamB'].push({colorHex: palette.base3,real:false,size:0});
        }
    }
    if (teamSizeDifference < 0) {
        for (let i=0; i < Math.abs(teamSizeDifference); i++) {
            teamItems['teamA'].push({colorHex: palette.base3,real:false,size:0});
        }
    }

    return teamItems[team].map((player,index) => {
        return <ScoreboardTeamItemInstance
            playerAbility={abilities[team][index] ?? {cooldown: 0,name: 'none'}}
            player={player}
            color={gameMetadataProvider.teams[team][index]?.colorHex ?? null}
            index={index}
        />
    });
};
const ScoreboardObjective = () => {
    const { p1Score, p2Score} = useSelector(state => state.match);
    const endGame = (p1Score > 4 && p2Score > 4);
    return <ScoreboardObjectiveDiv>{endGame ? `WIN BY TWO POINTS` : 'FIRST TO SIX POINTS'}</ScoreboardObjectiveDiv>;
};

const parseAbilityMetadata = (player) => {
    const currentAbility = player.abilities[player.activeAbility];

    if (currentAbility?.type === 'mana') {
        // Indicate that user can use the item now
        return {
            ...currentAbility,
            remaining: currentAbility.mana / currentAbility.totalMana
        };
    }
    return {
        ...currentAbility,
        cooldown: currentAbility?.lastUsed + currentAbility?.coolDownTime
    };
};

const Score = ({gameMetadataProvider}) => {
    const { p1Score, p2Score} = useSelector(state => state.match);
    const [abilities,setAbilities] = useState({teamA:[],teamB:[]});
    let pollInterval;

    const parseAbilities = () => {
        let parsed = {teamA: [],teamB: []};
        // Iterate through teams
        Object.keys(gameMetadataProvider.teams).forEach(team => {
            // Iterate through players
            for (let i=0;i<gameMetadataProvider.teams[team].length;i++) {
                // The player iterator
                const player = gameMetadataProvider.teams[team][i];
                // Update the final object with extracted metadata
                parsed[team][i] = parseAbilityMetadata(player);
            }
        });
        // Finally update state
        setAbilities(parsed);
    };

    useEffect(() => {
        clearInterval(pollInterval);
        pollInterval = setInterval(() => {
            parseAbilities();
        }, 1000);
    },[gameMetadataProvider]);

    useEffect(() => {
        return () => {
            clearInterval(pollInterval);
        }
    }, []);

    return (
        <ScoreboardPositionDiv>
            <ScoreboardObjective />
            <ScoreboardContainerDiv>
                <ScoreboardTeamItems
                    gameMetadataProvider={gameMetadataProvider}
                    abilities={abilities}
                    team={'teamA'}
                />
                <ScoreboardCenterDiv>
                    <ScoreboardTimeDiv>
                        <MatchClock />
                    </ScoreboardTimeDiv>
                    <ScoreboardScoreDiv>
                        <div style={{fontSize: '.75em'}}>
                            {`${p1Score} - ${p2Score}`}
                        </div>
                    </ScoreboardScoreDiv>
                </ScoreboardCenterDiv>
                <ScoreboardTeamItems
                    gameMetadataProvider={gameMetadataProvider}
                    abilities={abilities}
                    team={'teamB'}
                />
            </ScoreboardContainerDiv>
        </ScoreboardPositionDiv>
    )
};

export default Score;