import matchScope from "../../data/matchScope";

const Floor = (refCanvas,context) => {
    context.globalAlpha = 1;
    context.clearRect(0, 0, refCanvas.current.width, refCanvas.current.height);
    context.lineWidth = '0';
    context.fillStyle = matchScope.floorColor;
    context.fillRect(0, 897, 1920, 4);
};

export default Floor;