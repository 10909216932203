import SellItem from "./Sell/SellItem";
import LootItems from "./LootItems/LootItems";
import React, {useState} from "react";
import AccessoryLoadout, {determineLoadout} from "./AccessoryLoadout";
import {useSelector} from "react-redux";

const LootScreenRight = ({
    sellWindow,handleToggleSellWindow, setSellWindow,activeItem,
    itemNames,category, itemsFiltered, sellMode, setSellMode, history, setLootColorDemo,filtersOn,filter, setFilter,
 }) => {
    const [accessorySelected,setAccessorySelected] = useState(null);
    const accessories = useSelector(state => state.self.items.accessory);
    const loadout = determineLoadout(accessories);

    return (
        <div style={{flexGrow: 3, flexBasis: 0, display: 'flex', flexDirection: 'column', position: 'relative'}}>
            {sellWindow ?
                <SellItem
                    handleToggleSellWindow={handleToggleSellWindow.bind(this)}
                    category={category}
                    setSellWindow={setSellWindow}
                    interfaceColor={activeItem.colorHex}
                    item={activeItem}
                    setSellMode={setSellMode}
                />
                :
                <>
                    <LootItems
                        filter={filter}
                        setFilter={setFilter.bind(this)}
                        filtersOn={filtersOn}

                        setLootColorDemo={setLootColorDemo}
                        history={history}

                        setSellMode={setSellMode}
                        sellMode={sellMode}

                        sellWindow={sellWindow}
                        category={category}
                        itemNames={itemNames}

                        handleToggleSellWindow={handleToggleSellWindow.bind(this)}
                        setAccessorySelected={setAccessorySelected}

                        activeItem={activeItem}
                        itemsFiltered={itemsFiltered}
                    />
                    <AccessoryLoadout
                        accessorySelected={accessorySelected}
                        setAccessorySelected={setAccessorySelected}
                        category={category}
                        loadout={loadout}
                        loadoutQuantity={4 - loadout.filter(iterator => iterator.empty === true).length}
                    />
                </>
            }
        </div>
    )
};

export default LootScreenRight;