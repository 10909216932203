import { imageAssets } from '../../../data/images';

const Projectiles = (context, projectiles) => {
    projectiles.forEach((projectile) => {
        if (projectile.name === 'snowball') {
            context.fillStyle = '#FFF';
            context.beginPath();
            context.arc(projectile.position.x, projectile.position.y, 20, 0, 2 * Math.PI);
            context.fill();
        } else if (projectile.name === 'grenade') {
            context.beginPath();
            context.strokeStyle = '#fff';
            context.arc(projectile.position.x, projectile.position.y, 300, 0, 2 * Math.PI);
            context.setLineDash([5, 15]);
            context.stroke();
            context.setLineDash([]);
            context.beginPath();
            context.strokeStyle = '#000';
            context.fillStyle = '#666';
            context.arc(projectile.position.x, projectile.position.y, 20, 0, 2 * Math.PI);
            context.fill();
            context.stroke();
        } else if (projectile.name === 'cage') {
            context.lineWidth = projectile.barWidth;
            context.beginPath();
            context.fillStyle = '#fff';
            context.strokeStyle = '#000';
            context.beginPath();
            let topLeftCorner = {
                x: projectile.position.x - projectile.width / 2 - projectile.barWidth,
                y: projectile.position.y - projectile.height / 2,
            };
            let topRightCorner = {
                x: projectile.position.x + projectile.width / 2 + projectile.barWidth,
                y: projectile.position.y - projectile.height / 2,
            };
            let bottomLeftCorner = {
                x: projectile.position.x - projectile.width / 2 - projectile.barWidth,
                y: projectile.position.y + projectile.height / 2,
            };
            let bottomRightCorner = {
                x: projectile.position.x + projectile.width / 2 + projectile.barWidth,
                y: projectile.position.y + projectile.height / 2,
            };
            context.moveTo(topLeftCorner.x, topLeftCorner.y);
            context.lineTo(topRightCorner.x, topRightCorner.y);
            context.moveTo(topLeftCorner.x + projectile.barWidth / 2, topLeftCorner.y);
            context.lineTo(bottomLeftCorner.x + projectile.barWidth / 2, bottomLeftCorner.y);
            context.moveTo(topRightCorner.x - projectile.barWidth / 2, topRightCorner.y);
            context.lineTo(bottomRightCorner.x - projectile.barWidth /2, bottomRightCorner.y);
			for(let i = 1; i < 9; i++){
				context.moveTo(topLeftCorner.x + projectile.barWidth / 2, topLeftCorner.y + i * projectile.height / 8 - projectile.barWidth /2);
				context.lineTo(topRightCorner.x - projectile.barWidth / 2, topLeftCorner.y + i * projectile.height / 8 - projectile.barWidth /2);
			}
            context.fill();
            context.stroke();
        }
    });
};

export default Projectiles;
