import Proton from 'proton-engine';
import { updatePerFrame } from './protonConfig';

let rainParticles = {};
rainParticles.enabled = false;

let emitter = new Proton.Emitter();
emitter.rate = new Proton.Rate(0, 1000);
emitter.addInitialize(new Proton.Mass(1));
emitter.damping = 0;
emitter.addInitialize(new Proton.Radius(2, 1));
emitter.addInitialize(new Proton.Life(8, 16));
emitter.addInitialize(
    new Proton.Velocity(new Proton.Span(-0.1, 0.1), new Proton.Span(2, 4), 'vector')
);
emitter.addBehaviour(new Proton.RandomDrift(2, 1, 0.01));
emitter.addBehaviour(new Proton.Color('00aaff', '00eeff'));
emitter.addBehaviour(new Proton.Scale(1, 0.7));
emitter.addBehaviour(new Proton.Alpha(0.8, 0));
emitter.addInitialize(new Proton.Position(new Proton.RectZone(0, 0, 1920, 0)));
let zone = new Proton.RectZone(0,0,1920,900);
emitter.addBehaviour(new Proton.CrossZone(zone, 'dead'));

//EMITTER
rainParticles.emitter = emitter;
rainParticles.emitter.rate = new Proton.Rate(6, updatePerFrame);

//ENABLE
rainParticles.enable = () => {
    if (!rainParticles.enabled) {
        emitter.emit(); 
        rainParticles.enabled = true;
    }
};

//DISABLE
rainParticles.disable = () => {
    if (rainParticles.enabled) {
        emitter.stop();
        rainParticles.enabled = false;
    }
};

//UPDATE
rainParticles.update = () => {};

export { rainParticles };
