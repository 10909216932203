import Proton from 'proton-engine';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';

export function createAirJumpParticles(lobby, team, teamIndex, radius, color) {
    let player = lobby.teams[team][teamIndex];
    let airJumpEmitter = new Proton.Emitter();
    airJumpEmitter.rate = new Proton.Rate(10, updatePerFrame);
    airJumpEmitter.addInitialize(new Proton.Mass(1));
    airJumpEmitter.addInitialize(new Proton.Radius(5, 17));
    airJumpEmitter.addInitialize(new Proton.Life(0.3));
    airJumpEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    airJumpEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-4, 4), new Proton.Span(3, 6), 'vector')
    );
    airJumpEmitter.addBehaviour(new Proton.Color(color));
    airJumpEmitter.addBehaviour(new Proton.Gravity(-2));
    airJumpEmitter.addBehaviour(new Proton.Scale(.1, 1));
    airJumpEmitter.addBehaviour(new Proton.Alpha(0.7, 0));
    airJumpEmitter.addBehaviour({
        initialize: function (particle) {
            let variation = Math.random() * (radius - 17);
            if (Math.random() > 0.5) variation *= -1;
            particle.p.x += variation;
        },
        applyBehaviour: () => {},
    });
    airJumpEmitter.p.x = player.position.x;
    airJumpEmitter.p.y = player.position.y;
    airJumpEmitter.damping = 0.04;
    airJumpEmitter.emit(0.4, 5);

    protonBackground.addEmitter(airJumpEmitter);
    let interval = setInterval(() => {
        player = lobby.teams[team][teamIndex];
        if (player) {
            let position = player.position;

            airJumpEmitter.p.x = position.x;
            airJumpEmitter.p.y = position.y;
        }
    }, 1000 / 60);
    setTimeout(() => {
        clearInterval(interval);
    }, 430);
}
