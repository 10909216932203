import React, {useEffect, useRef, useState} from 'react';
import CardFront from './CardFront';
import CardBack from './CardBack';
import { getTierData } from '../../data/tier';
import { playSound } from '../../data/sound';
import {colorSaturate, getRandomInt} from '../../data/format';
import {CardContainerDiv} from './CardStyle';
import {PageTypes} from "../../system/types";

const Card = ({item,index,flipped,history,backgroundColor,disabled}) => {
    const [isFlipped,setIsFlipped] = useState(flipped || false);
    const [tierStyle,setTierStyle] = useState(null);
    const [tierData] = useState({
        color: getTierData(item.tier ?? 0).color,
        saturated: colorSaturate(getTierData(item.tier ?? 0).color),
        name: getTierData(item.tier ?? 0).name
    });

    const refCard = useRef(null);

    const handleHover = (hovering) => {
        if (disabled) return null;

        if (hovering) {
            playSound('hoverItem', .2);
            setTierStyle(tierData.saturated);
        } else {
            setTierStyle(tierData.color);
        }
    };
    const handleUserFlip = () => {
        if (disabled) return null;

        if (!item.sold) {
            flip();
        }
    };
    const flip = () => {
        if (disabled) return null;

        if (item.name) {
            const possibleSounds = ['Space_Note1','Space_Note2','Space_Note3','Space_Note4','Space_Note5'];
            const choice = getRandomInt(possibleSounds.length);
            playSound(possibleSounds[choice], .25);

            setIsFlipped(!isFlipped);
        }
    };

    useEffect(() => {
        // Flip the card back over to the front to show the user that the card has already been sold.
        if (item.sold) {
            if (isFlipped) {
                flip();
            }
        }
    }, [item.sold]);

    useEffect(() => {
        setTierStyle(tierData.color);
    }, []);

    return (
        <CardContainerDiv
            //There's an index for the wheel cards, but not for the finalCard
            className={`card ${history && history.location.pathname === PageTypes.SLOTS ? `animated ${index ? 'fadeIn':'zoomIn'}`: ''}`}
            ref={refCard}
        >
            <CardFront
                tierStyle={tierStyle}
                handleUserFlip={handleUserFlip}
                handleHover={handleHover}
                isFlipped={isFlipped}
                disabled={disabled}
                backgroundColor={backgroundColor}
                item={item}
                history={history}
            />
            <CardBack
                tierStyle={tierStyle}
                tierData={tierData}
                handleUserFlip={handleUserFlip}
                isFlipped={isFlipped}
                index={index}
                disabled={disabled}
                backgroundColor={backgroundColor}
                item={item}
                history={history}
            />
        </CardContainerDiv>
    )
};

export default React.memo(Card);