import React, { useState, useEffect, useRef } from 'react';
import {
    RatingBarDiv,
    RatingBarFillDiv,
    RatingBarInfoDiv,
} from './RatingBarStyle';
import { getDivision, getDivisionNeighbor } from '../../../data/rating';
import { playSound } from '../../../data/sound';
import {useSelector} from "react-redux";

// todo: Could add a sequence where the user actually sees the rating bar hit 100 and move on to the next division.

const RatingBar = ({userWon, history}) => {
    const sport = useSelector(state => state.match.sport);
    const rating = useSelector(state => state.self.rating);

    let fill = useRef(0);
    let ratingWasUpdated = useRef(false);

    let division = getDivision(rating.current[sport]);
    let neighbor = getDivisionNeighbor(rating.current[sport]);

    const determineProgress = (rating) => {
        // Do not include rating earned in previous divisions
        const divisonProgress = rating - division.minimum;
        // Determine the difference between two divisions
        const divisionDifference = neighbor.next.minimum - division.minimum;
        // The result is the progress earned in this division divided by the total rating in the division
        let result = (divisonProgress / divisionDifference) * 100;
        // Handle Bounds
        if (result > 100) result = 100;
        if (result < 0) result = 0;
        return result;
    };

    useEffect(() => {
        // This expects rating to update before evaluating, then it fires once.
        // The win screen must mount after the updateRatings event fires
        if (!ratingWasUpdated.current) {
            const ratingPrevious = rating.previous[sport];
            const ratingCurrent = rating.current[sport];
            // We must determine the progress for this divison only
            let userPriorRating = determineProgress(ratingPrevious);
            fill.current = userPriorRating;
            // Determine the user rating after the match
            let userPostRating = determineProgress(ratingCurrent);
            setTimeout((() => {
                if (history.location.pathname === PageTypes.WIN) {
                    // Interpolate to the users new rating position
                    document.getElementById('fill').style.transition = 'all 1s ease-in-out';
                    fill.current = userPostRating;
                    playSound(userWon ? 'RatingBarFillWin':'RatingBarFillLoss', 1);
                }
            }), 2000);
            ratingWasUpdated.current = true;
        }
    }, [rating]);

    let nextRank;
    //if (neighbor.previous) previousRank = `${neighbor.previous.rank} ${neighbor.previous.division}`;
    //if (neighbor.previous.rank === 'potato' && neighbor.next.minimum === 100) previousRank = '';
    if (neighbor.next) {
        nextRank = neighbor.next.minimum === 20000 ? '':`${neighbor.next.rank} ${neighbor.next.division}`;
    }
    return (
        <RatingBarDiv className="animatedSlow fadeIn">
            <RatingBarInfoDiv>
                <div style={{textTransform: 'capitalize', fontSize: '20px'}}>{division.word} {division.rank}</div>
                <div>{nextRank}</div>
            </RatingBarInfoDiv>
            <RatingBarFillDiv id="fill" width={fill.current}/>
        </RatingBarDiv>
    )
};

export default RatingBar;

/*
 console.log('userWon',userWon);
 console.log('userPriorMMR',ratingPrevious);
 console.log('userPostMMR',ratingCurrent);
 console.log('userPriorRating',userPriorRating);
 console.log('userPostRating',userPostRating);
 */
