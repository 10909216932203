import React from 'react';
import BarGraph from "./BarGraph/BarGraph";
import {stats, mapStats} from "../../../data/stats";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import palette from "../../../styled/Palette";
import styled from 'styled-components';
import {
    BarBackgroundDiv
} from "./BarGraph/BarGraphStyle";
import ModalLootStats from "../../../component/Modal/Type/ModalLootStats";
import {HighscoresFindUserInput} from "../../Highscores/HighscoresStyle";
import {useIcon} from "../../../data/icon";

export const StatsIconDiv = styled.div`
        color: ${palette.base2};
        z-index: 1; 
        position: absolute;
        right: .5em;
        top: .5em;
`;
const LootStats = ({focusItem}) => {
    const dispatch = useDispatch();

    return (
        <BarBackgroundDiv style={{backgroundColor: palette.midnightBlue}}>
            <div style={{width: '100%', fontSize: '14px', display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <ul style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    fontWeight: 'bold'}}
                >
                    {
                        stats.map(stat => {
                            return (
                                <BarGraph
                                    focusItem={focusItem}
                                    key={stat}
                                    stat={stat}
                                    label={mapStats(stat,'label')}
                                />
                            )
                        })
                    }
                    <StatsIconDiv
                        tabIndex={0}
                        className="buttonZoom"
                        onClick={() => ModalLootStats(dispatch)}
                    >
                        {useIcon('info')}
                    </StatsIconDiv>
                </ul>
            </div>
        </BarBackgroundDiv>
    )
};

export default LootStats;