import { imageAssets } from "../../../data/images";

const BallLerp = (context, ball, percentageOfFrame, status) => {
  let lerpX =
    (ball.position.x - ball.lastPosition.x) * percentageOfFrame +
    ball.position.x -
    ball.radius;
  let lerpY =
    (ball.position.y - ball.lastPosition.y) * percentageOfFrame +
    ball.position.y -
    ball.radius;
  context.drawImage(
    imageAssets.Other.Ball.base,
    status === "live" ? lerpX : ball.position.x - ball.radius,
    status === "live" ? lerpY : ball.position.y - ball.radius,
    ball.radius * 2,
    ball.radius * 2
  );
};

export default BallLerp;
