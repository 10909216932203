import React, {useEffect,useState,useRef} from 'react';
import { playSound } from '../../data/sound';
import { color } from '../../data/format';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {removeMatchContextMessage} from "../../redux/modules/match/matchActions";

const DISPLAY_DURATION = 3000;
const MatchMessageAlertText = styled.div`
    width: 100%;
    position: absolute;
    color: white;
    font-size: 35px;
    margin-top: 150px;
    z-index: 1;
    display: none;
    pointer-events: none;
`;

const MatchMessageManager = ({teams}) => {
    const dispatch = useDispatch();
    const messageQueue = useSelector(state => state.match.contextMessages);
    const [messageActive,setMessageActive] = useState(false);
    const [currentMessageToDisplay,setCurrentMessageToDisplay] = useState();
    const refAlertText = useRef();

    // Determine the message to display
    const getMessage = (action,data) => {
        const getOvertime = () => {
           playSound('finalCountdown', .25);
           return 'Overtime';
        };
        const getWinnerProspect = (data) => {
           let result = 'MATCH POINT ';
           if (teams.teamA.length > 1) {
               if (data.team === 'teamA') {
                   result += 'LEFT TEAM';
               } else if (data.team === 'teamB') {
                   result += 'RIGHT TEAM';
               }
           } else {
               result += teams[data.team][0].colorName;
           }
           return result;
        };
        const getMatchResult = (data) => {
           if (data.result === 'tie') {
               return 'MATCH DRAW';
           }
           if (teams.teamA.length > 1) {
               let side = data.result === 'teamA' ? 'LEFT' : 'RIGHT';
               return `WINNER ${side} TEAM`;
           } else {
               return `WINNER ${teams[data.result][0].colorName}`;
           }
        };
        const getPoint = (data) => {
           const user = teams[data.team][0];
           if (teams.teamA.length > 1) {
               let side = data.result === 'teamA' ? 'LEFT' : 'RIGHT';
               return `POINT ${side} TEAM`;
           } else {
               return `POINT ${user.colorName}`;
           }
        };

        const messageMap = {
            goalTending: () => 'Goal Tending Not Permitted',
            oneMinute: () => 'One Minute Remains',
            matchStart: () => 'Match Start',
            rematchStart: () => 'Rematch Begin',
            matchPoint: () => getWinnerProspect(data),
            matchEnd: () => getMatchResult(data),
            scorePoint: () => getPoint(data),
            overtime: () => getOvertime(),
        };
        return messageMap[action](data).toUpperCase();
    };
    // Set the message
    const setMessage = () => {
        // A message can be displayed
        if (!messageActive) {
            // There is a message available to display
            if (messageQueue.length > 0) {
                // Flag that we're displaying a message
                setMessageActive(true);
                // Display the message
                setCurrentMessageToDisplay(getMessage(messageQueue[0].action,messageQueue[0].data));
            }
        }
    };

    // Attempts to display the message
    useEffect(() => {
        setMessage();
    }, [messageQueue]);

    // Display the next message
    useEffect(() => {
        if (currentMessageToDisplay) {
            const alertCurrent = refAlertText.current;
            const handleMessageIn = () => {
                playSound('message', 1);
                // Display element
                alertCurrent.style.display = 'block';
                alertCurrent.classList.remove('zoomOut');
                alertCurrent.classList.add('zoomIn');
            };
            const handleMessageOut = () => {
                if (alertCurrent) {
                    alertCurrent.classList.remove('zoomIn');
                    alertCurrent.classList.add('zoomOut');
                }
            };

            handleMessageIn();
            setTimeout(() => {
                // Animate hiding the message
                handleMessageOut();
                // Allow the next message to be displayed
                setMessageActive(false);
                // Drop the just displayed message from the queue
                dispatch(removeMatchContextMessage());
                // Clear the current message so that the same event can fire in a row
                setCurrentMessageToDisplay(null);
            }, DISPLAY_DURATION);
        }
    }, [currentMessageToDisplay]);

    return (
        <MatchMessageAlertText
            ref={refAlertText}
            className="animated fadeIn"
        >
            {currentMessageToDisplay}
        </MatchMessageAlertText>
    )
};

export default MatchMessageManager;


/**
 * Todo: show the one minute message
 if (roomId) {
                if (spectateRooms[roomId]) {
                    let secondsLeft = Math.floor((spectateRooms[roomId].localExpiration - Date.now()) / 1000);
                    if (secondsLeft === 60 && !minuteShown) {
                        setMinuteShown(true);
                        handleTriggerMessage('oneMinute');
                    }
                }
            }
 */