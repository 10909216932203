
import RenderVolley from "../../../system/render/mode/volley";
import RenderSoccer from "../../../system/render/mode/soccer";
import RenderHoops from "../../../system/render/mode/hoops";

const GameMode = (gameMode,context,hoopHeight,hoopSize,teams) => {
    switch (gameMode) {
        case 'volley': RenderVolley(context); break;
        case 'soccer': RenderSoccer(context,teams); break;
        case 'hoops': RenderHoops(context,hoopHeight,hoopSize); break;
    }
};

export default GameMode;