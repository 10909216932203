

/**
 * The store of currently active filters per category
 */
export let storeLootFilters = {
    set update(payload) {
        const { category, key, value } = payload;
        this.filters[category][key] = value;
    },
    filters: {
        'paint': {
            color: '',
            tier: '',
            name: '',
        },
        'skin': {
            color: '',
            tier: '',
            name: ''
        },
        'cap': {
            color: '',
            tier: '',
            name: ''
        },
        'accessory': {
            color: '',
            tier: '',
            name: ''
        },
        'face': {
            // Since there are always items in the list, and there are no filters for faces, we don't need properties.
        },
    }
};