import {imageAssets} from "../../data/images";

const BallTracker = (context,ball) => {
    if (ball.position.y < -15) {
        context.globalAlpha = 1;
        context.fillStyle = '#fff';
        context.beginPath();
        context.moveTo(ball.position.x, 30);
        context.lineTo((ball.position.x + ball.position.y * .1), (-.2 * ball.position.y) + 30);
        context.lineTo((ball.position.x - ball.position.y * .1), (-.2 * ball.position.y) + 30);
        context.fill();
    }
};

export default BallTracker;