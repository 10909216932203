import palette from '../../styled/Palette';
import styled from 'styled-components';

export const ChatDiv = styled.div`
    display: flex;
    flex-basis: 0;  
    flex-grow: 1;
    flex-direction: column;
    position: ${props => props.page === 'match' && 'absolute'};
    width: ${props => props.page === 'match' && '25em'};
    margin: ${props => props.page === 'match' && '0 1em'};
    border-left: ${props => props.page === 'win' && '1px solid black'};
    background-color: ${props => props.page === 'win' && palette.base3};
`;
export const MatchChatHeaderDiv = styled.div`
    align-items: center;
    border-bottom: ${props => props.page !== 'match' && '1px solid black'};
    margin: ${props => props.page === 'match' && '1em 0'};
    height: ${props => props.page !== 'match' && '100px'};
    z-index: 1;
    width: 100%;
    display: flex;
    padding: ${props => props.page !== 'match' && '0 1em'};
`;
export const MatchChatBodyDiv = styled.div`
    height: ${props => props.page === 'match' ? '5.5em' : '350px'};
    max-width: ${props => props.page === 'match' && '25em'};
    padding: ${props => props.page !== 'match' && '1em'};
    word-break: break-word;
    width: 100%;
    flex-grow: 1;
    text-align: left;
    
    ${props => props.page === 'match' && `
        overflow-y: hidden;
        &:hover {
            overflow-y: auto;
        }
    `
    }
`;

export const MatchChatObserversDiv = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.interfaceColorBright};
`;