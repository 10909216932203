import React, {useEffect, useState} from "react";
import Dialogue from "../index";
import {randomItem} from "../../../utility";

const dialogueOptions = [
    'Niceeeee. This is going to be sweet!',
    `What's it gonna be, let us wait and see...`,
    `Please be a legendary set!`,
    'Did mom say you could use the credit card?',
    `Hope it's something slimey!`,
];

const DialoguePresent = ({ history, handleSpin, dialogueVisible }) => {
    const [dialogueIndex, setDialogueIndex] = useState(0);
    const [dialogueButtons,setDialogueButtons] = useState([]);
    const [renderDialogue,setRenderDialogue] = useState(true);
    const [dialogueSequence] = useState(randomItem(dialogueOptions));

    useEffect(() => {
        if (dialogueIndex === [dialogueSequence].length) {
            handleSpin();
        } else {
            // dialogueIndex becomes stale if we don't update this
            setDialogueButtons(
                [
                    {
                        title: 'OKAY',
                        action: () => setDialogueIndex(dialogueIndex + 1 )
                    }
                ]
            )
        }

        // Note: I'm forcing a component rerender here. Not the best, but not the worst
        setRenderDialogue(false);
        setTimeout(() => {
            setRenderDialogue(true);
        }, 0);
    }, [dialogueIndex]);

    return (
        <>
            {renderDialogue &&
                <Dialogue
                    styles={`bottom: -20em;`}
                    history={history}
                    dialogueIndex={dialogueIndex}
                    dialogueSequence={[dialogueSequence]}
                    buttons={dialogueButtons}
                    visible={dialogueVisible}
                />
            }
        </>
    )
};

export default DialoguePresent;