import palette from '../../styled/Palette';
import styled from 'styled-components';

export const DrawerDiv = styled.div`
  flex-shrink: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: ${palette.base1};
  animation-fill-mode: forwards;
  width: 6em;
`;

export const DrawerHeader = styled.div`
    cursor: pointer;
    min-height: 4em;
    
    justify-content: space-between;
    font-weight: bold;
    display: flex;
    padding: 0 10px 0 5px;
    background-color: ${palette.base3};
    
    align-items: center;
    font-size: 14px;
    .coins {
        color: ${palette.sunFlower};
        text-decoration: underline;
    }
`; //border-bottom: 1px solid ${palette.belizeHole};


export const Section = styled.button`
    position: relative;
    padding: 1em;
    font-size: 2em;

    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${palette.base1};
    border-right: 2px solid ${palette.base1};
    color: ${palette.concrete};
    
    svg {
        color: ${palette.concrete};
    }
    span {
        bottom: .5em;
        font-size: .40em;
        position: absolute;
        font-weight: bold;
        opacity: 0
    }
    &:hover span {
        opacity: 1 !important;
    }
    &:hover {
        svg {
            transform: scale(1.3);
        }
        div {
            transform: scale(1.3);
        }
    }
    
    ${props => props.isActive && `
        border-right: 2px solid ${props.interfaceColor} !important;
        background-color: ${palette.base0};
        span {
            opacity: 1 !important;
            color: ${props.interfaceColor};
        }
        svg {
            color: ${props.interfaceColor};
            transform: scale(1.3);
        }
    `};
`;


export const Notification = styled.div`
    position: absolute;
    margin-left: 1em;
    margin-top: .75em;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid ${palette.base1};
    background-color: ${palette.base3};
    
    border-radius: 4px;
    width: 25px;
    height: 25px;
    font-size: 12px;
    font-weight: bold;
    
    color: ${props => props.interfaceColor};
`;