import Spinner from "../Spinner";
import {QueueButtonButton} from "../../page/GameSelect/GameSelectStyle";
import React, {useEffect, useRef} from "react";

const QueueButton = ({inQueue,leader,modeIsSelected,queueFunc,queue}) => {
    const refQueueButton = useRef();

    useEffect(() => {
        setTimeout(() => {
            // The slideInRight animation needs to complete before we execute any operations because it hangs the page and breaks the transition.
            refQueueButton.current.focus();
        }, 250);
    },[]);

    const enabled = modeIsSelected && leader && queue.cooldown === 0;

    return (
        <div style={{display: 'flex', height: '100%', alignItems: 'center'}}>
            <div style={{marginRight: '1em', width: '3em', marginLeft: '1em'}}>
                <Spinner
                    size='40px'
                    display={inQueue}
                />
            </div>
            <QueueButtonButton
                ref={refQueueButton}
                tabIndex={0}
                className={enabled ? '' : 'disabled'}
                enabled={enabled}
                onClick={() => queueFunc()}
            >
                {inQueue ?
                    'SEARCHING'
                    :
                    <>{leader ? 'FIND MATCH' : 'LEAD ONLY'}</>
                }
            </QueueButtonButton>
        </div>
    )
};

export default QueueButton;