import React from 'react';
import ItemSlot from "../../component/Item/ItemSlot";
import {itemModalEnable} from "../../redux/modules/itemModal/itemModalActions";
import {useDispatch} from "react-redux";
import {
    ItemDetailDiv,
    ItemDetailMountDiv,
    ItemDetailDescriptionDiv,
    ItemDetailTitleDiv,
    ItemDetailTextDiv,
    ItemDetailContentDiv,
    ItemDetailSeasonDiv
} from './ItemDetailStyle';

const remapForStyle = {
    small: 10,
    medium: 20,
    large: 30
};

export const ItemDetail = ({ size, item, history, slotMargin, index }) => {
    const dispatch = useDispatch();
    return (
        <ItemDetailDiv
            size={remapForStyle[size]}
        >
            <ItemDetailMountDiv
                size={remapForStyle[size]}
            >
                <ItemSlot
                    index={index}
                    slotMargin={slotMargin}
                    click={() => dispatch(itemModalEnable(item))}
                    location={history.location.pathname}
                    item={item}
                />
            </ItemDetailMountDiv>
            <ItemDetailTextDiv>
                <ItemDetailContentDiv>
                    <ItemDetailTitleDiv size={size}>{item.name.toUpperCase()}</ItemDetailTitleDiv>
                    {size !== 'small' && item.season && <ItemDetailSeasonDiv>{item.season}</ItemDetailSeasonDiv>}
                    <ItemDetailDescriptionDiv>{item.description}</ItemDetailDescriptionDiv>
                </ItemDetailContentDiv>
            </ItemDetailTextDiv>
        </ItemDetailDiv>
    )
};