import styled from "styled-components";
import palette from "../../styled/Palette";

export const SteamUserPortraitDiv = styled.div`
    width: 4em;
    height: 4em;
    flex-shrink: 0;
    border-radius: 2px;
`;

export const SteamUserNamesDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 1em;
    text-align: left;
    height: 100%;
    
    min-width: 0;
    font-size: .75em;
    font-weight: bold;
`;

export const SteamUserColorNameDiv = styled.div`
    color: ${palette.base4};
    display: flex;
    flex-grow: 1;
    background: rgb(52,52,52);
    background: linear-gradient(90deg, rgba(52,52,52,1) 0%, rgba(64,64,64,1) 100%);
    border-radius: 2px;
    align-items: center;
    padding: 0 .5em;
`;

export const SteamUserNameDiv = styled.div`
    color: ${palette.base4};
    display: flex;
    font-weight: bold;
    font-size: 22px;
`;

