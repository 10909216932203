import React, {useEffect, useRef, useState} from "react";
import {UserBannerDiv, UserBannerIconDiv, UserBannerNameDiv, UserBannerOptionsDiv} from "../UserBannerStyle";
import Icon, {useIcon} from "../../../../data/icon";
import styled from 'styled-components';
import palette from "../../../../styled/Palette";
import {playSound} from "../../../../data/sound";
import {getDivision} from "../../../../data/rating";
import {gamepad, processed, toggleAllNodes} from "../../../../gamepad/controller";
import {requestAddFriend} from "../../../../system/endpoints/master";
import {modalEnable} from "../../../../redux/modules/modal/modalActions";
import {useDispatch, useSelector} from "react-redux";
import ModalBanUser from "../../../../component/Modal/Type/ModalBanUser";
import {PageTypes} from "../../../../system/types";

export const BestModeDiv = styled.div`
  position: absolute;
  font-size: 100px;
  top: -.5em;
  right: -.75em;
  opacity: .1;
  color: ${props => props.color};
`;
const PublicUser = ({setWhisper,handleObserveMatch,history,selected, setSelected,refBanner,player}) => {
    const dispatch = useDispatch();
    const self = useSelector(state => state.self);

    const [options,setOptions] = useState([]);
    const refFirstOption = useRef();
    const refSecondOption = useRef();
    const refThirdOption = useRef();
    const refFourthOption = useRef();
    const refFifthOption = useRef();

    const handleSelect = () => {
        // Disable the banner as a selectable option
        refBanner.current.setAttribute("tabindex","-1");
        // Show the sub-options for the banner
        setSelected(!selected);
        playSound('itemHover', 1);
    };
    const handleViewProfile = () => {
        history.push({
            pathname: PageTypes.FEATS,
            state: {
                username: player.username,
            }
        });
    };
    const handleAddFriend = () => {
        requestAddFriend(player.username);
    };

    /**
     * Only make the sub-options selectable once a banner is selected
     */
    useEffect(() => {
        if (options.length > 0) {
            toggleAllNodes(false);
            // Make the sub-options selectable
            options.forEach((option,i) => {
                option.setAttribute("tabindex","0");
                if (i === options.length-1) {
                    options[0].focus();
                }
            });
        }
    }, [options]);

    /**
     * Watch the view for changes to update the options focus
     */
    useEffect(() => {
        const temp = [];
        if (refFirstOption.current) temp.push(refFirstOption.current);
        if (refSecondOption.current) temp.push(refSecondOption.current);
        if (refThirdOption.current) temp.push(refThirdOption.current);
        if (refFourthOption.current) temp.push(refFourthOption.current);
        if (refFifthOption.current) temp.push(refFifthOption.current);
        setOptions(temp);
    }, [selected]);

    /**
     * When no longer selecting the banner, permit access to other banners
     */
    useEffect(() => {
        if (!selected) {
            toggleAllNodes(true);
        }
    }, [selected]);

    return (
        <UserBannerDiv
            key={player.username}
            selected={selected}
            tabIndex={0}
            ref={refBanner}
            onClick={() => handleSelect()}
        >
            <BestModeDiv color={player.colorHex.light}>
                {player.bestRank.sport === 'hoops' && Icon('basketball-ball')}
                {player.bestRank.sport === 'soccer' && Icon('futbol')}
                {player.bestRank.sport === 'volley' && Icon('volleyball-ball')}
            </BestModeDiv>
            {!selected ?
                <>
                    {player.roomId &&
                    <UserBannerIconDiv color={palette.nephritis}>
                        {Icon('sword')}
                    </UserBannerIconDiv>
                    }
                    {player.mod > 0 &&
                    <UserBannerIconDiv color={palette.sunFlower}>
                        {Icon('ankh')}
                    </UserBannerIconDiv>
                    }
                    <UserBannerNameDiv
                        style={{marginLeft: !player.roomId?'.5em':0}}
                        color={player.colorHex.light}>
                        {player.username}
                    </UserBannerNameDiv>
                    <div style={{justifyContent: 'flex-end',alignItems: 'center',display: 'flex', flexGrow: 1}}>
                        <div style={{marginRight: '5px', color: player.colorHex.light, fontWeight: 'bold'}}>
                            {player.bestRank.rating}
                        </div>
                        <div>
                            <img
                                src={getDivision(player.bestRank.rating).image}
                                alt="player rank"
                                style={{ width: '40px' }}
                            />
                        </div>
                    </div>
                </>
                :
               <>
                   <UserBannerNameDiv color={player.colorHex.light}>
                       {player.username}
                   </UserBannerNameDiv>
                   <UserBannerOptionsDiv
                       style={{display: 'flex', flexGrow: 1, justifyContent: 'flex-end'}}
                   >
                       {
                           player.username === self.username ?
                               <div>
                                   You
                               </div>
                               :
                               <>
                                   {player.roomId &&
                                   <div
                                       ref={refFirstOption}
                                       onClick={() => handleObserveMatch(player.roomId,player.joinUrl)}
                                       style={{color: player.colorHex.light}}
                                       className={'buttonZoom'}
                                       tabIndex={0}
                                   >
                                       {Icon('sword')}
                                   </div>
                                   }
                                   {self.mod > 0 && !player.mod &&
                                   <div
                                       ref={refSecondOption}
                                       onClick={() => ModalBanUser(dispatch,player.username)}
                                       style={{color: player.colorHex.light}}
                                       className={'buttonZoom'}
                                       tabIndex={0}
                                   >
                                       {useIcon('ban')}
                                   </div>
                                   }
                                   <div
                                       ref={refThirdOption}
                                       onClick={() => setWhisper(player.username)}
                                       style={{color: player.colorHex.light}}
                                       className={'buttonZoom'}
                                       tabIndex={0}
                                   >
                                       {Icon('comments-alt')}
                                   </div>
                                   <div
                                       ref={refFourthOption}
                                       onClick={() => handleViewProfile()}
                                       style={{color: player.colorHex.light}}
                                       className={'buttonZoom'}
                                       tabIndex={0}
                                   >
                                       {Icon('address-card')}
                                   </div>
                                   <div
                                       ref={refFifthOption}
                                       onClick={() => handleAddFriend()}
                                       style={{color: player.colorHex.light}}
                                       className={'buttonZoom'}
                                       tabIndex={0}
                                   >
                                       {Icon('user-plus')}
                                   </div>
                               </>
                       }
                   </UserBannerOptionsDiv>
               </>
            }
        </UserBannerDiv>
    )
};

export default PublicUser;

/*
todo: Hide this element if the public user is already a friend
 */