import React from "react";
import styled from "styled-components";
import palette from "../styled/Palette";
import {FONT_FINE} from "../data/font";
import SlimeLogo from "../component/SlimeLogo";

const RedirectDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 3em;
    background-color: ${palette.peterRiver};
    color: ${palette.wetAsphalt};
    font-size: ${FONT_FINE};
`;

const MobileRedirect = () => {
    return (
        <RedirectDiv>
            <SlimeLogo />
            <div>can only be played on desktop</div>
        </RedirectDiv>
    )
};

export default MobileRedirect;