import React, {useEffect, useState} from "react";
import Dialogue from "../index";
import {PageTypes} from "../../../system/types";

const dialogueSequence = [
    "Well hello there!!!",
    "Can I show you a thing or two?",
    "Use A, or the <- arrow key to move left",
    "Use D, or the -> arrow key to move right",
    "Use S or the down arrow key to move down",
    "Use W, Spacebar, or the ^ arrow key to jump",
    "Use Shift, to perform a power-shot while touching the ball",
    "Use keys one, two, three, and four to share emotions",
    `Thems the basics!`
];
const DialogueTutorial = ({history}) => {
    const [dialogueVisible] = useState(true);
    const [dialogueIndex, setDialogueIndex] = useState(0);
    const [dialogueButtons,setDialogueButtons] = useState([
        {
            title: 'OKAY',
            action: () => setDialogueIndex(dialogueIndex + 1)
        }
    ]);
    const [renderDialogue,setRenderDialogue] = useState(true);

    useEffect(() => {
        if (dialogueIndex === dialogueSequence.length) {
            history.push(PageTypes.MAIN);
        } else {
            // dialogueIndex becomes stale if we don't update this
            setDialogueButtons(
                [
                    {
                        title: 'OKAY',
                        action: () => setDialogueIndex(dialogueIndex + 1 )
                    }
                ]
            )
        }

        // Note: I'm forcing a component rerender here. Not the best, but not the worst
        setRenderDialogue(false);
        setTimeout(() => {
            setRenderDialogue(true);
        }, 0);
    }, [dialogueIndex]);

    return (
        <>
            {renderDialogue &&
                <Dialogue
                    closable
                    bottom={15}
                    history={history}
                    dialogueIndex={dialogueIndex}
                    dialogueSequence={dialogueSequence}
                    buttons={dialogueButtons}
                    visible={dialogueVisible}
                />
            }
        </>
    )
};

export default DialogueTutorial;