import React, {useEffect, useRef, useState} from 'react';
import palette from '../../../styled/Palette';
import {useDispatch, useSelector} from "react-redux";
import Icon from "../../../data/icon";
import {requestAddFriend} from "../../../system/endpoints/master";

const Search = () => {
    const dispatch = useDispatch();
    const friends = useSelector(state => state.friends);

    const refFriendInput = useRef();
    const [friendUsername, setFriendValue] = useState('');
    const [characters, setCharacters] = useState(0);

    const clickRequest = () => {
        setCharacters(0);
        refFriendInput.current.blur();
        refFriendInput.current.value = '';
        requestAddFriend(friendUsername);
    };

    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <input
                tabIndex={0}
                ref={refFriendInput}
                placeholder="Invite by username"
                onChange={e => {
                        setFriendValue(e.target.value);
                        setCharacters(e.target.value.length);
                }}
                autoComplete="off"
                style={{backgroundColor: palette.wetAsphalt, padding: '20px 0 20px 20px', width: '100%'}}
            />
            {characters > 2 &&
                <div
                    onClick={clickRequest}
                    style={{backgroundColor: palette.wetAsphalt, height: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                    <div
                        className="animatedFast zoomIn"
                        style={{color: palette.emerald, fontSize: '25px', margin: '0 .5em'}}
                    >
                        {Icon('check-square')}
                    </div>
                </div>
            }
        </div>
    )
};

export default Search;
