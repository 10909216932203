import styled from 'styled-components';
import React from "react";

export const PresentContainerDiv = styled.div`
    width: 8em;
    height: 8em;
    margin-top: -5em;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.presentImage});
    font-size: 2em;
`;

export const PresentSmallContainerDiv = styled.div`
    margin: 0 30px;
    align-self: flex-end;
    
    width: 150px;
    height: 150px;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.presentImage});
`;
export const PresentSpinPhaseDiv = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;