import {mapStats} from "../../data/stats";
import React, {useMemo} from "react";
import styled from 'styled-components';
import palette from "../../styled/Palette";
import {getTierData} from "../../data/tier";
import {color} from "../../data/format";

export const ItemTooltipDiv = styled.div`
    padding: 1em;
    display: flex;
    flex-direction: column;
    text-align: left;
`;
export const ItemTextSpan = styled.span`
    font-weight: bold;
    margin-right: 1em;
`;

const RenderItemName = ({category,item}) => {
    switch (category) {
        case 'paint':
            return (
                <div style={{fontSize: '.7em', borderRadius: '2px', margin: '0 .5em', color: palette.clouds}}>
                    <div>This item will change your name to <ItemTextSpan>{item.adjective} {item.color}</ItemTextSpan></div>
                    <div style={{marginTop: '1em', color: color(item.colorHex, 'light')}}>
                        <ItemTextSpan>{item.adjective}</ItemTextSpan> {item.adjectiveDefinition}<br/>
                        <ItemTextSpan>{item.color}</ItemTextSpan> {item.colorDefinition}
                    </div>
                </div>
            );
        case 'accessory':
            return <div style={{fontWeight: 'bold', marginBottom: '.5em'}}>{item.name}</div>;
        case 'face':
            return <div style={{fontWeight: 'bold', marginBottom: '.5em'}}>{item.locked ? '???' : item.name}</div>;
        case 'skin':
        case 'cap':
            return (
                <div style={{display: 'flex'}}>
                    <div style={{fontWeight: 'bold', marginBottom: '.5em'}}>{item.name}</div>
                    <div style={{borderRadius: '2px', height: '1em', width: '1em', margin: '0 .5em', backgroundColor: item.colorHex}}> </div>
                </div>
            );
    }
};
const RenderItemTier = ({category,item}) => {
    switch (category) {
        case 'skin':
        case 'cap':
            //todo: still getting data of tier 5
            if (item.tier === 5) return;
            const tierData = getTierData(item.tier);
            return (
                <div style={{
                    fontSize: '.75em',
                    fontWeight: 'bold',
                    marginBottom: '.5em',
                    color: tierData.color
                }}>{tierData.name.toUpperCase()}</div>
            );
        default:
            return null;
    }
};
const RenderItemStats = ({category,item,statsOrdered}) => {
    if (['skin','cap'].includes(category)) {
        return Object.keys(statsOrdered).map((stat,index) => {
            const statLevel = item.stats[stat];
            const statPrefix = () => {
                if (statLevel < 0) {
                    return '-';
                }
                if (statLevel > 0) {
                    return '+';
                }
                return '';
            };
            const statColor = () => {
                if (statLevel < 0) {
                    return palette.pomegranate;
                }
                if (statLevel > 0) {
                    return palette.sunFlower;
                }
                return palette.base4;
            };

            return (
                <div key={index} style={{display: 'flex', fontWeight: 'bold'}}>
                    <div style={{display: 'flex', color: statColor()}}>
                        <div style={{width: '.75em'}}>
                            {statPrefix()}
                        </div>
                        <div style={{width: '2em'}}>{Math.abs(statLevel)}</div>
                    </div>
                    <div>{mapStats(stat, 'label')}</div>
                </div>
            )
        });
    }
    return null;
};

export const ItemTooltip = ({item, statsOrdered, category}) => {
    return (
        <ItemTooltipDiv>
            <RenderItemName
                item={item}
                category={category}
            />
            <RenderItemTier
                item={item}
                category={category}
            />
            <RenderItemStats
                item={item}
                category={category}
                statsOrdered={statsOrdered}
            />
        </ItemTooltipDiv>
    )
};
export default ItemTooltip;