import {createReducer} from "@reduxjs/toolkit";
import usersInitialState from "./usersInitialState";
import { history } from '../../../index';
import {censor} from "../../../system/chat";

const usersReducers = createReducer(usersInitialState, {
    USERS_UPDATE: (state, action) => {
        const { guests, members, data } = action.payload;

        if (guests != null) state.guests = guests;
        if (members != null) state.members = members;
        if (data != null) state.data = data;
    },
});

export default usersReducers;