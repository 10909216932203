import React from 'react';
import BallotOption from "./BallotOption";
import {
    BallotDiv,
} from '../BallotStyle';

const Ballot = ({teams, modifiers}) => {
    if (!modifiers) {
        return null;
    }
    return (
        <BallotDiv className="styleDarkScrollSquare">
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {Object.keys(modifiers).map((key, index) => (
                        <BallotOption
                            key={key}
                            label={key}
                            index={index}
                            teams={teams}
                            modifiers={modifiers}
                        />
                    ))
                }
            </div>
        </BallotDiv>
    )
};

export default Ballot;