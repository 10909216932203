import {createAction} from "@reduxjs/toolkit";

export const selfLoadInitialState = createAction('SELF_LOAD_INITIAL_STATE');

export const updateCoins = createAction('UPDATE_COINS');
export const updateColorName = createAction('UPDATE_COLOR_NAME');
export const sellItem = createAction('SELL_ITEM');
export const updateStats = createAction('UPDATE_STATS');
export const updateStarterColor = createAction('UPDATE_STARTER_COLOR');
export const updateTickets = createAction('UPDATE_TICKETS');
export const updateMetrics = createAction('UPDATE_METRICS');
export const updatePremium = createAction('UPDATE_PREMIUM');
export const updatePortrait = createAction('UPDATE_PORTRAIT');
export const updateTeam = createAction('UPDATE_TEAM');
export const updateRating = createAction('UPDATE_RATING');
export const updateXsollaToken = createAction('UPDATE_XSOLLA_TOKEN');
export const updateItemSaleValue = createAction('UPDATE_ITEM_SALE_VALUE');

// This replaces the current quantity for some item with the new value
export const selfUpdateConsumableItem = createAction('SELF_UPDATE_CONSUMABLE_ITEM');
// This adds the provided item to the users items
export const selfAddItem = createAction('SELF_ADD_ITEM');
// This removes the provided item from the users items
export const selfRemoveItem = createAction('SELF_REMOVE_ITEM');
// This adds the provided item to the end of the users newItems list (which is used on the drop page)
export const selfAddNewItem = createAction('SELF_ADD_NEW_ITEM');
// This removes the first item in the users newItems list
export const selfRemoveNewItem = createAction('SELF_REMOVE_NEW_ITEM');

// This updates the outfits array (Used by server)
export const loadOutfits = createAction('LOAD_OUTFITS');
// This updates the outfit object stored in self (Used by server and client)
export const updateOutfit = createAction('UPDATE_OUTFIT');
// This updates a single outfit in the outfits array (Used by client without server intervention)
export const outfitUpdate = createAction('OUTFIT_UPDATE');

// This updates the users accessory loadout (Used by server)
export const updateLoadout = createAction('UPDATE_LOADOUT');