import React from "react";
import styled from 'styled-components';
import palette from "../../../styled/Palette";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";

const ModalCoinLi = styled.li`
    padding: 1em;
    color: ${palette.base4};
`;

export let CoinsData;

const ModalCoins = (dispatch) => {

    CoinsData = (
        <ul style={{margin: '.25em', width: '100%', display: 'flex', flexDirection: 'column'}}>
            <ModalCoinLi>Winning a casual or ranked match earns you +100 coins</ModalCoinLi>
            <ModalCoinLi>Each match there is a chance you will obtain a random item</ModalCoinLi>
            <ModalCoinLi>Items can be sold for more coins on the <i>Loot</i> screen</ModalCoinLi>
            <ModalCoinLi>You can also bet against others on matches from the <i>Watch</i> screen</ModalCoinLi>
        </ul>
    );

    dispatch(modalEnable({
        title: 'Earning Slime Coin',
        body: 'Coins',
    }));
};

export default ModalCoins;