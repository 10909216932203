import {createAction} from "@reduxjs/toolkit";

export const chatLoadHistory = createAction('CHAT_LOAD_HISTORY');

export const updateChat = createAction('UPDATE_CHAT');
export const reloadChat = createAction('RELOAD_CHAT');
export const updateMatchChat = createAction('UPDATE_MATCH_CHAT');
export const resetMatchChat = createAction('RESET_MATCH_CHAT');
export const updateSentChat = createAction('UPDATE_SENT_CHAT');
export const updateChatPublicReads = createAction('UPDATE_CHAT_PUBLIC_READS');
export const updateBet = createAction('UPDATE_BET');

export const notifyChatMatchEnd = createAction('NOTIFY_CHAT_MATCH_END');
export const notifyChatBetOutcomes = createAction('NOTIFY_CHAT_BET_OUTCOMES');
export const notifyChatReadyUpdate = createAction('NOTIFY_CHAT_READY_UPDATE');
export const notifyChatPlayerGone = createAction('NOTIFY_CHAT_PLAYER_GONE');