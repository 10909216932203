import styled from 'styled-components';
import palette from '../../../styled/Palette';
import React from "react";

const width = 400;

export const BetsDetailDiv = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    max-height: 15em;
    margin-top: 3em;
`;

export const BetDetailDiv = styled.div`
    padding: .25em;
    background-color: ${props => props.dark ? palette.base5 : palette.base3};
    display: flex;
    justify-content: space-around;
    width: ${width};
    align-items: center;
    margin: 2px;
    font-size: 12px;
`;

export const BetTextDiv = styled.div`
    font-weight: 600;
    text-align: ${props => !props.actionItem && 'left'};
    flex-grow: 1;
    flex-basis: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const BetTableDiv = styled.div`
    max-height: 15em;
`;

export const BetTableHeadDiv = styled.div`
    margin-right: .5em;
    display: flex;
`;

export const BetTableHeaderDiv = styled.div`
   flex-grow: 1;
   flex-basis: 0;
   marginRight: ${props => props.first && 0};
   marginLeft: ${props => props.last && 0};
   margin: 1px;
   background-color: ${props => props.last ? '' : palette.midnightBlue};
`;
export const BetActionButton = styled.button`
    cursor: ${props => props.redacted && 'default'};
    padding: .5em 0;
    border-radius: 4px;
    font-weight: 600;
    text-align: ${props => !props.actionItem && 'left'};
    flex-grow: 1;
    flex-basis: 0;
    color: black;
    background-color: ${props => props.interfaceColor};
    white-space: nowrap;
    overflow: hidden;
`;