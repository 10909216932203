import Button from "../../../styled/Button";
import {socketSignInPhase, updateLoginToast} from "../../../redux/modules/socket/socketActions";
import React, {useEffect, useRef, useState} from "react";
import InputStandard from "../../../styled/Input";
import {masterSocket} from "../../../redux/middleware/wsMaster";
import {requestEmailRecovery} from "../../../system/endpoints/master";

const FormRecover = ({hideAlert,dispatch,validatedEmail}) => {
    const refCode = useRef(null);
    const refPassword = useRef(null);

    const [formValid,setFormValid] = useState(false);

    const isValid = () => {
        return refPassword.current.value.length > 3 &&
            refCode.current.value.length > 5;
    };
    const submitForm = () => {
        masterSocket.send(JSON.stringify({
            'event': 'resetAccount',
            'accessCode': refCode.current.value,
            'password': refPassword.current.value,
        }));
    };

    useEffect(() => {
        requestEmailRecovery(validatedEmail);
        dispatch(updateLoginToast('Recovery Email Delivered'));
    }, []);

    const handleKeyPress = (code) => {
        const pressEnter = code === 'Enter';
        if (pressEnter) {
            submitForm();
        }
    };

    // Generic configuration for handling a listener
    useEffect(() => {
        const keystrokeInstance = (e) => handleKeyPress(e.code);
        window.addEventListener('keydown', keystrokeInstance);
        return () => {
            window.removeEventListener('keydown', keystrokeInstance);
        };
    }, []);

    return (
        <>
            <>
                <InputStandard
                    autoComplete="off"
                    ref={refCode}
                    placeholder="Access Code"
                    onKeyUp={() => setFormValid(isValid())}
                />
                <InputStandard
                    autoComplete="off"
                    ref={refPassword}
                    placeholder="New Password"
                    onKeyUp={() => setFormValid(isValid())}
                />
            </>
            <div style={{display: 'flex',marginTop: '2em'}}>
                <Button
                    click={() => {
                        hideAlert();
                        submitForm();
                    }}
                    isDisabled={!formValid}
                    title={"Submit"}
                    type="button"
                    className="buttonNeutral"> </Button>
                <Button
                    click={() => {
                        hideAlert();
                        dispatch(socketSignInPhase('login'));}}
                    title={"Back"}
                    type="button"
                    className="buttonNeutral"
                > </Button>
            </div>
        </>
    )
};

export default FormRecover;