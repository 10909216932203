import styled from 'styled-components';
import palette from '../../../../styled/Palette';

export const LootItemsInfoDiv = styled.div`
    flex-grow: .1;
    flex-basis: 0;

    display: flex;
    align-items: center;    
    justify-content: center;
    margin: 1em;
    
    font-family: Montserrat;
    background-color: ${palette.midnightBlue};
    color: ${palette.base2};
    border: 1px solid black;
    
    font-size: 1.25em;
`;