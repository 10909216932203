import React, { useState, useEffect, useCallback } from 'react';
import {
    BetContentDiv, BetCreatorDiv,
    OverlayDiv,
} from './BetsOverlayStyle';
import { masterSocket } from "../../../../redux/middleware/wsMaster";

import Bets from "../Bets";
import palette from "../../../../styled/Palette";
import {currency, getRandomInt} from '../../../../data/format';
import { debounce } from '../../../../data/generic/generic';
import BetCreator from "../BetCreator";
import BetHeader from "../BetHeader";
import BetMaker from "../BetMaker";
import {playSound} from "../../../../data/sound";

const BetsOverlay = ({matchMessage, bets,theme,showOverlay, roomId, setShowOverlay, coins, username}) => {
    const [betAmount, setBetAmount] = useState('');
    const [team, setTeam] = useState('LEFT');
    const [numerator, setNumerator] = useState(1);
    const [denominator, setDenominator] = useState(1);

    // The bet the user wants the opponent to take
    const opponentValue = betAmount*numerator/denominator;
    // The number of coins the player has remainingp
    const remainder = coins - betAmount;
    // Check if the user can make a bet
    const betAffordable = remainder >= 0;

    const opponentBetValid = opponentValue % 1 === 0;
    const userBetValid = betAmount % 1 === 0;
    const betsWholeNumbers = (opponentBetValid && userBetValid);

    const inputnumerator = document.getElementById('inputnumerator');
    const inputdenominator = document.getElementById('inputdenominator');
    const inputbetamount = document.getElementById('inputbetamount');

    // Adjusts the denominator until the betting odds use whole numbers
    const updateBetProbability = () => {
        // idk how these happen, user puts in weird value?
        if (numerator < 1) {
            // fix state
            setNumerator(1);
            // fix view
            inputnumerator.value = numerator;
            return;
        }
        if (denominator < 1) {
            // fix state
            setDenominator(1);
            // fix view
            inputdenominator.value = denominator;
            return;
        }

        for (let x = betAmount; x > 0; x--) {
            if (x % denominator === 0) {
                setBetAmount(x);
                break;
            }
        }
    };

    // This debounces the button so that the user can only send the event every half second
    const handleBet = useCallback(debounce((n,d,a,s) => {
        let string = `coinSound${getRandomInt(14)}`;
        playSound(string, 1);
        const payload = {
            'event': 'makeBet',
            'numerator': n,
            'denominator': d,
            'amount': a,
            'side': s,
            'roomId': roomId,
        };
        masterSocket.send(JSON.stringify(payload));
        // Reset fields to default values
        setBetAmount(null);
        setNumerator(1);
        setDenominator(1);
    }, 500), []);
    // Use callback gets stale reference to state variables so they are passed to the function
    const makeBet = () => handleBet(numerator,denominator,betAmount, team);

    useEffect(() => {
        if (inputbetamount) {
            inputbetamount.value = betAmount;
        }
    }, [betAmount]);

    // todo: not sure if needed
    // Capture game event
    // useEffect(() => {
    //     if (matchMessage) {
    //         if (matchMessage.event === 'gameEnd') {
    //             setShowOverlay(false);
    //         }
    //     }
    // }, [matchMessage]);

    if (showOverlay) {
        return (
            <OverlayDiv>
                <BetContentDiv>
                    <BetHeader setShowOverlay={setShowOverlay} />
                    <BetCreator
                        theme={theme}
                        opponentValue={opponentValue}
                        updateBetProbability={updateBetProbability}
                        betsWholeNumbers={betsWholeNumbers}
                        betAmount={betAmount}
                        setBetAmount={setBetAmount}
                        team={team}
                        setTeam={setTeam}
                        denominator={denominator}
                        setDenominator={setDenominator}
                        numerator={numerator}
                        setNumerator={setNumerator}
                        betAffordable={betAffordable}
                        makeBet={makeBet}
                    />
                    {betAmount > 0 &&
                        <div style={{margin: '.25em', color: palette.base4, display: 'flex', justifyContent: 'space-around', alignItems: 'center', fontSize: '11px'}}>
                            <div>
                                {betAffordable ?
                                    `You will have ${remainder} coins remaining.`
                                    :
                                    `YOU CAN NOT AFFORD TO MAKE AN OFFER OF ${betAmount}`
                                }
                            </div>
                        </div>
                    }
                    <Bets
                        theme={theme}
                        coins={coins}
                        username={username}
                        roomId={roomId}
                        bets={bets}
                    />
                </BetContentDiv>
            </OverlayDiv>
        );
    }
    return null;
};

export default BetsOverlay;