import {
    BetContentDiv,
    BetCreatorDiv,
    OverlayButton,
    OverlayContentDiv,
    OverlayInput
} from "./BetsOverlay/BetsOverlayStyle";
import React from "react";
import BetMaker from "./BetMaker";

const BetCreator = ({opponentValue,updateBetProbability,betsWholeNumbers,betAmount,setBetAmount,team,setTeam,denominator,setDenominator,numerator,setNumerator,betAffordable,makeBet,theme}) => {
    return (
        <OverlayContentDiv>
            <BetCreatorDiv>
                <div
                    style={{display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center', padding: '1em'}}
                >
                    <div>BET</div>
                    <OverlayInput
                        tabIndex={0}
                        id={'inputbetamount'}
                        type={'number'}
                        interfaceColorBright={theme.interfaceColorBright}
                        value={betAmount}
                        placeholder={100}
                        onChange={(e) => setBetAmount(e.target.value)}
                    />
                    <div>COINS THAT</div>
                    <OverlayButton
                        tabIndex={0}
                        onClick={() => setTeam(team === 'LEFT' ? 'RIGHT' : 'LEFT')}
                        interfaceColorBright={theme.interfaceColorBright}
                    >
                        {team} TEAM
                    </OverlayButton>
                    <div>WILL WIN</div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <OverlayInput
                            tabIndex={0}
                            id={'inputnumerator'}
                            value={numerator}
                            interfaceColorBright={theme.interfaceColorBright}
                            onChange={(e) => setNumerator(e.target.value)}
                        />
                        :
                        <OverlayInput
                            tabIndex={0}
                            id={'inputdenominator'}
                            value={denominator}
                            interfaceColorBright={theme.interfaceColorBright}
                            onChange={(e) => setDenominator(e.target.value)}
                        />
                    </div>
                </div>
                <BetMaker
                    theme={theme}
                    betAffordable={betAffordable}
                    opponentValue={opponentValue}
                    updateBetProbability={updateBetProbability}
                    betsWholeNumbers={betsWholeNumbers}
                    betAmount={betAmount}
                    makeBet={makeBet}
                />
            </BetCreatorDiv>
        </OverlayContentDiv>
    )
};
export default BetCreator;