import styled from 'styled-components';
import palette from '../../styled/Palette';
import {shadow} from "../../data/globals";

export const BodyDiv = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    font-family: Montserrat;
`;
export const StatsDiv = styled.div`
    margin-bottom: 1.5em;
`;

export const HeaderDiv = styled.div`
    display: flex;
    font-size: 24px;
    font-family: Luckiest Guy;
    border-bottom: 1px solid ${palette.base2};
    width: 400px;
    text-align: left;
    padding: 0 5px;
`;

export const FeatsUserInfoDiv = styled.div`
    position: relative;
    flex-direction: column;
    background-color: rgba(52,73,94,.5);

    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-grow: 1;
    font-family: Luckiest Guy;
    text-align: left;
    
    width: 35em;
`;

export const FeatsUserDetailDiv = styled.div`
    display: flex;
    flex-direction: column;
    font-size: .9em;
    text-align: left;
    font-family: sans-serif;
    width: 100%;
    padding: .5em;
    font-family: Montserrat;
    ul {
        border: 1px solid ${palette.wetAsphalt};
    }
    li {
        align-items: center;
        padding: .5em 1em;
        display: flex;
        justify-content: space-between;
    }
`;

export const RowItemLi = styled.li`
    cursor: pointer;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const InfoDiv = styled.div`
    display: flex;
    justify-content: space-between;
    
    div:last-child {
        margin-left: 20px;
    }
`;

export const AchievementsModalBodyDiv = styled.div`
    padding: 1em;
    color: ${palette.base4};
`;

export const FeatsUserInfoItemDiv = styled.div`
    margin-top: 1em;
    font-family: Luckiest Guy;
    font-size: 1.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: ${props => props.hue};
`;

export const FeatsUserDetailTitleText = styled.span`
    font-weight: bold;
`;
export const FeatsUserDetailValueText = styled.span`
`;