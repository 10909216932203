
/**
 * I was having some difficulty updating listeners to subscribe at the component
 * level and properly react to changes. For this reason I decided to manage the gamepad inputs
 * in redux, and react to variable changes using the normal pattern.
 *
 * This might not be the most performant solution but it seems to work.
 */

import {createAction} from "@reduxjs/toolkit";
export const gamepadUpdate = createAction('GAMEPAD_UPDATE');
export const gamepadToggleConnected = createAction('GAMEPAD_TOGGLE_CONNECTED');
export const gamepadToggleNavigation = createAction('GAMEPAD_TOGGLE_NAVIGATION');