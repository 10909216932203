import isElectron from 'is-electron';
import { masterSocket } from "./redux/middleware/wsMaster";
import {steamSetFriends,steamSetFriendIds} from "./redux/modules/steam/steamActions";
import {restfulCall} from "./data/globals";
import {useEffect, useState} from "react";

let greenworks;
if (isElectron()) {
    greenworks = require('greenworks');
}

const ConnectSteamAPI = (dispatch) => {
    let steamData = {
        greenworksFriends: [],
        steamFriends: [],
        recentlyOnline: []
    };

    const init = () => {
        greenworks.init();
        // There is a bug with electron / greenworks where if you dont call this function, the steam overlay wont work
        greenworks.saveTextToFile('test_file.txt', 'test_content',
            function() { console.log('Save text to file successfully'); },
            function(err) {
                // An error that apparently doesn't matter
                //console.log('Failed on saving text to file');
            });
        // You need this ticket to sign-in and make mtx purchases
        greenworks.getAuthSessionTicket(ticket => {
            masterSocket.send(JSON.stringify({
                'event': 'steamAuthenticationTicket',
                'ticket': ticket.ticket.toString('hex'),
            }));
        });
    };
    const getFriendsSorted = (steamFriends,newRecentlyOnline) => {
        // Show friends on slime before offline friends
        steamFriends.sort((a,b) => b.inSlime - a.inSlime);
        // Show friends recently on slime before offline friends
        steamFriends.sort(function(x, y) {
            const recentX = newRecentlyOnline.includes(x.steamId);
            const recentY = newRecentlyOnline.includes(y.steamId);
            return (recentX === recentY) ? 0 : recentX ? -1 : 1;
        });
        return steamFriends;
    };

    const getAvatarImage = (steamid) => {
        var handle = greenworks.getMediumFriendAvatar(steamid);
        if (!handle) {
            console.log("The user has no avatar set.");
            return;
        }
        var image_buffer = greenworks.getImageRGBA(handle);
        var size = greenworks.getImageSize(handle);
        if (!size.height || !size.width) {
            console.log("Image corrupted. Please try again");
            return;
        }
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        canvas.width = size.width;
        canvas.height = size.height;
        let clampedArray = new Uint8ClampedArray(image_buffer);
        let imageData = new ImageData(clampedArray, size.width, size.height);
        context.putImageData(imageData, 0, 0);
        return canvas.toDataURL();
    };
    const loadFriends = async () => {
        steamData = {
            ...steamData,
            steamFriends: [],
            greenworksFriends: greenworks.getFriends(greenworks.FriendFlags.Immediate)
        };
        const getSlimeUsername = async () => {
            const steamIds = steamData.greenworksFriends.map(friend => friend.getRawSteamID());
            return restfulCall(
                masterSocket,
                {'event': 'getUsernamesFromSteamIds', 'steamIds': steamIds},
                'steamIdUsernameMap'
            );
        };
        const slimeUsernames = await getSlimeUsername();

        // Load a proper scheme for populating the drawer user component
        let newSteamFriends = [];
        let newRecentlyOnline = [...steamData.recentlyOnline];
        for (let i = 0; i < steamData.greenworksFriends.length; i++) {
            const steamid = steamData.greenworksFriends[i].getRawSteamID();
            const userPlayingSlime = greenworks.getFriendGamePlayed(steamid)?.m_gameID === '1124680';
            const steamAvatar = await getAvatarImage(steamid);

            newSteamFriends.push({
                'slimeUsername': slimeUsernames?.steamIdUsernameMap[steamid],
                'avatar': steamAvatar,
                'username': steamData.greenworksFriends[i].getPersonaName(),
                'steamId': steamid,
                'inSlime': userPlayingSlime
            });

            const steamIdIsUnique = steamData.recentlyOnline.indexOf(steamid) === -1;
            if (steamIdIsUnique && userPlayingSlime) {
                newRecentlyOnline.push(steamid)
            }
        }

        // Local Setter
        steamData = {
            steamFriends: newSteamFriends,
            recentlyOnline: newRecentlyOnline
        };

        // Set redux globals
        dispatch(steamSetFriendIds(newSteamFriends.map(friend => friend.steamId)));
        dispatch(steamSetFriends(getFriendsSorted(newSteamFriends,newRecentlyOnline)));
    };

    const watchSteamUsers = () => {
        // This is when steam tells us something has changed
        greenworks.on('persona-state-change', async (steamId, personaChangeFlag) => {
            const steamIdentification = steamId.getRawSteamID();
            let pc = greenworks.PersonaChange;
            
            // bitwise OR combines all possible flags into single value, relationship changed indicates add / remove friend
            let watchedFlags = pc.GamePlayed | pc.Avatar | pc.RelationshipChanged | pc.Name;
            
            if (watchedFlags & personaChangeFlag) { //one of the watched flags matches the persona change
                //console.log('watched flag hit');
                const userIds = steamData.steamFriends.map(user => user.steamId);
                const userIndex = userIds.indexOf(steamIdentification);

                // Clone
                let newSteamFriends = [...steamData.steamFriends];
                if (personaChangeFlag & pc.GamePlayed) { //if any of the personachanges indicates game played
                    // Mutate
                    //console.log('game change flag');
                    newSteamFriends[userIndex] = {
                        ...steamData.steamFriends[userIndex],
                        inSlime: greenworks.getFriendGamePlayed(steamIdentification)?.m_gameID === '1124680'
                    };
                }
                if (personaChangeFlag & pc.Name) {
                    console.log('change name flag');
                    // Mutate
                    newSteamFriends[userIndex] = {
                        ...steamData.steamFriends[userIndex],
                        username: steamData.greenworksFriends[userIndex].getPersonaName()
                    };
                }
                if (personaChangeFlag & pc.Avatar) {
                    //console.log('change avatar flag');
                    // Mutate
                    let steamAvatar = await getAvatarImage(steamIdentification);
                    newSteamFriends[userIndex] = {
                        ...steamData.steamFriends[userIndex],
                        avatar: steamAvatar
                    };
                }
                if (personaChangeFlag & pc.RelationshipChanged) {
                    //console.log('relationship changed');
                    // Add Steam Friend / Remove Steam Friend / Block Steam Friend
                    loadFriends();
                }
                // Update Local
                steamData = {
                    ...steamData,
                    steamFriends: newSteamFriends
                };

                // Final Values
                const sortedFriends = getFriendsSorted(steamData.steamFriends, steamData.recentlyOnline);
                const sortedUserIds = sortedFriends.map(friend => friend.steamId);

                // Set Redux global
                dispatch(steamSetFriends(sortedFriends));
                dispatch(steamSetFriendIds(sortedUserIds));
            }
        });
    };
    const loadMTX = () => {
        greenworks.on('micro-txn-authorization-response', (appId, orderId, authorized) => {
            if(authorized){
                masterSocket.send(JSON.stringify({
                    'event': 'finalizeSteamPurchase',
                    'orderId': orderId,
                }));
            }
            console.log('got micro txn authorization response', appId, orderId, authorized);
        });
    };

    /**
     * This is when our master server tells us something has changed.
     */
    const listenerInstance = () => {
        const message = JSON.parse(event.data);
        if (message.event === 'forceSteamUpdate') {
            console.log('forceSteamUpdate',message.event);
            loadFriends();
        }
    };

    masterSocket.addEventListener("message", listenerInstance);
    init();
    loadFriends().then(() => watchSteamUsers());
    loadMTX();
};

export const activateGameOverlay = () => {
    greenworks.activateGameOverlay("Friends");
};

export default ConnectSteamAPI;