import styled from 'styled-components';

export const RadioContainer = styled.div`
   z-index: 5;
   display: flex;
   position: absolute;
   top: ${props => props.source === 'match' ? '2em' : '6em'};
   right: 1em;
   min-height: 70px;
`;

export const RadioClose = styled.span`
    margin-left: 1em;
    font-size: 2em;
    margin-top: -.5em;
    z-index: 6;
    height: 0;
    cursor: pointer;
    color: #c0c0c0;
    &:hover {
        color: #ffffff;
    }
`;


export const RevealHoverDiv = styled.div`
    display: flex;
    visibility: hidden;
    opacity: 0;
    width: 0;
    transition: all .5s ease;
    border-left: 1px solid ${props => props.interfaceColor};
`;

export const RevealMountDiv = styled.div`
    display: flex;
    &:hover ${RevealHoverDiv} {
        visibility: visible;
        opacity: 1;
        width: 100%;
        padding-left: 10px;
    }
`;