import styled from 'styled-components';
import palette from './Palette';

const InputStandard = styled.input`
    background-color: ${palette.base0};
    
    margin: .1em 0;
    
    width: 100%;
    display: flex;
    color: white;
    
    padding: 5px;
    
    border-radius: 2px;
    border: 2px solid transparent;
    
    font-size: 18px;
    
    &:active, &:focus {
        border: 2px solid ${palette.base6};
        outline: none;
    }
    
     &::placeholder {
        color: ${palette.base1};
     }
`;

export default InputStandard;
