import Proton from 'proton-engine';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';

export function createLevitateParticles(room, team, teamIndex) {
    let player = room.teams[team][teamIndex];
    let levitateEmitter = new Proton.Emitter();
    levitateEmitter.rate = new Proton.Rate(16, updatePerFrame);
    levitateEmitter.addInitialize(new Proton.Mass(1));
    levitateEmitter.addInitialize(new Proton.Radius(2, 4));
    levitateEmitter.addInitialize(new Proton.Life(0.5, 1));
    levitateEmitter.addBehaviour(new Proton.RandomDrift(30, 0, 0.05));
    levitateEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-2, 2), new Proton.Span(3, 6), 'vector')
    );
    levitateEmitter.addBehaviour(new Proton.Color('random', 'random'));
    levitateEmitter.addBehaviour(new Proton.Gravity(-1));
    levitateEmitter.addBehaviour(new Proton.Scale(1, 1.5));
    levitateEmitter.addBehaviour(new Proton.Alpha(0.8, 0));
    levitateEmitter.p.x = player.position.x;
    levitateEmitter.p.y = player.position.y;
    levitateEmitter.damping = 0.04;
    levitateEmitter.emit();

    protonBackground.addEmitter(levitateEmitter);

    let endParticles = () =>{
        levitateEmitter.rate = new Proton.Rate(0, 1000);
        clearInterval(interval);
        setTimeout(() => {
            protonBackground.removeEmitter(levitateEmitter);
        }, 4000);
    }
    let interval = setInterval(() => {
        player = room.teams[team][teamIndex];
        if(player){
            let position = player.position;
            levitateEmitter.p.x = 0;
            levitateEmitter.p.y = 0;
            levitateEmitter.addInitialize(
                new Proton.Position(
                    new Proton.CircleZone(
                        position.x,
                        position.y,
                        (player.stats.size * player.scale * 100) / 2
                    )
                )
            );
            if (!player.abilities.levitate?.enabled || player.abilities.levitate == null) {
                endParticles();
            }
        }
        else{
            endParticles();
        }
    }, 1000 / 60);
}
