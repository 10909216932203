import Proton from 'proton-engine';
import { protonForeground } from './protonForeground';
import { updatePerFrame } from './protonConfig';

export function createVanishingPowderParticles(position, radius) {
    let vanishingPowderEmitter = new Proton.Emitter();
    vanishingPowderEmitter.rate = new Proton.Rate(40, updatePerFrame);
    vanishingPowderEmitter.addInitialize(new Proton.Mass(1));
    vanishingPowderEmitter.addInitialize(new Proton.Radius(50, 70));
    vanishingPowderEmitter.addInitialize(new Proton.Life(new Proton.Span(1, 3)));
    vanishingPowderEmitter.addBehaviour(new Proton.RandomDrift(35, 15, 0.05));
    vanishingPowderEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-2, 2), new Proton.Span(-2, 2), 'vector')
    );

    // vanishingPowderEmitter.addInitialize(new Proton.Velocity(Proton.Span(-2,2),-3));
    vanishingPowderEmitter.addBehaviour(
        new Proton.Color(['777777', '888888', '666666', '999999', 'aaaaaa', 'bbbbbb', 'cccccc'])
    );
    vanishingPowderEmitter.addBehaviour(new Proton.Gravity(-1.5));
    vanishingPowderEmitter.addBehaviour(new Proton.Scale(.1, 2,Infinity,'easeOutExpo'));
    vanishingPowderEmitter.addBehaviour(new Proton.Alpha(1, 0));
    vanishingPowderEmitter.addInitialize(
        new Proton.Position(new Proton.CircleZone(position.x, position.y, radius))
    );
    vanishingPowderEmitter.damping = 0.04;
    vanishingPowderEmitter.emit(updatePerFrame * 2, 10);

    protonForeground.addEmitter(vanishingPowderEmitter);
}
