import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import palette from "../../styled/Palette";
import React from "react";
import {useIcon} from "../../data/icon";
import {StrokeDiv, SvgDiv} from "../ItemSwitchManager";
import {PageTypes} from "../../system/types";

const QuantityOverlayDiv = styled.div`
    position: absolute;
    top: .5em;
    font-family: Modak;
    color: ${palette.peterRiver};
    font-weight: bold;
    z-index: 5;
    font-size: 1.2em;
`;
const IconCheckBackgroundDiv = styled.div`
     display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 4;
    border: 3px solid ${palette.belizeHole};
    background-color: rgba(31,31,31,.90);
`;
const IconSearchDiv = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(44,62,80,.75);
    position: absolute;
    z-index: 4;
`;
const OverlayExamine = () => {
    return (
        <IconSearchDiv>
            {useIcon('search',{color: palette.emerald, fontSize: '1.5em'})}
        </IconSearchDiv>
    )
};
const OverlaySell = (sellMode) => {
    if (sellMode.sellMode) {
        return (
            <IconSearchDiv>
                <FontAwesomeIcon
                    style={{color: palette.emerald, fontSize: '2em'}}
                    icon={['fas', 'dollar-sign']}
                />
            </IconSearchDiv>
        )
    }
    return null;
};
const OverlaySelected = () => {
    return (
        <IconCheckBackgroundDiv>
            <FontAwesomeIcon
                style={{color: palette.emerald, fontSize: '2em', zIndex: 1}}
                icon={['fas', 'check']}
            />
        </IconCheckBackgroundDiv>
    )
};
const OverlayConsumable = ({quantity}) => {
    return (
        <QuantityOverlayDiv>
            <SvgDiv
                viewBox="0 0 100 100"
            >
                <StrokeDiv
                    x={'50%'}
                    y={'50%'}
                >
                    {quantity}
                </StrokeDiv>
            </SvgDiv>
        </QuantityOverlayDiv>
    )
};

const recentlyAcquired = (item) => {
    if (!item.date) return false;
    const oneHour = 60 * 60 * 1000;
    const timeSinceAcquiringItem = Date.now() - Date.parse(item.date);
    return timeSinceAcquiringItem < oneHour;
};

const Overlays = ({overlay,location,item,sellMode,disabled,isLoadout}) => {
    if (item.category === 'accessory' && item.slot && !disabled && !isLoadout) {
        return <OverlaySelected/>;
    }
    if (item.category === 'accessory' && item.quantity) {
        return <OverlayConsumable quantity={item.quantity} />
    }

    // This is the hovering condition, should probably change variable name
    if (overlay) {
        switch (location) {
            case [PageTypes.ATLAS]: return <OverlayExamine />;
            case [PageTypes.LOOT]: return <OverlaySell sellMode={sellMode} />;
        }
    }

    return null;
};

export default Overlays;