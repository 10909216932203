import {imageAssets} from "../../../../data/images";

const Ghost = (context,lerpX,lerpY,size) => {
    //DRAW GHOST AURA
	size = size * 256
    context.translate(lerpX, lerpY);
    context.drawImage(imageAssets.Other['Ghost Aura'].base, -size/2, -size/2,size,size);
    context.translate(-lerpX, -lerpY);
};

export default Ghost;