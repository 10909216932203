import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import TextUnderlineHover from "../../../styled/TextUnderlineHover";
import palette from "../../../styled/Palette";
import {PageTypes} from "../../../system/types";

export let UserAliasData;

const ModalUserAlias = (dispatch,equippedPaint,theme,path) => {
    UserAliasData = (
        <div style={{margin: '2em',fontFamily: 'Montserrat',color: palette.base4}}>
            <div>
                In matches {path === PageTypes.LOOT ? 'you' : 'this player'} will be known as <span style={{color: theme.interfaceColor}}>{`${equippedPaint.adjective} ${equippedPaint.color}`}</span>. Equip other paint items in order to change your alias.
            </div>
           <div style={{display: 'flex',flexDirection:'column',marginTop: '1em'}}>
               <div style={{fontWeight: 'bold', fontSize: '.75em',margin: '1em 0'}}>
                   NAME DEFINITION
               </div>
               <div style={{display: 'flex',color:palette.belizeHole}}>
                   <div style={{fontWeight: 'bold',minWidth: '10em'}}>{equippedPaint.adjective}</div>
                   <div>{equippedPaint.adjectiveDefinition}</div>
               </div>
               <div style={{display: 'flex',color:palette.peterRiver}}>
                   <div style={{fontWeight: 'bold',minWidth: '10em'}}>{equippedPaint.color}</div>
                   <div>{equippedPaint.colorDefinition}</div>
               </div>
           </div>
        </div>
    );

    dispatch(modalEnable({
        title: `Player Alias`,
        body: 'UserAlias',
    }));
};

export default ModalUserAlias;