import {createReducer} from "@reduxjs/toolkit";
import initialState from "./themeInitialState";
import tinycolor from "tinycolor2";

const getInterfaceColor = (newColor) => {
    const color = tinycolor(newColor).toHsl();
    if (color.l < .5) color.l = .5;
    return tinycolor.fromRatio({ h: color.h, s: color.s, l: color.l }).toString("hex6");
};
const getInterfaceColorBright = (newColor) => {
    const color = tinycolor(newColor).toHsl();
    return tinycolor.fromRatio({ h: color.h, s: color.s, l: .7 }).toString("hex6");
};

const themeReducers = createReducer(initialState, {
    UPDATE_THEME: (state, action) => {
        const colorHex = action.payload;
        state.hexValue = colorHex;
        state.interfaceColor = getInterfaceColor(colorHex);
        state.interfaceColorBright = getInterfaceColorBright(colorHex);
    },
});

export default themeReducers;