import React, {useState} from 'react';
import UserBanner from "../UserBanner/UserBanner.js";
import styled from 'styled-components';
import palette from "../../../styled/Palette";
import Icon from "../../../data/icon";

const listTypes = ['online','received','sent','offline'];

const IconContainerDiv = styled.div`
    font-size: 1.5em;
    transform: rotate(${props => props.expanded ? '90deg':0});
    padding: 0 .5em;
`;
const ListContainerDiv = styled.div`
    
`;
const ListTitleDiv = styled.div`
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${props => props.expanded ? palette.base4 : palette.base2};
    height: 5em;
    flex-shrink: 0;
    font-size: 1.2em;
`;

const FriendUsers = ({setWhisper,friends,history}) => {
    const [activeList, setActiveList] = useState('online');
    const handleSelect = (listType) => {
        if (activeList === listType) {
            setActiveList(null);
        } else {
            setActiveList(listType);
        }
    };

    const mapListUsers = (type) => {
        return (
            <ListContainerDiv>
                {
                    friends[type].map((friend, index) => {
                        return (
                            <UserBanner
                                key={index}
                                history={history}
                                type={type}

                                setWhisper={setWhisper}
                                player={friend}
                            />
                        )
                    })
                }
            </ListContainerDiv>
        )
    };

    return listTypes.map(type => {
        const expanded = type === activeList;
        const listHasUsers = friends[type].length > 0;
        if (listHasUsers) {
            return (
                <div key={type}>
                    <ListTitleDiv
                        onClick={() => handleSelect(type)}
                        expanded={expanded}
                    >
                        <IconContainerDiv
                            expanded={expanded}
                        >
                            {Icon('angle-right')}
                        </IconContainerDiv>
                        {type}
                    </ListTitleDiv>
                    {expanded && mapListUsers(type)}
                </div>
            )
        }
        return null;
    });
};

export default FriendUsers;