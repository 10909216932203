import {createSelector} from "@reduxjs/toolkit";

export const selectPreference = (state) => state.queue.preference;

export const selectGameModesTitles = createSelector(
    [selectPreference],
    (preferenceModes) => ( {
        volley: `${preferenceModes.volley1 ? '1s ' : ''}${preferenceModes.volley2 ? '2s ' : ''}${preferenceModes.volley3 ? '3s ' : ''}${preferenceModes.volley4 ? '4s ' : ''}`,
        soccer:`${preferenceModes.soccer1 ? '1s ' : ''}${preferenceModes.soccer2 ? '2s ' : ''}${preferenceModes.soccer3 ? '3s ' : ''}${preferenceModes.soccer4 ? '4s ' : ''}`,
        hoops:`${preferenceModes.hoops1 ? '1s ' : ''}${preferenceModes.hoops2 ? '2s ' : ''}${preferenceModes.hoops3 ? '3s ' : ''}${preferenceModes.hoops4 ? '4s ' : ''}`
    })
);