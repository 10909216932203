import MockChats from "../../../test/MockChats";

export default {
    unread: 0,
    public: [],//MockChats
    publicUncensored: [], // The uncensored chat
    match: [],
    bets: {}, // todo: This should go in like match or something
    recentSent: 0,
    banned: false,
};