
import React, {useEffect, useRef, useState} from 'react';
import {FeatsUserInfoItemDiv} from "../../page/Feats/FeatsStyle";
import workerGetPortrait from "../../webworker/main";
import loader from "../../images/load2.svg";
import {useIcon} from "../../data/icon";
import {useDispatch, useSelector} from "react-redux";
import ModalUserAlias from "../Modal/Type/ModalUserAlias";

const Avatar = ({outfit, hue, size, styles, starterColor, equippedPaint,history,lookLeft}) => {
    const theme = useSelector(state => state.theme);
    const dispatch = useDispatch();
    const noPaintEquipped = !outfit.paint;
    let payload = noPaintEquipped ? {...outfit, paint: starterColor} : outfit;
    const [portrait, setPortrait] = useState(null);
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (outfit) {
            workerGetPortrait(payload).then(dataUrl => {
                if (mounted) {
                    setPortrait(dataUrl);
                }
            });
        }
    }, [outfit]);

    return (
        <div style={styles}>
            <FeatsUserInfoItemDiv>
                <img
                    style={{marginTop: '-.25em', width: size, transform: lookLeft ? 'scaleX(-1)' : ''}}
                    src={portrait || loader}
                    alt={"player outfit"}
                />
            </FeatsUserInfoItemDiv>
            {equippedPaint &&
                <FeatsUserInfoItemDiv hue={hue}>
                    {`${equippedPaint.adjective} ${equippedPaint.color}`}
                    <div
                        onClick={() => ModalUserAlias(dispatch,equippedPaint,theme,history.location.pathname)}
                        className={'buttonZoom'}
                        style={{fontSize: '.5em', marginLeft: '.5em'}}
                    >
                        {useIcon('info')}
                    </div>
                </FeatsUserInfoItemDiv>
            }
        </div>
    )
};
export default Avatar;