import styled from 'styled-components';
import palette from "../../styled/Palette";

export const ReasonDiv = styled.div`
    align-items: center;
    justify-content: center;
    height: 30vh;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: ${palette.base4};
    background-color: ${palette.base5};
`;