import {createAction} from "@reduxjs/toolkit";

export const match = createAction('MATCH');
export const updateTeams = createAction('UPDATE_TEAMS');
export const updateMatchScore = createAction('UPDATE_MATCH_SCORE');
export const updateMatchStatus = createAction('UPDATE_MATCH_STATUS');
export const updateMatchView = createAction('UPDATE_MATCH_VIEW');
export const updateModifierVotes = createAction('UPDATE_MODIFIER_VOTES');
export const updateReadyStatus = createAction('UPDATE_READY_STATUS');
export const updateRematchReadyStatus = createAction('UPDATE_REMATCH_READY_STATUS');
export const updateUserActiveStatus = createAction('UPDATE_USER_ACTIVE_STATUS');
export const updateTimeUntilMatchStart = createAction('UPDATE_TIME_UNTIL_MATCH_START');
export const updateModifiers = createAction('UPDATE_MODIFIERS');
export const toggleCenterWall = createAction('TOGGLE_CENTER_WALL');
export const addMatchContextMessage = createAction('ADD_MATCH_CONTEXT_MESSAGE');
export const removeMatchContextMessage = createAction('REMOVE_MATCH_CONTEXT_MESSAGE');
export const updateMatchResults = createAction('UPDATE_MATCH_RESULTS');
export const updateTimeLeft = createAction('UPDATE_TIME_LEFT');
export const updateBets = createAction('UPDATE_BETS');
export const matchStartRebootTimer = createAction('MATCH_START_REBOOT_TIMER');
export const matchStopRebootTimer = createAction('MATCH_STOP_REBOOT_TIMER');
export const matchUpdateRebootTimer = createAction('MATCH_UPDATE_REBOOT_TIMER');

export const matchUpdateServerMetadata = createAction('MATCH_UPDATE_SERVER_METADATA');