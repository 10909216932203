import Proton from 'proton-engine';
import { updatePerFrame } from './protonConfig';
import { hslToRgb } from './particleHelpers';
let life = 500;
let ballTrailParticles = {};
ballTrailParticles.enabled = false;

let emitter = new Proton.Emitter();
emitter.rate = new Proton.Rate(0, 1000);
emitter.addInitialize(new Proton.Radius(50));
emitter.addInitialize(new Proton.Mass(1));
emitter.addInitialize(new Proton.Life(life / 1000));
emitter.p.x = 1920 / 2;
emitter.p.y = 900 / 2;
emitter.emit();

//EMITTER
ballTrailParticles.emitter = emitter;

//ENABLE
ballTrailParticles.enable = () => {
    if (!ballTrailParticles.enabled) {
        ballTrailParticles.enabled = true;
        emitter.rate = new Proton.Rate(1, updatePerFrame / 2);
    }
};

//DISABLE
ballTrailParticles.disable = () => {
    if (ballTrailParticles.enabled) {
        ballTrailParticles.emitter.rate = new Proton.Rate(new Proton.Span(0, 0), 1000);
        ballTrailParticles.enabled = false;
    }
};

//UPDATE
ballTrailParticles.update = (ball) => {
    let scale = ball.radius / 50;
    emitter.removeAllBehaviours();
    let trailOpacity = Math.pow(ball.speed / 45, 6);
    if (trailOpacity > 0.8) trailOpacity = 0.8;
    emitter.addBehaviour(new Proton.Alpha(trailOpacity, 0));
    emitter.addBehaviour(new Proton.Scale(scale, 0));
    emitter.addBehaviour({
        initialize: function (particle) {
            particle.start = Date.now();
        },

        applyBehaviour: function (particle) {
            let percentageOfLife = 1 - (Date.now() - particle.start) / life;
            let h = Math.abs(360 - Math.floor(percentageOfLife * 360));
            let s = 1;
            let l = .5;
            let rgb = hslToRgb(h,s,l);
            particle.rgb.r = rgb.r;
            particle.rgb.g = rgb.g;
            particle.rgb.b = rgb.b;

        },
    });
    emitter.p.x = ball.position.x;
    emitter.p.y = ball.position.y;
};

export { ballTrailParticles };
