import React, {useState, useEffect, useRef, useMemo} from 'react';
import MatchChat from '../../component/Chat/MatchChat'
import Players from '../../component/Match/Players';
import RatingBar from './RatingBar/RatingBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { playSound } from '../../data/sound';
import { leaveGame } from "../../redux/middleware/wsMatchThunks";
import { color } from "../../data/format";
import {useDispatch, useSelector} from "react-redux";
import { gameSocket } from '../../redux/middleware/wsMatch';
import CoinEarnings from "./CoinEarnings";
import ModalServerFeedback from '../../component/Modal/Type/ModalServerFeedback';
import palette from "../../styled/Palette";
import {
    WinRootDiv,
    WinTitleDiv,
    WinTeamsMountDiv,
    WinTeamsTitleDiv,
    WinContainerDiv,
    WinDetailsDiv,
    WinFooterDiv,
    WinScoreDiv,
    WinTeamsDiv,
    WinRematchTimerDiv
} from './WinStyle';
import {PageTypes} from "../../system/types";

const winScreenInitialState = {
    winner: '...',
    winnerHex: 'fff',
    winningTeam: '',
    allowRematch: false,
    teamAScore: 0,
    teamBScore: 0,
};

const Win = ({history}) => {
    const dispatch = useDispatch();
    const { roomId, teams, ranked, sport, timeUntilNextMatchStart, results, server } = useSelector(state => state.match);
    // private variable is reserved
    const isPrivateMatch = useSelector(state => state.match.private);
    // This is some default
    const { winner, winnerHex , winningTeam , allowRematch, teamAScore, teamBScore } = results ? results : winScreenInitialState;
    const { sessionId, playerId, team, newItems } = useSelector(state => state.self);
    // Data that was passed along when when the win screen was requested
    const [rematchButton, setRematchButton] = useState('REMATCH');

    const handleLeaveGameSocket = () => {
        dispatch(leaveGame(teams));
        history.push(PageTypes.MAIN);
    };
    const handleRematch = () => {
        setRematchButton('WAITING');
        const msg = {
            "event": "rematch",
            "sessionId": sessionId,
            "roomId": roomId,
            "playerId": playerId};
        gameSocket.send(JSON.stringify(msg));
    };

    useEffect(() => {
        const userWon = winningTeam === team;
        if (!newItems.length) {
            // Only play the sound when the user is going to stay mounted on /win
            playSound(userWon ? 'matchWon' : 'matchLost', 1);
        }
    }, []);
    /**
     * Returns the user to the home screen if they don't ready up in time.
     */
    useEffect(() => {
        if (timeUntilNextMatchStart === 0) {
            handleLeaveGameSocket();
        }
    }, [timeUntilNextMatchStart]);
    /**
     * Show the user the new items, now that we've reached the win screen.
     */
    useEffect(() => {
        if (newItems.length > 0 ) {
            history.push({
                pathname: PageTypes.DROP,
                state: { from: PageTypes.WIN }
            });
        }
    }, [newItems]);

    const singlesMatch = teams.teamA.length === 1;
    const leftTeamWins = winningTeam === 'teamA';
    // Yeah this is the weird way we are handling match draw and match cancel states
    const someTeamWon = ['teamA','teamB'].includes(winningTeam);
    const matchNeverStarted = winningTeam === 'none';
    const userPlaying = ['teamA','teamB'].includes(team);
    const userWon = winningTeam === team;
    const matchTie = winner === 'tie';

    const footerContent = useMemo(() => {
        if (userPlaying) {
            if (ranked) {
                return <RatingBar userWon={userWon} history={history}/>;
            }
            if (userWon && !isPrivateMatch) {
                return <CoinEarnings />;
            }
        }
    }, []);

    return (
        <WinRootDiv>
            <WinContainerDiv className="animatedQuick fadeIn">
                <div style={{display: 'flex', height: '30em'}}>
                    <WinDetailsDiv>
                        <WinTitleDiv
                            color={teams.teamA.length === 1 && teams.teamB.length === 1 ? winnerHex : 'white'}
                        >
                            {someTeamWon ?
                                <div style={{display: 'flex'}}>
                                    <FontAwesomeIcon style={{margin: '0 1em', color: palette.sunFlower}} icon={['fas','crown']} />
                                    {singlesMatch
                                        ? <div style={{color: color(winnerHex, 'light')}}>{!matchTie && winner}</div>
                                        : <div>{`${leftTeamWins ? 'LEFT' : 'RIGHT'} TEAM WINS`}</div>
                                    }
                                </div>
                                :
                                <div>
                                    {matchNeverStarted && 'MATCH CANCELED'}
                                    {matchTie && 'MATCH DRAW'}
                                </div>
                            }
                        </WinTitleDiv>
                        <WinTeamsDiv className="styleDarkScrollSquare">
                            <WinTeamsTitleDiv>
                                LEFT TEAM
                            </WinTeamsTitleDiv>
                            <div style={{display: 'flex', flexGrow: 1}}>
                                <WinTeamsMountDiv>
                                    <Players
                                        history={history}
                                        sport={sport}
                                        screen="rematch"
                                        team={teams.teamA}
                                    />
                                </WinTeamsMountDiv>
                                <WinScoreDiv>{teamAScore}</WinScoreDiv>
                            </div>
                            <WinTeamsTitleDiv style={{marginTop: '5px'}}>
                                RIGHT TEAM
                            </WinTeamsTitleDiv>
                            <div style={{display: 'flex', flexGrow: 1}}>
                                <WinTeamsMountDiv>
                                    <Players
                                        history={history}
                                        sport={sport}
                                        team={teams.teamB}
                                    />
                                </WinTeamsMountDiv>
                                <WinScoreDiv>{teamBScore}</WinScoreDiv>
                            </div>
                        </WinTeamsDiv>
                    </WinDetailsDiv>
                    <MatchChat
                        page="win"
                        history={history}
                    />
                </div>
                <WinFooterDiv>
                    <WinRematchTimerDiv userPlaying={userPlaying}>
                        <div>{timeUntilNextMatchStart}</div>
                    </WinRematchTimerDiv>
                    {userPlaying && winningTeam !== 'none' &&
                    <div
                        tabIndex={0}
                        className={'standardButton ' + (allowRematch ? '':'disabled')}
                        onClick={() => {handleRematch()}}
                        style={{ padding: '0 1em', marginRight: '10px', backgroundColor: palette.belizeHole, color: palette.base4 }}>
                        {rematchButton}
                    </div>
                    }
                    <div
                        tabIndex={0}
                        onClick={() => handleLeaveGameSocket()}
                        className="standardButton"
                        style={{ padding: '10px 15px', marginRight: '10px'}}
                    >
                        <FontAwesomeIcon style={{marginRight: '.5em'}} icon={['fas', 'home']}/> HOME
                    </div>
                    {userPlaying &&
                        <div
                            tabIndex={0}
                            onClick={() => ModalServerFeedback(dispatch, server)}
                            className={`standardButton ${server.userRated ? 'disabled' : ''}`}
                            style={{ padding: '10px 15px', marginRight: '10px'}}
                        >
                            <FontAwesomeIcon style={{marginRight: '.5em'}} icon={['fas', 'wifi']}/> RATE
                        </div>
                    }
                    {footerContent}
                </WinFooterDiv>
            </WinContainerDiv>
        </WinRootDiv>
    )
};

export default Win;