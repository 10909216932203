/**
 * Only load the Ad-in-Play advert if we are on production
 * Attempting to load the scripts on the development server throws console errors
 */
const isProduction = document.domain !== 'localhost';
if (isProduction) {
    (function(document, tag) {
        var scriptTag = document.createElement(tag), // create a script tag
            firstScriptTag = document.getElementsByTagName(tag)[0]; // find the first script tag in the document
        scriptTag.src = "//api.adinplay.com/libs/aiptag/pub/SNT/slime.network/tag.min.js"; // set the source of the script to your script
        firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag); // append the script to the DOM
    }(document, 'script'));

    var aiptag = aiptag || {};
    aiptag.cmd = aiptag.cmd || [];
    aiptag.cmd.display = aiptag.cmd.display || [];	aiptag.cmd.player = aiptag.cmd.player || [];
    // Settings
    aiptag.consented = true; // GDPR setting, please set this value to false if an EU user has declined or not yet accepted marketing cookies, for users outside the EU please use true and for users accepted the GDPR also use true

    aiptag.cmd.player.push(function() {
        window.adplayer = new window.aipPlayer({
            AD_WIDTH: 960,
            AD_HEIGHT: 540,
            AD_FULLSCREEN: false,
            AD_CENTERPLAYER: false,
            LOADING_TEXT: 'loading advertisement',
            PREROLL_ELEM: function(){return document.getElementById('preroll')},
            AIP_COMPLETE: function ()  {
                /*******************
                 ***** WARNING *****
                 *******************
                 Please do not remove the PREROLL_ELEM
                 from the page, it will be hidden automaticly.
                 If you do want to remove it use the AIP_REMOVE callback.
                 */
                alert("Ad Completed");
            },
            AIP_REMOVE: function ()  {
                // Here it's save to remove the PREROLL_ELEM from the page.
                // But it's not necessary.
            }
        });
    });
}

export const loadAdvert = () => {
    if (window.aiptag) {
        window.aiptag.cmd.display.push(function () {
            window.aipDisplayTag.display('slime-network_300x250');
        });
    }
};
