import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import wsMaster from "./middleware/wsMaster";
import wsMatch from "./middleware/wsMatch";
import socketReducer from './modules/socket/socketReducers';
import shopReducer from './modules/shop/shopReducers';
import selfReducers from "./modules/self/selfReducers";
import matchReducers from "./modules/match/matchReducers";
import slotsReducers from "./modules/slots/slotsReducers";
import themeReducers from "./modules/theme/themeReducers";
import friendsReducers from "./modules/friends/friendsReducers";
import chatReducers from "./modules/chat/chatReducers";
import squadReducers from "./modules/squad/squadReducers";
import presentReducers from "./modules/present/presentReducers";
import radioReducers from "./modules/radio/radioReducers";
import modalReducers from "./modules/modal/modalReducers";
import queueReducers from "./modules/queue/queueReducers";
import devReducers from "./modules/dev/devReducers";
import layoutReducers from "./modules/layout/layoutReducers";
import steamReducers from "./modules/steam/steamReducers";
import settingReducers from "./modules/setting/settingReducers";
import notificationReducers from "./modules/notification/notificationReducers";
import gamepadReducers from "./modules/gamepad/gamepadReducers";
import userReducers from "./modules/users/usersReducers";
import itemModalReducers from "./modules/itemModal/itemModalReducers";
import lootReducers from "./modules/loot/lootReducers";

/**
 * The default middleware includes all of redux toolkits intended helper middlewares
 * The middlewares below are disabled because it causes the app to lag in dev mode (when the state is too large).
 */
const defaultMiddleware = getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
    immutableCheck: false
});
// Combine middleware defaults and custom middleware
const middleware = [...defaultMiddleware, wsMaster, wsMatch];

export const store = configureStore({
    reducer: {
        chat: chatReducers,
        dev: devReducers,
        friends: friendsReducers,
        loot: lootReducers,
        match: matchReducers,
        modal: modalReducers,
        modalItem: itemModalReducers,
        self: selfReducers,
        socket: socketReducer,
        shop: shopReducer,
        theme: themeReducers,
        present: presentReducers,
        radio: radioReducers,
        queue: queueReducers,
        layout: layoutReducers,
        gamepad: gamepadReducers,
        notification: notificationReducers,
        setting: settingReducers,
        slots: slotsReducers,
        steam: steamReducers,
        squad: squadReducers,
        users: userReducers
    },
    middleware: middleware
});

export default store;