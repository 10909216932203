import styled from 'styled-components';
import React from "react";
import UserBanner from '../page/PublicChat/UserBanner/UserBanner.js';

const ShowBannersDiv = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: gray;
`;

const mockData = [
    {
        type: 'sent',
        player: {
            username: 'gordoshlombinkinzys'
        },
    },
    {
        type: 'received',
        player: {
            username: 'gordoshlombinkinzys'
        },
    },
    {
        type: 'online',
        player: {
            username: 'gordoshlombinkinzys',
            roomId: true
        },
    },
    {
        type: 'offline',
        player: {
            username: 'gordo',
            roomId: true
        },
    },
    {
        type: 'online',
        player: {
            username: 'gordo',
            roomId: true
        },
    },
    {
        type: 'public',
        player: {
            username: 'gordo',
            roomId: true,
            bestRank: {
                sport: 'volley',
                rating: 1000
            },
            colorHex: {
                light: '#d3a'
            }
        },
    },
    {
        type: 'public',
        player: {
            username: 'gordoshlombinkinzys',
            roomId: true,
            bestRank: {
                sport: 'hoops',
                rating: 42000
            },
            colorHex: {
                light: '#5de334'
            }
        },
    },
];

export const TestUserBanner = (history) => {
    return (
        <ShowBannersDiv>
            {mockData.map((mock,i) =>
                <UserBanner
                    type={mock.type}
                    key={i}
                    history={history}
                    player={mock.player}
                />
            )}
        </ShowBannersDiv>
    )
};

export default TestUserBanner;