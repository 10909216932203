import palette from "../../styled/Palette";
import React from "react";
import {selfIsCaptain} from "../../data/selectors";
import ModalPremiumPrivateMatch from "../Modal/Type/ModalPremiumPrivateMatch";
import SquadOptionsDropdown from "./SquadOptionsDropdown/SquadOptionsDropdown";
import {squadRequestLeave} from "../../redux/modules/squad/squadActions";
import {PageTypes} from "../../system/types";

const SquadLeadOptions = ({ squad, self, history, dispatch, theme }) => {
    const handlePrivateMatch = () => {
        if (self.premium) {
            history.push(PageTypes.PRIVATE_MATCH);
        } else {
            ModalPremiumPrivateMatch(dispatch,theme,history);
        }
    };

    const requestSquadLeave = () => {
        //todo: Idk how this is interfacing with the server
        dispatch(squadRequestLeave(null));
    };

    return (
        <>
            <button
                tabIndex={0}
                onClick={() => requestSquadLeave()}
                style={{
                    marginRight: '.5em',
                    border: `1px solid ${palette.midnightBlue}`,
                    padding: '5px',
                    color: palette.base4,
                    backgroundColor: palette.wetAsphalt
                }}>
                Leave
            </button>
            {squad.current.length > 1 && selfIsCaptain(squad, self) &&
            <button
                tabIndex={0}
                onClick={() => handlePrivateMatch()}
                style={{
                    marginRight: '.5em',
                    border: `1px solid ${palette.midnightBlue}`,
                    padding: '5px',
                    color: palette.base4,
                    backgroundColor: palette.wetAsphalt
                }}>
                Private Match
            </button>
            }
            <SquadOptionsDropdown
                squad={squad}
                self={self}
            />
        </>
    )
};

export default SquadLeadOptions;