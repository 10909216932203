import styled from 'styled-components';
import palette from "../../styled/Palette";

export const TitleDiv = styled.div`
    width: 100%;
    height: 100%;
    color: ${props => props.color};
    z-index: 99999;
`;


export const TitleLoadingDiv = styled.div`
    color: white;
    background-color: black;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
`;

export const TitleLoadingValueDiv = styled.div`
    display: flex;
    font-size: 3em;
`;
export const TitlePrimaryTextDiv = styled.div`
  font-size: 20vw;
  font-family: Modak, sans-serif;
`;
export const TitleSecondaryTextDiv = styled.div`
  margin-top: 13vw;
  align-items: center;
  margin-left: 20px;
  color: white;
  font-size: 6vw;
  font-family: Indie Flower;
  font-weight: bold;
  letter-spacing: 10px;
`;

export const TitleSubTextDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    whiteSpace: nowrap;
    transition: all 1s linear;
    color: hsl(${props => props.theme.interfaceColor % 360},100%,50%);
    font-size: 25px;
    opacity: 0;
    padding: 6px 20px 6px 20px;
    font-family: 'Indie Flower';
    letter-spacing: 24px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
`;


export const GamepadDiv = styled.div`
        display: flex;
        font-family: Montserrat;
        font-weight: bold;
        justify-content: center;
        margin: 0 1em;
    `;
export const GamepadMountDiv = styled.div`
        display: flex;
        padding: .5em;
        border-radius: 4px;
        background-color: ${palette.wetAsphalt};
        svg {
            font-size: 1.25em;
        }
    `;