import {createReducer} from "@reduxjs/toolkit";
import initialState from "./settingInitialState";

const settingReducers = createReducer(initialState, {
    SETTING_CHAT_BUBBLE: (state,action) => {
        Object.keys(action.payload).map(key => {
            state.chatBubble[key] = action.payload[key];
        });
    },
});

export default settingReducers;