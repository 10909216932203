import React, {useEffect, useState} from "react";
import {TabDiv, TabsContainerDiv} from "../../styled/Tabs";
import CashItems from "./CashItems";
import CashItemView from "./CashItemView";
import Header from "../../component/Header/Header";
import SlimePan from "../../styled/SlimePan";
import {drawerToggle} from "../../redux/modules/socket/socketActions";
import styled from 'styled-components';
import palette from "../../styled/Palette";
import InfoBanner from "../../component/InfoBanner/InfoBanner";
import {shadow} from "../../data/globals";
import {PageTypes} from "../../system/types";

const CashContainerDiv = styled.div`
    width: 70em;
    margin-top: 2em;
`;
const CashBodyDiv = styled.div`
    background-color: ${palette.base1};
    height: 35em;
    box-shadow: ${shadow};
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    
    display: flex;
    justify-content: center;
    align-items: center;
`;

const cashTabs = ['REGULARS','SEASONAL','POTIONS'];

const loadTabs = (activeTab, setActiveTab) => {
    return (
        <TabsContainerDiv>
            {
                cashTabs.map((tab) => {
                    return (
                        <TabDiv
                            tabIndex={0}
                            key={tab}
                            active={activeTab === tab}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.toUpperCase()}
                        </TabDiv>
                    )
                })
            }
        </TabsContainerDiv>
    )
};

const Cash = ({dispatch}) => {
    const [viewingItem,setView] = useState(null);
    const [activeTab,setActiveTab] = useState('REGULARS');
    const [tabs,setTabs] = useState(loadTabs());

    // This assures that the tabs are not rerendered unnecessarily
    useEffect(() => {
        setTabs(loadTabs(activeTab, setActiveTab));
        setView(null);
    }, [activeTab]);

    // Hook we shouldn't really need but just in case something wonks out
    useEffect(() => {
        dispatch(drawerToggle({open: true}));
    }, []);

    return (
        <div className="fullScreen animatedFast slideInRight">
            <Header closeRoute={PageTypes.MAIN} title="CASH SHOP"/>
            <SlimePan style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <CashContainerDiv>
                    <InfoBanner
                        title={'(╯°□°)╯︵ ┻━┻ '}
                        body={
                            <div>
                                Do you hate fair play and working hard to earn your achievements? With moms credit card and her permission you too can pay your way to the top! Most of the items found here naturally have a chance to drop following any match.
                            </div>
                        }
                    />
                    <div style={{marginTop: '2em'}}>
                        {tabs}
                        <CashBodyDiv>
                            {viewingItem ?
                                <CashItemView
                                    dispatch={dispatch}
                                    setView={setView}
                                    item={viewingItem}
                                />
                                :
                                <CashItems
                                    activeTab={activeTab}
                                    setView={setView}
                                />
                            }

                        </CashBodyDiv>
                    </div>
                </CashContainerDiv>
            </SlimePan>
        </div>
    )
};

export default Cash;

/*
<CashShopDisclaimerDiv>
    All purchases are final and can not be refunded. We reserve the right to modify or remove any item.<br/> Item owners will be compensated at our discretion. Thank you for supporting the production of Slime.lol
</CashShopDisclaimerDiv>
 */