/**
 * The selection of servers that is by default set, and can be modified by the user.
 * @param acceptableServers - The servers delivered by master
 */
export const userServerSelection = (acceptableServers) => {
    // If the user overrides the preferred value, use the users value instead
    let userSelection = acceptableServers.filter(server => typeof server.override === 'boolean' ? server.override : server.acceptable);
    // If there are no enabled servers, use the first preferred server instead of the users selection
    if (userSelection.filter(selection => typeof selection.override === 'boolean' ? selection.override === true : selection.acceptable === true).length === 0) {
        userSelection = [acceptableServers[0]];
    }
    // The server only needs the gameServerKeys
    return userSelection.map(server => server.gameServerKey);
};

