import React, {useEffect, useRef, useState} from "react";
import palette from "../../styled/Palette";
import styled from 'styled-components';
import {getTierData} from "../../data/tier";

const CardButtonDiv = styled.div`
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #404040;
        padding: 5px 10px;
        width: 50%;
        justify-content: center;
        &:hover {
            background-color: #6e6e6e;
        }
    `;
export const CardTierFooter = styled.div`
       color: ${props => props.color};
       background-color: ${palette.base0};
       font-size: 1.5em;
       justify-content: center;
       align-items: center;
       display: flex;
       flex-grow: 1;
       height: 100%;
    `;
const CardBackFooterDiv = styled.div`
    cursor: pointer;
    font-size: 12px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${palette.base5};
    color: ${palette.base4};
    font-weight: bold;
`;

const CardBackFooter = ({refCancelButton,refBuyButton, item, coins, confirmPurchase, setConfirmPurchase, handlePurchase, history, handleUserFlip, isFlipped,tierData}) => {
    const [tierColor] = useState(tierData.color);
    const [tierText] = useState(tierData.name.toUpperCase());

    return (
        <CardBackFooterDiv>
            {(()=>{
                if (confirmPurchase) {
                    return (
                        <>
                            <CardButtonDiv
                                ref={refCancelButton}
                                tabIndex={isFlipped ? 0 : -1}
                                onClick={(e) => {
                                    setConfirmPurchase(false);
                                }}
                                style={{borderRight: `1px solid ${palette.base6}`}}
                            >
                                CANCEL
                            </CardButtonDiv>
                            {coins >= item.price &&
                            <CardButtonDiv
                                tabIndex={isFlipped ? 0 : -1}
                                onClick={(e) => {handlePurchase(e, item)}}
                                style={{ flexGrow: 1, height: '100%'}}
                            >
                                CONFIRM
                            </CardButtonDiv>
                            }
                        </>
                    )
                }
                if (history && history.location.pathname === '/market') {
                    return (
                        <>
                            <CardButtonDiv
                                ref={refBuyButton}
                                style={{borderRight: `1px solid ${palette.base6}`}}
                                tabIndex={isFlipped ? 0 : -1}
                                onClick={(e) => {
                                    setConfirmPurchase(true);
                                }}>
                                BUY
                            </CardButtonDiv>
                            <CardButtonDiv
                                tabIndex={isFlipped ? 0 : -1}
                                onClick={(e) => {
                                    handleUserFlip()
                                }}
                            >
                                BACK
                            </CardButtonDiv>
                        </>
                    )
                }
                return (
                    <CardTierFooter
                        onClick={(e) => {
                            handleUserFlip()
                        }}
                        color={tierColor}
                    >
                        {tierText}
                    </CardTierFooter>
                );
            })()}
        </CardBackFooterDiv>
    )
};

export default CardBackFooter;