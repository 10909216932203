import styled from 'styled-components';
import palette from "../../styled/Palette";

export const HeaderDiv = styled.div`
    position: relative;
    border: solid black;
    border-width: 0 0 1px 0;
    flex-shrink: 0;
    height: 3.5em;
    width: 100%;
    background-color: #2c3e50;
    font-size: 16px;
    color: ${palette.base4};
    font-weight: bold;
`;

export const CloseButtonSpan = styled.span`
    font-size: 1.5em;
    position: absolute;
    right: .25em;
    top: .25em;
    cursor: pointer;
    color: #c0c0c0;
    &:hover {
        color: #ffffff;
    }
`;