import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import {modalDetails} from "../../../data/achievements";
import Icon from "../../../data/icon";
import {getTierData} from "../../../data/tier";
import potato from "../../../images/fruitDivisions/potato.png";
import kiwi from "../../../images/fruitDivisions/kiwi.png";
import blueberry from "../../../images/fruitDivisions/blueberry.png";
import plum from "../../../images/fruitDivisions/plum.png";
import cherry from "../../../images/fruitDivisions/cherry.png";
import styled from 'styled-components';
import palette from "../../../styled/Palette";
import {rank} from "../../../data/rating";

const images = [potato,kiwi,blueberry,plum,cherry];
const RankContainerDiv = styled.div`
    
`;
export const RankImg = styled.img`
    width: 4em;
`;
export const RankTextDiv = styled.div`
    font-weight: bold;
    font-size: .8em;
    color: ${palette.base4};
`;

export let GameSelectData;

const ModalGameSelect = (dispatch) => {

    GameSelectData = (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center',padding: '1em 0'}}>
            <div style={{margin: '.5em 0'}}>{Icon('check-circle')} Ability Mode can be turned off to play without item benefits.</div>
            <div style={{margin: '.5em 0'}}>{Icon('fist-raised')} Casual matches only track as part of your total games played.</div>
            <div style={{margin: '.5em 0'}}>{Icon('boxing-glove')} Ranked match outcomes will determine rating and league ranking.</div>
        </div>
    );

    dispatch(modalEnable({
        title: 'About online matches',
        body: 'GameSelect'
    }))
};

export default ModalGameSelect;