
const palette = {
    base7: '#1a1a1a',
    base6: '#1f1f1f',
    base0: '#2A2A2A',
    base5: '#2F2F2F',
    base3: '#343434',
    base1: '#404040',
    base2: '#6b6b6b',
    base4: '#B9B9B9',

    turquoise: '#1abc9c',
    greenSea: '#16a085',
    emerald: '#2ecc71',
    nephritis: '#27ae60',
    peterRiver: '#3498db',
    belizeHole: '#2980b9',
    amethyst: '#9b59b6',
    wisteria: '#8e44ad',
    wetAsphalt: '#34495e',
    midnightBlue: '#2c3e50',
    sunFlower: '#f1c40f',
    orange: '#f39c12',
    carrot: '#e67e22',
    pumpkin: '#d35400',
    alizarin: '#e74c3c',
    pomegranate: '#c0392b',
    clouds: '#ecf0f1',
    silver: '#bdc3c7',
    concrete: '#95a5a6',
    asbestos: '#7f8c8d',
};

export default palette;