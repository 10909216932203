const OverheadChat = (context, teams, percentageOfFrame, status) => {
    const chatPersistence = 5000;
    teams.teamA.concat(teams.teamB).forEach((player) => {
        if (player.chatTime + chatPersistence > Date.now() && player.chatBubble) {
            context.globalAlpha = 1;
            let size = player.stats.size * player.scale;
            let lerpX =
                (player.position.x - player.lastPosition.x) * percentageOfFrame + player.position.x;
            let lerpY =
                (player.position.y - player.lastPosition.y) * percentageOfFrame + player.position.y;
            context.font = '30px Gochi Hand';
            context.fillStyle = player.colorHex;
            context.textAlign = 'center';
            context.fillText(
                player.chatMessage,
                status === 'live' ? lerpX : player.position.x,
                status === 'live' ? lerpY - 100 * size : player.position.y - 100 * size
            );
        }
    });
};

export default OverheadChat;
