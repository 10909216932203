import React, { useEffect, useRef } from 'react';
import Header from "../../component/Header/Header";
import Page from '../../styled/Page';
import SignInForms from "./SignInForms";
import SlimePan from "../../styled/SlimePan";
import { useSelector } from 'react-redux';
import {
    SignInTitle,
    SignInSlime,
    SignInLol
} from './SignInStyle';
import styled from 'styled-components';
import LoginFeedback from "../../component/SideNav/LoginFeedback";
import {shadow} from "../../data/globals";
import {PageTypes} from "../../system/types";

const SignInDiv = styled.div`
    box-shadow: ${shadow};
    background-color: rgba(52,52,52,.5);
    border-radius: 5px;
    display: flex;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding: 6em 2em 2em 2em;
    margin-top: 20em;
    width: 28em;
`;

const SignIn = ({history}) => {
    const username = useSelector(state => state.self.username);
    const theme = useSelector(state => state.theme);

    useEffect(() => {
        if (username) {
            setTimeout(() => {
                history.push(PageTypes.MAIN);
            },1000);
        }
    }, [username]);

    return (
        <Page className="animatedFast slideInRight">
            <div style={{display: 'flex', flexGrow: '1', flexDirection: 'column', fontSize: '10px'}}>
                <Header closeRoute={PageTypes.MAIN} title="SIGN IN"/>
                <SlimePan style={{display: 'flex', flexDirection: 'column', flexGrow: 1, color: 'white', alignItems: 'center'}}>
                    <SignInDiv>
                        <SignInTitle>
                            <SignInSlime color={theme.interfaceColor}>
                                slime
                            </SignInSlime>
                            <SignInLol>
                                .LOL
                            </SignInLol>
                        </SignInTitle>
                        <LoginFeedback />
                        <SignInForms />
                    </SignInDiv>
                </SlimePan>
            </div>
        </Page>
    );
};

export default SignIn;