import styled from 'styled-components';
import palette from "../../styled/Palette";
import {shadow} from "../../data/globals";

export const OptionDiv = styled.div`
    visibility: hidden;
    cursor: pointer;
    font-size: 4em;
    color: ${palette.emerald};
`;


export const ItemSlotDiv = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    position: relative;
    padding: .5em;

    width: ${props => props.slotWidth ? `${props.slotWidth}em` : 'auto'};
    height: ${props => props.slotHeight ? `${props.slotHeight}em` : 'auto'};
    margin: ${props => props.slotMargin ?? '.25em'};
    
    ${props => props.disabled ? 'pointer-events: none;':''}
    cursor: ${props => props.disabled ? 'inherit' : 'pointer'};
    
    box-shadow: ${shadow};
    
    ${props => `border: 4px solid ${props.showActive}`};
    
    background-color: ${props => props.backgroundColor ?? palette.base6};
    &:hover, :focus {
        border: 4px solid ${palette.belizeHole};
        background-color: ${props => props.backgroundColor ?? palette.base5};
    }
    
    &:active {
        border: 4px solid #fff;
    }

    img {
        pointer-events: none;
        width: 100%;
    }
    svg {
        pointer-events: none;
    }

    &:before {
        display: flex;
      content: "";
      background-image: url(${props => props.backgroundImage});
      background-position: 50% 50%;
      background-size: 200%;
      z-index: 2;
      filter: brightness(1) contrast(1);
      transition: all .33s ease;
      mix-blend-mode: color-dodge;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 1;
      animation: rainbow-background-shift 30s infinite;
    }
    &:after {
      content: "";
      background-image: ${props => props.backgroundImage ? 'linear-gradient(125deg, #ff008450 15%, #fca40040 30%, #ffff0030 40%, #00ff8a20 60%, #00cfff40 70%, #cc4cfa50 85%)' : ''};
      background-position: 50% 50%;
      background-size: 150%;
      background-blend-mode: overlay;
      z-index: 2;
      filter: brightness(1) contrast(1);
      transition: all .33s ease;
      mix-blend-mode: color-dodge;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: .2;
      animation: rainbow-background-shift 30s infinite;
    }
`;