import {ballInitialState} from "../redux/modules/match/matchInitialState";

/**
 * These are globals which we do not want in the redux store.
 * 1. They update so frequently it would hog the redux debugger.
 * 2. It actually causes a latency issue from updating redux so frequently.
 */
const HOOP_TALL = 10;
const HOOP_DIAMETER = 130;
const RIM_WIDTH = 15;
export const REGULAR_FLOOR_COLOR = '#bdbdbd';
const matchScopeInitialState = {
    ball: ballInitialState,
    teams: {
        teamA: [],
        teamB: []
    },
    ballCrit: [],
    powerShot: [],
    tickCounter: 0,
    netHeight: 250,
    hoopDiameter: HOOP_DIAMETER,
    hoopHeight: 575,
    hoopSize: HOOP_DIAMETER + RIM_WIDTH + RIM_WIDTH,
    hoopTall: HOOP_TALL,
    innerPlatformHeight: HOOP_TALL / 1.4,
    rimWidth: RIM_WIDTH,
    floorColor: REGULAR_FLOOR_COLOR,
    trail: [],
    p1Score: 0,
    p2Score: 0,
    abilityEffects: [],
    projectiles: [],
};
export const matchScopeReset = () => {
    matchScope = matchScopeInitialState;
};
let matchScope = {
    ...matchScopeInitialState
};

export default matchScope;