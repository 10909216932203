import { ballTrailParticles } from '../particles/ballTrailParticles';
import { slowBallParticles } from '../particles/slowBallParticles';
import { speedBallParticles } from '../particles/speedBallParticles';
import {devMode} from "../../data/globals";

export default (sandbox, protonBackground, protonForeground) => {
    let allActiveAbilities = [];
    let shouldUpdate = true;
    sandbox.teams.teamA.concat(sandbox.teams.teamB).forEach(player =>{
        let activeAbility = player.activeAbility;
        if(activeAbility){
            let ability = player.abilities[activeAbility];
            if(ability){
                if(ability.active){
                    if(ability.name === 'pause') shouldUpdate = false;
                    allActiveAbilities.push(ability.name);
                }
            }
        }
    })
    if (allActiveAbilities.includes('slowBall')) {
        slowBallParticles.enable();
        slowBallParticles.update(sandbox.ball);
    } else {
        slowBallParticles.disable();
    }
    if (allActiveAbilities.includes('speedBall')) {
        speedBallParticles.enable();
        speedBallParticles.update(sandbox.ball);
    } else {
        speedBallParticles.disable();
    }
    ballTrailParticles.update(sandbox.ball);
    if(devMode){
        //proton.stats.update(3);
    }
    if(shouldUpdate){
        protonBackground.update();
        protonForeground.update();
    }
};
