import {ClockDiv, RestockDiv, RestockMountDiv,RestockTimeLeftDiv} from "./MarketStyle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState, useEffect, useRef} from "react";
import {timer} from "../../data/format";
import { useSelector } from 'react-redux';
import {playSound} from "../../data/sound";

const RESTOCK_DELAY = 2000;
//todo: The clock should reference a global restock time, not individual itemExpiration
const RestockClock = ({refOverlayDiv,setRestocking}) => {
    const shop = useSelector(state => state.shop);
    const { expiration } = shop;

    const handleRestock = () => {
        playSound('Space_Notification15', .5);

        const animateOverlay = () => {
            refOverlayDiv.current.classList.remove('fadeOut');
            refOverlayDiv.current.classList.add('fadeIn');
            refOverlayDiv.current.style.display = 'flex';
            setTimeout(() => {
                if (refOverlayDiv.current) {
                    refOverlayDiv.current.classList.remove('fadeIn');
                    refOverlayDiv.current.classList.add('fadeOut');
                    setTimeout(() => {
                        if (refOverlayDiv.current) {
                            refOverlayDiv.current.style.display = 'none';
                        }
                    }, 1000);
                }
            }, 4000);
        };
        animateOverlay();
        // This is a hack implemented in order to rerender the <Card/> components in order to queue up the image change.
        setTimeout(() => {
            setRestocking(true);
            setTimeout(() => {
                setRestocking(false);
            }, 500);
        }, RESTOCK_DELAY);
    };
    const [restockTime, setRestockTime] = useState();
    const handleTimeUpdate = () => {
        const time = Math.round((expiration - Date.now()) / 1000);
        //Note: Added the ? operator suspecting this may have caused report#237
        const timeFormatted = timer(time)?.toUpperCase();
        setRestockTime(timeFormatted);
        if (time === RESTOCK_DELAY/1000) {
            handleRestock();
        }
    };
    let restockTimeInterval;

    /**
     * Counts down the seconds remaining before a restock
     * todo: Optimization - Determine the time until the interval actually needs to begin setting the restockTime state.
     *  Hourly, then by the minute, until finally seconds. This would cut down on the rerendering this page does.
     */
    useEffect(() => {
        // Initialize the time without waiting
        restockTimeInterval = handleTimeUpdate();
        // Update the time each second
        restockTimeInterval = setInterval(handleTimeUpdate, 1000);
        return () => {
            clearInterval(restockTimeInterval);
        }
    }, []);

    /**
     * Keep the data hydrated by watching the shop store for changes
     */
    useEffect(() => {
        clearInterval(restockTimeInterval);
        restockTimeInterval = setInterval(handleTimeUpdate, 1000);
        return () => {
            clearInterval(restockTimeInterval);
        }
    }, [shop]);

    return (
        <ClockDiv>
            <RestockMountDiv>
                <RestockDiv>ITEMS RESTOCK</RestockDiv>
                <RestockTimeLeftDiv>{restockTime}</RestockTimeLeftDiv>
            </RestockMountDiv>
            <FontAwesomeIcon
                style={{
                    fontSize: '4em',
                    marginLeft: '.25em'
                }}
                icon={['fas', 'clock']}
            />
        </ClockDiv>
    )
}
export default RestockClock;