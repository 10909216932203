import {createReducer} from "@reduxjs/toolkit";
import matchInitialState from "./matchInitialState";

export const TIME_LIMIT_BEFORE_REMATCH_OR_BOOT = 30;

const matchReducers = createReducer(matchInitialState, {
    MATCH: (state, action) => {
        // We ultimately don't want to be doing this because its not explicitly assigning state.
        const setMultiple = action.payload.hasOwnProperty('payload');
        if (setMultiple) {
            Object.keys(action.payload.payload).forEach(attribute => {
                if (attribute !== 'event') {
                    state[attribute] = action.payload.payload[attribute];
                }
            });
        } else {
            //console.log('2', action.payload);
            state[action.payload] = action.payload;
        }
    },
    UPDATE_MATCH_SCORE: (state, action) => {
        const { p1Score, p2Score } = action.payload;
        if (action.payload.hasOwnProperty('p1Score')) {
            state.p1Score = p1Score;
        }
        if (action.payload.hasOwnProperty('p2Score')) {
            state.p2Score = p2Score;
        }
    },
    UPDATE_MATCH_STATUS: (state, action) => {
        state.status = action.payload;
    },
    UPDATE_TEAMS: (state,action) => {
        state.teams = action.payload;
    },
    UPDATE_MATCH_VIEW: (state,action) => {
        const { chat, rule, options } = action.payload;
        if (action.payload.hasOwnProperty('chat')) state.view.chat = chat;
        if (action.payload.hasOwnProperty('rule')) state.view.rule = rule;
        if (action.payload.hasOwnProperty('options')) state.view.options = options;
    },

    UPDATE_READY_STATUS: (state, action) => {
        // Update the status of the player who readied up
        const { team, teamIndex, status } = action.payload;
        const teamsChange = state.teams;
        teamsChange[team][teamIndex].ready = status;
        state.teams = teamsChange;
    },
    UPDATE_REMATCH_READY_STATUS: (state, action) => {
        const { team, teamIndex, status } = action.payload;
        let update = {...state.teams}; //clone
        update[team][teamIndex].rematch = status; //mutate
        state.teams = update; //update
    },

    UPDATE_USER_ACTIVE_STATUS: (state, action) => {
        const { team, teamIndex, status } = action.payload;
        let update = {...state.teams}; //clone
        update[team][teamIndex].active = status; //mutate
        state.teams = update; //update
    },

    UPDATE_TIME_LEFT: (state, action) =>{
        state.timeLeft = action.payload;
        state.timeLeftTimestamp = Date.now();
    },

    UPDATE_BETS: (state, action) =>{
        state.bets = action.payload;
    },

    UPDATE_TIME_UNTIL_MATCH_START: (state, action) => {
        state.timeUntilStart = action.payload;
    },
    UPDATE_MODIFIERS: (state, action) => {
        state.modifiers = action.payload;
    },
    TOGGLE_CENTER_WALL: (state, action) => {
        state.modifiers['Center Wall'].status = action.payload ? 'on' : 'off';
    },
    ADD_MATCH_CONTEXT_MESSAGE: (state, action) => {
        state.contextMessages = state.contextMessages.concat(action.payload);
    },
    REMOVE_MATCH_CONTEXT_MESSAGE: (state) => {
        state.contextMessages.shift();
    },
    UPDATE_MATCH_RESULTS: (state,action) => {
        state.results = action.payload;
    },

    MATCH_START_REBOOT_TIMER: (state,action) => {
        state.timeUntilNextMatchStart = TIME_LIMIT_BEFORE_REMATCH_OR_BOOT;
    },
    MATCH_STOP_REBOOT_TIMER: (state,action) => {
        state.timeUntilNextMatchStart = null;
    },
    MATCH_UPDATE_REBOOT_TIMER: (state,action) => {
        if (state.timeUntilNextMatchStart !== null) {
            state.timeUntilNextMatchStart = action.payload;
        }
    },

    MATCH_UPDATE_SERVER_METADATA: (state,action) => {
        state.server = action.payload;
    }

    // UPDATE_MODIFIER_VOTES: (state, action) => {
    //     const { myVote, voteCount, modifier } = action.payload;
    //     if (myVote) state.modifiers[modifier].myVote = myVote;
    //     if (voteCount) state.modifiers[modifier].voteCount = voteCount;
    // },
});

export default matchReducers;