import palette, { legacy } from '../styled/Palette';

export const tiers = ['common','unusual','remarkable','extraordinary','legendary'];

// Accepts a tier level to return information about that level
export const getTierData = (tier) => {
    return {
        0:{
            color: '#808080',
            name: 'common',
            chance: '50%'
        },
        1:{
            color: palette.emerald,
            name: 'unusual',
            chance: '30%'
        },
        2:{
            color: palette.peterRiver,
            name: 'remarkable',
            chance: '14%'
        },
        3:{
            color: palette.wisteria,
            name: 'extraordinary',
            chance: '5%'
        },
        4:{
            color: palette.pomegranate,
            name: 'legendary',
            chance: '1%'
        },
    }[tier]
};

export default getTierData;

// Just to see the colors in the editor (don't use these)
const tierHex = {
    0: '#808080',
    1: '#2ecc71',
    2: '#3498db',
    3: '#8e44ad',
    4: '#c0392b'
};