import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MatchChat from '../../component/Chat/MatchChat'
import MatchOptions from './MatchOptions';
import BetsOverlay from './Bets/BetsOverlay/BetsOverlay';
import Ping from "../../component/Match/Ping";
import {updateMatchView} from "../../redux/modules/match/matchActions";
import {drawerToggle} from "../../redux/modules/socket/socketActions";
import {gamepadToggleNavigation} from "../../redux/modules/gamepad/gamepadActions";
import Score from "./Score/Score";
import ItemSwitchManager from "../../component/ItemSwitchManager";
import matchScope from "../../data/matchScope";
import {gameSocket} from "../../redux/middleware/wsMatch";
import {sandbox} from "../../system/physics/physics";

const Match = ({ history, chatRender }) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const { rooms } = useSelector(state => state.socket);
    const { coins, username } = useSelector(state => state.self);
    const { roomId} = useSelector(state => state.match);

    const [showOverlay, setShowOverlay] = useState(false);
    const [escrowCoins,setEscrowCoins] = useState(0);

    useEffect(() => {
        dispatch(drawerToggle({open: false}));
        // Render the chat
        dispatch(updateMatchView({ chat: true }));
        // Assume the user wants to interact with the game and not the UI
        dispatch(gamepadToggleNavigation(false));
    }, []);

    return (
        <>
            <Ping />
            <Score gameMetadataProvider={matchScope} />
            <ItemSwitchManager gameMetadataProvider={matchScope}/>
            {chatRender &&
                <MatchChat
                    page="match"
                    history={history}
                />
            }
            <MatchOptions
                bets={rooms[roomId] ? rooms[roomId].bets : []}
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
                history={history}
            />
            <BetsOverlay
                username={username}
                coins={coins}
                theme={theme}
                bets={rooms[roomId] ? rooms[roomId].bets : []}
                escrowCoins={escrowCoins}
                setEscrowCoins={setEscrowCoins}
                roomId={roomId}
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
            />
        </>
    )
};
export default Match;