

export const selectLeader = (squad, username) => {
    let result = false;
    if (squad.length === 0) {
        result = true;
    } else {
        squad.forEach(user => {
            if (user.name === username) {
                if (user.captain) {
                    result = true;
                }
            }
        });
    }
    return result;
};