import {createReducer} from "@reduxjs/toolkit";
import chatInitialState from "./chatInitialState";
import { history } from '../../../index';
import {censor} from "../../../system/chat";
import {chatTypes} from "../../../component/Chat/MatchChat";
import {PageTypes} from "../../../system/types";

const sideMap = (side) => {
    switch (side) {
        case 'left': return 'teamA';
        case 'right': return 'teamB';
    }
};

const chatReducers = createReducer(chatInitialState, {
    CHAT_LOAD_HISTORY: (state, action) => {
        const message = action.payload;

        // Store uncensored chat in the event the user switches levels
        state.publicUncensored = [...state.public, ...message];
        // When retrieving the history of messages, the payload contains multiple messages
        const censoredMessage = message.map(m => {return {...m, msg: censor(m.msg)}});
        state.public = [...state.public, ...censoredMessage];
    },
    UPDATE_CHAT: (state, action) => {
        const message = action.payload;
        // Determine the notification state for the chat button
        const onPublicChatPage = history.location.pathname === PageTypes.PUBLIC_CHAT;
        const publicUnread = onPublicChatPage ? 0 : (state.unread +1);
        state.unread = publicUnread;

        // Store uncensored chat in the event the user switches levels
        state.publicUncensored = [...state.publicUncensored, message];
        // Usually there is only one message, though.
        const censoredMessage = {...message, msg: censor(message.msg)};
        state.public = [...state.public, censoredMessage];
    },
    // This action is used to reload the chat based on the users specified censor level
    RELOAD_CHAT: (state, action) => {
        if (action.payload === 'disabled') {
            state.public = state.publicUncensored;
        } else {
            const censoredChat = state.publicUncensored.map(m => {return {...m, msg: censor(m.msg)}});
            state.public = censoredChat;
        }
    },

    UPDATE_MATCH_CHAT: (state, action) => {
        const message = action.payload;
        state.match = [...state.match, message];
    },
    RESET_MATCH_CHAT: (state) => {
        state.match = [];
    },

    // This action is used to track the users message sending limits
    UPDATE_SENT_CHAT: (state, action) => {
        const { recentSent, banned } = action.payload;
        if (action.payload.hasOwnProperty('recentSent')) state.recentSent = recentSent;
        if (action.payload.hasOwnProperty('banned')) state.banned = banned;
    },

    UPDATE_BET: (state, action) => {
        const message = action.payload;
        switch (message.event) {
            case 'betMade': {
                message.message = `${message.data.maker.username} has placed an offer`;
                break;
            }
            case 'betTaken': {
                message.message = `${message.data.maker.username} has revoked their offer`;
                break;
            }
            case 'betCanceled': {
                message.message = `${message.data.taker.username} (${message.data.amount * message.data.denominator}) has taken ${message.data.maker.username}s offer (${message.data.amount})`;
                break;
            }
        }
        state.match = [...state.match, message];
    },
    UPDATE_CHAT_PUBLIC_READS: (state, action) => {
        state.unread = action.payload;
    },

    NOTIFY_CHAT_MATCH_END: (state, action) => {
        const message = action.payload;
        state.match = [...state.match, {
            chatType: chatTypes.gameEnd,
            reason: message.reason,
            winningTeam: message.winningTeam,
        }];
    },
    NOTIFY_CHAT_BET_OUTCOMES: (state, action) => {
        const message = action.payload;
        const bets = [];
        message.bets.forEach((bet) => {
            bets.push({
                chatType: chatTypes.bets,
                maker: bet.maker,
                taker: bet.taker,
                amount: bet.amount,
                numerator: bet.numerator,
                denominator: bet.denominator,
                active: bet.active,
                won: sideMap(bet.side) === message.winningTeam,
                winningTeam: message.winningTeam,
            });
        });
        state.match = [...state.match, ...bets];
    },
    NOTIFY_CHAT_READY_UPDATE: (state, action) => {
        const message = action.payload;
        const player = message.teams[message.team][message.teamIndex];
        state.match = [...state.match, {
            chatType: chatTypes.isReady,
            colorName: player.colorName,
            colorHex: player.colorHex
        }];
    },
    NOTIFY_CHAT_PLAYER_GONE: (state, action) => {
        const message = action.payload;
        let player = message.teams[message.team][message.teamIndex];
        state.match = [...state.match, {
            message: `${player.colorName}`,
            colorName: player.colorName,
            colorHex: player.colorHex,
            chatType: chatTypes.event,
        }];
    },
});

export default chatReducers;