
export default {
    'connected': false,
    'navigating': false,
    
    'button_1': false,
    'button_2': false,
    'button_3': false,
    'button_4': false,
    'shoulder_top_left': false,
    'shoulder_top_right': false,
    'shoulder_bottom_left': false,
    'shoulder_bottom_right': false,
    'select': false,
    'start': false,
    'stick_button_left': false,
    'stick_button_right': false,
    'd_pad_up': false,
    'd_pad_down': false,
    'd_pad_left': false,
    'd_pad_right': false,
};