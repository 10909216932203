import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import LinkURL from "../../LinkURL/LinkURL";

export let BrowserData;

const ModalBrowserWarning = (dispatch) => {

    BrowserData = (
        <div style={{padding: '1em'}}>
            You must use the
            &thinsp;
            <LinkURL title={'Chrome Browser'} url={'https://www.google.com/chrome/'} />
            &thinsp;
            to play Slime on the web. The chrome browser provides experimental technology (web workers), which we use in order to provide the best experience.
        </div>
    );

    dispatch(modalEnable({
        body: 'BrowserWarning',
        title: 'This browser is not supported',
        noOptions: true
    }));
};

export default ModalBrowserWarning;