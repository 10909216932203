import {createReducer} from "@reduxjs/toolkit";
import initialState from "./lootInitialState";

const lootReducers = createReducer(initialState, {
    LOOT_FOCUS_ITEM: (state, action) => {
        state.item = action.payload;
    },
    LOOT_UNFOCUS_ITEM: (state, action) => {
        state.item = null;
    },
});

export default lootReducers;