import {createReducer} from "@reduxjs/toolkit";
import matchInitialState from "./friendsInitialState";
import {masterSocket} from "../../middleware/wsMaster";

const clearAll = (state, friendName) => {
    return {
        online: state.online.filter(user => user.username !== friendName),
        offline: state.offline.filter(user => user.username !== friendName),
        sent: state.sent.filter(user => user.username !== friendName),
        received: state.received.filter(user => user.username !== friendName),
    };
};

const friendsReducers = createReducer(matchInitialState, {
    UPDATE_FRIENDS: (state, action) => {
        const { online, offline, sent, received } = action.payload;
        state.online = online;
        state.offline = offline;
        state.sent = sent;
        state.received = received;
    },
    REMOVE_FRIEND: (state, action) => {
        const friendName = action.payload;
        const updatedState = clearAll(state, friendName);
        state.online = updatedState.online;
        state.offline = updatedState.offline;
        state.sent = updatedState.sent;
        state.received = updatedState.received;
    },
    UPDATE_FRIEND_GAME_STATUS: (state, action) => {
        const message = action.payload;
        const change = state.online;
        change.forEach(friend => {
            if (friend.username === message.username) {
                friend.roomId = message.roomId;
                friend.joinUrl = message.joinUrl;
            }
        });
        state.online = change;
    },
    UPDATE_FRIENDS_STATUS: (state, action) => {
        const message = action.payload;
        const updatedFriends = clearAll(state, message.username);

        let attributes;
        if (message.status === 'online') {
            attributes = {'username': message.username, 'port': message.port, 'roomId': message.roomId};
        } else {
            attributes = {'username': message.username};
        }

        let newArray = updatedFriends[message.status];
        newArray.unshift(attributes);

        let change = updatedFriends;
        change[message.status] = newArray;

        state.online = change.online;
        state.offline = change.offline;
        state.sent = change.sent;
        state.received = change.received;
    },
    FRIENDS_ALERT: (state, action) => {
        state.alert = action.payload.status;
    }
});

export default friendsReducers;