import env from '../env/env';
export const AMOUNT_COIN_EARNED_FROM_WIN = 100;
export const ISSUE_REPORT_CHARACTER_MAXIMUM = 512;
export const ISSUE_REPORT_CHARACTER_MINIMUM = 50;
export const ANIMATION_SELL_DURATION = 1000;
export const ITEM_SLOT_SIZE = 130;
export const USER_BANNER_SLOT_SIZE = 50;
export const WATCH_MATCH_PANEL_SLOT_SIZE = 120;
export const DURATION_WAIT_AUTO_SIGN_IN_BEFORE_FADE = 1500;
export const DURATION_COOLDOWN_PING_MATCH_SERVERS = 10000;
export const DURATION_COOLDOWN_CHAT_SUSPENSION = 60000;

export const devMode = env.environment === 'dev';

export const sports = ['volley','soccer','hoops'];

export const shadow = `0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.15), 0 2px 1px -1px rgba(0,0,0,.13)`;

/**
 * When we need to get a specific response from a server we use this
 * because we already have an open connection with the server.
 */
export const restfulCall = (websocketserver, message, eventToListenTo) => {
    return new Promise((resolve, reject)=>{
        websocketserver.send(JSON.stringify(message));
        websocketserver.addEventListener('message', function listenToIt(message){
            let msg = JSON.parse(message.data);
            if(msg.event === eventToListenTo){
                websocketserver.removeEventListener('message',listenToIt);
                resolve(msg);
            }
        })
    })
};