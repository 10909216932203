import styled from 'styled-components';
import palette from '../../styled/Palette';

export const ClockDiv  = styled.div`
`;
export const MatchPingDiv = styled.div`
    position: absolute;
    left: .5em;
    bottom: .5em;
    font-family: Montserrat;
    color: ${palette.base4};
`;
