import React, {useState} from 'react';
import palette from '../../../../styled/Palette';
import {useSelector} from "react-redux";
import {
    BarGraphLi,
    BarTextDiv,
    BarGraphLabelDiv,
    BarGraphDiv
} from './BarGraphStyle';

const BarGraph = ({focusItem, label, stat}) => {
    const theme = useSelector(state => state.theme);
    const { outfit, stats } = useSelector(state => state.self);

    const getBarWidth = (stat) => {
        return ((stat * 100) / 2) + '%';
    };
    const getBarChange = (currentStat,newStat) => {
        if (typeof(currentStat) === 'undefined') currentStat = 0;
        let modifier =  newStat - currentStat;

        let setWidth = ((modifier) / 2) + '%';
        if (modifier > 0) {
            return (
                {width: setWidth, backgroundColor: palette.emerald}
            );
        }
        else {
            setWidth = -1 * ((modifier) / 2) + '%';
            return (
                {width: setWidth, marginLeft: '-'+setWidth, backgroundColor: palette.pomegranate}
            );
        }
    };
    const renderDifference = () => {
        if (!outfit[focusItem.category]) {
            return focusItem.stats[stat];
        }
        return focusItem.stats[stat] - outfit[focusItem.category.toLowerCase()].stats[stat];
    };

    return (
        <BarGraphLi>
            <div style={{position: 'absolute', width: '100%', height: '100%', display: 'flex'}}>
                <BarGraphDiv style={{width: getBarWidth(stats[stat])}}/>
                {focusItem?.stats &&
                    <>
                        <BarGraphDiv
                            style={getBarChange(outfit[focusItem.category.toLowerCase()] != null ? outfit[focusItem.category.toLowerCase()].stats[stat] : 0,focusItem.stats[stat])}
                        />
                        <BarGraphLabelDiv
                            green={renderDifference() >= 1}
                        >
                            {renderDifference() !== 0 && renderDifference()}
                        </BarGraphLabelDiv>
                    </>
                }
            </div>
            <BarTextDiv>
                <div style={{textAlign: 'right', marginRight: '1em', width: '2em', color: theme.interfaceColorBright, fontSize: '1em'}}>
                    {Math.round(stats[stat] *100 )-100}
                </div>
                <div style={{color: theme.interfaceColorBright}}>{label}</div>
            </BarTextDiv>
        </BarGraphLi>
    )
};

export default BarGraph;