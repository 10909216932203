import {imageAssets} from "../../../../data/images";

const Face = (player,context,lerpX,lerpY,lerpAngle,size) => {
    let face = player.outfit.face.images[player.emote];
	if(player.alternativeAssets[player.activeAbility]){
		face = player.alternativeAssets[player.activeAbility].outfit.face[player.emote];
	}
    //console.log(face);
    let faceWidth = 77 * size;
    let faceHeight = 77 * size;
    let offset = -.2;
    let playerAngle = lerpAngle;
    context.translate(lerpX, lerpY);
    context.rotate(lerpAngle);
    //console.log(player.stats.size)
    try {
        if (player.direction === 'left') {
            context.scale(-1, 1);
        }
        context.drawImage(face, -faceWidth / 2 - (size * 180 * offset), -faceHeight / 2 - 5, faceWidth, faceHeight);
        if (player.direction === 'left') {
            context.scale(-1, 1);
        }
    }
    catch (err) {
        console.log(err);
    }
    context.rotate(-lerpAngle);
    context.translate(-lerpX, -lerpY);
};

export default Face;