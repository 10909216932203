import {setBackdrop} from "../redux/modules/socket/socketActions";
import {generateBackgroundJson} from "../system/physics/physics";

export const clientGenerateAndLoad = (theme, dispatch) => {
    const background = generateBackgroundJson(theme);
    // Remove unneeded attributes server delivered
    delete background.toString;
    delete background.match;
    // Load the final settings into redux
    dispatch(setBackdrop(background));
};

/**
 * Applies the backdrop to the canvas
 * @param refCanvasBackground - the canvas
 * @param backdrop - the backdrop metadata
 */
export const loadBackground = (refCanvasBackground,backdrop) => {
    const canvas = refCanvasBackground.current;
    const otherColor = '#2C2C2C';

    if (backdrop.type === 'stripe') {
        canvas.style.backgroundImage = `repeating-linear-gradient(
         ${backdrop.rotation}deg,

         ${otherColor},
         ${otherColor} ${backdrop.size}px,

         ${backdrop.fadeColor} ${backdrop.size}px,
         ${backdrop.fadeColor} ${backdrop.size*2}px,

         ${otherColor} ${backdrop.size*2}px,
         ${otherColor} ${backdrop.size*3}px,

         ${backdrop.fadeColor} ${backdrop.size*3}px,
         ${backdrop.fadeColor} ${backdrop.size*4}px)`;
    }
    else if (backdrop.type === 'gradient') {
        canvas.style.background = `linear-gradient(${backdrop.rotation}deg, ${otherColor}, ${backdrop.fadeColor})`;
    }
    else if (backdrop.type === 'image') {
        //todo: we need to start testing rendering full images / gifs / etc
    }
};

// Note: See background.ts for the generator function which is cloned into physics.js on client
