import {getCookie} from "../../../data/generic/generic";

export default {
    song: null,
    title: null,
    paused: false,
    image: null,
    analyser: null,
    volume: getCookie('radioVolumeLevel') || .01,
    loading: false,
    next: null,
    canvas: null,
    mounted: false,
    index: -1,
    history: []
};