import palette from "../styled/Palette";
import React from "react";
import gifSnowballs from "../images/demos/snowballs.gif";
import imageTickets from "../images/ticket3.png";
import imageTicket from "../images/ticket.png";
import imagePotions from "../images/potions.gif";
import imagePresent from "../images/present.png";
import creatureFaces from "../images/creatureFaces.png";
import imageCoin from "../images/slimeCoin.png";
import styled from 'styled-components';
import svggrowthPotion from "../images/potions/potionGreen.png";
import svgspeedPotion from "../images/potions/potionRed.png";
import svgjumpPotion from "../images/potions/potionBlue.png";
import svgairJumpPotion from "../images/potions/potionPurple.png";
import { ReactComponent as SvgSpectralAmulet } from '../images/accessories/spectre.svg'
import { ReactComponent as SvgSlugger } from '../images/accessories/slugger.svg'
import { ReactComponent as SvgRingOfFrost } from '../images/accessories/frostRing.svg'
import { ReactComponent as SvgBrainBlast } from '../images/accessories/fireworks.svg'
import { ReactComponent as SvgShroud } from '../images/accessories/shroud.svg'
import { ReactComponent as SvgShroom } from '../images/accessories/mushroom.svg'
import { ReactComponent as SvgReflect } from '../images/accessories/divert.svg'
import { ReactComponent as SvgAttraction } from '../images/accessories/attractor.svg'
import { ReactComponent as SvgWeb } from '../images/accessories/web.svg'
import { ReactComponent as SvgVanishingPowder } from '../images/accessories/powder.svg'
import { ReactComponent as SvgLevitate } from '../images/accessories/levitate.svg'
import { ReactComponent as SvgGrenade } from '../images/accessories/grenade.svg'
import { ReactComponent as SvgWind } from '../images/accessories/wind.svg'
import { ReactComponent as SvgSpiritOrb } from '../images/accessories/orb.svg'
import { ReactComponent as SvgPinwheel } from '../images/accessories/pinwheel.svg'
import { ReactComponent as SvgRemote } from '../images/accessories/tv-remote.svg'
import { ReactComponent as SvgVhs } from '../images/accessories/vhs.svg'
import { ReactComponent as SvgJetPack } from '../images/accessories/jetpack.svg'
import { ReactComponent as SvgFlashlight } from '../images/accessories/torch.svg'
import { ReactComponent as SvgCage } from '../images/accessories/cage.svg'
import { ReactComponent as SvgBubble } from '../images/accessories/bubble.svg'
import { ReactComponent as SvgEarth } from '../images/accessories/earth.svg'
import { ReactComponent as SvgHook } from '../images/accessories/hook.svg'
import { ReactComponent as SvgWallJump } from '../images/accessories/wallJump.svg'
import { ReactComponent as SvgVacuum } from '../images/accessories/vacuum.svg'
import { ReactComponent as SvgDash } from '../images/accessories/dash.svg'
import { ReactComponent as SvgMeteor } from '../images/accessories/meteor.svg'
import { ReactComponent as SvgSnowball } from '../images/accessories/snowball.svg';
import { ReactComponent as SvgCalculator } from '../images/accessories/calculator.svg';
import drop from "../system/drop";
import {useIcon} from "./icon";
import slimeWizard from "../images/slimeWizard.png";

const ShringSvg = styled.div`
    > svg {
        width: 150px;
        height: 150px;
    }
`;
const DemoPresentNameDiv = styled.div`
    display: flex;
    width: 90%;
    margin-top: .5em;
    div {
        font-weight: bold;
        color: ${palette.base4};
        width: 10em;
        text-align: center;
    }
`;

// Cooldown type Abilities
const dash = {
    abilityName: "dash",
    category:"accessory",
    description: 'Woosh',
    extended: 'This ability allows you dash through the air. It uniquely requires two consecutive key presses in the left or right direction to be activated.',
    name:"Dash",
    season: 'spring',
    tier:0,
    type: 'cooldown',
    image: <SvgDash/>,
};
const focusJump = {
    category:"accessory",
    abilityName:"focusJump",
    name:"Fireworks",
    tier:0,
    type: 'cooldown',
    description: '1776',
    extended: 'This ability allows you to behave like an extravagant explosion, launching you to the ceiling after a short time.',
    image: <SvgBrainBlast />,
    season: 'summer'
};
const reverseBall = {
    name: 'Reflector',
    type: 'cooldown',
    abilityName:"reverseBall",
    category: 'accessory',
    description: 'The ole\' switcheroo',
    extended: 'This ability allows you to reverse the balls velocity across the horizontal plane from any location.',
    tier: 0,
    image: <SvgReflect />
};
const camouflage = {
    name:"Shroud",
    type: 'cooldown',
    abilityName:"camouflage",
    category:"accessory",
    tier:0,
    description: 'Now you see me',
    extended: 'This ability allow to become completely hidden from your opponents and creep around the stage undetected.',
    image: <SvgShroud />,
    season: 'winter'
};
const slowBall = {
    category:"accessory",
    name:"Ring of Frost",
    abilityName:"slowBall",
    tier:0,
    type: 'mana',
    description: 'Chilling',
    extended: 'This ability allows you to slow the velocity of the ball from any location on the stage.',
    image: <SvgRingOfFrost />,
    season: 'winter'
};
const speedBall = {
    category:"accessory",
    name:"Meteor",
    abilityName:"speedBall",
    tier:0,
    type: 'mana',
    extended: 'This ability allows you to increase the velocity of the ball from any location on the stage.',
    description: "That's hot",
    image: <SvgMeteor />,
    season: 'summer'
};
const sticky = {
    name: 'Magnets',
    abilityName:"sticky",
    category: 'accessory',
    description: 'How do they work',
    extended: 'This ability, when contacting the ball, grants access to electromagnetic attraction to carry the ball across the stage.',
    tier: 0,
    type: 'mana',
    image: <SvgAttraction />
};
// Infinite type Abilities
const ghost = {
    category:"accessory",
    name:"Spectral Amulet",
    abilityName:"ghost",
    tier:0,
    description: 'oOoOOOo',
    extended: 'This ability relieves you of all mass allowing you to drift across the stage in a ghostly manner.',
    image: <SvgSpectralAmulet />,
    season: 'autumn',
    type: 'infinite',
};
const levitate = {
    name: 'Levitate',
    abilityName:"levitate",
    category: 'accessory',
    description: 'MiNdFrEaK',
    extended: 'This ability allows you to remain afloat and even glide across the stage until contacting some obstacle.',
    tier: 0,
    type: 'infinite',
    image: <SvgLevitate />,
    season: 'autumn'
};
const bigSlugger = {
    name: 'Big Slugger',
    abilityName:"bigSlugger",
    category: 'accessory',
    description: 'Batter up',
    extended: 'This ability gives you unlimited opportunities to crush the ball while using power-shot.',
    tier: 0,
    image: <SvgSlugger />,
    type: 'infinite',
};
const ballPath = {
    name: 'Calculator',
    abilityName: 'ballPath',
    category: 'accessory',
    description: '5318008',
    extended: 'This ability grants you an honorary degree in projectile motion, intuitively revealing the trajectory of the ball.',
    tier: 0,
    type: 'infinite',
    image: <SvgCalculator />
};
const wallJump = {
    name: 'Mountaineer',
    abilityName:"wallJump",
    category: 'accessory',
    description: 'Yodelayheehoo',
    extended: 'This ability gives you the ability to jump from walls in any match.',
    tier: 0,
    type: 'infinite',
    image: <SvgWallJump />,
    season: 'spring'
};
// Mana type Abilities
const web = {
    name: 'Web',
    abilityName:"web",
    category: 'accessory',
    description: 'My slime senses are tingling',
    extended: 'This ability gives you spidey powers to produce slimy webs you can use to swing across the stage.',
    tier: 0,
    image: <SvgWeb />,
    type: 'mana',
};
const growth = {
    name: 'Shroom',
    abilityName:"growth",
    category: 'accessory',
    description: 'I feel funny',
    extended: 'This ability allows you to grow dramatically in size for a short period of time.',
    tier: 0,
    image: <SvgShroom />,
    type: 'mana',
};
const spinner = {
    name: 'Pinwheel',
    abilityName:"spinner",
    category: 'accessory',
    description: "Spin me right round baby",
    extended: 'This ability sends you tumbling by increasing the rotational force applied in the direction your slime is moving.',
    tier: 0,
    type: 'mana',
    image: <SvgPinwheel />,
    season: 'spring'
};
const orb = {
    name: 'Spirit Orb',
    abilityName:"orb",
    category: 'accessory',
    description: 'Dark arts',
    extended: 'This ability allows you to strike the ball using a guided orb, conjured from the spirits of champion slimes past.',
    tier: 0,
    type: 'mana',
    image: <SvgSpiritOrb />,
    season: 'autumn'
};
const timewarp = {
    name: 'VHS',
    abilityName:"timeWarp",
    category: 'accessory',
    description: 'Glory days',
    extended: 'This ability allows you to rewind the match to a more accommodating moment in time. You can also utalize the hotkey SHIFT while using the ability to fast-forward.',
    tier: 0,
    type: 'cooldown',
    image: <SvgVhs />
};
const pause = {
    name: 'Remote',
    abilityName:"pause",
    category: 'accessory',
    description: 'Click',
    extended: 'This ability allows you to pause everything in the match except yourself for a short period of time.',
    tier: 0,
    type: 'cooldown',
    image: <SvgRemote />
};
const wind = {
    name: 'Stormwaker',
    abilityName:"wind",
    category: 'accessory',
    description: 'Bring a jacket',
    extended: 'This ability allows you to manifest directional wind to an otherwise entirely windless match experience.',
    tier: 0,
    type: 'mana',
    image: <SvgWind />,
    season: 'autumn'
};
const jetPack = {
    name: 'Jet Pack',
    abilityName:"jetPack",
    category: 'accessory',
    description: 'Own the sky',
    extended: 'This ability allows you to take off to the air and navigate a course for any location on the stage.',
    tier: 0,
    type: 'mana',
    image: <SvgJetPack />
};
const flashlight = {
    name: 'Spotlight',
    abilityName:"flashlight",
    category: 'accessory',
    description: "All eyes on you",
    extended: 'This ability makes you the star of the show by placing a spotlight over your slime. All other players are left in the dark to fend for themselves.',
    tier: 0,
    type: 'cooldown',
    image: <SvgFlashlight />,
    season: 'winter'
};
const trap = {
    name: 'Cage',
    abilityName:"trap",
    category: 'accessory',
    description: 'You\'ve activated my trap card',
    extended: 'This ability allows you to trap your opponents by suddenly deploying a falling cage from above.',
    tier: 0,
    type: 'cooldown',
    image: <SvgCage />,
    season: 'winter'
};
const bubble = {
    name: 'Bubble',
    abilityName:"bubble",
    category: 'accessory',
    description: 'Buddy',
    extended: 'This ability allows you to produce a bubble and float away towards the skies.',
    tier: 0,
    type: 'mana',
    image: <SvgBubble />,
    season: 'summer'
};
const earth = {
    name: 'Gauntlet',
    abilityName:"earth",
    category: 'accessory',
    description: 'Bending earth',
    extended: 'This ability allows you to summon an impenetrable rock wall in front of your slime to aid in battle.',
    tier: 0,
    type: 'cooldown',
    image: <SvgEarth />,
    season: 'spring'
};
const hook = {
    name: 'Grappling Hook',
    abilityName:"hook",
    category: 'accessory',
    description: 'That guy',
    extended: 'This ability allows you to shoot a projectile hook which, if it connects, will pull you to the ball.',
    tier: 0,
    type: 'cooldown',
    image: <SvgHook />
};
const vacuum = {
    name: 'Vacuum',
    abilityName:"vacuum",
    category: 'accessory',
    description: 'Shvoooo',
    extended: 'This ability allows you to attract the ball towards your slime from any location on the stage.',
    tier: 0,
    type: 'mana',
    image: <SvgVacuum />
};
// Consumables
const potionAny = {
    category: "accessory",
    name: 'Magic Potion',
    tier: 0,
    description: "Chug for maximum impact",
    extended: "There are four types of potions. Red for speed. Green for growth. Blue for jump power. Purple for extra air jumps. Magic potions can be stacked up to x3 by consuming them back to back.",
    image: imagePotions,
    consumable: drop.potions,
    type: 'one use'
};
const jumpPotion = {
    category: "accessory",
    name: 'Jump Potion',
    abilityName: 'jumpPotion',
    tier: 0,
    description: "Tastes blue",
    extended: "This is a potion of jump power. Magic potions can be stacked up to x3 by consuming them back to back.",
    image: svgjumpPotion,
    consumable: drop.potions,
    type: 'one use'
};
const airJumpPotion = {
    category: "accessory",
    name: 'Air Jump Potion',
    abilityName: 'airJumpPotion',
    tier: 0,
    description: "Tastes purple",
    extended: "This is a potion of air jump. Magic potions can be stacked up to x3 by consuming them back to back.",
    image: svgairJumpPotion,
    consumable: drop.potions,
    type: 'one use'
};
const growthPotion = {
    category: "accessory",
    name: 'Growth Potion',
    abilityName: 'growthPotion',
    tier: 0,
    description: "Tastes green",
    extended: "This is a potion of growth. Magic potions can be stacked up to x3 by consuming them back to back.",
    image: svggrowthPotion,
    consumable: drop.potions,
    type: 'one use'
};
const speedPotion = {
    category: "accessory",
    name: 'Speed Potion',
    abilityName: 'speedPotion',
    tier: 0,
    description: "Tastes red",
    extended: "This is a potion of speed. Magic potions can be stacked up to x3 by consuming them back to back.",
    image: svgspeedPotion,
    consumable: drop.potions,
    type: 'one use'
};
const grenade = {
    name: 'Grenade',
    abilityName:"grenade",
    category: 'accessory',
    description: 'Light em\' up',
    extended: 'Toss a grenade and watch the sparks fly!',
    tier: 0,
    image: <SvgGrenade />,
    season: 'summer',
    consumable: drop.grenade,
    type: 'one use'
};
const vanishingPowder = {
    name: 'Vanishing Powder',
    abilityName:"vanishingPowder",
    category: 'accessory',
    description: 'Smoke bomb',
    extended: 'Distract your foes and flee to the shadows. This consumable will make you invisible to other players for 6 seconds!',
    tier: 0,
    image: <SvgVanishingPowder />,
    consumable: drop.vanishingPowder,
    type: 'one use'
};
const snowball = {
    name: 'Snowball',
    abilityName:"snowball",
    category: 'accessory',
    consumable: drop.snowball,
    type: 'one use',
    description: 'Fun.. for the bearer',
    extended: 'A brilliant snowball of perfect powder snow. Slimes lack the dexterity to create them but they are sometimes found after matches.',
    tier: 0,
    image: <SvgSnowball/>,
    demo: <img src={gifSnowballs}/>
};

// Other
const coins = {
    category: "non",
    name: 'Slime Coin',
    tier: 0,
    description: "Legal tender",
    extended: "These coins are the foundation of the entire slime economy. They can be earned them in a variety of ways. Look around!",
    image: imageCoin,
};
const ticket = {
    category: "non",
    name: 'Ticket',
    tier: 0,
    description: "It's golden",
    extended: "Tickets can be found from winning matches as well as in the cash shop. They can be redeemed for some sweet loot on the fortune wheel.",
    image: imageTicket,
};
const present = {
    category: "non",
    name: 'Present',
    xsollaSku: 'present',
    steamName: 'Present',
    tier: 0,
    description: "Just for you",
    image: imagePresent,
    price: 2.99,
    extended: 'We think its time you treat yourself to a little something special for all that you do. Items received from the present will match using either complementary, analogous, or triadic color combinations.',
    specifics: [
        'Common, unusual, remarkable, extraordinary, or legendary tier',
        'One creature face, one skin, one cap, and one paint',
        'Up to five exclusive faces can be unlocked',
    ],
    alternative: <div style={{position: 'absolute',top:0,right:0,bottom:0,left:0,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <img src={creatureFaces} style={{width: '90%'}} />
        <DemoPresentNameDiv>
            <div>Doggo</div>
            <div>Serpent</div>
            <div>Cat</div>
            <div>Good Boy</div>
            <div>Bird</div>
        </DemoPresentNameDiv>
    </div>
};
const chatBubble = {
    type: 'mtx',
    category: "non",
    name: 'Chat Bubble',
    xsollaSku: 'chatBubble',
    steamName: 'chatBubble',
    tier: 0,
    description: "Just for you",
    image: <div style={{fontSize: '100px', color: palette.clouds}}>{useIcon('chatBubble')}</div>,
    price: 1.99,
    extended: 'Make your voice heard! With this purchase all chat messages will appear above your slime avatars head.',
    specifics: [
        'This perk can be turned off or on at any time from the options screen'
    ],
    alternative: <div style={{
        fontFamily: 'Indie Flower',fontSize: '1.5em',
        position: 'absolute',top:0,right:0,bottom:0,left:0,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        This sure is grand
        <img src={slimeWizard} style={{width: '25%'}}/>
    </div>
};

// Skins and Caps
const allSeasonCaps = [
    {
        category:"cap",
        name:"Afro",
        tier:0,
        description: 'Comb included',
        extended: 'This is a hairy topper for your slime dome. Color matching headband and comb included. You will be balling in no time, king.',
        colorHex: palette.pomegranate
    },
    {
        category:"cap",
        name:"Beanie",
        tier:0,
        description: 'Hand knit',
        extended: 'Knitted by your great grand slime, this topper will keep you warm!',
        colorHex: palette.alizarin
    },
    {
        category:"cap",
        name:"Beret",
        tier:0,
        description: 'Salute',
        extended: 'Worn by the commanders of the third Slime War against the Candy Kingdom, this topper commands respect among all the slime citizens.',
        colorHex: palette.pumpkin
    },
    {
        category:"cap",
        name:"Bridal Veil",
        tier:0,
        description: 'Love at first sight',
        extended: 'A beautiful veil, long flowing hair. What more could you ask for.',
        colorHex: palette.carrot
    },
    {
        category:"cap",
        name:"Bunny Ears",
        tier:0,
        description: 'Hop hop',
        extended: 'Grab a carrot and get to it! This topper is best worn when no one is making slime stew.',
        colorHex: palette.orange
    },
    {
        category:"cap",
        name:"Cat Ears",
        tier:0,
        description: '^ↀᴥↀ^',
        extended: 'No one will be able to resist your cuteness in this getup.',
        colorHex: palette.sunFlower
    },
    {
        category:"cap",
        name:"Cowboy Hat",
        tier:0,
        description: 'Yee haw',
        extended: 'This topper is worn by all the great slime ranchers. Don this topper and you are ready to herd some cattle!',
        colorHex: palette.midnightBlue
    },
    {
        category:"cap",
        name:"Jester Cap",
        tier:0,
        description: 'It was but a ruse',
        extended: 'Worn by the Jesters of the Royal Slime Court. If your joke doesn\'t land then off with your head!',
        colorHex: palette.wetAsphalt
    },
    {
        category:"cap",
        name:"Halo",
        tier:0,
        description: 'Sinners repent',
        extended: 'Be good in this life and you may be rewarded in the next. That\'s right, slime heaven is real.',
        colorHex: palette.wisteria
    },
    {
        category:"cap",
        name:"Mushroom Cap",
        tier:0,
        description: 'Don\'t jump on me',
        extended: 'This ain\'t a fungal infection, this is a topper that may make you a target to mustached plumbers.',
        colorHex: palette.amethyst
    },
    {
        category:"cap",
        name:"Ninja Band",
        tier:0,
        description: 'Believe it',
        extended: 'This topper is proudly worn by all shinobi of the Slime Village. Show off your ninja moves in style!',
        colorHex: palette.belizeHole
    },
    {
        category:"cap",
        name:"Party Hat",
        tier:0,
        description: 'Buying GF',
        extended: 'It may be made of paper but from a distance you will look rather elegant.',
        colorHex: palette.peterRiver
    },
    {
        category:"cap",
        name:"Ram Horns",
        tier:0,
        description: 'Baaahhhh',
        extended: 'Show off those horns as you ram and bump your opponents into submission!',
        colorHex: palette.emerald
    },
    {
        category:"cap",
        name:"Sombrero",
        tier:0,
        description: '¡Arriba, Ándale!',
        extended: 'Show off your slime heritage with this festive sombrero!',
        colorHex: palette.greenSea
    },
    {
        category:"cap",
        name:"Straw Hat",
        tier:0,
        description: 'King of the pirates',
        extended: 'This topper will keep the sun out of your eyes as you sail the great slimey seas!',
        colorHex: palette.turquoise
    },
    {
        category:"cap",
        name:"Wizard Hat",
        tier:0,
        description: '(∩｀-´)⊃━☆ﾟ.*･｡ﾟ',
        extended: 'This topper may not give you magical powers, but it sure is stylish!',
        colorHex: palette.clouds
    },
];
const allSeasonSkins = [
    {
        category:"skin",
        name:"Bridal Gown",
        tier:0,
        description: 'Androgynous',
        extended: 'Wear this elegant dress and your slime will be ready to walk down the aisle!',
        colorHex: palette.pomegranate
    },
    {
        category:"skin",
        name:"Cheetah Print",
        tier:0,
        description: 'Hiss!',
        extended: 'Cheetah print will never go out of style, don this attire and show off your safari style!',
        colorHex: palette.alizarin
    },
    {
        category:"skin",
        name:"Cow Print",
        tier:0,
        description: 'How bovine',
        extended: 'Needs more cowbell!',
        colorHex: palette.pumpkin
    },
    {
        category:"skin",
        name:"Glitter",
        tier:0,
        description: 'Oooooh, shiny',
        extended: 'Show off your shiny shimmer with this glistening glitter!',
        colorHex: palette.orange
    },
    {
        category:"skin",
        name:"Kimono",
        tier:0,
        description: 'Domo arigato',
        extended: 'From the island of the rising sun, wearing this fashionable garmet is sure to impress!',
        colorHex: palette.sunFlower
    },
    {
        category:"skin",
        name:"Royal Cloak",
        tier:0,
        description: 'Hear ye, hear ye',
        extended: 'Show off your superiority to the slime peasants with this royal cloak!',
        colorHex: palette.midnightBlue
    },
    {
        category:"skin",
        name:"Punk",
        tier:0,
        description: 'You\'re not the boss of me now',
        extended: 'Rebel against society by getting some dope tattoos and donning this iconic leather jacket!',
        colorHex: palette.wetAsphalt
    },
    {
        category:"skin",
        name:"Stripe",
        tier:0,
        description: 'Oldie but goodie',
        extended: 'A stripe may not be too fancy, but sometimes you just can\'t beat the classics!',
        colorHex: palette.wisteria
    },
    {
        category:"skin",
        name:"Suit",
        tier:0,
        description: 'A regular 9 to 5 slime',
        extended: 'The shirt of choice for the young sophisticate. Straighten your tie, sit in your cubicle, and get to work!',
        colorHex: palette.amethyst
    },
    {
        category:"skin",
        name:"Prison Tats",
        tier:0,
        description: '25 to life',
        extended: 'Grand larceny... ✔️, man slaughter... ✔️, arson... ✔️. It never felt so good to be this bad!',
        colorHex: palette.nephritis
    },
    {
        category:"skin",
        name:"Tiger Stripes",
        tier:0,
        description: 'It\'s grrrreat',
        extended: 'Blend into your surroundings with these sheek streaks!',
        colorHex: palette.emerald
    },
];

// Roll-ups
const abilities = [dash,focusJump,reverseBall,camouflage,slowBall,speedBall,sticky,ghost,web,growth,bigSlugger,
    levitate,spinner,orb,timewarp,pause,wind,jetPack,flashlight,trap,bubble,earth,
    wallJump,hook,vacuum,ballPath];
const consumables = [jumpPotion,airJumpPotion,growthPotion,speedPotion,grenade,vanishingPowder,snowball];
const other = [coins,ticket,present,chatBubble];
const possibleDrops = [...abilities,...consumables,...other];
const accessories = [...abilities,...consumables];

export const getAccessoryByAbility = (abilityName) => {
    let instance = accessories.find(item => item.abilityName === abilityName);
    if(!instance){
        instance = {
            abilityName: abilityName,
        }
    }
    return instance;
};
export const getItemByName = (name) => {
    return possibleDrops.find(item => item.name === name);
};

// Atlas roll-up
export default {
    autumn: [ghost,orb,levitate,wind],
    winter: [slowBall,flashlight,camouflage,snowball],
    spring: [dash,wallJump,spinner,earth],
    summer: [speedBall,bubble,focusJump,grenade],
    all: {
        ability: abilities,
        cap: allSeasonCaps,
        skin: allSeasonSkins,
        consumable: consumables,
        other: [coins,ticket]
    }
};

// Cash shop Items
const ticketPack = {
    name: 'Ticket Pack',
    xsollaSku: 'tickets',
    steamName: 'Tickets',
    price: .99,
    image: imageTickets,
    extended: 'Feeling lucky? These golden tickets are redeemable at the slot machine, cash them in for a chance to win big. With the slot machine you could win up to 10,000 coins, or better, LEGENDARY items. These same tickets can be found from winning matches, but they\'re super rare.',
    specifics: [
        'Can be used 3 total times'
    ],
    sourceItem: ticket
};
const snowballPack = {
    ...snowball,
    name: 'Snowball Pack',
    xsollaSku: 'snowball',
    steamName: 'Snowball Pack',
    count: 200,
    price: .99,
    specifics: [
        'Can be used 200 total times'
    ],
    // We need the original attributes to not override when we display the item in the modal
    sourceItem: snowball,
    image: <ShringSvg><SvgSnowball/></ShringSvg>,
};
const potionParcel = {
    name: 'Parcel',
    xsollaSku: 'potionParcel',
    steamName: 'Potion Parcel',
    price: 1.99,
    count: 40,
    extended: 'A solid parcel for new players looking to get an edge on the competition',
    specifics: [
        '10 Speed Potions',
        '10 Growth Potions',
        '10 Jump Power Potions',
        '10 Air Jump Potions',
    ],
    image: imagePotions,
    sourceItem: potionAny,
    imageSize: '20%'
};
const potionBundle = {
    name: 'Bundle',
    xsollaSku: 'potionBundle',
    steamName: 'Potion Bundle',
    price: 3.99,
    count: 120,
    extended: 'A great value bundle that is sure to pack a punch',
    specifics: [
        '30 Speed Potions',
        '30 Growth Potions',
        '30 Jump Power Potions',
        '30 Air Jump Potions',
    ],
    image: imagePotions,
    sourceItem: potionAny,
    imageSize: '30%'
};
const potionCollection = {
    name: 'Collection',
    xsollaSku: 'potionCollection',
    steamName: 'Potion Collection',
    price: 5.99,
    count: 240,
    extended: 'An excellent value collection that will elevate your potential for hours of game time',
    specifics: [
        '60 Speed Potions',
        '60 Growth Potions',
        '60 Jump Power Potions',
        '60 Air Jump Potions',
    ],
    image: imagePotions,
    sourceItem: potionAny,
    imageSize: '40%'
};

// Cash shop roll-up
export const cashShopData = {
    regulars: {
        ticketPack: ticketPack,
        present: present,
        chatBubble: chatBubble
    },
    seasonal: {
        snowballPack: snowballPack
    },
    potions: {
        parcel: potionParcel,
        bundle: potionBundle,
        collection: potionCollection
    }
};

/*const flannel = {
    category:"accessory",
    name: "Flannel",
    tier: 0,
    description: "Flashy Fall Flannel Affair!",
    extended: "Originally worn by the slime lumberjacks of the north west, this pattern is worn by slime hipsters all across the globe."
};
const jackOLantern = {
    category: "accessory",
    name: 'Jack',
    tier: 0,
    description: "The legend returns!",
    extended: "Painfully carved into the face of an poor unsuspecting slime, Jack will spook even the bravest adventurers."
};
const santaHat = {
    category: 'accessory',
    name: 'Santa Hat',
    tier: 0,
    description: 'Ho ho ho!',
    extended: 'Tis the season to be slimy!'
};
const hawaiianShirt = {
    category: 'accessory',
    name: 'Hawaiian Shirt',
    tier: 0,
    description: 'Aloha! ',
    extended: 'Wear this fresh island shirt and your slime is ready for some beach side margaritas!'
};
const shades = {
    category: 'accessory',
    name: 'Shades',
    tier: 0,
    description: 'Too cool for school!',
    extended: 'Although the sun will be out of your eyes, all the eyes will be on you when you wear these ultra cool sunglasses!'
};
const floralArrangement = {
    category: 'accessory',
    name: 'Floral Arrangement',
    tier: 0,
    description: '',
    extended: 'Collected from exotic flowers from the distant fields of Goolifornia and turned into a fashionable pattern for your slime to wear.'
};
 */
//todo: Put paints in other section
export const paintExample = {
    adjective: 'Handless',
    color: 'Sunburn',
    name: 'Paint',
    adjectiveDefinition: 'lacking physical movement skills, especially with the hands',
    description: 'These can be found randomly as you play the game.',
    category: 'paint',
    colorCategory: 'red',
    colorDefinition: 'a browning of the skin resulting from exposure to the rays of the sun',
    colorHex: '#F41',
    colorName: 'Handless Sunburn',
    itemId: 2,
    namedColorId: 3906,
    stats: {
        size: 1,
        jumpDuration: 1,
        jumpPower: 1,
        nimbleness: 1,
        acceleration: 1,
        maxSpeed: 1,
    },
    tier: 0,
    userItemId: 121483,
};