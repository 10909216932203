import styled from 'styled-components';
import React, {useEffect, useState} from "react";
import Icon from "../../data/icon";
import palette from "../../styled/Palette";
import {useDispatch, useSelector} from "react-redux";
import {animated, useSpring} from "react-spring";
import {notificationGamepad} from "../../redux/modules/notification/notificationActions";
import store from "../../redux/store";

const GamepadNotificationMount = styled.div`
        padding: 1em;
        font-size: 2em;
        background-color: ${palette.wetAsphalt};
        font-weight: bold;
        font-family: Montserrat;
        color: ${palette.base4};
        border: 1px solid ${props => props.status === 'DETECTED' ? palette.nephritis : palette.alizarin};
        letter-spacing: 2px;
    `;
const GamepadNotification = () =>  {
    const dispatch = useDispatch();
    const [showing,setShowing] = useState(null);
    const status = useSelector(state => state.notification.gamepad);
    const gamepad = useSelector(state => state.gamepad);
    const contentProps = useSpring({
        zIndex: 2,
        position: 'absolute',
        bottom: showing ? '10em' : '-20em',
        right: '10em',
    });

    useEffect(() => {
        if (status) {
            setShowing(true);
            setTimeout(() => {
                setShowing(false);
                setTimeout(() => {
                    dispatch(notificationGamepad(null));
                },1000);
            }, 2000);
        }
    }, [status]);

    /**
     * This is what allows the select button to trigger the focused element
     */
    useEffect(() => {
        if (gamepad.button_1) {
            if (!document.activeElement.classList.contains('disabled')) {
                const gamepad = store.getState().gamepad;
                if (gamepad.connected && gamepad.navigating) {
                    // Sometimes the activeElement is null, not sure why
                    if (document.activeElement.click) {
                        document.activeElement.click();
                    }
                }
            }
        }
    }, [gamepad]);


    return (
        <animated.div style={contentProps} >
            <GamepadNotificationMount status={status}>
                <div style={{fontSize: '2em', color: status === 'DETECTED' ? palette.nephritis : palette.pomegranate}}>
                    {Icon('gamepad-alt')}
                </div>
                <div>
                    {status}
                </div>
            </GamepadNotificationMount>
        </animated.div>
    )
};

export default GamepadNotification;