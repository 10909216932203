import {animated, useSpring} from "react-spring";
import React, {useEffect, useState} from "react";

const AnimationFade = ({componentToRender,toggled}) => {
    const [loaded,setLoad] = useState(false);
    const contentProps = useSpring({
        opacity: loaded ? 1 : 0,
    });

    useEffect(() => {
        if (toggled) {
            setLoad(true);
            return () => {
                setLoad(false);
            }
        }
    }, [toggled]);

    return (
        <animated.div style={contentProps}>
            {componentToRender}
        </animated.div>
    )
};

export default AnimationFade;