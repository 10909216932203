import React, {useEffect, useRef, useState} from "react";
import {ClockDiv} from "../../page/Match/MatchStyle";
import {useSelector} from "react-redux";
import {gameSocket} from "../../redux/middleware/wsMatch";
import {playSound} from "../../data/sound";

const MatchClock = () => {
    const theme = useSelector(state => state.theme);
    const { timeLeft, timeLeftTimestamp } = useSelector(state => state.match);
    const refClock = useRef(0);
    const [time,setTime] = useState('5:00');

    const formatGameTime = (secondsLeft) => {
        if (typeof timeLeft === 'number') {
            if (secondsLeft < 0) {
                secondsLeft += 60;
            }
            const minutes = Math.floor(secondsLeft / 60);
            if (minutes < 0) {
                return '0:00';
            }
            let seconds = secondsLeft % 60;
            if (seconds < 10) {
                seconds = '0' + seconds;
            }
            return minutes + ':' + seconds;
        }
        return '0:00';
    };
    const animateClock = (secondsLeft) => {
        if (secondsLeft === 10) {
            refClock.current.classList.add('flash');
            refClock.current.classList.add('infinite');
        } else if (secondsLeft > 0 && secondsLeft < 10) {
            playSound('blip', 1);
            if (refClock.current.style.color !== 'white') refClock.current.style.color = 'white';
            else refClock.current.style.color = theme.interfaceColorBright;
        } else {
            if (refClock.current.classList.contains('flash')) refClock.current.classList.remove('flash');
            if (refClock.current.style.color !== 'white') refClock.current.style.color = 'white';
        }
    };

    useEffect(() => {
        const matchCountdownTimer = setInterval(() => {
            if (gameSocket) {
               const secondsLeft = Math.ceil((timeLeft - (Date.now() - timeLeftTimestamp)) / 1000);
               if (timeLeft) {
                   setTime(formatGameTime(secondsLeft));
               } else {
                   setTime('5:00');
               }
               animateClock(secondsLeft);
            }
        }, 500);

        return () => {
            window.clearInterval(matchCountdownTimer);
        }
    }, [timeLeft, timeLeftTimestamp]);

    return (
        <ClockDiv
            ref={refClock}
            className="animated"
            style={{animationDuration: '1s', animationIterationCount: 'infinite'}}
        >
            {time}
        </ClockDiv>
    );
};

export default MatchClock;