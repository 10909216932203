import styled from 'styled-components';
import palette from "../../../styled/Palette";
import {ButtonUnequip} from "./UnequipItem";
import {shadow} from "../../../data/globals";

export const SlotTag = styled.div`
    cursor: ${props => props.active ? 'default' : 'pointer'};
    color: ${props => props.active ? palette.base4 : palette.belizeHole};
    flex-grow: 1;
    flex-basis: 0;
    font-weight: ${props => props.active ? 'bold' : 'normal'};
`;

export const SlotTagMount = styled.div`
    padding: .5em 1em;
    display: flex;
    background-color: ${palette.midnightBlue};
`;
export const SlotIcon = styled.div`
    color: ${props => props.color};
`;

export const SlotsMount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 8em;
`;


//todo: add box shadow to these slots
const borderWidth = '2px';
export const EquiptSlotDiv = styled.div`
    position: relative;
    box-shadow: ${shadow};

    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    
    justify-content: center;
    align-items: center;
    
    margin: 0 2px;
    
    overflow: hidden;
    
    border: ${borderWidth} solid ${props => props.currentActive ? palette.belizeHole : palette.base0};
    background-color: ${props => props.currentActive ? palette.wetAsphalt : palette.base3};
    border-radius: 3px;
    
    min-height: 50px;
    
    img {
        transform: scale(.85);
        pointer-events: none;
    }
    svg {
        pointer-events: none;
    }
    
    &:hover {
        cursor: pointer;
        background-color: ${palette.wetAsphalt};
        border: ${borderWidth} solid ${palette.belizeHole};
        ${ButtonUnequip} {
            visibility: visible;
        }
    }
    &:focus {
        background-color: ${palette.wetAsphalt};
        border: ${borderWidth} solid ${palette.belizeHole};
    }
    
    &:active {
        background-color: ${palette.wetAsphalt};
        border: ${borderWidth} solid ${palette.belizeHole};
    }
`;