import { useRef, useEffect } from 'react';

/**
 * This hook merely indicates if it's the first render for a component
 */
const useOnce = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

export default useOnce;