import React, {useEffect, useRef, useState} from "react";
import slimeWizard from '../../images/slimeWizard.png';
import useTypeWriter from "../../hooks/useTypeWriter";
import palette from "../../styled/Palette";
import Button, {STANDARD_BUTTON_WIDTH} from "../../styled/Button";
import {
    DialogueWordsDiv,
    DialogueContainerDiv,
    DialogueDiv, DialogueCloseDiv
} from './DialogueStyle';
import ButtonClose from "../../atoms/ButtonClose";
import {PageTypes} from "../../system/types";

const ENOUGH_TIME_FOR_THE_SPOKEN_DIALOGUE_TO_FINISH = 2000;
const ENOUGH_TIME_FOR_THE_FADE_OUT = 2000;

const Dialogue = ({buttons = [], visible, dialogueSequence, dialogueIndex = 0, bottom, history, closable, styles}) => {
    const refContainer = useRef(null);
    const [spinInvoked,setSpinInvoked] = useState(false);
    const [dialogueCompleted,setDialogueCompleted] = useState(false);
    const writtenProgress = useTypeWriter(dialogueSequence[dialogueIndex], 500);

    // Reset the complete button for each dialogue
    useEffect(() => {
        if (dialogueIndex) {
            setDialogueCompleted(false);
        }
    }, [dialogueIndex]);

    // This code is specific to the slots page
    useEffect(() => {
        if (!visible) {
            setSpinInvoked(true);
            handleDialogueFade();
        }
    }, [visible]);

    // Allow the user to move forward by flipping the dialogueComplete flag
    useEffect(() => {
        if (writtenProgress === dialogueSequence[dialogueIndex]) {
            // When dialogueCompleted is true we enable the go forward button
            setDialogueCompleted(true);
        }
    }, [writtenProgress]);

    const handleDialogueFade = () => {
        setTimeout(() => {
            refContainer.current.classList.remove(`slideInUp`);
            refContainer.current.classList.add(`fadeOut`);
            setTimeout(() => {
                refContainer.current.style.visibility = 'hidden';
            }, ENOUGH_TIME_FOR_THE_FADE_OUT)
        }, ENOUGH_TIME_FOR_THE_SPOKEN_DIALOGUE_TO_FINISH);
    };
    return (
        <DialogueContainerDiv
            styles={styles}
            bottom={bottom}
            ref={refContainer}
            className={dialogueIndex === 0 ? `animated slideInUp` : `animated`}
        >
            <DialogueDiv>
                {closable &&
                <DialogueCloseDiv>
                    <ButtonClose
                        click={() => history.push(PageTypes.MAIN)}
                    />
                </DialogueCloseDiv>
                }
                <div
                    style={{position: 'relative', width: '35%', overflow: 'hidden', height: '15em', top: '-9em'}}
                >
                    <img
                        src={slimeWizard}
                        className={dialogueIndex === 0 ? 'animated slideInUp' : null}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            left: 0,
                            top: '.25em'
                        }}
                    />
                </div>
                <DialogueWordsDiv>
                    <div style={{fontWeight: 'bold', color: '#7799ff'}}>
                        <div
                            className={dialogueIndex === 0 ? 'animated slideInLeft' : null}
                            style={{
                                fontSize: '.75em',
                                backgroundColor: palette.midnightBlue, borderRadius: '4px', padding: '.5em', maxWidth: '7.22em'}}>
                            Slime Wizard
                        </div>
                    </div>
                    <div style={{fontFamily: 'Indie Flower', fontSize: '1.5em', height: '1.25em', color: palette.base4}}>
                        {writtenProgress}
                    </div>
                </DialogueWordsDiv>
            </DialogueDiv>
            <div style={{margin: '.5em', display: 'flex', width:'60em', justifyContent: 'flex-end'}}>
                {buttons.map((button,i) => {
                    return (
                        <Button
                            key={i}
                            isDisabled={spinInvoked || !dialogueCompleted}
                            title={button.title}
                            click={button.action}
                            styles={{width: STANDARD_BUTTON_WIDTH}}
                        />
                    )
                })}
            </div>
        </DialogueContainerDiv>
    )
};

export default Dialogue;