import styled from 'styled-components';
import palette from '../../styled/Palette';
import {shadow} from "../../data/globals";

// todo: the box shadow needs to be imported as a standard

export const UpdateContainerLi = styled.li`
    display: flex;
    flex-direction: column;
    color: ${palette.base4};
    margin: 5px 0;
    font-family: Montserrat;
`;

export const UpdateRowDiv = styled.div`
    border: 1px solid ${palette.base1};
    box-shadow: ${shadow};
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    height: 50px;
      &:hover {
      cursor: pointer;
      background-color: ${palette.base1};
      }
`;

export const DateDiv = styled.div`
    align-items: center;
    display: flex;
    padding: 0 15px;
`;

export const VersionDiv = styled.div`
    align-items: center;
    display: flex;
    background-color: ${palette.base0};
    padding: 0 15px;
    width: 9em;
`;

export const TitleDiv = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin: 0 1em;
`;

export const SubTitleDiv = styled.div`
    font-size: 11px;
    font-weight: bold;
    margin: 1em 0 .25em 0;
    border-bottom: 1px solid ${palette.base0};
    
`;

export const DetailDiv = styled.div`
      font-size: 1.2em;
      margin-left: 1em;
`;

export const ContentDiv = styled.div`
    display: ${props => props.show ? 'flex' : 'none'};

    font-size: 12px;

    flex-direction: column;
    flex-grow: 1;
    padding: 1em;
    border-left: 1px solid ${palette.base1};
    border-right: 1px solid ${palette.base1};
    border-bottom: 1px solid ${palette.base1};
    background: repeating-linear-gradient(
      45deg,
      ${palette.base6},
      ${palette.base6} 10px,
      ${palette.base7} 10px,
      ${palette.base7} 20px
    );
`;

export const UpdateContainerLiOld = styled.li`
    box-shadow: ${shadow};
  
    background-color: ${palette.base1};

    display: flex;
    flex-grow: 1;
    
    align-items: center;
    justify-content: space-between;
    
    height: 50px;
    
    margin: 5px 0;
    padding: 0 15px;
    
    div:last-child {
        margin-left: 15px;
    }
`;