import React from "react";
import {modalEnable} from "../../../redux/modules/modal/modalActions";
import {PageTypes} from "../../../system/types";

export let MembersRankedData;

const ModalMembersRanked = (dispatch,history) => {

    MembersRankedData = (
        <div style={{margin: '1em'}}>
            Register now to access ranked play?
        </div>
    );

    dispatch(modalEnable({
        title: "Registered Players Only",
        body: 'MembersRanked',
        userAccept: () => {
            history.push(PageTypes.SIGN_IN);
        },
        conditional: true
    }));
};

export default ModalMembersRanked;