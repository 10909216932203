import slimeCoin from "../../../images/slimeCoin.png";
import React from "react";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";
import {InfoMount2} from "../../../styled/InfoMount2";

const DropCoin = ({currentViewItem, handleReturn}) => {
    const body = (
        <>
            <InfoMount2>{currentViewItem.amount} coins taken from {currentViewItem.opponent}</InfoMount2>
            <img
                alt=" "
                style={{margin: '2em', width: '15%'}}
                className="animated animationFlip"
                src={slimeCoin} />
        </>
    );

    return (
        <>
            {body}
            <Button
                styles={{width: STANDARD_BUTTON_WIDTH}}
                title="Cool"
                click={() => handleReturn()}
            />
        </>
    )
};

export default DropCoin;