import {createReducer} from "@reduxjs/toolkit";
import initialState from "./steamInitialState";

const steamReducers = createReducer(initialState, {
    STEAM_SET_FRIENDS: (state, action) => {
        state.friends = action.payload;
    },
    STEAM_SET_FRIEND_IDS: (state, action) => {
        state.friendSteamIds = action.payload;
    },
});

export default steamReducers;