import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import LinkURL from "../../LinkURL/LinkURL";

export let loadoutData;

const ModalLoadout = (dispatch) => {

    loadoutData = (
        <div style={{padding: '1em'}}>
            Items placed in the loadout slots can be quickly accessed in match using the hotkey Q. You can then use the hotkey E in order to select your next ability. Use hotkey ESC to hide an active quick switch menu.
        </div>
    );

    dispatch(modalEnable({
        body: 'Loadout',
        title: 'Quick Switch accessories with Loadout'
    }));
};

export default ModalLoadout;