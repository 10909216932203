import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {getTierData} from '../../data/tier';
import {playSound} from '../../data/sound';
import {ItemSlotDiv} from './ItemStyle';
import workerGetPortrait from '../../webworker/main';
import {useSelector} from "react-redux";
import Item from "./Item";
import {colorSaturate} from "../../data/format";
import Overlays from "./Overlays";
import useOnce from '../../hooks/useOnce';
import holo from '../../images/holo.gif';
import {debounce} from "../../data/generic/generic";
import {lootImages} from "../../data/storeLootImages";
import palette from "../../styled/Palette";

const bounceItem = (event) => {
    let element = event.target;
    element.childNodes.forEach(child => {
        if (child && child.classList) child.classList.add('animated','smallBounce');
    });
    setTimeout(() => {
        if (element) {
            element.childNodes.forEach(child => {
                if (child && child.classList) child.classList.remove('animated','smallBounce');
            });
        }
    }, 1000);
};

const tierInfo = (item) => {
    if(item.tier)
    return ({
        color: getTierData(item.tier).color,
        saturated: colorSaturate(getTierData(item.tier).color),
        name: getTierData(item.tier).name,
    })
    else
    return ({
        color: getTierData(0).color,
        saturated: colorSaturate(getTierData(0).color),
        name: getTierData(0).name,
    })
};

const getBackground = (item) => {
    // We never want to show a background if its an empty slot
    if (item.empty) {
        return null;
    }
    // Accessories have a holo background, unless they are consumables
    if (item.category === 'accessory' && !item.consumable) {
        return holo;
    }
    // Most items don't have a background
    return null;
};

const ItemSlot = ({
item,sellMode,disabled,activeFace,click,slotWidth,slotHeight,location,selected,
handleItemEnter,handleItemLeave,overlay,filter,accessorySelected,isLoadout,slotMargin,index,size
}) => {
    //selectors
    const outfit = useSelector(state => state.self.outfit);
    const focusItem = useSelector(state => state.loot.item?.userItemId);
    const refValue = useRef(focusItem);
    //state
    const [tierData,setTierData] = useState(tierInfo(item));
    const [portrait, setPortrait] = useState(null);
    // I couldn't make this state or else it would persist old values
    const background = getBackground(item);
    //refs
    const mounted = useRef(false);
    const refSlot = useRef();
    //constantly reloaded variables
    const firstRun = useOnce();
    const itemNeedsCreation = ['cap','skin'].includes(item.category);

    const handleSelect = (e,item,index) => {
        if (!disabled) {
            bounceItem(e);
            playSound('selectItem', .5);
            click(e,item,index);
        }
    };

    useEffect(() => {
        refValue.current = focusItem;
    });

    /**
     * Triggers the initial portrait rendering
     */
    useEffect(() => {
        setTierData(tierInfo(item));
        setPortrait(null);
        if (item && !portrait && itemNeedsCreation) {
            workerGetPortrait(null, item).then(dataUrl => {
                // We want the image to load immediately on the item so we use state also.
                setPortrait(dataUrl);
            })
        }
    }, [item, filter]);

    /**
     * This is probably for the gamepad idk
     */
    useEffect(() => {
        if (selected) {
            refSlot.current.focus();
        }
    }, [selected]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false
        };
    }, []);

    const evaluateShowActive = useMemo(() => {
        const slotIsEmpty = !item.userItemId;
        const slotIsActive = accessorySelected;
        const slotItemEquipped = item?.userItemId === outfit[item.category]?.userItemId;

        if (slotIsActive) return '#fff';
        if (slotIsEmpty) return tierData.color;
        if (slotItemEquipped) return '#fff';

        return tierData.color;
    }, [accessorySelected, outfit, item, tierData]);

    const handleDebounce = (e,index) => debouncer(e,index);
    const debouncer = useCallback(debounce((e,index) => {
        if (focusItem === refValue.current) {
            handleItemLeave(e,index);
        }
        return null;
    }, 250), [focusItem,handleDebounce]);

    return (
        <ItemSlotDiv
            key={item.userItemId}
            backgroundImage={background}
            backgroundColor={item.category === 'paint'? item.colorHex : palette.base6}
            color={item.colorHex}

            ref={refSlot}
            tabIndex={0}

            slotMargin={slotMargin}
            slotWidth={slotWidth}
            slotHeight={slotHeight}
            disabled={disabled}

            showActive={evaluateShowActive}

            onClick={(e) => click ? handleSelect(e,item,index) : null}
            onMouseEnter={(e) => handleItemEnter ? handleItemEnter(e,index) : null}
            onFocus={(e) => handleItemEnter ? handleItemEnter(e,index) : null}
            onMouseLeave={(e) => handleItemLeave ? handleDebounce(e,index) : null}
            onBlur={(e) => handleItemLeave ? handleItemLeave(e,index) : null}
        >
            <Overlays
                overlay={overlay}
                location={location}
                item={item}
                sellMode={sellMode}
                disabled={disabled}
                isLoadout={isLoadout}
            />
            {item.empty
                 ?
                    <div>

                    </div>
                    :
                    <Item
                        key={item.userItemId}
                        size={size}
                        item={item}
                        index={index}
                        activeFace={activeFace}
                        portrait={portrait}
                    />
            }

        </ItemSlotDiv>
    )
};

ItemSlot.propTypes = {
    // Is the user able to interact with the component
    disabled: PropTypes.bool,
    // The size the component is render as in em units
    slotSize: PropTypes.number,
    // The contents of the slot
    item: PropTypes.object,
    // Used in the context of accessory items for use in <Overlays />
    selected: PropTypes.bool,
    // Used in the context of accessory items to illustrate the next recommended selection
    accessorySelected: PropTypes.bool,
    // Used to determine if this is specifically a loadout slot for use in <Overlays />
    isLoadout: PropTypes.bool,
};

export default ItemSlot;

/* todo: This needs to be reimplemented with better abstraction or replaced entirely

    const config = { tension: 300, friction: 15, duration: 250 };
    const initialStyles = { opacity: 0};
    const [spring, setSpring] = useSpring(() => initialStyles);

    function onHide({ unmount }) {
        setSpring({
            ...initialStyles,
            onRest: unmount,
            config: { ...config, clamp: true }
        });
    }
    function onMount() {
        setSpring({
            opacity: .95,
            onRest: () => {},
            config
        });
    }
    <Tippy
            key={item.userItemId}

            disabled={!tooltip}
            onMount={onMount}
            onHide={onHide}
            hideOnClick={false}
            delay={[50, 0]}
            render={attrs => (
                <Tooltip
                    tier={tierData ? tierData.color : null}
                    style={spring} {...attrs}>
                    <ItemTooltip
                        category={item.category}
                        item={item}
                        statsOrdered={statsSortAlphabetical(item.stats)}
                    />
                </Tooltip>
            )}
        >

        </Tippy>
 */