import styled from 'styled-components';
import palette from "./Palette";

const Page = styled.div`
  border-left: 1px solid black;
  background-color: ${palette.base5};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default Page;
