import React from 'react';
import {censor} from "../../../system/chat";
import { updateChat, updateSentChat, updateMatchChat } from "./chatActions";
import matchScope from "../../../data/matchScope";
import {DURATION_COOLDOWN_CHAT_SUSPENSION} from "../../../data/globals";
import {chatTypes} from "../../../component/Chat/MatchChat";
import {PageTypes} from "../../../system/types";

/**
 * When a message comes in from the server we only wish to apply the censoring once; not on rerender.
 */
export const formatPublicMessage = (message) => {
    return (dispatch) => {
        const { players, options, context } = message;

        if (context === 'queue') {
            let queue = '';
            let squad = '';
            const queuedVolley = (options.volley[1] || options.volley[2] || options.volley[3] || options.volley[4]);
            const queuedSoccer = (options.soccer[1] || options.soccer[2] || options.soccer[3] || options.soccer[4]);
            const queuedHoops = (options.hoops[1] || options.hoops[2] || options.hoops[3] || options.hoops[4]);
            const queueAmount = () => {
                let q = 0;
                if (queuedVolley) q += 1;
                if (queuedSoccer) q += 1;
                if (queuedHoops) q += 1;
                return q;
            };
            if (queueAmount() === 3) {
                queue = 'a';
            } else {
                if (queueAmount() === 1) queue += 'a ';
                if (queuedVolley) queue += 'VOLLEY';
                if (queueAmount() > 1 && queuedVolley) queue += ', or ';
                if (queuedSoccer) queue += 'SOCCER';
                if (queueAmount() > 1 && queuedSoccer && queuedHoops) queue += ', or ';
                if (queuedHoops) queue += 'HOOPS';
            }
            queue += ' match';
            if (players.length > 2) {
                queue += ' with the squad — ';
                for (let i = 0; i < players.length; i++) {
                    if (i === (players.length-1)) squad += `and `;
                    if (i !== 0) squad += `${players[i].username}`;
                    if (i !== (players.length-1) && i !== 0) squad += `, `;
                }
            }
            if (players.length === 2) {
                queue += ` with friend ${players[1].username}`;
            }

            message = {
                ...message,
                queue: queue,
                squad: squad
            };
        }

        dispatch(updateChat(message));
    }
};

export const formatMatchMessage = (message) => {
    return (dispatch) => {
        // Chat messages are delivered to both the redux store and positions scope
        const censoredMessage = {
            message: censor(message.message),
            chatType: chatTypes.userMessage,
            colorName: message.colorName,
            colorHex: message.colorHex
        };

        // 1. To display on the UI
        dispatch(updateMatchChat(censoredMessage));

        // 2. To display on the match canvas
        if (message.team) {
            // Only match participants would ever draw text to the canvas
            matchScope.teams[message.team][message.teamIndex].chatMessage = censoredMessage.message;
            matchScope.teams[message.team][message.teamIndex].chatTime = Date.now();
        }

    }
};

// Deliver a message to any chat
export const sendMessage = (text, keyCode, pathname, roomId, gameSocket, masterSocket, team, playerId, sessionId, chatInputElement) => {
    return (dispatch, getState) => {
        const { recentSent, banned } = getState().chat;
        const chatPublic = pathname === PageTypes.PUBLIC_CHAT;
        const userPressEnter = keyCode === 13;

        const chatCooldown = () => {
            if (recentSent < 4) {
                dispatch(updateSentChat({recentSent: recentSent + 1}));
                setTimeout(() => {
                    dispatch(updateSentChat({recentSent: recentSent - 1}));
                }, 6000);
            } else {
                dispatch(updateSentChat({banned: true}));
                document.activeElement.blur();
                setTimeout(() => {
                    dispatch(updateSentChat({banned: false}));
                }, DURATION_COOLDOWN_CHAT_SUSPENSION);
            }
        };
        const deliverPublicMessage = () => {
            let username = null;
            const privateMessage = text[0] === '@';
            if (privateMessage) {
                username = text.substring(text.indexOf('@') + 1, text.indexOf(" "));
                text = text.substring(text.indexOf(" ") + 1, text.length);
            }
            masterSocket.send(JSON.stringify({
                'event': 'chat',
                'to': username,
                'msg': text,
            }));
        };
        const deliverMatchMessage = () => {
            if (gameSocket !== null) {
                try {
                    if (team !== null) {
                        let msg = {
                            'event': 'sendChat',
                            'roomId': roomId,
                            'playerId': playerId,
                            'sessionId': sessionId,
                            'chatMessage': text,
                        };
                        gameSocket.send(JSON.stringify(msg));
                    }
                    else {
                        let msg = {
                            'event': 'spectatorChat',
                            'roomId': roomId,
                            //'playerId': playerId,
                            //'sessionId': sessionId,
                            'msg': text,
                        };
                        masterSocket.send(JSON.stringify(msg));
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }
        };

        if (userPressEnter && !banned) {
            if (text) {
                if (chatPublic) {
                    deliverPublicMessage();
                } else {
                    deliverMatchMessage();
                }
                chatCooldown();
            }

            if (chatInputElement.current) {
                chatInputElement.current.value = null;
                if (!chatPublic) {
                    // If the user is in a match they only want to send one message than get back in the game.
                    setTimeout(() => {
                        if (chatInputElement.current) {
                            chatInputElement.current.blur()
                        }
                    }, 0);
                }
            }
        }
    }
};