import Proton from 'proton-engine';
import { updatePerFrame } from './protonConfig';
import { getSound } from '../../data/sound';

let burnSound = new Audio(getSound('burn').noise);
let dampener = getSound('burn').dampener;
burnSound.currentTime = 0;
burnSound.volume = 1;
let fadeOutTime = 1000;
let fadeTime = 0;

let speedBallParticles = {};
speedBallParticles.enabled = false;

let emitter = new Proton.Emitter();
emitter.rate = new Proton.Rate(new Proton.Span(0, 0), 1000);
emitter.addInitialize(new Proton.Mass(1));
emitter.damping = 0;
emitter.addInitialize(new Proton.Radius(15, 30));
emitter.addInitialize(new Proton.Life(0.15, 0.3));
emitter.addBehaviour(new Proton.Color('ff0000', 'ffff00'));
emitter.addBehaviour(new Proton.Scale(1, 3));
emitter.addBehaviour(new Proton.Alpha(0.6, 0, 'easeIn'));
emitter.addInitialize(new Proton.Velocity(new Proton.Span(1, 2), new Proton.Span(0, 360), 'polar'));
emitter.emit();

//EMITTER
speedBallParticles.emitter = emitter;

//ENABLE
speedBallParticles.enable = () => {
    if (!speedBallParticles.enabled) {
        burnSound.currentTime = 0;
        burnSound.volume = 1 * dampener;
        burnSound.play();
        speedBallParticles.enabled = true;
        speedBallParticles.emitter.rate = new Proton.Rate(6, updatePerFrame);
    }
};

//DISABLE
speedBallParticles.disable = () => {
    if (speedBallParticles.enabled) {
        speedBallParticles.enabled = false;
        fadeTime = Date.now();
        emitter.rate = new Proton.Rate(new Proton.Span(0, 0), 1000);
    }
    if (!burnSound.paused || burnSound.currentTime !== 0) {
        let volume = (1 - (Date.now() - fadeTime) / fadeOutTime) * dampener;
        if (volume < 0) {
            volume = 0;
			burnSound.pause();
			burnSound.currentTime = 0;
        }
        if (volume > 1) {
            volume = 1;
        }
        burnSound.volume = volume;
    }
};

//UPDATE
speedBallParticles.update = (ball) => {
    if (speedBallParticles.enabled) {
        emitter.p.x = ball.position.x;
        emitter.p.y = ball.position.y;
    }
};

export { speedBallParticles };
