import Proton from 'proton-engine';
import { updatePerFrame } from './protonConfig';

let snowParticles = {};
snowParticles.enabled = false;

let emitter = new Proton.Emitter();
emitter.rate = new Proton.Rate(0, 1000);
emitter.addInitialize(new Proton.Mass(1));
emitter.damping = 0;
emitter.addInitialize(new Proton.Radius(4, 8));
emitter.addInitialize(new Proton.Life(8, 16));
emitter.addInitialize(
    new Proton.Velocity(new Proton.Span(-0.1, 0.1), new Proton.Span(0.5, 1), 'vector')
);
emitter.addBehaviour(new Proton.RandomDrift(2, 1, 0.01));
emitter.addBehaviour(new Proton.Color('ffffff', 'aaaaff'));
emitter.addBehaviour(new Proton.Scale(1, 0.7));
emitter.addBehaviour(new Proton.Alpha(0.8, 0));
emitter.addInitialize(new Proton.Position(new Proton.RectZone(0, 0, 1920, 0)));

//EMITTER
snowParticles.emitter = emitter;
snowParticles.emitter.rate = new Proton.Rate(1, updatePerFrame * 4);


//ENABLE
snowParticles.enable = () => {
    if (!snowParticles.enabled) {
        emitter.emit(); 
        snowParticles.enabled = true;
    }
};

//DISABLE
snowParticles.disable = () => {
    if (snowParticles.enabled) {
        emitter.stop();
        snowParticles.enabled = false;
    }
};

//UPDATE
snowParticles.update = () => {};

export { snowParticles };
