import {OverlayContentDiv, RestockOverlayDiv, RestockTextDiv, TruckDeliveryDiv} from "../MarketStyle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";


const MarketRestockOverlay = ({refOverlayDiv}) => {

    return (
        <RestockOverlayDiv
            ref={refOverlayDiv}
            className="animated"
        >
            <OverlayContentDiv>
                <TruckDeliveryDiv>
                    <FontAwesomeIcon icon={['fas', 'shipping-fast']}/>
                </TruckDeliveryDiv>
                <RestockTextDiv>RESTOCKING NEW ITEMS!</RestockTextDiv>
            </OverlayContentDiv>
        </RestockOverlayDiv>
    )
};

export default MarketRestockOverlay;