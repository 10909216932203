
export default {
    // The default game mode preference settings for the session
    preference: {
        volley1: true,
        soccer1: true,
        hoops1: true,
        volley2: false,
        volley3: false,
        volley4: false,
        soccer2: false,
        soccer3: false,
        soccer4: false,
        hoops2: false,
        hoops3: false,
        hoops4: false,
        stats: true,
    },

    active: null,   // Is the user is currently in queue
    wait: 0,        // Duration the user has been in queue
    cooldown: 0     // The remaining time before the user can queue again
};