import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';

import { masterSocket } from "../../redux/middleware/wsMaster";
import { getDivision } from '../../data/rating';
import { playSound } from '../../data/sound';
import Header from '../../component/Header/Header';
import GameSelectFooter from './GameSelectFooter';
import {selectLeader} from "../../data/squadSelector";
import {getTierData} from "../../data/tier";
import styled from 'styled-components';
import palette from "../../styled/Palette";
import ModalGameSelect from "../../component/Modal/Type/ModalGameSelect";
import {userServerSelection} from "../../system/queue";
import {
    ModeButton,
    HeaderDiv,
    SectionDiv,
    ModeDiv,
    ModeRowDiv,
    BodyDiv,
    SportDiv
} from './GameSelectStyle';
import {requestJoinQueue, requestLeaveQueue} from "../../system/endpoints/master";
import {setPreferenceGameModes} from "../../redux/modules/queue/queueActions";
import {selectPreference} from "../../redux/modules/queue/queueSelectors";
import {PageTypes} from "../../system/types";

const UserRankDiv = styled.div`
        font-weight: bold;
        font-size: .8em;
        letter-spacing: .1em;
        color: ${palette.base4};
        justify-content: center; 
        align-items: center;
        ${props => props.color && `color: ${props.color}`}
    `;

const GameSelect = (props) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const squad = useSelector(state => state.squad);
    const queue = useSelector(state => state.queue);
    const inQueue = useSelector(state => state.queue.active);
    const { rating, username } = useSelector(state => state.self);
    const acceptableServers = useSelector(state => state.socket.acceptableServers);

    const queueSettings = useSelector(selectPreference);

    const ranked = props.history.location.state.mode === 'ranked';
    const modeIsSelected = Object.keys(queueSettings).filter(mode => queueSettings[mode] === true && mode !== 'stats').length > 0;

    const updateQueue = (mode) => {
        playSound(!queueSettings[mode] ? 'disable' : 'enable',1);
        dispatch(setPreferenceGameModes({queueSettings, mode}));
    };

    const leader = selectLeader(squad.current,username);

    const queueFunc = () => {
        if (inQueue) {
            playSound('leftQueue', 1);
            requestLeaveQueue();
        } else {
            if (acceptableServers.length > 0) {
                requestJoinQueue(
                    queueSettings,
                    acceptableServers,
                    {ranked: ranked, ability: queueSettings.stats}
                )
            }
        }
    };

    const getModeButton = (mode) => {
        const squadTooLargeForMode = getModeSize(mode) < squad.current.length;
        const inSquad = squad.current.length > 0;
        function buttonText () {
            if (mode.includes('1')) {
                return '1 vs 1';
            } else if (mode.includes('2')) {
                return '2 vs 2';
            } else if (mode.includes('3')) {
                return '3 vs 3';
            } else if (mode.includes('4')) {
                return '4 vs 4';
            } else {
                return 'Any Mode'
            }
        }
        function getModeSize () {
            if (mode.includes('1')) {
                return 1;
            } else if (mode.includes('2')) {
                return 2;
            } else if (mode.includes('3')) {
                return 3;
            } else if (mode.includes('4')) {
                return 4;
            } else {
                return null
            }
        }

        const isEnabled = () => {
            if (inSquad) {
                if (getModeSize(mode) < squad.current.length) return false;
            }
            return queueSettings[mode];
        };
        const enabled = isEnabled();

        // Also update the preference object to reflect which buttons are currently enabled
        if (queueSettings[mode] !== enabled) {
            updateQueue(mode);
        }

        const text = buttonText();
        return (
            <ModeButton
                tabIndex={0}
                theme={theme}
                onClick={() => updateQueue(mode)}
                id={mode}
                locked={!leader || squadTooLargeForMode}
                enabled={enabled}>
                <div>{text}</div>
                <div>
                    {leader && enabled && <FontAwesomeIcon style={{color: theme.interfaceColorBright, fontSize: '20px'}} icon={['fas', 'check']}/>}
                    {(!leader || squadTooLargeForMode) && <FontAwesomeIcon style={{color: theme.interfaceColor, fontSize: '20px'}} icon={['fas', 'lock']}/>}
                </div>
            </ModeButton>
        );
    };

    const renderRank = (mode) => {
        return (
            <img
                alt='rankImage'
                style={{margin: '0 1em'}}
                width="60px"
                height="60px"
                src={getDivision(rating.current[mode]).image}/>
        )
    };

    useEffect(() => {
        playSound('screenClose',1);
    }, []);

    return (
        <div className="windowed animatedFast slideInRight"
             style={{color: 'white'}}
        >
            <Header closeRoute={PageTypes.MAIN}
                    title={
                        <div>
                            {ranked ? 'RANKED PLAY' : 'CASUAL PLAY'}
                            <FontAwesomeIcon
                                tabIndex={0}
                                className="buttonZoom"
                                style={{marginLeft: '1em'}}
                                onClick={() => ModalGameSelect(dispatch)}
                                icon={['fas', 'info-circle']}
                            />
                        </div>
                    } />
            <BodyDiv>
                <HeaderDiv>SELECT MODE {queueSettings.stats}</HeaderDiv>
                <SectionDiv>
                    <SportDiv><i className="fas fa-volleyball-ball"> </i></SportDiv>
                    <ModeDiv>Volley</ModeDiv>
                    <ModeRowDiv>
                        {ranked &&
                        <UserRankDiv color={getTierData(getDivision(rating.current['volley']).tier).color}>
                            {getDivision(rating.current['volley']).word}&nbsp;
                            {getDivision(rating.current['volley']).rank}&nbsp;
                            {getDivision(rating.current['volley']).division}
                        </UserRankDiv>
                        }
                        {ranked  && renderRank('volley')}
                        {getModeButton('volley1')}
                        {getModeButton('volley2')}
                        {getModeButton('volley3')}
                        {getModeButton('volley4')}
                    </ModeRowDiv>
                </SectionDiv>
                <SectionDiv>
                    <SportDiv><i className="fas fa-futbol"> </i></SportDiv>
                    <ModeDiv>Soccer</ModeDiv>
                    <ModeRowDiv>
                        {ranked &&
                        <UserRankDiv color={getTierData(getDivision(rating.current['soccer']).tier).color}>
                            {getDivision(rating.current['soccer']).word}&nbsp;
                            {getDivision(rating.current['soccer']).rank}&nbsp;
                            {getDivision(rating.current['soccer']).division}
                        </UserRankDiv>
                        }
                        {ranked  && renderRank('soccer')}
                        {getModeButton('soccer1')}
                        {getModeButton('soccer2')}
                        {getModeButton('soccer3')}
                        {getModeButton('soccer4')}
                    </ModeRowDiv>
                </SectionDiv>
                <SectionDiv>
                    <SportDiv><i className="fas fa-basketball-ball"> </i></SportDiv>
                    <ModeDiv>Hoops</ModeDiv>
                    <ModeRowDiv>
                        {ranked &&
                        <UserRankDiv color={getTierData(getDivision(rating.current['hoops']).tier).color}>
                            {getDivision(rating.current['hoops']).word}&nbsp;
                            {getDivision(rating.current['hoops']).rank}&nbsp;
                            {getDivision(rating.current['hoops']).division}
                        </UserRankDiv>
                        }
                        {ranked  && renderRank('hoops')}
                        {getModeButton('hoops1')}
                        {getModeButton('hoops2')}
                        {getModeButton('hoops3')}
                        {getModeButton('hoops4')}
                    </ModeRowDiv>
                </SectionDiv>
                <GameSelectFooter
                    queueSettings={queueSettings}
                    updateQueue={updateQueue}
                    queueFunc={queueFunc}
                    queue={queue}
                    inQueue={inQueue}
                    modeIsSelected={modeIsSelected}
                    leader={leader}
                />
            </BodyDiv>
        </div>
    );

};

export default GameSelect;