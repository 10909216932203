import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { playSound } from '../../data/sound';
import {useSelector} from "react-redux";
import styled from 'styled-components';
import palette from "../../styled/Palette";

export const ButtonOptionDiv = styled.div`
    display: flex;
    flex-grow: 1;
    max-width: 10em;
    flex-direction: column;
    margin-right: .5em;
    background-color: ${palette.midnightBlue};
    border: 1px solid ${palette.wetAsphalt};
    height: 5em;
    justify-content: center;
    cursor: pointer;
`;
const ButtonMode = ({mode, click}) => {
    const theme = useSelector(state => state.theme);

    const sportIconMap = () => {
        switch (mode) {
            case 'volley':
                return 'volleyball-ball';
            case 'soccer':
                return 'futbol';
            case 'hoops':
                return 'basketball-ball';
            case 'ability on':
                return 'check-circle';
            case 'ability off':
                return 'times-circle';
            case 'set region':
                return 'globe-americas';
        }
    };

    return (
        <ButtonOptionDiv
            tabIndex={0}
            onClick={() => {
                click();
                playSound('pressButton', 1);
            }}
            onMouseEnter={() => {
                playSound('itemHover', 1)
            }}
        >
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <FontAwesomeIcon style={{color: theme.interfaceColorBright, fontSize: '30px', marginBottom: '5px'}} icon={['fas', sportIconMap()]} />
                <div style={{whiteSpace: 'nowrap'}}>{mode.toUpperCase()}</div>
            </div>
        </ButtonOptionDiv>
    )
};
export default ButtonMode;