import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coin from "../../images/coin.png";
import { currency } from '../../data/format';
import { playSound } from '../../data/sound'
import { useSelector, useDispatch } from 'react-redux';
import {drawerToggle} from "../../redux/modules/socket/socketActions";
import {masterSocket} from "../../redux/middleware/wsMaster";
import {
    DrawerDiv,
    DrawerHeader,
    Section,
    Notification,
} from './DrawerDiv';
import ModalCoins from "../Modal/Type/ModalCoins";
import {PageTypes} from "../../system/types";

const IconChat = require('react-icons/lib/md/forum');

const Drawer = ({history}) => {
    if (history.location.pathname === '/') {
        return null;
    }

    const dispatch = useDispatch();
    const chat = useSelector(state => state.chat);
    const theme = useSelector(state => state.theme);
    const drawerView = useSelector(state => state.layout.drawer.view);
    const drawerOpen = useSelector(state => state.layout.drawer.open);
    const assetsLoaded = useSelector(state => state.socket.assetsLoaded);
    const { username, tickets, coins } = useSelector(state => state.self);

    const route = (destination) => {
        if (username || destination === PageTypes.SIGN_IN) {
            if (history.location.pathname === destination) {
                playSound('screenOpen',1);
            } else {
                playSound('screenClose', 1);
            }

            if (history.location.pathname === destination) {
                history.push(PageTypes.MAIN);
                dispatch(drawerToggle({view: null}));
            } else {
                dispatch(drawerToggle({view: destination}));
                history.push(destination);
            }
        }
    };

    const handleHover = () => {
        playSound('Birdy_Done5',.1);
    };

    const gameLoaded = masterSocket.readyState === 1 && assetsLoaded;
    if (drawerOpen && username && gameLoaded) {
        return (
            <DrawerDiv
                id="drawer"
                className="styleDarkScrollSquare animatedFast slideInLeft">
                <>
                    <DrawerHeader
                        tabIndex={0}
                        onClick={() => ModalCoins(dispatch)}>
                        <>
                            <div><img src={coin} alt='Coins'/></div>
                            <div className="coins">
                                {currency(coins)}
                            </div>
                        </>
                    </DrawerHeader>
                    <>
                        <Section
                                 tabIndex={0}
                                 onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.FEATS}
                                 className="section"
                                 onClick={() => route(PageTypes.FEATS)}>
                            <FontAwesomeIcon icon={['fas', 'star']} />
                            <span>Profile</span>
                        </Section>
                        <Section
                                tabIndex={0}
                                onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.LOOT}
                                 className="section"
                                 onClick={() => route(PageTypes.LOOT)}>
                            <FontAwesomeIcon icon={['fas', 'sack']} />
                            <span>Loot</span>
                        </Section>
                        <Section
                            tabIndex={0}
                            onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.PUBLIC_CHAT}
                                 className="section"
                                 onClick={() => route(PageTypes.PUBLIC_CHAT)}>
                            <IconChat/>
                            {drawerView !== PageTypes.PUBLIC_CHAT && chat.unread > 0 &&
                            <Notification>{chat.unread}</Notification>
                            }
                            <span>Chat</span>
                        </Section>
                        <Section
                            tabIndex={0}
                            onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.WATCH}
                                 className="section"
                                 onClick={() => route(PageTypes.WATCH)}>
                            <FontAwesomeIcon icon={['far', 'tv-retro']} />
                            <span>Watch</span>
                        </Section>
                        <Section
                            tabIndex={0}
                            onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.MARKET}
                                 className="section"
                                 onClick={() => route(PageTypes.MARKET)}>
                            <FontAwesomeIcon icon={['fas', 'archway']} />
                            <span>Market</span>
                        </Section>
                        <Section
                            tabIndex={0}
                            onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.HIGH_SCORES}
                                 className="section" onClick={() => route(PageTypes.HIGH_SCORES)}>
                            <FontAwesomeIcon icon={['fas', 'trophy-alt']} />
                            <span>Hiscores</span>
                        </Section>
                        <Section
                            tabIndex={0}
                            onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.SLOTS}
                                 className="section"
                                 onClick={() => route(PageTypes.SLOTS)}>
                            <FontAwesomeIcon icon={['fas', 'ticket-alt']} />
                            <span>Chance {tickets > 0 && `(${currency(tickets)})`}</span>
                        </Section>
                        <Section
                            tabIndex={0}
                            onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.CASH_SHOP}
                                 className="section"
                                 onClick={() => route(PageTypes.CASH_SHOP)}>
                            <FontAwesomeIcon icon={['fas', 'treasure-chest']} />
                            <span>Cash</span>
                        </Section>
                        <Section
                            tabIndex={0}
                            onMouseEnter={handleHover}
                                 interfaceColor={theme.interfaceColor}
                                 isActive={drawerView === PageTypes.OPTIONS}
                                 className="section"
                                 onClick={() => route(PageTypes.OPTIONS)}>
                            <FontAwesomeIcon icon={['fas', 'cog']} />
                            <span>Options</span>
                        </Section>
                    </>
                </>
            </DrawerDiv>
        )
    }
    return null;
};

export default Drawer;