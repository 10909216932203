import {MatchPingDiv} from "../../page/Match/MatchStyle";
import React, {useEffect, useState} from "react";
import {restfulCall} from "../../data/globals";
import {gameSocket} from "../../redux/middleware/wsMatch";
import {useSelector} from "react-redux";


const Ping = () => {
    const { server } = useSelector(state => state.match);
    const [latency, setLatency] = useState(0);

    useEffect(() => {
        const pingInterval = setInterval(async () => {
            let startTimer = Date.now();
            await restfulCall(gameSocket, {'event': 'ping'}, 'pong');
            setLatency((Date.now() - startTimer) / 2);
        }, 4000);

        return () => {
            clearInterval(pingInterval);
        }
    },[]);
    return (
        <MatchPingDiv>
            Server: {server.name} - {server.region} ({latency} ms)
        </MatchPingDiv>
    )
};

export default Ping;