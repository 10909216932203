
export default {
    chatBanned: 0,
    items: {
        accessory: [],
        cap: [],
        face: [],
        paint: [],
        skin: [],
    },
    itemSellValue: null,
    newItems: [],
    outfits: [
        {
            name: 'Default Outfit',
            accessory: null,
            cap: null,
            face: null,
            paint: null,
            skin: null,
        },
    ],
    outfit: {
        name: 'Default Outfit',
        accessory: null,
        cap: null,
        face: null,
        paint: null,
        skin: null,
    },
    present: {},
    premium: false,

    rating: {
        previous: null,
        current: {
            volley: null,
            soccer: null,
            hoops: null
        },
    },
    // Not sure these are totally necessary. Could maybe determine this by analyzing the 'match' player usernames
    team: null,
    teamIndex: null,
    stats: {
        size: 1,
        jumpDuration: 1,
        jumpPower: 1,
        maxSpeed: 1,
        nimbleness: 1,
        acceleration: 1,
        mass: 1,
        airJumps: 0,
        height: 1,
        width: 1,
        tumble: false,
    },
    xsollaToken: null,
};