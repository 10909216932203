import achievements from "../../../data/achievements";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import styled from 'styled-components';
import {
    DropAchievementDescriptionDiv,
    DropAchievementDiv, DropContainer,
    DropDescriptionDiv, DropFooter,
    DropMountDiv,
    DropStarsDiv
} from "../style";
import {roman} from "../../../data/generic/generic";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";

const StarFillDiv = styled.div`
    animation-delay: 2s;
`;

const stars = (achievementLevel, currentViewItem) => {
    const starsForAchievement = achievements[currentViewItem.name].levels;
    return [...Array(starsForAchievement)].map((iterator,i) =>  {
        switch (true) {
            case achievementLevel > i+1: return <FontAwesomeIcon key={i} style={{opacity: 1}} icon={['fas', 'star']} />;
            case achievementLevel === i+1: return (
                <div style={{position: 'relative', marginTop: '-.05em'}}>
                    <StarFillDiv style={{opacity: 0}} className='animatedSlow fadeIn'>
                        <FontAwesomeIcon key={i} style={{opacity: 1}} icon={['fas', 'star']} />
                    </StarFillDiv>
                    <div style={{top: 0, left: 0, right: 0, bottom: 0, position: 'absolute', opacity: 1}} >
                        <FontAwesomeIcon key={i} icon={['far', 'star']} />
                    </div>
                </div>
            );
            case achievementLevel < i+1: return <FontAwesomeIcon key={i} style={{opacity: 1}} icon={['far', 'star']} />;
        }
    });
};

const DropAchievement = ({currentViewItem, theme, handleReturn}) => {
    return (
        <>
            <div className={'animatedSlow zoomIn'} style={{margin: '2em'}}>
                <DropMountDiv
                    color={theme.interfaceColor}>
                    <DropStarsDiv>
                        {stars(parseInt(currentViewItem.name.match(/\d+/)),currentViewItem)}
                    </DropStarsDiv>
                    <DropDescriptionDiv>
                        <DropAchievementDiv>{currentViewItem.name.replace(/[0-9]/g, '').toUpperCase()} {roman(parseInt(currentViewItem.name.match(/\d+/)))}</DropAchievementDiv>
                        <DropAchievementDescriptionDiv>
                            {achievements[currentViewItem.name].description.toUpperCase()}
                        </DropAchievementDescriptionDiv>
                    </DropDescriptionDiv>
                </DropMountDiv>
            </div>
            <DropFooter>
                <div style={{display: 'flex'}}>
                    <Button
                        styles={{width: STANDARD_BUTTON_WIDTH}}
                        title="Cool"
                        click={() => handleReturn()}
                    />
                </div>
            </DropFooter>
        </>
    )
}

export default DropAchievement;