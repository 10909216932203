import styled from "styled-components";
import palette from "../../styled/Palette";
import {shadow} from "../../data/globals";


export const SquadButtonButton = styled.button`
    font-size: ${props => props.small ? '.65em' : '1em'};
    ${props => props.small && 'padding: .5em 1em;'}
    border-radius: 4px;
    flex-grow: 1;
    width: 100%;
    color: ${palette.concrete};
    display: ${props => props.layoutFlex ? 'flex' : null};
    align-items: center;
    &:hover {
        background-color: ${palette.base0};
    }
`;

export const SquadUsernameDiv = styled.div`
    color: ${props => props.colorHex};
    font-weight: bold;
    margin: .35em 0;
`;

//We have the means to stagger the images with margin: .35em 0 1em 0;
export const SquadUserDiv = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: ${palette.base3};
    :nth-of-type(even) {
        background-color: ${palette.base5};
        ${SquadUsernameDiv} {
            margin: .35em 0; 
        }
    }
`;
export const SquadHeaderDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${palette.base1};
    padding: .5em 1em;
    color: ${palette.base4};
`;
export const SquadBodyDiv = styled.div`
    box-shadow: ${shadow};
    display: flex;
    flex-grow: 1;
    :nth-of-type(even) {
        background-color: green;
    }
`;
export const SquadContainerDiv = styled.div`
    border: 1px solid ${palette.wetAsphalt};
    margin-top: 2em;
    display: flex;
    width: ${props => props.fullWidth ? '100%' : '60.75em'};
    height: 15em;
    flex-direction: column;
`;
export const SquadFooterDiv = styled.div`
    justify-content: center;
    display: flex;
    background-color: ${palette.base1};
    height: 6em;
    justify-self: flex-end;
    padding: .5em;
`;