import React, {useEffect, useRef, useState} from 'react';
import Card from "../../component/Card/Card";
import Header from "../../component/Header/Header";
import RestockClock from "./RestockClock";
import MarketRestockOverlay from "./MarketRestockOverlay/MarketRestockOverlay";
import {TabDiv, TabsContainerDiv} from '../../styled/Tabs';
import SlimePan from "../../styled/SlimePan";
import { setActiveTab } from "../../redux/modules/shop/shopActions";
import { playSound } from '../../data/sound';
import {useDispatch, useSelector} from "react-redux";
import {drawerToggle} from "../../redux/modules/socket/socketActions";
import palette from "../../styled/Palette";
import InfoBanner from "../../component/InfoBanner/InfoBanner";
import {
    MountDiv,
    BodyDiv
} from './MarketStyle';
import {PageTypes} from "../../system/types";

const Market = ({history}) => {
    const dispatch = useDispatch();
    const shopItems = useSelector(state => state.shop.gear);
    const activeTab = useSelector(state => state.shop.activeTab);
    const [restocking,setRestocking] = useState(false);
    const refOverlayDiv = useRef();

    const handleCategory = (tab) => {
        if (tab !== activeTab) {
            dispatch(setActiveTab(tab));
            playSound('pressButton', 1);
        }
    };
    const renderTabs = () => {
        const marketTabs = ['cap','skin','paint'];
        return (
            <TabsContainerDiv style={{alignSelf: 'flex-end'}}>
                {
                    marketTabs.map((tab) => {
                        return (
                            <TabDiv
                                tabIndex={0}
                                key={tab}
                                active={activeTab === tab}
                                onClick={() => handleCategory(tab)}
                            >
                                {tab.toUpperCase()}
                            </TabDiv>
                        )
                    })
                }
            </TabsContainerDiv>
        )
    };


    useEffect(() => {
        dispatch(drawerToggle({open: true}));
    }, []);

    return (
        <div className="animatedFast slideInRight fullScreen" style={{position: 'relative', display: 'flex', height: '100%', fontSize: '10px'}}>
            <MarketRestockOverlay
                refOverlayDiv={refOverlayDiv}
            />
            <Header closeRoute={PageTypes.MAIN} title="MARKET"/>
            <SlimePan style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <BodyDiv>
                    <div style={{display: 'flex', flexDirection: 'column', margin: '0 1em', width: '120em',marginTop: '2.5em'}}>
                        <InfoBanner
                            title={'Live Marketplace'}
                            body={
                                <div>
                                    Compete with other players to grab up the best items! Whether you're trying to look your best or play your best, all the items you need can be found here. Items restock every few hours so keep your eye out for a steal.
                                </div>
                            }
                        />
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <div style={{display: 'flex'}}>
                                {renderTabs()}
                            </div>
                            <RestockClock refOverlayDiv={refOverlayDiv} setRestocking={setRestocking}/>
                        </div>
                        <div style={{display: 'flex'}}>
                            <MountDiv className="styleDarkScrollSquare">
                                {!restocking &&
                                shopItems.map((item, index) => {
                                    if (item.category === activeTab) {
                                        return (
                                            <div
                                                className="animated animatedSlow"
                                                key={item.key}
                                            >
                                                <Card
                                                    disabled={false}
                                                    key={item.key}
                                                    flipped={false}
                                                    history={history}
                                                    index={index}
                                                    item={item}
                                                    backgroundColor={item.category === 'paint' ? item.colorHex : palette.base7}
                                                />
                                            </div>
                                        )
                                    }
                                    return null;
                                })
                                }
                            </MountDiv>
                        </div>
                    </div>
                </BodyDiv>
            </SlimePan>
        </div>
    )
};

export default Market;

/*
  <ShopFlipButton onClick={() => flipAll()}>
                                    FLIP
                                    <FontAwesomeIcon
                                        style={{fontSize: '1em', marginLeft: '.5em'}}
                                        icon={['fas', 'sync']}
                                    />
                                </ShopFlipButton>
 */