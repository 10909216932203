import styled from 'styled-components';
import bg from "../images/repeater.png";
import starBG from "../images/repeaterStar.png";
import palette from "./Palette";

/**
 * All the styles in this file are repeater backgrounds
 */

const SlimePan = styled.div`
  animation: slide 20s linear infinite;
  background-image: url(${bg});
  background-repeat: repeat;
  flex-grow: 1;
  background-color: ${palette.base5};
`;

export const StarPan = styled.div`
    position: absolute;
    animation: slide 20s linear infinite;
    background-image: url(${starBG});
    background-repeat: repeat;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

export const FloorGeneric = styled.div`
  ${props => props.styles};
  background-image: url(${props => props.floor});
  background-repeat: repeat;
  flex-grow: 1;
`;

export default SlimePan;
