import palette from '../../styled/Palette';
import styled from 'styled-components';

export const LoadingDiv = styled.div`
    z-index: 101;
    pointer-events: none;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    position: absolute;
    height: 100%;
    width: 100%;
    
    color: ${palette.base4};
    background-color: ${palette.base3};
    
    opacity: ${props => props.isLoading ? 1:0};
    transition: all 1s ease-in-out;
`;

export const StatusDiv = styled.div`
    font-weight: bold;
    font-size: 2em;
    margin-top: 1em;
`;