import React, {useEffect, useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useSelector} from "react-redux";
import {modalDisable} from "../../redux/modules/modal/modalActions";
import { getRandomInt } from '../../data/format';
import Icon, {useIcon} from "../../data/icon";
import {CloseButtonSpan} from "../Header/HeaderStyle";
import {
    ModalDiv,
    ModalContentDiv,
    BodyDiv,
    TitleDiv
} from './ModalStyle';
import palette from "../../styled/Palette";
import {ServerFeedbackData} from "./Type/ModalServerFeedback";
import {CoinsData} from "./Type/ModalCoins";
import {ServerSelectData, ServerSelectOptionData} from "./Type/ModalServerSelect";
import {AchievementsData} from "./Type/ModalAchievements";
import {GameSelectData} from "./Type/ModalGameSelect";
import {BrowserData} from "./Type/ModalBrowserWarning";
import {PremiumOutfitData} from "./Type/ModalPremiumOutfit";
import {LootStatsData} from "./Type/ModalLootStats";
import {SlotsTicketsData} from "./Type/ModalSlotsTickets";
import {MembersRankedData} from "./Type/ModalMembersRanked";
import {PremiumPrivateMatchData} from "./Type/ModalPremiumPrivateMatch";
import {BanUserData} from "./Type/ModalBanUser";
import {UserAliasData} from "./Type/ModalUserAlias";
import {loadoutData} from "./Type/ModalLoadout";

const Modal = ({dispatch}) => {
    const { content } = useSelector(state => state.modal);

    let approval = content.conditional ? ['Yes'] : ['Okay','Great','Cool','Nice'];
    if (approval.length > 1) {
        approval = approval[getRandomInt(approval.length)];
    }

    const handleAccept = () => {
        if (content.userAccept) content.userAccept();
        dispatch(modalDisable());
    };
    const handleDecline = () => {
        if (content.userReject) content.userReject();
        dispatch(modalDisable());
    };

    const options = () => {
        switch (content.option) {
            case 'ServerSelectOption': return ServerSelectOptionData;
            default: return null;
        }
    };

    /**
     * This really only needs to happen if the user has the controller currently enabled.
     * I'm just leaving it as is to keep it simple.
     */
    useEffect(() => {
        // All of the selectable elements
        const elementPool = document.querySelectorAll('[tabIndex="0"], [tabIndex="-1"]');
        const modalIsOpen = content.title;

        if (modalIsOpen) {
            // Should be disabled during modal open
            elementPool.forEach(element => {
                element.setAttribute("tabindex","-1");
            });
           // Only the elements on the modal should be selectable
            const options = document.getElementsByClassName('modalOption');

            Array.prototype.slice.call( options ).map(element => {
                element.setAttribute("tabIndex", "0");
            });

            // Select the first option
            if (options.length > 0) {
                options[0].focus();
            }
        } else {
            // All elements should again become selectable
            elementPool.forEach(element => {
                element.setAttribute("tabindex","0");
            });
        }
    }, [content.title]);

    if (!content.title) return null;

    return (
        <ModalDiv className={'animatedFast fadeIn'}>
            <ModalContentDiv>
                <TitleDiv className="fontLarge">
                    <FontAwesomeIcon
                        icon={['fas', 'scroll-old']}
                        style={{display: 'flex', fontSize: '1.5em', margin: '0 1em 0 .5em'}}
                    />
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {content.title}
                    </div>
                    {content.escapable &&
                    <CloseButtonSpan onClick={() => handleDecline()}>
                        {useIcon('exit')}
                    </CloseButtonSpan>
                    }
                </TitleDiv>
                <div style={{backgroundColor: palette.base6}}>
                    {content.body &&
                    <BodyDiv>
                        {(() => {
                            // Since redux is unable to store elements I just export the modals 'body' from the associated modal type.
                            switch (content.body) {
                                case 'ServerFeedback': return ServerFeedbackData;
                                case 'Coins': return CoinsData;
                                case 'ServerSelect': return ServerSelectData;
                                case 'Achievements': return AchievementsData;
                                case 'GameSelect': return GameSelectData;
                                case 'BrowserWarning': return BrowserData;
                                case 'PremiumOutfit': return PremiumOutfitData;
                                case 'PremiumPrivateMatch': return PremiumPrivateMatchData;
                                case 'LootStats': return LootStatsData;
                                case 'SlotsTickets': return SlotsTicketsData;
                                case 'MembersRanked': return MembersRankedData;
                                case 'BanUser': return BanUserData;
                                case 'UserAlias': return UserAliasData;
                                case 'Loadout': return loadoutData;
                            }
                        })()}
                    </BodyDiv>
                    }
                    {!content.noOptions &&
                    <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '2em 0'}}>
                        {options()}
                        <button style={{minWidth: '150px', margin: '0 10px'}}
                                className={`modalOption standardButton ${content.disabled ? 'disabled' : ''}`}
                                tabIndex={0}
                                onClick={() => handleAccept()}
                        >
                            {approval}
                        </button>
                        {content.conditional &&
                        <button
                            style={{minWidth: '150px', margin: '0 10px'}}
                            className="standardButton modalOption"
                            onClick={() => handleDecline()}
                        >
                            No
                        </button>
                        }
                    </div>
                    }
                </div>
            </ModalContentDiv>
        </ModalDiv>
    )
};

export default Modal;