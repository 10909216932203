import React from 'react';
import {
    SunburstDiv,
    OuterDiv
} from './SunburstStyle';

export const Sunburst = () => {
    return (
        <SunburstDiv className="animationSpinReverse" style={{animationDuration: '200s'}}>
            <OuterDiv>
                <b></b><b></b><b></b><b></b><b></b><b></b><b></b><b></b><b></b><b></b>
            </OuterDiv>
        </SunburstDiv>
    )
};

export default Sunburst;