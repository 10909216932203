import React, {useEffect, useState} from 'react';
import {
    FooterDiv, ModesDiv
} from './GameSelectStyle';
import QueueButton from "../../component/QueueButton/QueueButton";
import ButtonMode from "../../component/Match/ButtonMode";
import {useDispatch, useSelector} from 'react-redux';
import ModalServerSelect from "../../component/Modal/Type/ModalServerSelect";
import {selectGameModesTitles} from "../../redux/modules/queue/queueSelectors";

const GameSelectFooter = ({queueSettings,updateQueue,inQueue,modeIsSelected,leader,queueFunc,queue}) => {
    const dispatch = useDispatch();
    const acceptableServers = useSelector(state => state.socket.acceptableServers);
    const modalIsOpen = useSelector(state => state.modal.content.body);
    const [firstRun, setFirstRun] = useState(true);
    const cooldown = useSelector(state => state.socket.cooldown);

    const { volley, soccer, hoops} = useSelector(selectGameModesTitles);

    /**
     * Hack that hydrates the modal with the latest data
     */
    useEffect(() => {
        if (!firstRun && modalIsOpen) {
            ModalServerSelect(dispatch,acceptableServers,cooldown);
        } else {
            setFirstRun(false);
        }
    }, [acceptableServers, cooldown.servers]);

    return(
        <FooterDiv style={{marginBottom: '5px'}}>
            <ButtonMode
                click={() => updateQueue('stats')}
                mode={queueSettings.stats ? 'ability on' : 'ability off'}
            />
            <div style={{flexGrow: 1}}>
                <ButtonMode
                    click={() => ModalServerSelect(dispatch,acceptableServers,cooldown)}
                    mode={'set region'}
                />
            </div>
            <ModesDiv>
                {volley &&
                    <div>Volley {volley}</div>
                }
                {soccer &&
                    <div>Soccer {soccer}</div>
                }
                {hoops &&
                    <div>Hoops {hoops}</div>
                }
            </ModesDiv>
            <QueueButton
                queue={queue}
                queueFunc={queueFunc}
                inQueue={inQueue}
                leader={leader}
                modeIsSelected={modeIsSelected}
            />
        </FooterDiv>
    )
};

export default GameSelectFooter;