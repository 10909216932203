
const PowerShot = (lobby, context) => {
    lobby.powerShotPositions.forEach((powerShot, index) => {
        let powerShotHeight = 190;
        let powerShotWidth = 103;
        context.translate(powerShot.x, powerShot.y);
        context.rotate(powerShot.angle);
        //console.log(powerShot);
		let player = lobby.teams[powerShot.team][powerShot.teamIndex];
		let base = player.powerShot[powerShot.frame].base;
        let outline = player.powerShot[powerShot.frame].outline;
		if(player.alternativeAssets[player.activeAbility]){
			base = player.alternativeAssets[player.activeAbility].powerShot[powerShot.frame].base;
			outline = player.alternativeAssets[player.activeAbility].powerShot[powerShot.frame].outline;
		}

        powerShot.tickCounter++;
        if (powerShot.tickCounter == powerShot.frameDuration) {
            powerShot.tickCounter = 0;
            powerShot.frame++;
            if (powerShot.frame == powerShot.totalFrames - 1) {
                lobby.powerShotPositions.splice(index, 1);
            }
        }
        context.drawImage(base, -powerShotWidth / 2, -powerShotHeight / 2, powerShotWidth, powerShotHeight);
        context.drawImage(outline, -powerShotWidth / 2, -powerShotHeight / 2, powerShotWidth, powerShotHeight);
        context.rotate(-powerShot.angle);
        context.translate(-powerShot.x, -powerShot.y);
    });
};

export default PowerShot;