import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';

import Header from '../../component/Header/Header';
import SlimePan from "../../styled/SlimePan";
import { playSound } from '../../data/sound';
import LootScreenRight from "./LootScreenRight";
import LootScreenLeft from "./LootScreenLeft";
import {storeLootFilters} from "../../data/storeLootImages";
import {reset} from "../../webworker/main";
import {getAccessoryByAbility, getAccessoryByItem, getAccessoryData} from "../../data/items";
import {PageTypes} from "../../system/types";

const filterInitialState = {
    name: '',
    tier: '',
    color: ''
};

const Loot = ({history}) => {
    const self = useSelector(state => state.self);

    const [category, setCategory] = useState('face');
    const [sellWindow, setSellWindow] = useState(false);
    const [activeItem, setActiveItem] = useState({
        category: '',
        name: ''
    });

    const [itemNames, setItemNames] = useState([]);

    const [sellMode,setSellMode] = useState(false);

    const [demoColor, setDemoColor] = useState(null);

    const [filter, setFilter] = useState(filterInitialState);
    const [itemsFiltered, setItemsFiltered] = useState([]);
    const [filtersOn, setFiltersOn] = useState(false);

    const handleToggleSellWindow = (item) => {
        setActiveItem(item);

        setSellWindow(!sellWindow);
    };

    const toggleCategory = (newCategory) => {

        // As long as the category is not the one currently displayed.
        if (category !== newCategory) {
            playSound('pressButton', 1);

            // Clear all active workers trying to draw portraits
            reset().then(() => {
                setCategory(newCategory);
                setSellWindow(false);

                // Loads the filter setting for the active category
                setFilter(storeLootFilters.filters[newCategory]);

                // Exit sell mode if the user switches to a category whose items can not be sold.
                if (['face','accessory'].includes(newCategory)) {
                    setSellMode(false);
                }
            });
        }
    };

    const getFilteredItems = (items) => {
        if (filter.name) {
            items = items.filter(item => item.name === filter.name);
        }
        if (filter.tier) {
            items = items.filter(item => item.tier.toString() === filter.tier);
        }
        if (filter.color) {
            items = items.filter(item => item.colorCategory === filter.color);
        }

        // We need to append the related client side accessory data to each item
        if (category === 'accessory') {
            items = items.map(item => {
                // Append the client data for the item
                return {...item, ...getAccessoryByAbility(item.ability)};
            });
        }

        // We sort the consumable items to the front of the accessory list
        if (category === 'accessory') {
            items = items.sort((a, b) => (b.consumable) ? 1 : -1)
        }

        // We sort the new items in the front of the list
        items = items.reduce((acc, element) => {
            const timeSinceAcquiringItem = Date.now() - Date.parse(element.date);
            const isNewItem = timeSinceAcquiringItem < 60 * 60 * 1000;
            if (isNewItem) {
                return [element, ...acc];
            }
            return [...acc, element];
        }, []);

        return items;
    };

    const handleLoadItemNames = () => {
        // Populate filter drop down for the current category by dynamically building a list of items from the items store
        let itemNames = [];
        let categoryItems = self.items[category];
        categoryItems.forEach(item => {
            if (!itemNames.includes(item.name)) {
                itemNames.push(item.name);
            }
        });
        setItemNames(itemNames);
    };

    const setLootColorDemo = (name,colorHex) => {
        if (!name) {
            setDemoColor(null);
        } else {
            setDemoColor({name, colorHex});
        }
    };

    useEffect(() => {
        // Track if any filters are on; used for conditional rendering.
        const condition = filter.name !== '' || filter.tier !== '' || filter.color !== '';
        setFiltersOn(condition);
    },[filter.name,filter.tier,filter.color]);

    useEffect(() => {
        handleLoadItemNames();

        // If we don't clear the filtered items before setting them to the updated value
        // The associated ItemSlot does not reload from scratch. This causes some of the state
        // to be preserved, which leads to stale information being displayed.
        setItemsFiltered(getFilteredItems(self.items[category]));
    }, [
        category,               // When the category changes we rerender to display a different set of items
        filter,                 // When the filter changes we rerender to display fewer items
        self.items[category]    // When an accessory is enabled, we display the item differently, so we must rerender
    ]);
    return (
        <div className="animatedFast slideInRight fullScreen">
            <Header closeRoute={PageTypes.MAIN} title="LOOT"/>
            <SlimePan
                className="panBackdrop"
                style={{display: 'flex'}}
            >
                <LootScreenLeft
                    history={history}
                    demoColor={demoColor}
                    toggleCategory={toggleCategory}
                    itemsFiltered={itemsFiltered}
                    category={category}
                    item={activeItem}
                />
                <LootScreenRight
                    filtersOn={filtersOn}
                    filter={filter}
                    setFilter={setFilter}
                    history={history}
                    setLootColorDemo={setLootColorDemo}
                    sellMode={sellMode}
                    setSellMode={setSellMode}

                    setSellWindow={setSellWindow}
                    interfaceColor={activeItem.colorHex}

                    item={activeItem}
                    activeItem={activeItem}

                    sellWindow={sellWindow}
                    category={category}
                    itemNames={itemNames}
                    handleToggleSellWindow={handleToggleSellWindow}
                    itemsFiltered={itemsFiltered}
                    items={self.items}
                />
            </SlimePan>
        </div>
    )
};

export default Loot;