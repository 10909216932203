import styled from 'styled-components';

const TextUnderlineHover = styled.span`
    
    ${props => props.theme && `color: ${props.theme.interfaceColor}`};
    ${props => props.bold && 'font-weight: bold;'}
   
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export default TextUnderlineHover;
