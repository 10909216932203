import styled from 'styled-components';
import {useIcon} from "../data/icon";
import React from "react";
import palette from "../styled/Palette";

const ButtonCloseDiv = styled.div`
    ${({ styles }) => styles}
    
    font-size: 1.5em;
    cursor: pointer;
    
    color: ${palette.base4};
    &:hover {
        color: ${palette.clouds};
    }
    
    &:hover:not(:focus) {
        transform: scale(1.3);
    }
    &:focus:not(:hover) {
        transform: scale(1.3);
    }
`;

const ButtonClose = ({ click, styles }) => {
    return (
        <ButtonCloseDiv
            tabIndex={0}
            onClick={click}
            styles={styles}
        >
            {useIcon('exit')}
        </ButtonCloseDiv>
    )
};

export default ButtonClose;