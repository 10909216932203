
export const stats = ['acceleration','jumpDuration','jumpPower','maxSpeed','nimbleness','size'];

export const mapStats = (stat, returnType) => {
    switch (stat) {
        case 'acceleration':
            if (returnType === 'label') return 'Acceleration';
            break;
        case 'jumpDuration':
            if (returnType === 'label') return 'Jump Duration';
            break;
        case 'jumpPower':
            if (returnType === 'label') return 'Jump Power';
            break;
        case 'maxSpeed':
            if (returnType === 'label') return 'Max Speed';
            break;
        case 'nimbleness':
            if (returnType === 'label') return 'Nimbleness';
            break;
        case 'size':
            if (returnType === 'label') return 'Size';
            break;
    }
};

export const definitionStats = {
    acceleration: 'The time it takes for you to reach maximum velocity',
    jumpDuration: 'The amount of time a jumping force continues to be exerted',
    jumpPower: 'The amount of force your slime exerts when traveling upward',
    maxSpeed: 'The maximum threshold of speed achievable',
    nimbleness: 'The amount of time it takes to reverse directions',
    size: 'The big or smallness of your slime',
};

const renderTransforms = (statNames) => {
    const r = {...statNames};
    delete r.airJumps;
    delete r.height;
    delete r.width;
    delete r.tumble;
    delete r.mass;
    return r;
};
export const statsSortAlphabetical = (statNames) => {
    statNames = renderTransforms(statNames);

    const statsOrdered = {};
    Object.keys(statNames).sort().forEach(function(key) {
        statsOrdered[key] = statNames[key];
    });
    return statsOrdered;
};