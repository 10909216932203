import styled from 'styled-components';
import palette from '../../styled/Palette';
import {shadow} from "../../data/globals";

export const HomeHeaderDiv = styled.div`
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        letter-spacing: 3px;
        height: 3em;
        background-color: ${palette.base3};
        color: ${palette.base4};
        font-size: 1.25em;
        transition-delay: 2s;
        transition: all .5s;
`;

export const HomeIconDiv = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-size: 3em;
        color: ${palette.base4};
`;

export const HomeButtonDiv = styled.div`
        display: flex;
        flex-direction: column;
        
        box-shadow: ${shadow};
        
        transition-delay: 2s;
        transition: all .5s;
        
        z-index: 2;
        
        width: 20em;
        height: 10em;
        
        background-color: ${palette.base5};
        margin: 0 .16em;
            border: ${palette.wetAsphalt} 1px solid;
    
        &:hover, :focus {
            background-color: ${palette.midnightBlue} !important;
            color: ${palette.base4};
            cursor: pointer;
            margin-left: 25px;
            margin-right: 25px;
            border: ${palette.belizeHole} 1px solid;
            transform: scale(1.05);
            
            ${HomeHeaderDiv} {
                color: white;
                background-color: ${palette.wetAsphalt};
            }
              ${HomeIconDiv} {
                transform: scale(1.25);
                color: ${props => props.interfaceColor};
            }
        }
`;

export const MainContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
`;

export const MainNotifyQueueDiv = styled.div`
    opacity: ${props => props.inQueue ? 1 : 0};
    display: ${props => props.inQueue ? 'flex':'none'};
    
    font-weight: bold;
    font-style: italic;
    
    justify-content: center;
    align-items: center; 
    
    color: ${palette.base4};
    height: 3em;
    width: 100%;
    
    z-index: 10;
`;

export const NavHeaderDiv = styled.div`
    background-color: ${palette.base3};
    display: flex;
    width: 100%;
    height: 3.5em;
    z-index: 1;
    padding: 0 .25em;
    margin-bottom: 3em;
`;

export const HomeUserMetrics = styled.div`
    color: ${palette.base4};
    font-size: .75em;
    text-align: left;
    margin-left: 1em;
    display: flex;
    align-items: center;
    background-color: ${palette.base1};
    border-radius: 2px;
    margin: .5em;
`;