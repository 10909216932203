import {createReducer} from "@reduxjs/toolkit";
import initialState from "./itemModalInitialState";

const itemModalReducers = createReducer(initialState, {
    ITEM_MODAL_ENABLE: (state, action) => {
        state.item = action.payload;
    },
    ITEM_MODAL_DISABLE: (state) => {
        state.item = null;
    },
});

export default itemModalReducers;