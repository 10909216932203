import React, { useState, useEffect } from 'react';
import Header from '../../component/Header/Header';
import ButtonMode from "../../component/Match/ButtonMode";
import {
    PrivateMatchBodyDiv,
    SectionDiv,
    PlayerDiv,
    SubheaderDiv
} from './PrivateMatchStyle';
import { playSound } from '../../data/sound';
import {masterSocket} from "../../redux/middleware/wsMaster";
import {useSelector} from "react-redux";
import {selectLeader} from "../../data/squadSelector";
import QueueButton from "../../component/QueueButton/QueueButton";
import {sports} from "../../data/globals";
import {getNext} from "../../data/generic/generic";
import palette from "../../styled/Palette";
import SlimePan from "../../styled/SlimePan";
import ModalServerSelect from "../../component/Modal/Type/ModalServerSelect";
import {FooterDiv} from "../GameSelect/GameSelectStyle";
import {userServerSelection} from "../../system/queue";
import {PageTypes} from "../../system/types";


const PrivateMatch = ({dispatch}) => {
    const theme = useSelector(state => state.theme);
    const squad = useSelector(state => state.squad);
    const username = useSelector(state => state.self.username);
    const inQueue = useSelector(state => state.queue.active);
    const queue = useSelector(state => state.queue);
    const leader = selectLeader(squad.current, username);
    const acceptableServers = useSelector(state => state.socket.acceptableServers);
    const [abilityEnabled, setAbilityEnabled] = useState(true);
    const [gameMode, setGameMode] = useState('volley');
    const [lists,setLists] = useState({
        'LEFT TEAM': [],
        'OBSERVERS': [],
        'RIGHT TEAM': []
    });
    const [firstRun, setFirstRun] = useState(true);
    const modalIsOpen = useSelector(state => state.modal.content.body);
    const cooldown = useSelector(state => state.socket.cooldown);

    /**
     * Hack that hydrates the modal with the latest data
     */
    useEffect(() => {
        if (!firstRun && modalIsOpen) {
            ModalServerSelect(dispatch,acceptableServers,cooldown);
        } else {
            setFirstRun(false);
        }
    }, [acceptableServers, cooldown.servers]);

    const handleChangeGameMode = () => {
        setGameMode(getNext(gameMode,sports));
    };
    const handleChangeAbility = () => {
        setAbilityEnabled(!abilityEnabled);
    };

    const toggleQueue = () => {
        inQueue ? leaveQueue() : joinQueue();
    };
    const joinQueue = () => {
        if (acceptableServers.length > 0) {
            masterSocket.send( JSON.stringify({
                    'event': 'privateMatch',
                    'sport': gameMode.toLowerCase(),
                    'teams': {
                        'teamA': lists['LEFT TEAM'],
                        'teamB': lists['RIGHT TEAM'],
                    },
                    'spectators': lists['OBSERVERS'],
                    'ability': abilityEnabled,
                    acceptableServers: userServerSelection(acceptableServers),
                })
            );
        }
    };
    const leaveQueue = () => {
        playSound('leftQueue', 1);
        //send update to end-point
        masterSocket.send(JSON.stringify({
            "event": "leaveQueue",
        }));
    };
    const moveList = (player, listName) => {
        playSound('pressButton', 1);
        const currentList = lists[listName];
        const index = currentList.indexOf(player);
        currentList.splice(index, 1);
        const nextListName = getNext(listName,Object.keys(lists));
        const nextListUpdate = [...lists[nextListName], player];

        setLists({
            ...lists,
            [listName]: currentList,
            [nextListName]: nextListUpdate
        });
    };

    useEffect(() => {
        let teamA = [];
        let teamB = [];
        squad.current.forEach((player,index) => {
            if (index % 2 === 0) {
                teamA.push(player.name);
            } else {
                teamB.push(player.name);
            }
        });
        setLists({
            ...lists,
            'LEFT TEAM': teamA,
            'RIGHT TEAM': teamB
        });
    }, [squad]);

    const renderLists = () => {
        return Object.keys(lists).map(listName => (
            <div style={{ padding: '0', width: '100%'}}>
                <SubheaderDiv theme={theme}>{listName}</SubheaderDiv>
                <SectionDiv className="styleDarkScrollSquare">
                    {
                        lists[listName].map((player, index) => {
                            return (
                                <div
                                    key={player}
                                    tabIndex={0}
                                    onClick={() => moveList(player,listName)}
                                >
                                    <PlayerDiv
                                        style={{
                                            backgroundColor: (index % 2 === 1 ? palette.base3 : palette.base1)
                                        }}
                                    >
                                        {player}
                                    </PlayerDiv>
                                </div>
                            )
                        })
                    }
                </SectionDiv>
            </div>
        ))
    };

    return (
        <div
            style={{display: 'flex', flexDirection: 'column'}}
            className="windowed animatedFast slideInRight"
        >
            <Header closeRoute={PageTypes.MAIN} title="PRIVATE MATCH"/>
            <PrivateMatchBodyDiv>
                    <SlimePan>
                        <div style={{flexGrow: '1', overflowY: 'auto'}}>
                            <div style={{display: 'flex', minHeight: '200px', alignItems: 'stretch'}}>
                                {renderLists()}
                            </div>
                        </div>
                    </SlimePan>
                    <div style={{display: 'flex', padding: '.5em', borderTop: '1px solid black'}}>
                        <div style={{display: 'flex', flexGrow: 1}}>
                            <ButtonMode
                                click={() => handleChangeGameMode()}
                                mode={gameMode}
                            />
                            <ButtonMode
                                click={() => handleChangeAbility()}
                                mode={abilityEnabled ? 'ability on' : 'ability off'}
                            />
                            <ButtonMode
                                click={() => ModalServerSelect(dispatch,acceptableServers,cooldown)}
                                mode={'set region'}
                            />
                        </div>
                        <QueueButton
                            queueFunc={toggleQueue}
                            queue={queue}
                            inQueue={inQueue}
                            leader={leader}
                            modeIsSelected={lists['LEFT TEAM'].length !== 0 && lists['RIGHT TEAM'].length !== 0}
                        />
                    </div>
                </PrivateMatchBodyDiv>
        </div>
    )
};
export default PrivateMatch;
