import React, {useEffect, useRef, useState} from 'react';
import {RadioContainer, RevealHoverDiv, RevealMountDiv, RadioClose} from "./Radio/RadioStyle";
import RadioOff from "./Radio/RadioOff";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {
    getNextSong,
    changeVolume,
    togglePlaying,
    loadRadio,
    radioMounted,
    getPreviousSong
} from "../redux/modules/radio/radioActions";
import radioImage from "../images/other/radio.png";
import Button from "../styled/Button";
import palette from "../styled/Palette";
import {FONT_SMALLER} from "../data/font";

// Radio is a singleton so this interface exists to serve and interact with that process
const RadioInterface = ({theme, setViewingRadio, source}) => {
    const dispatch = useDispatch();
    const { paused, song, title, image, volume, next, analyser, index } = useSelector(state => state.radio);
    const refSoundSlider = useRef();

    useEffect(() => {
        if (!image) {
            const radioElement = document.createElement('img');
            radioElement.src = radioImage;
            dispatch(loadRadio({image: radioElement}));
        }
        // Load the volume cookie
        refSoundSlider.current.value = volume;
        // Load the first song
        if (!song) {
            // console.log('there was no song playing, getting next song');
            dispatch(getNextSong());
        }

        dispatch(radioMounted(true));
    }, []);

    useEffect(() => {
        if (song) {
            // Pauses the player when the user drags the volume to zero.
            if (volume === '0' && !paused) {
                dispatch(togglePlaying());
            }
        }
    }, [volume]);

    const RadioButton = ({icon, disabled, click}) => (
        <Button click={click}
                isDisabled={disabled}
                icon={<FontAwesomeIcon icon={['fas', icon]} />}
        />
    );

    const RadioDetails = () => {
        if (title === null) {
            return null;
        }

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div>
                    <div>Now listening to</div>
                    <div style={{color: theme.interfaceColorBright}} className="fontLarge">{title}</div>
                    <div>only on Slime Radio</div>
                </div>
            </div>
        )
    };

    return (
        <RadioContainer source={source}>
           <RevealMountDiv>
               <div
                   style={{color: 'white', fontSize: FONT_SMALLER, flexShrink: 0, textAlign: 'right', opacity: song ? 1 : 0}}
               >
                   {paused ?
                       <RadioOff theme={theme} />
                       :
                       <div style={{display: 'flex'}}>
                           <RadioDetails />
                           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 8px 0 16px'}}>
                               <div style={{width: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: 'all .5s all',
                                   backgroundColor: 'rgba(86,86,86,.5)', borderRadius: '50%'}}
                               >
                                   <div style={{marginLeft: '1px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                       <canvas
                                           width="70"
                                           height="70"
                                           id="radioButtonCanvas"
                                           style={{position: 'absolute', width:'70px', height:'70px'}}
                                       >
                                       </canvas>
                                   </div>
                               </div>
                           </div>
                       </div>
                   }
               </div>
               <RevealHoverDiv interfaceColor={theme.interfaceColor}>
                   <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                       <div style={{display: 'flex'}}>
                           <div className={`${!song || paused || index === 0 ? 'disabled':''}`}
                                onClick={() => dispatch(getPreviousSong())}
                           >
                               <RadioButton title={'BACK'} icon={'backward'}/>
                           </div>
                           <div
                               style={{margin: '0 .25em'}}
                               onClick={() => dispatch(togglePlaying())}>
                               {paused ?
                                   <RadioButton title={'PLAY'} icon={'play'}/>
                                   :
                                   <RadioButton title={'PAUSE'} icon={'pause'}/>
                               }
                           </div>
                           <div className={`${!song || paused ? 'disabled':''}`}
                                onClick={() => {
                                    dispatch(getNextSong())
                                }}
                           >
                               <RadioButton title={'NEXT'} icon={'forward'}/>
                           </div>
                       </div>
                       <div style={{margin: '4px'}}>
                           <input
                               id="radioSlider"
                               autoComplete="off"
                               ref={refSoundSlider}
                               onChange={(e) => dispatch(changeVolume(e.target.value))}
                               type="range"
                               step=".01"
                               min="0"
                               max="1"
                               style={{width: '100%'}}
                           />
                       </div>
                   </div>
                   {setViewingRadio &&
                   <RadioClose
                       tabIndex={0}
                       onClick={() => setViewingRadio(false)}>
                       <FontAwesomeIcon icon={['fas', 'times']} />
                   </RadioClose>
                   }
               </RevealHoverDiv>
           </RevealMountDiv>
        </RadioContainer>
    )
};

export default RadioInterface;