import * as Comlink from 'comlink';
/* eslint-disable import/no-webpack-loader-syntax */
import Worker from 'worker-loader!./portraitWorker';
import { bitmapAssets } from '../data/images';
import { capitalize } from '../data/generic/generic';

const maxWorkers = 3;
let workerActiveDiscardArray = [];
let workToBeDone = [];
let imagesToProcessAtATime = 3;

for (let i = 0; i < maxWorkers; i++) {
    let worker = new Worker();
    let obj = Comlink.wrap(worker);
    workerActiveDiscardArray.push({
        worker: obj,
        discard: false,
        active: false,
    });
}

let checkWorkToBeDoneInterval = null;

/**
 * Requests and retrieves the loaded portrait from the web worker.
 * @param outfit - If the requested image is an avatar, a whole outfit is included.
 * @param item - If the requested image is a single item, the second parameter is instead included.
 * @returns {Promise<string>} - The dataURL format which can be loaded by an image element.
 */
async function workerGetPortrait(outfit, item) {
    return new Promise((resolve, reject) => {
        workToBeDone.push({
            outfit,
            item,
            resolve,
            reject,
        });
        if (checkWorkToBeDoneInterval === null) {
            checkWorkToBeDoneInterval = setInterval(checkWorkToBeDone, 1);
        }
    });
}

function checkWorkToBeDone() {
    if (workToBeDone.length > 0) {
        let availableWorkerActive;
        for (let i = 0; i < maxWorkers; i++) {
            let worker = workerActiveDiscardArray[i];
            if (!worker.active) {
                availableWorkerActive = worker;
                break;
            }
        }
        if (availableWorkerActive) {
            let portraitPromises = [];
            if (workToBeDone.length > 0) {
                availableWorkerActive.active = true;
                availableWorkerActive.discard = false;
            }
            for (let i = 0; i < imagesToProcessAtATime; i++) {
                let workToBeDoneNow = workToBeDone.shift();
                if (workToBeDoneNow) {
                    portraitPromises.push(
                        workerDigestWorkToBeDone(
                            availableWorkerActive,
                            workToBeDoneNow.outfit,
                            workToBeDoneNow.item,
                            workToBeDoneNow.resolve,
                            workToBeDoneNow.reject
                        )
                    );
                }
            }
            Promise.all(portraitPromises).then(() => {
                availableWorkerActive.active = false;
            });
        }
    } else {
        clearInterval(checkWorkToBeDoneInterval);
        checkWorkToBeDoneInterval = null;
    }
}

async function workerDigestWorkToBeDone(workerActive, initialOutfit, initialItem, resolve, reject) {
    let outfit = null;
    let item = null;
    if (initialOutfit) {
        outfit = {
            face: null,
            skin: null,
            cap: null,
            paint: {
                colorHex: initialOutfit.paint.colorHex,
            },
        };
        if (initialOutfit.face) {
            outfit.face = {
                name: initialOutfit.face.name,
            };
        }
        if (initialOutfit.skin) {
            outfit.skin = {
                name: initialOutfit.skin.name,
                colorHex: initialOutfit.skin.colorHex,
            };
        }
        if (initialOutfit.cap) {
            outfit.cap = {
                name: initialOutfit.cap.name,
                colorHex: initialOutfit.cap.colorHex,
            };
        }
    }
    if (initialItem) {
        item = {
            name: initialItem.name,
            colorHex: initialItem.colorHex,
            category: initialItem.category,
        };
    }
    // Worker doesn't like images in the payload
    const sendableOutfit = () => {
        if (!outfit) return null;
        if (!outfit.face) {
            outfit.face = {
                name: 'Default',
                category: 'Face',
            };
        }
        return outfit;
    };
    // Retrieve a bitmap because idk what is best for the image data payload
    let bitmap = await workerActive.worker.portrait(
        sendableOutfit(),
        item,
        getNeededAssets(outfit, item)
    );
    //The worker is done generating, now return the rendered image.
    let canvas = document.createElement('canvas');
    canvas.width = bitmap.width;
    canvas.height = bitmap.height;
    let context = canvas.getContext('2d');
    context.drawImage(bitmap, 0, 0);
    // Now that we have the bitmap, generate a canvas on the browser and return the URL form of it.
    if (!workerActive.discard) {
        resolve(canvas.toDataURL());
    } else {
        reject('work discarded');
    }
    return;
}

/**
 * Populated the items the worker will need
 */
const getNeededAssets = (outfit, item) => {
    let minimalAssets = {
        Face: {},
        Cap: {},
        Skin: {},
        Other: {
            Slime: {
                base: bitmapAssets.Other.Slime.base,
                outline: bitmapAssets.Other.Slime.outline,
                texture: bitmapAssets.Other.Slime.texture,
            },
        },
    };
    if (outfit) {
        ['Cap', 'Skin', 'Face'].forEach((category) => {
            if (outfit[category.toLowerCase()]) {
                minimalAssets[category][outfit[category.toLowerCase()].name] =
                    bitmapAssets[category][outfit[category.toLowerCase()].name];
            }
        });
    } else {
        minimalAssets.Face = {
            Default: bitmapAssets.Face.Default,
        };
        minimalAssets[capitalize(item.category)][item.name] =
            bitmapAssets[capitalize(item.category)][item.name];
    }
    return minimalAssets;
};

export const reset = () => {
    return new Promise((res, rej) => {
        workToBeDone = [];
        for (let i = 0; i < maxWorkers; i++) {
            workerActiveDiscardArray[i].discard = true;
        }
        res();
    });
};

export default workerGetPortrait;
