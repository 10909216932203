import Button from "../../../styled/Button";
import {socketSignInPhase} from "../../../redux/modules/socket/socketActions";
import React, {useEffect, useRef, useState} from "react";
import InputStandard from "../../../styled/Input";
import palette from "../../../styled/Palette";
import {isValidEmail} from "../../../system/validation";

const FormReset = ({dispatch,hideAlert,setValidatedEmail}) => {
    const refEmail = useRef(null);
    const [formValid,setFormValid] = useState(false);

    const isValid = () => {
       return isValidEmail(refEmail.current.value);
    };
    const submitForm = () => {
        setValidatedEmail(refEmail.current.value);
        dispatch(socketSignInPhase('recover'));
    };
    const goBack = () => {
        hideAlert();
        dispatch(socketSignInPhase('login'));
    };

    const handleKeyPress = (code) => {
        const pressEnter = code === 'Enter';
        if (pressEnter) {
            submitForm();
        }
    };

    // Generic configuration for handling a listener
    useEffect(() => {
        const keystrokeInstance = (e) => handleKeyPress(e.code);
        window.addEventListener('keydown', keystrokeInstance);
        return () => {
            window.removeEventListener('keydown', keystrokeInstance);
        };
    }, []);

    return (
        <>
            <>
                <div style={{maxWidth: '20em', fontSize: '1.25em', marginBottom: '2em', color: palette.base4}}>
                    Provide the email associated with the account. If you do not have access to the email you will not be able to recover.
                </div>
                <InputStandard
                    tabIndex={0}
                    autoComplete="off"
                    ref={refEmail}
                    placeholder="Recovery Email"
                    onKeyUp={() => setFormValid(isValid())}
                />
            </>
            <div style={{display: 'flex',marginTop: '2em'}}>
                <Button
                    isDisabled={!formValid}
                    click={() => submitForm()}
                    title={"Submit"}
                    type="button"
                    className="buttonNeutral"> </Button>
                <Button
                    title={"Back"}
                    type="button"
                    className="buttonNeutral"
                    click={() => goBack()}
                > </Button>
            </div>
        </>
    )
};

export default FormReset;