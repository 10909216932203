import React, { useState, useEffect } from 'react';

import Header from '../component/Header/Header.js';
import ButtonStandard from '../component/ButtonStandard/ButtonStandard.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { playSound } from '../data/sound';
import {setCookie, toggleFullScreen} from "../data/generic/generic";
import {useSelector} from "react-redux";
import isElectron from "is-electron";
import styled from 'styled-components';
import palette from "../styled/Palette";
import {settingChatBubble} from "../redux/modules/setting/settingActions";
import {drawerToggle} from "../redux/modules/socket/socketActions";
import {requestToggleChatBubble} from "../system/endpoints/master";
import CensorToggle from "./Options/CensorToggle";
import {PageTypes} from "../system/types";

const FooterDiv = styled.div`
        font-weight: bold;
        padding: 10px;
        background-color: ${palette.base1};
        color: #a1a1a1;
        min-height: 20px;
    `;
const Options = ({history,dispatch}) => {
    const username = useSelector(state => state.self.username);
    const chatBubble = useSelector(state => state.setting.chatBubble);

    const [mouseLogOut,setMouseLogOut] = useState(false);

    const handleExpireToken = () => {
        setCookie('username','',365);
        setCookie('accessToken','',365);
        window.location.reload()
    };

    const handleToggleChatBubble = () => {
        playSound('enable', 1);
        // Inform client
        dispatch(settingChatBubble({isEnabled: !chatBubble.isEnabled}));
        // Inform server
        requestToggleChatBubble(!chatBubble.isEnabled);
    };

    // Determine if the user has a chatCensorLevel stored in local storage, and use that as the default
    useEffect(() => {
        dispatch(drawerToggle({open: true}));
    }, []);

    return (
        <div className="windowed animatedFast slideInRight">
            <Header closeRoute={PageTypes.MAIN} title="OPTIONS"/>
            <div className="styleDarkScrollSquare"
                 style={{backgroundColor: palette.base5, fontFamily: 'Montserrat', display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%', color: 'white', borderTop: '0', overflowY: 'auto'}} >
                <div className="styleDarkScrollSquare" style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                   {chatBubble.has &&
                       <div onClick={() => handleToggleChatBubble()}>
                           <ButtonStandard
                               title={`Overhead Chat set to  ${chatBubble.isEnabled ? 'ENABLED' : 'DISABLED'}`}
                               icon={
                                   <FontAwesomeIcon
                                       style={{fontSize: '1.2em'}}
                                       icon={['fas', chatBubble.isEnabled ? 'comment-alt' : 'comment-alt-slash']}
                                   />
                               }
                           />
                       </div>
                   }
                    <CensorToggle />
                    <div onClick={() => {toggleFullScreen()}}>
                        <ButtonStandard title='Full Screen (F11)' icon={<i className="fa-fw far fa-desktop-alt"> </i>} />
                    </div>
                    <div onClick={() => {history.push(PageTypes.AFFILIATES);}}>
                        <ButtonStandard title='About' icon={<i className="fas fa-hands-helping"> </i>} />
                    </div>
                    {!isElectron() &&
                        <div onClick={() => handleExpireToken()}
                             onMouseEnter={() => setMouseLogOut(true)}
                             onMouseLeave={() => setMouseLogOut(false)}>
                            {mouseLogOut && <ButtonStandard title={`Sign Out: ${username}`} icon={<i className="fa-fw far fa-door-open"> </i>} />}
                            {!mouseLogOut && <ButtonStandard title={`Sign Out: ${username}`} icon={<i className="fa-fw far fa-door-closed"> </i>} />}
                        </div>
                    }
                </div>
                <FooterDiv>
                    <div> </div>
                </FooterDiv>
            </div>
        </div>
    )
};
export default Options;

/*
 handleDiscord() {
 window.open('https://discord.me/slimelol','_blank');
 <div onClick={() => {handleDiscord()}}>
 <ButtonStandard title='Discord Community' icon={<FontAwesomeIcon style={{marginRight: '5px'}} icon={['fab', 'discord']} />} />
 </div>
 }
 */