import React from 'react';
import Header from '../component/Header/Header';
import {useSelector} from "react-redux";
import SlimePan from "../styled/SlimePan";
import {WatchBodyDiv} from "./Watch/WatchStyle";
import styled from 'styled-components';
import palette from "../styled/Palette";
import TextUnderlineHover from "../styled/TextUnderlineHover";
import LinkURL from "../component/LinkURL/LinkURL";
import {PageTypes} from "../system/types";

export const AffiliatesBodyDiv = styled.div`
    width: 60em;
    padding: 4em;
    display: flex;
    flex-direction: column;
    color: ${palette.concrete};
    text-align: left;
    background-color: ${palette.base1};
    font-family: Montserrat;
    
    h2 {
        font-size: 1em;
        color: ${palette.base4};
    }
`;

const Affiliates = () => {
    return (
        <div className="fullScreen animatedFast slideInLeft">
            <Header closeRoute={PageTypes.OPTIONS} title="ABOUT"/>
            <SlimePan style={{padding: '5em 0 0 0', justifyContent: 'center', display: 'flex'}}>
                <AffiliatesBodyDiv
                    className={`styleDarkScrollSquare`}
                >
                    <div style={{height: '2em'}}>
                        <h2>THE DEVELOPERS</h2>
                        We are a small but passionate team dedicated to delivering slime excellence.<br/>
                        <br/><h2>INQUIRIES</h2>
                        <LinkURL title={'info@slime.lol'} url={'info@slime.lol'} /><br/>
                        <LinkURL title={'Discord'} url={'https://discord.me/slimelol'} />
                        <br/><br/><h2>THE SLIME LEGACY</h2>
                        <div>
                            <b>1974</b> Table top game Dungeons and Dragons features several Slime characters.<br/><br/>
                            <b>1980</b> Game developer Yuri Horii spots slime-looking characters in the game Wizardy.<br/><br/>
                            <b>1981</b> Yuri Horii is inspired to add the "slime-looking characters" to his influential RPG, Dragon Quest. Due to it's enormous success, slime are forever sensationalized in fantasy games.<br/><br/>
                            <b>1995</b> Java applets are introduced to the web. Sometime soon an unknown creator will produce the first slime volleyball game.<br/><br/>
                            <b>1999</b> Australian computer science student Clive Gout somehow acquires the game and sends it to his peers over the university intranet.<br/><br/>
                            <b>1999</b> Quin Pendragon, one of Gout's peers, produces a variety of game modes. Daniel Wedge also contributes significantly to new releases and web exposure through his website.<br/><br/>
                            <b>2001</b> Quin Pendragon deems slime open-source. Programmers from different walks of life begin forking the project and releasing spin-off versions.<br/><br/>
                            <b>2006</b> The developers behind Slime.lol experience some of these games for the first time at 12 years old.<br/><br/>
                            <b>2015</b> The technology powering flash player, which the historical games run on, is deemed a security risk and nearly all modern browsers drop support.<br/><br/>
                            <b>2018</b> The Slime.lol team begins building a modern and formidable title to become a champion product in the realm of slime gaming.<br/><br/>
                            <br/><br/>

                            <br/><br/><h2>REFERENCE</h2>
                            <LinkURL title={'Brief Known History'} url={'https://oneslime.net/kb/A_Brief_History_Of_Slime.html'} /><br/>
                            <LinkURL title={'Mirror Site with some historical notes'} url={'http://slimegames.eu/index.html'} /><br/>
                            <LinkURL title={'Slime Article circa 2002'} url={'https://web.archive.org/web/20061230164250/http://volleyball.about.com/od/slimevolleyball/a/slime.htm'} />
                            <br/><br/><h2>MORE SLIME GAMES ( No affiliation )</h2>
                            <LinkURL title={'Slime Sports'} url={'https://store.steampowered.com/app/763350/Slime_Sports/'} /><br/>
                            <LinkURL title={'oneslime.net'} url={'https://oneslime.net'} /><br/>
                            <LinkURL title={'slime.cc'} url={'http://www.slime.cc/'} /><br/>
                        </div>
                        <br/><h2>SPECIAL THANKS</h2>
                        <LinkURL title={'Slime Radio - Mikeobie'} url={''} /><br/>
                        <LinkURL title={'MatterJS - Liabru'} url={'http://brm.io/matter-js/'} /><br/>
                        <LinkURL title={'Reactiflux Community'} url={'https://www.reactiflux.com/'} /><br/>
                        <LinkURL title={'Redux Maintainer - Acemarke'} url={'https://github.com/markerikson'} /><br/>
                        <br/><h2>PUBLISHERS</h2>
                        <LinkURL title={'Steam (Coming Soon)'} url={'http://steampowered.com'} /><br/>
                        <LinkURL title={'Crazy Games'} url={'http://crazygames.com'} /><br/>
                        <LinkURL title={'IO Games Space'} url={'http://iogames.space'} /><br/>
                        <LinkURL title={'Silver Games'} url={'http://silvergames.com'} /><br/>
                        <LinkURL title={'Titotu'} url={'http://titotu.io'} /><br/>
                        <LinkURL title={'IO Games Live'} url={'https://iogames.live'} /><br/>
                        <LinkURL title={'Deemo'} url={'http://deemo.io'} />
                        <br/><br/><br/><br/>
                    </div>
                </AffiliatesBodyDiv>
            </SlimePan>
        </div>
    )
};
export default Affiliates;