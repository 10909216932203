import React, {useEffect, useState} from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import styled from 'styled-components';
import {requestBanUser} from "../../../system/endpoints/master";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import palette from "../../../styled/Palette";

export let BanUserData;

const BanUserTitleDiv = styled.div`
    font-weight: bold;
    margin-bottom: .25em;
`;
const BanUserOptionDiv = styled.div`
    display: flex;
    flex-basis: 0;
`;

export const ModalBanUser = (dispatch,username) => {
    BanUserData = (<BanUserForm username={username} />);
    dispatch(modalEnable({
        body: 'BanUser',
        title: `Banning user ${username}`,
        escapable: true,
        noOptions: true
    }));
};

const FormSectionDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
`;
const BanUserForm = ({username}) => {
    const [banForm,setBanForm] = useState({
        banType: 'chat',
        duration: 2,
        reason: 'Harassment'
    });

    const handleFormUpdate = (value,attribute) => {
        setBanForm(cb => ({
            ...cb,
            [attribute]: value
        }));
    };

    return (
        <div style={{display: 'flex', width: '100%'}}>
            <FormSectionDiv style={{backgroundColor: palette.midnightBlue}}>
                <BanUserTitleDiv>Type</BanUserTitleDiv>
                <div onChange={(e) => handleFormUpdate(e.target.value,'banType')}>
                    <BanUserOptionDiv><input
                        type='radio'
                        value='global'
                        checked={banForm.banType === 'global'}
                    />Game</BanUserOptionDiv>
                    <BanUserOptionDiv><input
                        type='radio'
                        value='chat'
                        checked={banForm.banType === 'chat'}
                    />Chat</BanUserOptionDiv>
                </div>
            </FormSectionDiv>
           <div style={{display: 'flex',flexDirection:'column',backgroundColor: palette.midnightBlue}}>
               <FormSectionDiv>
                   <BanUserTitleDiv>Duration (hrs)</BanUserTitleDiv>
                   <div>
                       <input
                           onChange={e => handleFormUpdate(e.target.value,'duration')}
                           type='number'
                           value={banForm.duration}
                       />
                   </div>
               </FormSectionDiv>
               <FormSectionDiv>
                   <BanUserTitleDiv>Detailed Reason</BanUserTitleDiv>
                   <div>
                       <input
                           onChange={e => handleFormUpdate(e.target.value,'reason')}
                           value={banForm.reason}
                       />
                   </div>
               </FormSectionDiv>
           </div>
            <FormSectionDiv style={{flexGrow: 1}}>
                <BanUserTitleDiv>Recommended</BanUserTitleDiv>
                <div>Flooding 8760 (1yr) </div>
                <div>Advertising 168 (1wk)</div>
                <div>Harassment 48</div>
            </FormSectionDiv>
            <Button
                styles={{width: STANDARD_BUTTON_WIDTH}}
                title={'Punish Now'}
                icon={<FontAwesomeIcon style={{marginRight: '.5em', width: '25px'}} icon={['fas','fire-extinguisher']}/>}
                click={() => requestBanUser(username,banForm.banType,banForm.duration,banForm.reason)}
            />
        </div>
    )
};

export default ModalBanUser;

