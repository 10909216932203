import {useSelector} from "react-redux";
import React from "react";

const PingWarning = () => {
    const ping = useSelector(state => state.socket.ping);

    if (ping > 100) {
        return (
            <div style={{color: 'white',marginTop: '1em'}}>
                Poor Network Connection Detected ({ ping }ms)
            </div>
        )
    }
    return null;
};

export default PingWarning;