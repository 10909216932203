import React, {useEffect, useRef, useState} from 'react';
import GlobalChat from "./GlobalChat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Friends from "./Friends/Friends";
import Page from "../../styled/Page";
import PublicChatUsers from "../../page/PublicChat/PublicChatUsers";
import {PublicChatBodyDiv, PublicChatContainerDiv, PublicChatDiv} from './PublicChatStyle';
import SlimePan from "../../styled/SlimePan";
import { sendMessage } from '../../redux/modules/chat/chatThunks';
import { gameSocket } from '../../redux/middleware/wsMatch';
import { masterSocket } from "../../redux/middleware/wsMaster";
import { updateChatPublicReads } from "../../redux/modules/chat/chatActions";
import {useDispatch, useSelector} from "react-redux";
import palette from '../../styled/Palette';
import Squad from "../../component/Squad/Squad";
import {drawerToggle} from "../../redux/modules/socket/socketActions";
import {renderChatPlaceholder} from "../../system/chat";
import {
    PublicChatInput
} from './PublicChatStyle';


const PublicChat = ({checkMeSquadLead,history}) => {
    const dispatch = useDispatch();
    const { roomId } = useSelector(state => state.match);
    const self = useSelector(state => state.self);
    const { team, playerId, sessionId } = self;
    // We detected the user was sending too many messages
    const clientAutomatedChatBan = useSelector(state => state.chat.banned);
    // A moderator manually issued a ban
    const serverManualChatBan = self.chatBanned > Date.now();
    const isBanned = clientAutomatedChatBan || serverManualChatBan;
    const theme = useSelector(state => state.theme);
    const refChatInput = useRef(null);
    const [placeholder,setPlaceholder] = useState(renderChatPlaceholder(clientAutomatedChatBan,serverManualChatBan,self.chatBanned));

    const setWhisper = (username) => {
        let inputField = refChatInput.current;
        inputField.value = '@' + username + ' ';
        inputField.focus();
    };

    useEffect(() => {
        setPlaceholder(renderChatPlaceholder(clientAutomatedChatBan,serverManualChatBan,self.chatBanned));
    }, [self.chatBanned,clientAutomatedChatBan]);

    useEffect(() => {
        dispatch(drawerToggle({open: true}));
        // Clear any unread notification with respect to the public chat.
        dispatch(updateChatPublicReads(0));
    }, []);


    return (
        <PublicChatDiv style={{display: 'flex', height: '100%'}}>
            <Friends
                dispatch={dispatch}
                checkMeSquadLead={checkMeSquadLead.bind(this)}
                history={history}
                setWhisper={setWhisper.bind(this)}
            />
            <Page className="animatedFast slideInRight" style={{display: 'flex', flexGrow: 1}}>
                <SlimePan style={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', justifyContent: 'center', alignItems: 'center'}}>
                    <PublicChatContainerDiv>
                        <PublicChatBodyDiv color={theme.interfaceColorBright}>
                           <div style={{display: 'flex', flexGrow: 1, height: 0}}>
                               <GlobalChat
                                   chatInputElement={refChatInput.current}
                               />
                               <PublicChatUsers
                                   history={history}
                                   setWhisper={setWhisper.bind(this)}
                               />
                           </div>
                            <div className={isBanned ? 'disabled':''}>
                                <div style={{
                                    backgroundColor: palette.base3,
                                    borderColor: 'white',
                                    borderTop: `1px solid black`,
                                    cursor: isBanned ? 'wait' : null, display: 'flex', justifyContent: 'flex-start', height: '60px', fontSize: '20px'
                                }}>
                                    <FontAwesomeIcon style={{margin: '20px', color: 'white', transform: 'scaleX(-1)'}} className="iconChat" icon={['fas', 'comment-alt']} />
                                    <PublicChatInput
                                        tabIndex={0}
                                        ref={refChatInput}
                                        banned={isBanned}
                                        autoComplete="off"
                                        maxLength="128"
                                        style={{paddingLeft: 0, width: '100%'}}
                                        placeholder={placeholder}
                                        onKeyUp={e => dispatch(sendMessage(e.target.value,e.keyCode, history.location.pathname, roomId, gameSocket, masterSocket, team, playerId, sessionId, refChatInput))}
                                    />
                                </div>
                            </div>
                        </PublicChatBodyDiv>
                        <Squad fullWidth={true} history={history} />
                    </PublicChatContainerDiv>
                </SlimePan>
            </Page>
        </PublicChatDiv>
    )
}

export default PublicChat;