import slimeBase from '../images/skins/base.png';
import slimeOutline from '../images/skins/outline.png';
import slimeTexture from '../images/skins/texture.png';
import slimeWood from '../images/skins/wood.png';
import slimeGlow from '../images/skins/glow.png';
import hoopBase from '../images/other/hoop.png';
import ballBase from '../images/other/ball.png';
import giftBox from '../images/other/gift/box.png';
import giftRibbon from '../images/other/gift/ribbon.png';
import giftGoo from '../images/other/gift/goo.png';
import giftOutline from '../images/other/gift/outline.png';
import radioBase from '../images/other/radio.png';
import capSombreroBase from '../images/caps/sombrero/sombrero.png';
import capSombreroOutline from '../images/caps/sombrero/sombrerooutline.png';
import capHaloBase from '../images/caps/halo/halo.png';
import capHaloOutline from '../images/caps/halo/halooutline.png';
import capWizardBase from '../images/caps/wizard/wizard.png';
import capWizardOutline from '../images/caps/wizard/wizardoutline.png';
import capBridalVeilBase from '../images/caps/bridalveil/bridalveil.png';
import capBridalVeilOutline from '../images/caps/bridalveil/bridalveiloutline.png';
import capStrawHatBase from '../images/caps/straw/straw.png';
import capStrawHatOutline from '../images/caps/straw/strawoutline.png';
import capNinjaBase from  '../images/caps/ninja/ninja.png';
import capNinjaOutline from  '../images/caps/ninja/ninjaoutline.png';
import capRamHornsBase from '../images/caps/ram/ram.png';
import capRamHornsOutline from '../images/caps/ram/ramoutline.png';
import capBeanieBase from '../images/caps/beanie/beanie.png';
import capBeanieOutline from '../images/caps/beanie/beanieoutline.png';
import capMushroomBase from '../images/caps/mushroom/mushroom.png';
import capMushroomOutline from '../images/caps/mushroom/mushroomoutline.png';
import capPartyHatBase from '../images/caps/partyhat/partyhat.png';
import capPartyHatOutline from '../images/caps/partyhat/partyhatoutline.png';
import capBunnyEarsBase from '../images/caps/bunny/bunny.png';
import capBunnyEarsOutline from '../images/caps/bunny/bunnyoutline.png';
import capCatEarsBase from '../images/caps/cat/cat.png';
import capCatEarsOutline from '../images/caps/cat/catoutline.png';
import capBeretBase from '../images/caps/beret/beret.png';
import capBeretOutline from '../images/caps/beret/beretoutline.png';
import capJesterBase from '../images/caps/jester/jester.png';
import capJesterOutline from '../images/caps/jester/jesteroutline.png';
import capAfroBase from '../images/caps/afro/afro.png';
import capAfroOutline from '../images/caps/afro/afrooutline.png';
import capCowboyBase from '../images/caps/cowboy/cowboy.png';
import capCowboyOutline from '../images/caps/cowboy/cowboyoutline.png';
import skinCowBase from '../images/skins/cow/cow.png';
import skinCowOutline from '../images/skins/cow/cowoutline.png';
import skinTigerBase from '../images/skins/tiger/tiger.png';
import skinTigerOutline from '../images/skins/tiger/tigeroutline.png';
import skinCheetahBase from '../images/skins/cheetah/cheetah.png';
import skinCheetahOutline from '../images/skins/cheetah/cheetahoutline.png';
import skinRoyalBase from '../images/skins/king/king.png';
import skinRoyalOutline from '../images/skins/king/kingoutline.png';
import skinPrisonBase from '../images/skins/tattoo/tattoo.png';
import skinPrisonOutline from '../images/skins/tattoo/tattoooutline.png';
import skinSuitBase from '../images/skins/suit/suit.png';
import skinSuitOutline from '../images/skins/suit/suitoutline.png';
import skinKimonoBase from '../images/skins/kimono/kimono.png';
import skinKimonoOutline from '../images/skins/kimono/kimonooutline.png';
import skinPunkBase from '../images/skins/punk/punk.png';
import skinPunkOutline from '../images/skins/punk/punkoutline.png';
import skinBridalBase from '../images/skins/bridalgown/bridalgown.png';
import skinBridalOutline from '../images/skins/bridalgown/bridalgownoutline.png';
import skinGlitterBase from '../images/skins/glitter/glitter.png';
import skinGlitterOutline from '../images/skins/glitter/glitteroutline.png';
import skinStripeBase from '../images/skins/stripe/stripe.png';
import skinStripeOutline from '../images/skins/stripe/stripeoutline.png';
import powershotFrame1 from '../images/other/powershot/1.png';
import powershotFrame1b from '../images/other/powershot/1b.png';
import powershotFrame2 from '../images/other/powershot/2.png';
import powershotFrame2b from '../images/other/powershot/2b.png';
import powershotFrame3 from '../images/other/powershot/3.png';
import powershotFrame3b from '../images/other/powershot/3b.png';
import powershotFrame4 from '../images/other/powershot/4.png';
import powershotFrame4b from '../images/other/powershot/4b.png';
import powershotFrame5 from '../images/other/powershot/5.png';
import powershotFrame5b from '../images/other/powershot/5b.png';
import ballcritFrame1 from '../images/other/ballcrit/1.png';
import ballcritFrame2 from '../images/other/ballcrit/2.png';
import ballcritFrame3 from '../images/other/ballcrit/3.png';
import ballcritFrame4 from '../images/other/ballcrit/4.png';
import ballcritFrame5 from '../images/other/ballcrit/5.png';
import ballcritFrame6 from '../images/other/ballcrit/6.png';
import ballcritFrame7 from '../images/other/ballcrit/7.png';
import ghostAura from '../images/other/ghost.png';
import bubble from '../images/other/bubble.png';
import rock from '../images/other/rock.png';
import bassGuitar from '../images/other/bassGuitar.png';

const initFaces = () => {
    let faces = {};
    // Add facial expression images to assets.
    let expressions = ['idle','blink','angry','smug','struck','sad','shocked'];
    const FaceTypes = {
        'Standard': 'standard',
        'Serpent': 'snake',
        'Chibi': 'animegirl',
        'Kawaii': 'kawaii',
        'Cartoon': 'alpha',
        'Mustachio': 'elderly',
        'Cat': 'cat',
        'Derp': 'derp',
        'Doggo': 'doggo',
        'Good Boy': 'goodboy',
        'Seduction': 'animegirl2',
        'Baby': 'baby',
        'Bird': 'bird',
        'Cyclops': 'cyclops',
        'Generic': 'generic',
        'Limesqueeze': 'lemon',
        'Skeletal': 'skull',
        'Vintage': 'vintage',
        'Eastern': 'eastern',
        'Goth': 'goth',
        'Stitch': 'stitch',
        'Default': 'default'
    };
    Object.keys(FaceTypes).forEach(key => {
        expressions.forEach(expression => {
            if (!faces[key]) faces[key] = {};
            faces[key][expression] = require(`../images/faces/${FaceTypes[key]}/${expression}.png`).default;
        });
    });
    return faces;
};
const FacePaths = initFaces();
const SlimePaths = {
    'base': slimeBase,
    'outline': slimeOutline,
    'texture': slimeTexture,
    'wood': slimeWood,
    'glow': slimeGlow
};
const HoopPaths = {
    'base': hoopBase
};
const BallPaths = {
    'base': ballBase,
};
const GhostAuraPaths = {
    'base': ghostAura,
}
const BubblePaths = {
    'base': bubble,
}
const RockPaths = {
    'base': rock,
}
const BassGuitarPaths = {
    'base': bassGuitar,
}
const GiftPaths = {
    'box': giftBox,
    'ribbon': giftRibbon,
    'goo': giftGoo,
    'outline': giftOutline
};
const RadioPaths = {
    'base': radioBase
};
const CapPaths = {
    'Afro': {
        'base': capAfroBase,
        'outline': capAfroOutline,
        'width': 309 / 2,
        'height': 223 / 2,
        'yMargin': .56,
        'xMargin': -.08,
    },
    'Beanie': {
        'base': capBeanieBase,
        'outline': capBeanieOutline,
        'width': 293 / 2,
        'height': 179 / 2,
        'yMargin': .25,
        'xMargin': -.1,
    },
    'Beret': {
        'base': capBeretBase,
        'outline': capBeretOutline,
        'width': 230 / 2,
        'height': 100 / 2,
        'yMargin': .53,
        'xMargin': -.07,
    },
    'Bridal Veil': {
        'base': capBridalVeilBase,
        'outline': capBridalVeilOutline,
        'width': 348 / 2,
        'height': 237 / 2,
        'yMargin': .20,//.2
        'xMargin': -.08,//-.08
    },
    'Bunny Ears': {
        'base': capBunnyEarsBase,
        'outline': capBunnyEarsOutline,
        'width': 196 / 2,
        'height': 165 / 2,
        'yMargin': .82,
        'xMargin': -.043,
    },
    'Cat Ears': {
        'base': capCatEarsBase,
        'outline': capCatEarsOutline,
        'width': 188 / 2,
        'height': 119 / 2,
        'yMargin': .58,
        'xMargin': .25,
    },
    'Cowboy Hat': {
        'base': capCowboyBase,
        'outline': capCowboyOutline,
        'width': 312 / 2,
        'height': 160 / 2,
        'yMargin': .60,
        'xMargin': -.04,
    },
    'Jester Cap': {
        'base': capJesterBase,
        'outline': capJesterOutline,
        'width': 282 / 2,
        'height': 154 / 2,
        'yMargin': .73,
        'xMargin': -.11,
    },
    'Halo': {
        'base': capHaloBase,
        'outline': capHaloOutline,
        'width': 277 / 2,
        'height': 112 / 2,
        'yMargin': .85,
        'xMargin': -.15,
    },
    'Mushroom Cap': {
        'base': capMushroomBase,
        'outline': capMushroomOutline,
        'width': 383 / 2,
        'height': 205 / 2,
        'yMargin': .45,
        'xMargin': -.031,
    },
    'Ninja Band': {
        'base': capNinjaBase,
        'outline': capNinjaOutline,
        'width': 344 / 2,
        'height': 87 / 2,
        'yMargin': 0,
        'xMargin': -.09,
    },
    'Party Hat': {
        'base': capPartyHatBase,
        'outline': capPartyHatOutline,
        'width': 199 / 2,
        'height': 120 / 2,
        'yMargin': .55,
        'xMargin': -.32,
    },
    'Ram Horns': {
        'base': capRamHornsBase,
        'outline': capRamHornsOutline,
        'width': 233 / 2,
        'height': 166 / 2,
        'yMargin': .32,
        'xMargin': -.07,
    },
    'Sombrero': {
        'base': capSombreroBase,
        'outline': capSombreroOutline,
        'width': 325 / 2,
        'height': 164 / 2,
        'yMargin': .52,
        'xMargin': -.043,
    },
    'Straw Hat': {
        'base': capStrawHatBase,
        'outline': capStrawHatOutline,
        'width': 330 / 2,
        'height': 137 / 2,
        'yMargin': .15,
        'xMargin': -.032,
    },
    'Wizard Hat': {
        'base': capWizardBase,
        'outline': capWizardOutline,
        'width': 338 / 2,
        'height': 227 / 2,
        'yMargin': .45,
        'xMargin': -.05,
    },
};
const SkinPaths = {
    'Cow Print': {
        'base': skinCowBase,
        'outline': skinCowOutline,
        'type': 'staticImage',
    },
    'Tiger Stripes': {
        'base': skinTigerBase,
        'outline': skinTigerOutline,
        'type': 'staticImage',
    },
    'Cheetah Print': {
        'base': skinCheetahBase,
        'outline': skinCheetahOutline,
        'type': 'staticImage',
    },
    "Royal Cloak": {
        'base': skinRoyalBase,
        'outline': skinRoyalOutline,
        'type': 'staticImage',
    },
    'Prison Tats': {
        'base': skinPrisonBase,
        'outline': skinPrisonOutline,
        'type': 'staticImage',
    },
    'Suit': {
        'base': skinSuitBase,
        'outline': skinSuitOutline,
        'type': 'staticImage',
    },
    'Kimono': {
        'base': skinKimonoBase,
        'outline': skinKimonoOutline,
        'type': 'staticImage',
    },
    'Punk': {
        'base': skinPunkBase,
        'outline': skinPunkOutline,
        'type': 'staticImage',
    },
    'Bridal Gown': {
        'base': skinBridalBase,
        'outline': skinBridalOutline,
        'type': 'staticImage',
    },
    'Glitter': {
        'base': skinGlitterBase,
        'outline': skinGlitterOutline,
        'type': 'staticImage',
    },
    'Stripe': {
        'base': skinStripeBase,
        'outline': skinStripeOutline,
        'type': 'staticImage',
    },
};
const PowerShotPaths = [
    {
        base: powershotFrame1b,
        outline: powershotFrame1
    },
    {
        base: powershotFrame2b,
        outline: powershotFrame2
    },
    {
        base: powershotFrame3b,
        outline: powershotFrame3
    },
    {
        base: powershotFrame4b,
        outline: powershotFrame4
    },
    {
        base: powershotFrame5b,
        outline: powershotFrame5
    }
];
const BallCritPaths = [
    {
        outline: ballcritFrame1
    },
    {
        outline: ballcritFrame2
    },
    {
        outline: ballcritFrame3
    },
    {
        outline: ballcritFrame4
    },
    {
        outline: ballcritFrame5
    },
    {
        outline: ballcritFrame6
    },
    {
        outline: ballcritFrame7
    },
];

/**
 * This scheme is used to store the assets in loaded image form.
 */
const assetScheme = {
    'Skin': SkinPaths,
    'Cap': CapPaths,
    'Face': FacePaths,
    'Other': {
        'Slime': SlimePaths,
        'Gift': GiftPaths,
        'Radio': RadioPaths,
        'Hoop': HoopPaths,
        'Ball': BallPaths,
        'Power Shot': PowerShotPaths,
        'Ball Crit': BallCritPaths,
        'Ghost Aura': GhostAuraPaths,
        'Bubble': BubblePaths,
        'Rock': RockPaths,
        'Bass Guitar': BassGuitarPaths,
    },
};

// This is called once to store all image assets in memory for the game layer
export let loaded = 0;
export let promiseImages = [];
export const loadImagedAssets = async () => {
    let imagedAssets = {};
    let bitmapAssets = {};

    // How we will process the images and await their loading
    const addImageAndPromise = (imageFile, assetType, asset, attribute, frameIndex, xMargin, yMargin, width, height) => {
        promiseImages.push(new Promise(resolve => {
            const img = new Image();
            img.onload = () => {
                loaded++;
                /**
                 * Creates the imagedAssets from the assetScheme
                 * Creates the bitmapAssets from the assetScheme
                 */
                const getBitmap = (imageAsset,assetType,assetName,imageName) => {
                    createImageBitmap(
                        imageAsset, 0, 0, imageAsset.naturalWidth, imageAsset.naturalHeight
                    ).then(result => {
                        bitmapAssets[assetType][assetName][imageName] = result;
                    });
                };

                const isArray = ['Power Shot', 'Ball Crit'].includes(asset);
                // Initialize all possible empty 
                if (!imagedAssets[assetType]){
                    imagedAssets[assetType] = {};
                } 
                if (!bitmapAssets[assetType]) bitmapAssets[assetType] = {};
                if (!imagedAssets[assetType][asset] && !isArray) imagedAssets[assetType][asset] = {};
                if (!bitmapAssets[assetType][asset] && !isArray) bitmapAssets[assetType][asset] = {};
                if (!imagedAssets[assetType][asset] && isArray) imagedAssets[assetType][asset] = [];
                if (!bitmapAssets[assetType][asset] && isArray) bitmapAssets[assetType][asset] = [];
                // When the paths value is an array, write images for each item
                if (isArray) {
                    imagedAssets[assetType][asset][frameIndex] = {...imagedAssets[assetType][asset][frameIndex], [attribute]: img};
                    //bitmapAssets[assetType][asset][frameIndex] = {...imagedAssets[assetType][asset][frameIndex], [attribute]: img};
                } else {
                    imagedAssets[assetType][asset][attribute] = (img);
                    bitmapAssets[assetType][asset][attribute] = (getBitmap(img,assetType,asset,attribute));
                    if(xMargin !== undefined && yMargin !== undefined){
                        bitmapAssets[assetType][asset].xMargin = xMargin;
                        bitmapAssets[assetType][asset].yMargin = yMargin;
                        imagedAssets[assetType][asset].xMargin = xMargin;
                        imagedAssets[assetType][asset].yMargin = yMargin;
                        imagedAssets[assetType][asset].width = width;
                        imagedAssets[assetType][asset].height = height;
                    }
                }
                resolve();
            };
            img.src = imageFile;
        }));
    };
    // Iterate through scheme and load all images in object storage form
    Object.keys(assetScheme).forEach(k => {
        // e.g. Other, Paint, Cap, Skin, Accessory
        const category = assetScheme[k];
        Object.keys(category).forEach(j => {
            // e.g. base, outline, texture, goo,
            const asset = category[j];
            if (Array.isArray(asset)) {
                asset.forEach((frame,frameIndex) => {
                    Object.keys(frame).forEach(attribute => {
                        if (!['width','height','yMargin','xMargin','type'].includes(attribute)) {
                            const file = frame[attribute];
                            addImageAndPromise(file, k, j, attribute,frameIndex);
                        }
                        else{
                        }
                    })
                });
            } else {
                let xMargin = asset.xMargin;
                let yMargin = asset.yMargin;
                let width = asset.width;
                let height = asset.height;
                Object.keys(asset).forEach(attribute => {
                    if (!['width','height','yMargin','xMargin','type'].includes(attribute)) {
                        const file = asset[attribute];
                        addImageAndPromise(file, k, j, attribute, null, xMargin, yMargin, width, height);
                    }
                });
            }
        });
    });

    return await Promise.allSettled(promiseImages).then(() => {
        return {
            imagedAssets,
            bitmapAssets
        };
    });
};
// The assets referenced by the game canvas layer
/* global imageAssets */
let imageAssets = null;
let bitmapAssets = null;
export const setImageAssets = (value) => {
    imageAssets = value.imagedAssets;
    bitmapAssets = value.bitmapAssets;
};

export {
    imageAssets, bitmapAssets
};
