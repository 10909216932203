
import potatoImage from '../images/fruitDivisions/potato.png';
import kiwiImage from '../images/fruitDivisions/kiwi.png';
import blueberryImage from '../images/fruitDivisions/blueberry.png';
import plumImage from '../images/fruitDivisions/plum.png';
import cherryImage from '../images/fruitDivisions/cherry.png';

// The rating a user must achieve in order to enter a new division
const d1Potato = 0;         // match wins
const d1Kiwi = 100;         // 4
const d2Kiwi = 300;         // 12
const d3Kiwi = 600;         // 24

const d1Blueberry = 1000;   // 40
const d2Blueberry = 1500;   // 60
const d3Blueberry = 2100;   // 84

const d1Plum = 2800 *2;     // 224
const d2Plum = 3600 *2;     // 288
const d3Plum = 4500 *2;     // 360

const d1Cherry = 5500 *3;   // 660
const d2Cherry = 6600 *3;   // 792
const d3Cherry = 7800 *3;   // 936

// Used as an iterator globally
export const rank = {
    names: ['potato','kiwi','blueberry','plum','cherry'],
    images: [potatoImage,kiwiImage,blueberryImage,plumImage,cherryImage],
    minimumRating: [d1Potato,d1Kiwi,d1Blueberry,d1Plum,d1Cherry]
};

// The information associated with each division
export const divisionInfo = {
    [d1Potato]: {
        minimum: d1Potato,
        word: '',
        rank: 'potato',
        division: '',
        image: potatoImage,
        tier: 0,
    },
    [d1Kiwi]: {
        minimum: d1Kiwi,
        word: 'kind',
        rank: 'kiwi',
        division: 'I',
        image: kiwiImage,
        tier: 1,
    },
    [d2Kiwi]: {
        minimum: d2Kiwi,
        word: 'kooky',
        rank: 'kiwi',
        division: 'II',
        image: kiwiImage,
        tier: 1,
    },
    [d3Kiwi]: {
        minimum: d3Kiwi,
        word: 'kickbox',
        rank: 'kiwi',
        division: 'III',
        image: kiwiImage,
        tier: 1
    },
    [d1Blueberry]: {
        minimum: d1Blueberry,
        word: 'backup',
        rank: 'blueberry',
        division: 'I',
        image: blueberryImage,
        tier: 2
    },
    [d2Blueberry]: {
        minimum: d2Blueberry,
        word: 'bold',
        rank: 'blueberry',
        division: 'II',
        image: blueberryImage,
        tier: 2
    },
    [d3Blueberry]: {
        minimum: d3Blueberry,
        word: 'bodacious',
        rank: 'blueberry',
        division: 'III',
        image: blueberryImage,
        tier: 2
    },
    [d1Plum]: {
        minimum: d1Plum,
        word: 'plain',
        rank: 'plum',
        division: 'I',
        image: plumImage,
        tier: 3
    },
    [d2Plum]: {
        minimum: d2Plum,
        word: 'poised',
        rank: 'plum',
        division: 'II',
        image: plumImage,
        tier: 3
    },
    [d3Plum]: {
        minimum: d3Plum,
        word: 'prophetic',
        rank: 'plum',
        division: 'III',
        image: plumImage,
        tier: 3
    },
    [d1Cherry]: {
        minimum: d1Cherry,
        word: 'choice',
        rank: 'cherry',
        division: 'I',
        image: cherryImage,
        tier: 4
    },
    [d2Cherry]: {
        minimum: d2Cherry,
        word: 'conquering',
        rank: 'cherry',
        division: 'II',
        image: cherryImage,
        tier: 4
    },
    [d3Cherry]: {
        minimum: d3Cherry,
        word: 'chosen',
        rank: 'cherry',
        division: 'III',
        image: cherryImage,
        tier: 4
    }
};

// Returns the users current division information
export const getDivision = (userRating) => {
    const numDivisions = Object.keys(divisionInfo).length;
    let division;
    for (let i=1; i < numDivisions; i++) {
        // Check if user is not ranked
        if (!userRating || userRating < d1Kiwi) {
            division = divisionInfo[d1Potato];
            break;
        }
        // Check if user is max rank
        if (userRating >= d3Cherry) {
            division = divisionInfo[d3Cherry];
            break;
        }
        // Check user rank
        const nextDivision = Object.keys(divisionInfo)[i];
        const currentDivision = Object.keys(divisionInfo)[i-1];
        if (nextDivision > userRating) {
            division = divisionInfo[currentDivision];
            break;
        }
    }
    return division;
};
// Determine the users previous and next division info
export const getDivisionNeighbor = (userRating) => {
    const userDivisionRatingMinimum = getDivision(userRating).minimum;

    // Index of the key representing the users division in divisionInfo
    const divisionInfoIndex =  Object.keys(divisionInfo).indexOf(userDivisionRatingMinimum.toString());

    const previousRankMinimum = (divisionInfoIndex -1 < 0) ? null : Object.keys(divisionInfo)[divisionInfoIndex-1];
    const nextRankMinimum = (divisionInfoIndex +1 > divisionInfoIndex.length) ? null : Object.keys(divisionInfo)[divisionInfoIndex+1];

    // Ranks expressed as objects with info
    const previousInfo = getDivision((parseInt(previousRankMinimum,10)));
    const nextInfo = getDivision(parseInt(nextRankMinimum,10));
    return {
        previous: previousInfo,
        next: nextInfo
    }
};