import styled from 'styled-components';
import palette from '../../styled/Palette';
import {shadow} from "../../data/globals";

export const DropHeader = styled.div`
    font-family: Luckiest Guy;
    font-size: 2em;
    color: ${palette.base4}
`;

export const DropBodyDiv = styled.div`
    color: ${palette.base4}
`;

export const DropFooter = styled.div`
`;

export const DropContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${palette.base5}
`;

export const DropStarsDiv = styled.div`
    font-size: 8em;
    display: flex;
`;

export const DropAchievementDiv = styled.div`
    font-family: Montserrat;
    font-size: 3em;
`;
export const DropAchievementDescriptionDiv = styled.div`
    font-size: 2em;
    font-family: Luckiest Guy;
`;

export const DropMountDiv = styled.div`
    box-shadow: ${shadow};
    
    color: ${props => props.color};
    background-color: ${palette.base5};
    padding: 2em;
    display: flex;
    flex-direction: column;
    border: 1px solid ${palette.wetAsphalt};
`;

export const CircleMountDiv = styled.div`
    margin: 2em;
    border: .5em solid ${palette.base5};
    background-color: ${palette.base3};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 12em;
    width: 12em;
`;

export const DropDescriptionDiv = styled.div`
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid ${palette.wetAsphalt};
`;
