import styled from 'styled-components';
import palette from './Palette';

const Hexagon = styled.div`
  transform: rotate(90deg);
  position: relative;
  margin: 1em auto;
  width: 9.375em;
  height: 16.2375em;
  border-radius: 1.25em/.625em;
  background: ${palette.base5};
  transition: opacity .5s;
    :before {
        margin-left: -50%;
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
        position: absolute;
        width: inherit; height: inherit;
        border-radius: inherit;
        background: inherit;
        content: '';
    }
    :after {
        margin-left: -50%;
        -webkit-transform: rotate(-60deg);
        transform: rotate(-60deg);
        position: absolute;
        width: inherit; height: inherit;
        border-radius: inherit;
        background: inherit;
        content: '';
    }
`;

export default Hexagon;
