import {createReducer} from "@reduxjs/toolkit";
import initialState, {MODAL_CONTENT_SCHEME} from "./modalInitialState";

const modalReducers = createReducer(initialState, {
    MODAL_ENABLE: (state, action) => {
        state.content = action.payload;
    },
    MODAL_DISABLE: (state) => {
        state.content = MODAL_CONTENT_SCHEME;
    },
});

export default modalReducers;