import styled from 'styled-components';
import palette from '../../styled/Palette';

export const PrivateMatchBodyDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${palette.base5};
    font-weight: bold;
    color: ${palette.base4};
`;

export const SectionDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 200px;
    background-color: ${palette.midnightBlue};
    margin: 1em;
`;

export const PlayerDiv = styled.div`
    display: flex;
    align-items: center;
    padding: 0 .5em;
    background-color: ${palette.base5};
    color: ${palette.base4};
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    height: 2em;
`;

export const SubheaderDiv = styled.div`
    color: ${props => props.theme ? props.theme.interfaceColorBright : null};
    padding: 5px;
    margin-bottom: 2px;
    background-color: ${palette.belizeHole};
`;