
const Skin = (player,context,size,lerpX,lerpY,lerpAngle) => {
    if (player.outfit.skin != null) {
        let image = player.outfit.skin.image;
		if(player.alternativeAssets[player.activeAbility]){
			image = player.alternativeAssets[player.activeAbility].outfit.skin;
		}
        context.translate(lerpX, lerpY);
        context.rotate(lerpAngle);
        try {
            if (player.direction === 'left') {
                context.scale(-1, 1);
            }
            context.drawImage(
                image,
                -image.width / 2 * size / 2,
                -image.height / 2 * size / 2 - Math.ceil(8 * size),
                image.width / 2 * size,
                image.height / 2 * size
            );
            if (player.direction === 'left') {
                context.scale(-1, 1);
            }
        }
        catch (err) {
            console.log(err);
        }
        context.rotate(-lerpAngle);
        context.translate(-lerpX, -lerpY);
    }
};

export default Skin;