import styled from 'styled-components';
import palette from '../../styled/Palette';
import {shadow} from "../../data/globals";
import {FONT_NORMAL} from "../../data/font";


export const HeaderDiv = styled.div`
    padding: 10px;
    text-align: right;
    background-color: ${palette.base1};
    color: ${palette.base2};
    font-weight: bold;
`;

export const BodyDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${palette.base5};
`;

export const SectionDiv = styled.div`

    position: relative;
    overflow: hidden;
    background-color: ${palette.base3};
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    height: 7.5em;
    margin: 5px 5px 0 5px;
    align-items: center;
    padding: 0 2em;
`;

export const FooterDiv = styled.div`
    position: relative;
    overflow: hidden;
    background-color: ${palette.base3};
    display: flex;
    flex-grow: 1;
    height: 7.5em;
    margin: 5px 5px 0 5px;
    align-items: center;
    padding: 0 2em;
`;

export const ModeButton = styled.button`

    box-shadow: ${shadow};
    pointer-events: ${props => props.locked && 'none'};
    font-weight: bold;
    color: ${palette.base2};
    display: flex;
    border-radius: 4px;
    border: 2px solid ${props => props.enabled ? props.theme.interfaceColorBright : palette.base2};
    background-color: ${palette.base5};
    margin: .3em;
    padding: 0 1em;
    width: 8em;
    height: 3em;
    justify-content: space-between;
    align-items: center;
`;

export const ModeDiv = styled.div`
    z-index: 2; 
    color: ${palette.base2};
    font-family: Pacifico;
    font-size: 3em;
`;

export const ModeRowDiv = styled.div`
    display: flex;
    align-items: center;
    
`;

export const QueueButtonButton = styled.button`
    color: ${palette.base4};
    pointer-events: ${props => props.enabled ? '':'none'};
    opacity: ${props => props.enabled ? '1':'.2'};
    background-color: ${props => props.inQueue ? palette.midnightBlue : palette.belizeHole};
    font-size: 1.5em;
    border-radius: 6px;
    display: flex;
    height: 3.25em;
    padding: 0 1em;
    width: 10em;
    justify-content: center;
    align-items: center;
    box-shadow: ${shadow};
`;

export const SportDiv = styled.div`
  opacity: .5;
  color: ${palette.base0};
  position: absolute;
  left: -45px;
  top: -80px;
  font-size: 150px;
`;

export const ModesDiv = styled.div`
    max-width: 20em;
    text-align: right;
    display: flex;
    flex-direction: column;
    color: ${palette.base4};
    font-weight: bold;
`;