import React, { useEffect, useState } from 'react';
import { playSound } from '../../data/sound';
import Sunburst from "../../component/Sunburst/Sunburst";
import {toggleUserInput} from "../../data/generic/generic";
import { drawerToggle } from "../../redux/modules/socket/socketActions";
import { selfRemoveNewItem } from "../../redux/modules/self/selfActions";
import {useDispatch, useSelector} from 'react-redux';
import {handleLoadPostMatch} from "../../redux/middleware/wsMatch";
import {StarPan} from "../../styled/SlimePan";
import {dropTitle} from "../../system/drop";
import {
    DropHeader,
    DropContainer,
} from './style';
import DropBody from "./body";
import {PageTypes} from "../../system/types";

const index = (props) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const { newItems, username, items } = useSelector(state => state.self);
    const { ranked } = useSelector(state => state.match);

    let [currentViewItem, setCurrentViewItem] = useState(null);

    const handleReturn = () => {
        dispatch(selfRemoveNewItem());

        if ([PageTypes.MARKET,PageTypes.CASH_SHOP,PageTypes.MAIN].includes(props.history.location.state.from)) {
            // Return to the previous page
            props.history.push(props.history.location.state.from);
        } else {
            // Kind of a hack but we don't want to go to the rank up screen again after a drop.
            dispatch(handleLoadPostMatch(ranked, null));
        }
    };

    useEffect(() => {
        // Production bug: Sometimes the drop screen mounts when there are no new items.
        // This may be a race condition where multiple scopes are trying to send the user to the Drop screen.
        // This is a band-aid fix.
        if (!newItems[0]) {
            props.history.push(PageTypes.MAIN);
        } else {
            dispatch(drawerToggle({open: false}));

            const currentItem = {
                ...newItems[0],
                category: newItems[0].category.toLowerCase()
            };
            setCurrentViewItem(currentItem);

            // Play sound
            const cash = ['market','bet','mtx'].includes(currentItem.type);
            playSound(cash ? 'cashRegister' : 'drop', .1);

            //Briefly disable input
            toggleUserInput(false);
            setTimeout(() => {
                toggleUserInput(true);
            }, 250);
        }
    }, []);

    useEffect(() => {
        if (currentViewItem?.category === 'trophy') {
            setTimeout(() => {
                playSound('selectItem', .1);
            }, 2000);
        }
    }, [currentViewItem]);

    if (currentViewItem) {
        return (
            <DropContainer className="animated fadeIn">
                <DropHeader>
                    {dropTitle(currentViewItem.type)}
                </DropHeader>
                <DropBody
                    handleReturn={handleReturn}
                    items={items}
                    currentViewItem={currentViewItem}
                    theme={theme}
                    username={username}
                    backgroundColor={currentViewItem.category === 'Paint' ? currentViewItem.colorHex : 'rgba(40,40,40,1)'}
                    history={props.history}
                />
                {currentViewItem.category !== 'trophy' ? <Sunburst/> : <StarPan> </StarPan>}
            </DropContainer>
        )
    }
    return null;
};

export default index;