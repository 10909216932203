import {SteamDrawerUserDiv} from "./SteamDrawerStyle";
import React, {useEffect, useState} from "react";
import {playSound} from "../../data/sound";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    SteamUserPortraitDiv,
    SteamUserNamesDiv,
    SteamUserNameDiv,
    SteamUserColorNameDiv
} from "./SteamDrawerUserStyle";
import {masterSocket} from "../../redux/middleware/wsMaster";
import {useSelector} from "react-redux";

const SteamDrawerUser = ({username, steamName, steamPortrait, isInSquad, inSlime}) => {
    const self = useSelector(state => state.self);
    const squad = useSelector(state => state.squad);
    const [hasBeenInvited, setHasBeenInvited] = useState(false);
    const squadLead = squad.current.filter(player => (player.name === self.username && player.captain)).length > 0;

    const handleInvite = () => {
        if (username) {
            playSound('pressButton', 1);

            if (!hasBeenInvited) {
                masterSocket.send(JSON.stringify({
                    'event': 'squadInvite',
                    'username': username,
                }));
            } else {
                masterSocket.send(JSON.stringify({
                    'event': 'kickSquadPlayer',
                    'username': username,
                }));
            }

            setHasBeenInvited(!hasBeenInvited);
        }
        if (!username || (isInSquad && !squadLead)) {
            playSound('playerJoinFailed', 1);
        }
    };

    const getUserIcon = () => {
        if (!username || !inSlime) {
            return 'user-slash';
        } else {
            if (isInSquad) {
                if (squadLead) {
                    return 'user-times';
                }
                return 'user';
            } else {
                if (hasBeenInvited) {
                    return 'paper-plane'
                }
                return 'user-plus'
            }
        }
    };

    useEffect(() => {
        if (!inSlime) {
            setHasBeenInvited(false);
        }
    }, [inSlime]);

    const inviteIconColor = username && inSlime ? 'yellow' : '';
    return (
        <SteamDrawerUserDiv
            inSlime={inSlime}
            className={!inSlime ? 'disabled' : null}
        >
            <SteamUserPortraitDiv>
                <img src={steamPortrait} alt={"Steam Portrait"}/>
            </SteamUserPortraitDiv>
            <SteamUserNamesDiv>
                <SteamUserNameDiv>
                    <div className="flexTruncate">
                        {username}
                    </div>
                </SteamUserNameDiv>
                <SteamUserColorNameDiv>
                    <FontAwesomeIcon
                        tabIndex={0}
                        icon={['fas', getUserIcon()]}
                        onClick={() => {handleInvite()}}
                        className="buttonZoom"
                        style={{fontSize: '1.5em', color: inviteIconColor, width: '1.5em', marginRight: '.5em'}}
                    />
                    <div className="flexTruncate">
                        {steamName}
                    </div>
                </SteamUserColorNameDiv>
            </SteamUserNamesDiv>
        </SteamDrawerUserDiv>
    )
};

export default SteamDrawerUser;