import React from 'react';
import styled from 'styled-components';
import palette from "../../styled/Palette";
import {shadow} from "../../data/globals";

export const AtlasColumnDiv = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.active ? palette.wetAsphalt : palette.base6};
    ${props => props.active ? `border: 1px solid ${palette.belizeHole};` : null};
    position: relative;
`;
export const AtlasContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10em;
    
    border-left: 1px solid ${palette.base1};
    border-right: 1px solid ${palette.base1};
    border-bottom: 1px solid ${palette.base1};
    background: repeating-linear-gradient(
      45deg,
      ${palette.base6},
      ${palette.base6} 10px,
      ${palette.base7} 10px,
      ${palette.base7} 20px
    );
`;
export const AtlasShadowDiv = styled.div`
     box-shadow: ${shadow};
`;
export const AtlasHeaderDiv = styled.div`
    flex-shrink: 0;
    font-family: Montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${palette.base4};
    ${props => props.active && 'font-weight: bold'};
    width: 100%;
    height: ${props => props.height ?? '4em'};
    font-size: 2em;
    background-color: ${props => props.active ? palette.belizeHole : palette.base3};
`;
export const AtlasColumnOverlayDiv = styled.div`
    background-color: rgba(0,0,0,.15);
    width: 100%;
    height: 100%;
    position: absolute;
`;
export const AtlasItemRollUpDiv = styled.div`
    margin: 5em 0;
`;
export const AtlasBlockDiv = styled.div`
    height: .1em;
    background-color: ${palette.belizeHole};
`;