
import React, {useState, useEffect, useCallback, useRef} from 'react';
import loader from "../../images/load2.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { timerGeneric } from '../../data/format';
import { playSound } from '../../data/sound';

import {debounce} from "../../data/generic/generic";
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import palette from "../../styled/Palette";
import {MainNotifyQueueDiv} from "./MainStyle";
import {requestLeaveQueue} from "../../system/endpoints/master";

const QuickPlayDiv = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: ${props => props.theme.interfaceColorBright};
    `;
const QuickPlay = () => {
    const theme = useSelector(state => state.theme);
    const inQueue = useSelector(state => state.queue.active);
    const queueWait = useSelector(state => state.queue.wait);
    const [queueCooldown, setQueueCooldown] = useState(0);
    const [userHovering, setUserHovering] = useState(false);
    const [queueTimeInterval, setQueueTimeInterval] = useState(0);
    const queueInterval = useRef();
    const [queueTime,setQueueTime] = useState('0:00');

    const handlePlayNow = () => debouncePlayNow(inQueue);
    const debouncePlayNow = useCallback(debounce((inQueue) => {
        return leaveQuickMatch();
    }, 250), []);

    const leaveQuickMatch = () => {
        playSound('leftQueue', 1);
        requestLeaveQueue();
        setCooldown();
    };

    const setCooldown = () => {
        const quickPlay = document.getElementById('quickPlay');
        if (quickPlay) {
            quickPlay.style.pointerEvents = 'none';
        }
        setQueueCooldown(3);
    };

    const tickQueue = () => {
        if (queueCooldown > 0) {
            setQueueCooldown(queueCooldown - 1);
        } else {
            const quickPlay = document.getElementById('quickPlay');
            if (quickPlay) quickPlay.style.pointerEvents = null;
        }
    };

    useEffect(() => {
        queueInterval.current = setInterval(() => {
            const time = timerGeneric(Math.round((Date.now() - queueWait)/1000));
            setQueueTime(time);
        }, 1000);
        return () => {
            clearInterval(queueInterval);
        };
    }, []);
    useEffect(() => {
        if (inQueue !== null) {
            // inQueue has been set, and is no longer its initial value of 'null'.
            if (!inQueue) {
                clearInterval(queueTimeInterval);
            }
        }
    },[inQueue]);
    useEffect(() => {
        setTimeout(() => {
            tickQueue();
        }, 1000);
    }, [queueCooldown]);

    return (
        <QuickPlayDiv
            theme={theme}
        >
            <MainNotifyQueueDiv
                className="transitionSlow"
                inQueue={inQueue}>
                SEARCHING FOR A MATCH
            </MainNotifyQueueDiv>
            <div
                onMouseEnter={() => setUserHovering(true)}
                onFocus={() => setUserHovering(true)}
                onBlur={() => setUserHovering(false)}
                onMouseLeave={() => setUserHovering(false)}
                onClick={handlePlayNow}
                tabIndex={0}
                style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: palette.wetAsphalt ,borderRadius: '50%', width: '5em', height: '5em'}}>
                <div style={{position: 'absolute', fontWeight: 'bold'}}>
                    {userHovering ?
                        <FontAwesomeIcon style={{fontSize: '3.5em'}} icon={['fas', 'times-circle']}/>
                        :
                        queueTime
                    }
                </div>
                <img
                    style={{width: '6em'}}
                    src={loader}
                    alt="Searching Indicator"
                />
            </div>
        </QuickPlayDiv>
    )
};

export default QuickPlay;