import { badWords } from "../data/badWords";

export const isValidUsername = (username) => {
    // Must be A-Z 0-9
    const regex = /^(?=.{4,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?![_.])$/;
    return regex.test(username);
};

export const isValidEmail = (email) => {
    // Must include @ and .
    const regex = /^\S+@\S+$/;
    return regex.test(email);
};

export const isAppropriateUsername = (username) => {
    // Must not use a no no word
    for (let word of badWords) {
        if (username.includes(word)) {
            return false;
        }
    }
    return true;
};