import Proton from 'proton-engine';
import { updatePerFrame } from './protonConfig';

let victoryParticles = {};
victoryParticles.enabled = false;

let emitter = new Proton.Emitter();
emitter.rate = new Proton.Rate(new Proton.Span(0, 0), 1000);
emitter.addInitialize(new Proton.Mass(1));
emitter.addInitialize(new Proton.Radius(6, 20));
emitter.addInitialize(new Proton.Life(2, 4));
emitter.addInitialize(
    new Proton.Velocity(new Proton.Span(1, 4), new Proton.Span(160, 200), 'polar')
);
emitter.addBehaviour(new Proton.RandomDrift(30, 30, 0.05));
emitter.addBehaviour(new Proton.Color('random', 'random'));
emitter.addBehaviour(new Proton.Scale(1, 4));
emitter.addBehaviour(new Proton.Alpha(1, 0));
emitter.addInitialize(new Proton.Position(new Proton.RectZone(0, 0, 1920, 0)));
emitter.emit();

//EMITTER
victoryParticles.emitter = emitter;

//ENABLE
victoryParticles.enable = () => {
    if (!victoryParticles.enabled) {
        victoryParticles.emitter.rate = new Proton.Rate(1, updatePerFrame);
        victoryParticles.enabled = true;
    }
};

//DISABLE
victoryParticles.disable = () => {
    if (victoryParticles.enabled) {
        victoryParticles.emitter.rate = new Proton.Rate(new Proton.Span(0, 0), 1000);
        victoryParticles.enabled = false;
    }
};

//UPDATE
victoryParticles.update = () => {};

export { victoryParticles };
