import coin from "../../images/coin.png";
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import palette from "../../styled/Palette";
import { playSound } from "../../data/sound";
import { AMOUNT_COIN_EARNED_FROM_WIN } from "../../data/globals";

export const CoinEarningsDiv = styled.div`
    display: flex;
    flex-grow: 1;
    font-size: 1.5em;
    background-color: ${palette.wetAsphalt};
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: ${palette.base4};
`;

const CoinEarnings = () => {
    const [coins, setCoins] = useState(0);

    const earnCoin = () => {
        setTimeout(() => {
            playSound('earnCoin', .05);
            setCoins(coins+1);
        }, 10);
    };

    useEffect(() => {
        earnCoin();
    },[]);

    useEffect(() => {
        if (coins < AMOUNT_COIN_EARNED_FROM_WIN) {
            earnCoin();
        }
    },[coins]);

    return (
        <CoinEarningsDiv>
            <div className="animated animatedSlow slideInLeft" style={{margin: '.25em .25em 0 0'}}><img src={coin} alt='Coins'/></div>
            <div className="animated animatedSlow slideInRight">+{coins}</div>
        </CoinEarningsDiv>
    )
};

export default CoinEarnings;