import palette from "../../../styled/Palette";
import Tippy from "@tippyjs/react";
import Tooltip from "../../../styled/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useSpring} from "react-spring";
import {useIcon} from "../../../data/icon";

const badgePremium = (initialStyles,config,profile, stats) => {
    const [props, setSpring] = useSpring(() => initialStyles);
    function onMount() {
        setSpring({
            opacity: 1,
            transform: "scale(1)",
            onRest: () => {},
            config
        });
    }
    function onHide({ unmount }) {
        setSpring({
            ...initialStyles,
            onRest: unmount,
            config: { ...config, clamp: true }
        });
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                color: profile.memberInfo.premium ? palette.sunFlower : palette.base4,
                fontSize: '2em'
            }}
        >
            <Tippy
                render={attrs => (
                    <Tooltip style={props} {...attrs}>
                        <div style={{padding: '10px', display: 'flex', flexDirection: 'column'}}>
                            <div>{ profile.memberInfo.premium ? 'PREMIUM SUPPORTER' : 'FREE PLAYER' }</div>
                        </div>
                    </Tooltip>
                )}
                animation={true}
                onMount={onMount}
                onHide={onHide}
                hideOnClick={false}
                arrow={true}
            >
                <div>{useIcon(profile.memberInfo.premium ? 'premiumUser' : 'memberUser')}</div>
            </Tippy>
        </div>
    )
};
export default badgePremium;