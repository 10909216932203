import Tippy from "@tippyjs/react";
import Tooltip from "../../../styled/Tooltip";
import palette from "../../../styled/Palette";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useSpring} from "react-spring";

const badgeMod = (initialStyles,config,profile, stats) => {
    const [props3, setSpring3] = useSpring(() => initialStyles);
    function onMount3() {
        setSpring3({
            opacity: 1,
            transform: "scale(1)",
            onRest: () => {},
            config
        });
    }
    function onHide3({ unmount }) {
        setSpring3({
            ...initialStyles,
            onRest: unmount,
            config: { ...config, clamp: true }
        });
    }

    if (profile.username.toLowerCase() !== 'bigpig') return null;
    return (
        <Tippy
            render={attrs => (
                <Tooltip style={props3} {...attrs}>
                    <div style={{padding: '10px', display: 'flex', flexDirection: 'column'}}>
                        <div>PLAYER MODERATOR</div>
                    </div>
                </Tooltip>
            )}
            animation={true}
            onMount={onMount3}
            onHide={onHide3}
            hideOnClick={false}
        >
            <div style={{color: palette.sunFlower, fontSize: '2em', padding: '0 .2em'}}>
                <FontAwesomeIcon icon={['fas', 'ankh']} />
            </div>
        </Tippy>
    )
};
export default badgeMod;