import React, {useEffect, useState} from "react";
import coin from "../../images/coin.png";
import { currency } from '../../data/format';
import {MatchChatHeaderDiv} from "./MatchChatStyle";

const MatchBetDisplay = ({bets}) => {
    const [activeBetOnLeft,setActiveBetOnLeft] = useState(0);
    const [activeBetOnRight,setActiveBetOnRight] = useState(0);
    const [betTotal,setBetTotal] = useState(0);
    const [userHovering,setUserHovering] = useState(false);

    useEffect(() => {
        setBetTotal(parseInt(activeBetOnLeft,10) + parseInt(activeBetOnRight,10));
    }, [activeBetOnLeft,activeBetOnRight]);

    useEffect(() => {
        if (bets) {
            let leftTeamBetTotal = 0;
            let rightTeamBetTotal = 0;
            for (let i = 0; i < Object.keys(bets).length; i++) {
                const { numerator, denominator, taker, amount } = bets[i];
                const usingOdds = numerator !== denominator;
                const favorLeftTeam = numerator < denominator;
                const betValue = parseInt(amount,10);

                if (taker) {
                    // These are bets which are in play
                    if (usingOdds) {
                        // There was an odds multiplier applied
                        if (favorLeftTeam) {
                            // The risk reward is higher for the right team
                            leftTeamBetTotal += betValue;
                            rightTeamBetTotal += betValue * numerator/denominator;
                        } else {
                            // The risk reward is higher for the left team
                            rightTeamBetTotal += betValue;
                            leftTeamBetTotal += betValue * numerator/denominator;
                        }
                    } else {
                        // There is no multiplier in effect
                        leftTeamBetTotal += betValue;
                        rightTeamBetTotal += betValue;
                    }
                }
            }
            setActiveBetOnLeft(leftTeamBetTotal);
            setActiveBetOnRight(rightTeamBetTotal);
        }
    }, [bets]);

    if (betTotal > 0) {
        return (
            <div className="animated bounceIn"
                 style={{zIndex: 5, display: 'flex', justifyContent: 'center', color: 'white', margin: '0 1em'}}
                 onMouseEnter={() => setUserHovering(true)}
                 onMouseLeave={() => setUserHovering(false)}
            >
                {userHovering && <div style={{ display: 'flex', alignItems: 'center' }}>{ currency(activeBetOnLeft) }</div>}
                <div style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{marginTop: !userHovering && '-.5em'}}><img src={coin} alt='Coins'/></div>
                    {!userHovering &&
                        <div style={{
                            position: 'absolute',
                            fontWeight: 700,
                            top: '.75em'
                        }}>
                            { currency(betTotal) }
                        </div>
                    }
                </div>
                {userHovering && <div style={{ display: 'flex', alignItems: 'center' }}>{ currency(activeBetOnRight) }</div>}
            </div>
        )
    }
    return null;
};

export default MatchBetDisplay;