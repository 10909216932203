import Proton from 'proton-engine';
import { playSound } from '../../data/sound';
import { playerColorHexToParticleColor } from './particleHelpers';
import { protonBackground } from './protonBackground';
import { updatePerFrame } from './protonConfig';

export function createFocusJumpParticles(room, team, teamIndex) {
    playSound('fireworksLaunch', 1);
    let player = room.teams[team][teamIndex];
    let focusJumpEmitter = new Proton.Emitter();
    focusJumpEmitter.rate = new Proton.Rate(20, updatePerFrame);
    focusJumpEmitter.addInitialize(new Proton.Mass(1));
    focusJumpEmitter.addInitialize(new Proton.Radius(5, 17));
    focusJumpEmitter.addInitialize(new Proton.Life(1));
    focusJumpEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    focusJumpEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-4, 4), new Proton.Span(3, 6), 'vector')
    );
    // focusJumpEmitter.addInitialize(new Proton.Velocity(Proton.Span(-2,2),-3));
    let color = playerColorHexToParticleColor(player.colorHex);
    focusJumpEmitter.addBehaviour(
        new Proton.Color(color, [
            'ffff00',
            'ff7700',
            'eeff00',
            'ffaa00',
            'dddd33',
            'ff9900',
            'eeaa00',
        ])
    );
    focusJumpEmitter.addBehaviour(new Proton.Gravity(-4));
    focusJumpEmitter.addBehaviour(new Proton.Scale(1, 4));
    focusJumpEmitter.addBehaviour(new Proton.Alpha(0.3, 0));
    focusJumpEmitter.p.x = player.position.x;
    focusJumpEmitter.p.y = player.position.y;
    focusJumpEmitter.damping = 0.04;
    focusJumpEmitter.emit(0.7, 5);

    protonBackground.addEmitter(focusJumpEmitter);
    let interval = setInterval(() => {
        player = room.teams[team][teamIndex];
        if (player) {
            let position = player.position;
            focusJumpEmitter.p.x = position.x;
            focusJumpEmitter.p.y = position.y;
        }
    }, 1000 / 60);
    setTimeout(() => {
        playSound('fireworksCrash', 1);

        player = room.teams[team][teamIndex];
        const subemitter = new Proton.Emitter();
        subemitter.rate = new Proton.Rate(new Proton.Span(100, 120), 1);

        subemitter.addInitialize(new Proton.Mass(1));
        subemitter.addInitialize(new Proton.Radius(4, 8));
        subemitter.addInitialize(new Proton.Life(2, 4));
        subemitter.addInitialize(new Proton.V([7, 10], new Proton.Span(0, 360), 'polar'));

        subemitter.addBehaviour(new Proton.Alpha(1, 0));
        subemitter.addBehaviour(new Proton.Scale(1, 0.1));
        subemitter.addBehaviour(new Proton.Gravity(2));
        let colors = ['ff0000', 'ffaa00', 'ffff00', '00ff00', '00ffff', '00ffff', 'ff00ff'];
        let color = playerColorHexToParticleColor(player.colorHex);
        subemitter.addBehaviour(new Proton.Color(color));

        subemitter.p.x = player.position.x;
        subemitter.p.y = player.position.y;
        subemitter.emit('once', true);

        const subemitter2 = new Proton.Emitter();
        subemitter2.rate = new Proton.Rate(new Proton.Span(100, 120), 1);

        subemitter2.addInitialize(new Proton.Mass(1));
        subemitter2.addInitialize(new Proton.Radius(4, 8));
        subemitter2.addInitialize(new Proton.Life(2, 4));
        subemitter2.addInitialize(new Proton.V([7, 10], new Proton.Span(0, 360), 'polar'));

        subemitter2.addBehaviour(new Proton.Alpha(1, 0));
        subemitter2.addBehaviour(new Proton.Scale(1, 0.1));
        subemitter2.addBehaviour(new Proton.Gravity(2));
        color = colors[Math.floor(Math.random() * colors.length)];

        subemitter2.addBehaviour(new Proton.Color(color));

        subemitter2.p.x = player.position.x;
        subemitter2.p.y = player.position.y;
        subemitter2.emit('once', true);

        const subemitter3 = new Proton.Emitter();
        subemitter3.rate = new Proton.Rate(new Proton.Span(100, 120), 1);

        subemitter3.addInitialize(new Proton.Mass(1));
        subemitter3.addInitialize(new Proton.Radius(1, 3));
        subemitter3.addInitialize(new Proton.Life(5));
        subemitter3.addInitialize(
            new Proton.V(new Proton.Span(0.2, 0.5), new Proton.Span(0, 360), 'polar')
        );

        subemitter3.addBehaviour(new Proton.Alpha(0.5, 0));
        subemitter3.addBehaviour(new Proton.Scale(1, 0.1));
        subemitter3.addBehaviour(new Proton.Gravity(2));
        subemitter3.addBehaviour(new Proton.RandomDrift(15, 15, 0.05));

        subemitter3.addBehaviour(
            new Proton.Color(['ffff00', 'ff7700', 'eeff00', 'ffaa00', 'dddd33', 'ff9900', 'eeaa00'])
        );

        subemitter3.p.x = player.position.x;
        subemitter3.p.y = player.position.y;
        subemitter3.emit('once', 40);

        protonBackground.addEmitter(subemitter);
        protonBackground.addEmitter(subemitter3);
        setTimeout(() => {
            protonBackground.addEmitter(subemitter2);
        }, 200);
        clearInterval(interval);
    }, 730);
}
