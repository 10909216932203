import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import palette from "../styled/Palette";
import AnimationFade from "../animation/AnimationFade";
import {playSound} from "../data/sound";
import ItemSlot from "./Item/ItemSlot";
import {requestSwitchAccessory} from "../system/endpoints/match";
import {useSelector} from "react-redux";
import {gameSocket} from "../redux/middleware/wsMatch";
import {sandbox,swapAbilities} from "../system/physics/physics";
import {getAccessoryByAbility} from "../data/items";

const AgnosticFlex = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 1em;
`;
const ItemSwitchDiv = styled.div`
    border-radius: 4px;
    background-color: ${palette.midnightBlue};
    border: 5px solid ${palette.wetAsphalt};
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => !props.toggled && 'pointer-events: none'};
`;
const ItemSwitchIndexNameDiv = styled.div`
    color: ${palette.peterRiver};
    font-weight: bold;
    font-size: 1.5em;
    width: 20em;
`;
export const SvgDiv = styled.svg`
    width: 100%;
    height: 100%;
    font-size: 2em;
`;
export const StrokeDiv = styled.text`
    fill            : ${palette.belizeHole};
    stroke          : ${palette.midnightBlue};
    stroke-width    :  1px;
    stroke-linejoin : round;
    text-anchor: middle;
    font-family: Modak;
`;

/**
 * This component displays the currently equipped accessory when the user switches.
 * The user can use this button in the lobby, or in match to switch accessories.
 */
const ItemSwitchManager = ({gameMetadataProvider}) => {
    const match = useSelector(state => state.match);
    const self = useSelector(state => state.self);
    const gamepad = useSelector(state => state.gamepad);
    const [screenEnabled,setScreenEnabled] = useState(false);
    const [currentIndex,setCurrentIndex] = useState(0);
    const [sandboxData,setSandboxData] = useState({abilities: [],activeAbility: null});
    const currentItemName = sandboxData?.abilities[currentIndex]?.name;

    const readSelfAbilities = () => {
        // Players in the lobby are represented as teams.teamA[0]
        // Players in a match are referenced in redux and specified by a server message
        const player = gameMetadataProvider.teams[self.team ?? 'teamA'][self.teamIndex ?? 0];

        // Production bug: There's been a common error in production where player was undefined.
        if (player) {
            const abilities = player.abilityNames.map(abilityName => {
                const ability = player.abilities[abilityName];
                return {
                    ...ability,
                    ...getAccessoryByAbility(abilityName)
                }
            });

            setSandboxData({
                abilities: abilities,
                activeAbility: player.activeAbility
            });
        }
    };
    const handleItemSelect = (e,item,mouseIndex) => {
        const index = mouseIndex ?? currentIndex;
        const swapToAbilityName = sandboxData.abilities[index].abilityName;

        if (gameSocket) {
            // Hit the endpoint to request the change on the remote
            requestSwitchAccessory(self.username,swapToAbilityName);
        } else {
            // Update the client side sandbox directly
            swapAbilities(gameMetadataProvider.teams[self.team ?? 'teamA'][self.teamIndex ?? 0], sandbox, swapToAbilityName, false);
        }
        playSound('selectItem', 1);
        setScreenEnabled(false);
    };
    const handleItemEnter = (e,index) => {
        setCurrentIndex(index);
    };
    const handleItemLeave = (e,index) => {
    };
    const handleUserItemSwitch = (code) => {
        const userHasNoAbilities = Object.keys(sandboxData.abilities).length === 0;
        if (userHasNoAbilities) return null;

        const pressQ = code === 'KeyQ';
        const pressE = code === 'KeyE';
        const pressEscape = code === 'Escape';
        const pressEnter = code === 'Enter';

        if (pressQ) {
            if (screenEnabled) {
                playSound('pressButton', 1);
                setCurrentIndex(cb => {
                    if (cb === Object.keys(sandboxData.abilities).length-1) {
                        return 0;
                    }
                    return cb+1;
                });
            } else {
                playSound('selectItem', 1);
                setScreenEnabled(true);

                // We always want the currentIndex item to be inFocus when the menu appears
                // This handles the edge case if the item becomes deselected and then reopened, no item is selected.
                setCurrentIndex(0);
                setTimeout(() => setCurrentIndex(currentIndex),0);
            }
        }

        if (screenEnabled) {
            if (pressE || pressEnter) {
                handleItemSelect();
            }
        }

        if (pressEscape) {
            setScreenEnabled(false);
        }
    };

    // Also listen to the controller inputs via redux
    useEffect(() => {
        if (gamepad.button_2) {
            handleUserItemSwitch('KeyE');
        }
        if (gamepad.button_4) {
            handleUserItemSwitch('KeyQ');
        }
    }, [gamepad.button_2,gamepad.button_4, sandboxData]);

    // Generic configuration for handling a listener
    useEffect(() => {
        const keystrokeInstance = (e) => handleUserItemSwitch(e.code);
        window.addEventListener('keydown', keystrokeInstance);
        return () => {
            window.removeEventListener('keydown', keystrokeInstance);
        };
    }, [screenEnabled,currentIndex,sandboxData]);

    useEffect(() => {
        readSelfAbilities();
    }, [
        self.outfit, // This data is read from the match scope when the user equips a new item, in order to update the loadout.
        screenEnabled // It is also read whenever the screen is enabled, in case the quantity of an item has changed.
    ]);

    // When the user is observing a match, don't render this component
    if (match.sessionId === 'spectator') {
        return null;
    }
    return (
        <AgnosticFlex>
            <AnimationFade
                toggled={screenEnabled}
                componentToRender={
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <ItemSwitchDiv
                            toggled={screenEnabled}
                        >
                            {
                                Object.keys(sandboxData.abilities).map((key,index) => {
                                    return (
                                        <ItemSlot
                                            index={index}
                                            click={handleItemSelect}
                                            handleItemEnter={handleItemEnter}
                                            handleItemLeave={handleItemLeave}

                                            key={key}
                                            accessorySelected={currentIndex===index}
                                            slotWidth={5}
                                            slotHeight={5}
                                            item={sandboxData.abilities[key]}
                                        />
                                    )
                                })
                            }
                        </ItemSwitchDiv>
                        <ItemSwitchIndexNameDiv>
                            <SvgDiv
                                viewBox="0 0 300 50"
                            >
                                <StrokeDiv
                                    style={{fontSize: '.5em'}}
                                    x={'50%'}
                                    y={'30'}
                                >{currentItemName}</StrokeDiv>
                            </SvgDiv>
                        </ItemSwitchIndexNameDiv>
                    </div>
                }
            />
        </AgnosticFlex>
    )
};

export default ItemSwitchManager;