import {gameSocket} from "../../redux/middleware/wsMatch";

const sendMatch = (msg) => {
    if(gameSocket?.readyState === 1) gameSocket.send(JSON.stringify(msg));
};

export const requestTakeUserInputs = (controls) => {
    sendMatch({
        'event': 'updateControls',
        'controls': controls
    });
};

export const requestSwitchAccessory = (username,abilityName) => {
    sendMatch({
        'event': 'swapAbilities',
        'ability': abilityName
    });
};

export const requestForfeit = () => {
    sendMatch({
        'event': 'forfeit'
    });
};