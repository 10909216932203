
const testSongs = [
    { src: '0.ogg', name: 'test song 0' },
    { src: '1.ogg', name: 'test song 1' },
    { src: '2.ogg', name: 'test song 2' },
    { src: '3.ogg', name: 'test song 3' },
];
const songs = [
    { src: '86.ogg', name: '86' },
    { src: 'Burkerking.ogg', name: 'Burger King' },
    { src: 'dontstop.ogg', name: 'Dont Stop' },
    { src: 'handsonthewheel.ogg', name: 'Hands on the Wheel' },
    { src: 'kfc.ogg', name: 'KFC' },
    { src: 'onesecond.ogg', name: 'One Second' },
    { src: 'Reckoning.ogg', name: 'Reckoning' },
    { src: 'slimebaki2.ogg', name: 'Bakki' },
    { src: 'westernquarl.ogg', name: 'Western Quarl' },
    { src: 'asianpirates.ogg', name: 'Slime Ninjas' },
    { src: 'chippertimbers.ogg', name: 'Chipper Timbers' },
    { src: 'draggedout.ogg', name: 'Dragged Out' },
    { src: 'Herionstreets.ogg', name: 'Herion Streets' },
    { src: 'mumpjam.ogg', name: 'Mumpjam' },
    { src: 'onlywayup.ogg', name: 'Only Way Up' },
    { src: 'Redhatsavior.ogg', name: 'Red Hat Savior' },
    { src: 'sluggin.ogg', name: 'Sluggin' },
    { src: 'WHERESMYBASSAT.ogg', name: 'Where my bass at?!' },
    { src: 'avacodamonday.ogg', name: 'Avocado Monday' },
    { src: 'creepers.ogg', name: 'Creepers' },
    { src: 'ElvesontheVine.ogg', name: 'Elves on the Vine' },
    { src: 'hoobla.ogg', name: 'Hoobla' },
    { src: 'nevergiveup.ogg', name: 'Never Give Up!' },
    { src: 'overtime.ogg', name: 'Overtime' },
    { src: 'saber.ogg', name: 'Saber' },
    { src: 'souleater1.ogg', name: 'Soul Eater' },
    { src: 'wildberry.ogg', name: 'Wild Berry' },
    { src: 'cureit.ogg', name: 'Cure It' },
    { src: 'freshair.ogg', name: 'Fresh Air' },
    { src: 'horses.ogg', name: 'Horses' },
    { src: 'NewBIOS.ogg', name: 'New BIOS' },
    { src: 'peachtea.ogg', name: 'Peach Tea' },
    { src: 'Savinggrace.ogg', name: 'Saving Grace' },
    { src: 'streetlife.ogg', name: 'Street Life' },
    { src: 'BOOGIEBIOS.ogg', name: 'Boogie Bios' },
    { src: 'dontdieonme.ogg', name: 'Dont Die on Me!' },
    { src: 'Grabthebag.ogg', name: 'Grab the Bag' },
    { src: 'jungleseason.ogg', name: 'Jungle Season' },
    { src: 'okaybaby.ogg', name: 'Okay Baby' },
    { src: 'persianrug.ogg', name: 'Persian Rug' },
    { src: 'six2.ogg', name: 'Six' },
    { src: 'wavemanwalking.ogg', name: 'Waveman Walking' },
];

export default songs;