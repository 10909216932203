import {createAction} from "@reduxjs/toolkit";

export const loadRadio = createAction('LOAD_RADIO');

// Get the next song. Fade out the current song. Set loading true.
export const getNextSong = createAction('GET_NEXT_SONG');
// Load the previous song. Fade out the current song.
export const getPreviousSong = createAction('GET_PREVIOUS_SONG');

export const togglePlaying = createAction('TOGGLE_PLAYING');
export const changeVolume = createAction('CHANGE_VOLUME');
export const changeCanvasColor = createAction('CHANGE_CANVAS_COLOR');

export const radioMounted = createAction('RADIO_MOUNTED');