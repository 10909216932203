import React, {useState, useRef, useEffect} from 'react';
import {
    BetDetailDiv,
    BetsDetailDiv,
    BetTextDiv,
    BetTableDiv,
    BetTableHeadDiv,
    BetTableHeaderDiv,
    BetActionButton
} from './BetsStyle';
import { masterSocket } from "../../../redux/middleware/wsMaster";
import {currency, getRandomInt} from "../../../data/format";
import {playSound} from "../../../data/sound";
import palette from "../../../styled/Palette";
import {BetContentDiv} from "./BetsOverlay/BetsOverlayStyle";
import { useSelector } from 'react-redux';



const Bets = ({theme, roomId, username, coins}) => {
    const { bets } = useSelector(state => state.match);
    const takeBet = (index) => {
        masterSocket.send(JSON.stringify({
            'event': 'takeBet',
            'roomId': roomId,
            'betIndex': index,
        }));
        let string = `coinSound${getRandomInt(14)}`;
        playSound(string,1);
    };

    const redactBet = (index) => {
        const bet = bets[index];
        const amount = parseInt(bet.amount,10);
        const numerator = parseInt(bet.numerator,10);

        if (!bet.redacted) {
            masterSocket.send(JSON.stringify({
                'event': 'cancelBet',
                'roomId': roomId,
                'betIndex': index,
            }));
            playSound('pop',1);
        }

    };

    if (bets) {
        if (bets.length > 0) {
            return (
                <BetsDetailDiv>
                    {bets.length > 0 &&
                    <BetTableHeadDiv>
                        <BetTableHeaderDiv first={true}>BET</BetTableHeaderDiv>
                        <BetTableHeaderDiv>MAKER</BetTableHeaderDiv>
                        <BetTableHeaderDiv>TAKER</BetTableHeaderDiv>
                        <BetTableHeaderDiv last={true}> </BetTableHeaderDiv>
                    </BetTableHeadDiv>
                    }
                    <BetTableDiv className="styleDarkScrollSquare" >
                        {
                            bets.map((bet,i) => {
                                const {maker, taker, amount, numerator, denominator, active, side} = bet;
                                const userMaker = username === maker;
                                const betLocked = maker && taker;
                                // todo: server side should pass maker/taker colors also
                                return (
                                    <BetDetailDiv
                                        dark={i%2===0}
                                        key={i}
                                    >
                                        <BetTextDiv active={active}>
                                            {side} TO WIN
                                        </BetTextDiv>
                                        <BetTextDiv active={active}>
                                            {maker} {amount}
                                        </BetTextDiv>
                                        <BetTextDiv active={active}>
                                            {taker ? `${taker}` : ``} {amount *numerator/denominator}
                                        </BetTextDiv>
                                        {active ?
                                            <BetActionButton
                                                tabIndex={0}
                                                actionItem={true}
                                                interfaceColor={theme.interfaceColorBright}
                                                onClick={() => !betLocked ? (userMaker ? redactBet(i) : takeBet(i)) : null}
                                            >
                                                {betLocked && 'BET ACTIVE'}
                                                {!taker &&
                                                    (
                                                        userMaker ?
                                                            'REDACT'
                                                            :
                                                            (
                                                                coins >= amount*numerator/denominator
                                                                    ?
                                                                    `BET ${amount *numerator/ denominator}`
                                                                    :
                                                                    `NOT AFFORDABLE`
                                                            )
                                                    )
                                                }
                                            </BetActionButton>
                                            :
                                            <BetActionButton
                                                tabIndex={0}
                                                redacted={true}
                                                actionItem={true}
                                                interfaceColor={theme.interfaceColor}
                                            >
                                                WITHDRAWN
                                            </BetActionButton>
                                        }
                                    </BetDetailDiv>
                                )
                            })
                        }
                    </BetTableDiv>
                </BetsDetailDiv>
            )
        } else {
            return (
                <div style={{padding: '2em', color: palette.base4, width: '40em', textAlign: 'left'}}>
                    Currently no one is betting on this match. Adjust the criteria above and hit submit to broadcast a bet offer to other observers.
                </div>
            )
        }
    }
    return null;
};

export default Bets;

/* This could be used for chat events in the future
const evenOdds = parseInt(numerator,10) === parseInt(denominator, 10);
const sideLabel = side === 'left' ? 'LEFT TEAM' : 'RIGHT TEAM';
const takersBet = parseInt((amount / denominator * numerator).toString(),10);
const betText = evenOdds ?  amount : `${amount}:${amount*denominator}`;
{`${maker} ${amount} bet ${taker} ${takersBet} on ${sideLabel}  win`}
{` ${maker} offers ${betText} on ${sideLabel} win`}
 */

/*
<div style={{margin: '0 10px'}}>
    {data.amount <= 100 && <img src={shortStack} alt='Short Stack'/> }
    {data.amount > 100 && data.amount < 1000 && <img src={mediumStack} alt='Medium Stack'/> }
    {data.amount >= 1000 && <img src={tallStack} alt='Tall Stack'/> }
</div>
 */