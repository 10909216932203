
import React from 'react';
import Header from "../component/Header/Header";
import Page from "../styled/Page";
import styled from 'styled-components';
import palette from '../styled/Palette';
import img1 from '../images/gameguide/1.png';
import img2 from '../images/gameguide/2.png';
import img3 from '../images/gameguide/3.gif';
import img4 from '../images/gameguide/4.png';
import img5 from '../images/gameguide/5.png';
import img6 from '../images/gameguide/6.png';
import img7 from '../images/gameguide/7.png';
import img8 from '../images/gameguide/8.png';
import isElectron from 'is-electron';
import SlimePan from "../styled/SlimePan";
import {PageTypes} from "../system/types";


const HeadFont = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${palette.base4};
`;


const Divider = styled.div`
  height: 1px;
  background-color: #131D29;
  width: 100%;
  margin: 40px 0;
`;

const StyleList = styled.div`
  color: ${palette.base4};
  img {
    margin-left: 40px;
  }
  ul {
    margin: 10px 0 0 40px;
  }
  li {
    background: transparent !important; 
    list-style-type: square;
  }
`;


export default class GameGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Page className="animatedFast slideInRight">
                <SlimePan style={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto'}}>
                    <Header closeRoute={PageTypes.MAIN} title="GAME GUIDE"/>
                    <StyleList style={{flexGrow: 1, padding: '100px 200px', textAlign: 'left', backgroundColor: '#1B2838'}} className="styleDarkScrollSquare">
                        <HeadFont>Currently Supported Modes</HeadFont>
                        <img src={img1} alt="guide"/>
                        <ul>
                            <li>Volley, Soccer, and Hoops</li>
                            <li>Up to 4 versus 4</li>
                        </ul>
                        <Divider/>
                        <HeadFont>Equips make your slime bigger, faster, and stronger</HeadFont>
                        <img src={img2} alt="guide"/>
                        <ul>
                            <li>Stats include Size, Jump Duration, Jump Power, Acceleration, Max Speed, and Nimbleness</li>
                            <li>There's also a standard mode available which makes all equips strictly cosmetic</li>
                        </ul>
                        <Divider/>
                        <HeadFont>Extreme Customization</HeadFont>
                        <img src={img3} alt="guide"/>
                        <ul>
                            <li>4096 Possible Item color drops</li>
                            <li>Tons of unique faces, caps, and skins</li>
                        </ul>
                        <Divider/>
                        <HeadFont>Democratically Elect Rule Modifications</HeadFont>
                        <img src={img4} alt="guide"/>
                        <ul>
                            <li>Divide the court with a center wall to keep players on their side</li>
                            <li>Have a random ball size for each round in a match</li>
                            <li>Allow players to tumble and roll</li>
                            <li>And More!</li>
                        </ul>
                        <Divider/>
                        <HeadFont>Flash your fruit in RANKED PLAY as you advance to new divisions</HeadFont>
                        <ul><li>Will you be a lowly potato.. or rise to be a conquering cherry?</li></ul>
                        <img src={img5} alt="guide"/>
                        <Divider/>
                        <HeadFont>Item Color Level System</HeadFont>
                        <img src={img6} alt="guide"/>
                        <Divider/>
                        <HeadFont>Online Co-op Play with Friends</HeadFont>
                        <img src={img7} alt="guide"/>
                        <Divider/>
                        <HeadFont>GamePad Support (XBOX/PS3/PS4)</HeadFont>
                        <img src={img8} alt="guide"/>
                        <Divider/>
                        {!isElectron() &&
                            <>
                                <HeadFont>Premium Supporter ❤</HeadFont>
                                <ul>
                                    <li>Private Matches against friends (only squad leader needs premium to initiate)</li>
                                    <li>Better drop rates for high level items</li>
                                </ul>
                            </>
                        }
                        <Divider/>
                        <HeadFont>What else?</HeadFont>
                        <ul>
                            <li>Achievements unlock certain items</li>
                            <li>Watch Live Matches in real-time</li>
                            <li>Make bets and win slime coin against other players</li>
                            <li>Buy items in the market with your match winnings</li>
                        </ul>
                        <Divider/>
                        <HeadFont>Frequently Asked Questions</HeadFont>
                        <ul>
                            <li>Why is Mac/Linux not supported as a desktop application?</li>
                            <div>Platform builds and deployments are not a wise time investment at the moment. Players on these platforms can just open a web browser https://slime.lol</div>
                        </ul>
                    </StyleList>
                </SlimePan>
            </Page>
        )
    }
}