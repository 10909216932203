import React, {useEffect, useState} from 'react'
import FeedbackMount from "./FeedbackMount";
import {useDispatch, useSelector} from 'react-redux';
import {animated, useSpring} from "react-spring";
import {updateLoginToast} from "../../redux/modules/socket/socketActions";
import {playSound} from "../../data/sound";

export const LoginFeedback = () => {
    const dispatch = useDispatch();
    const loginToast = useSelector(state => state.socket.loginToast);
    const [toastMessage, setToastMessage] = useState('');
    const springStyle = useSpring({
        opacity: loginToast ? 1 : 0
    });

    useEffect(() => {
        if (loginToast) {
            setToastMessage(loginToast);
            setTimeout(() => {
                dispatch(updateLoginToast(null));
            }, 3000);
        }
    }, [loginToast]);

    return (
        <animated.div style={springStyle}>
            <FeedbackMount
                styles={{margin: '.5em 0'}}
                alertValue={toastMessage}
            />
        </animated.div>
    )
};

export default LoginFeedback;