
const CenterWall = (context,modifiers) => {
    const centerWallActive = modifiers['Center Wall'].status === 'on';
    if (centerWallActive) {
        context.fillStyle = "rgba(31, 31, 31, .5)";
        let centerWallWidth = 15;
        context.fillRect(1920 / 2 - (centerWallWidth / 2), -1, centerWallWidth, 898);
    }
};

export default CenterWall;