import React, { useState, useEffect } from 'react';
import Header from "../../component/Header/Header";
import Page from "../../styled/Page";
import SlimePan from "../../styled/SlimePan";
import palette from '../../styled/Palette';
import { useSpring, animated, config  } from "react-spring";
import {getDivision, rank} from '../../data/rating';
import { masterSocket } from "../../redux/middleware/wsMaster";
import {useDispatch, useSelector} from 'react-redux';
import loader from "../../images/load2.svg";
import {sports} from "../../data/globals";
import Metrics from "./Metrics";
import Avatar from "../../component/Avatar/Avatar";
import Badges from "../../component/Badges/Badges";
import {color} from "../../data/format";
import {drawerToggle} from "../../redux/modules/socket/socketActions";
import ProfileEquipped from "./ProfileEquipped/ProfileEquipped";
import {
    FeatsUserInfoDiv,
    FeatsUserDetailDiv,
    BodyDiv,
    FeatsUserDetailTitleText,
    FeatsUserDetailValueText
} from "./FeatsStyle";
import {PageTypes} from "../../system/types";

const Feats = ({history}) => {
    const dispatch = useDispatch();
    const { username, starterColor } = useSelector(state => state.self);
    const profile = useSelector(state => state.socket.profile);
    const [stats, setStats] = useState(null);
    const [playerBestSport, setPlayerBestSport] = useState(null);
    const [date, setDate] = useState({
        accountCreation: '',
        lastOnline: '',
    });
    const [powerLevel,setPowerLevel] = useState(0);
    const [opening,setOpening] = useState(false);
    const contentProps = useSpring({
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: opening ? '0' : '100%',
        config: { duration: 150 },
    });

    const equippedPaint = profile?.outfit?.paint;

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    useEffect(() => {
       if (profile) {
           const wins = sports.map(sport => profile.metrics.win[sport]);
           const rankedWins = sports.map(sport => profile.metrics.rankedWin[sport]);
           const losses = sports.map(sport => profile.metrics.lose[sport]);
           const rankedLoss = sports.map(sport => profile.metrics.rankedLose[sport]);

           const numWins = wins.reduce(reducer);
           const numRankedWins = rankedWins.reduce(reducer);
           const numLosses = losses.reduce(reducer);
           const numRankedLosses = rankedLoss.reduce(reducer);

           // We ignore the achievements which are actually not good from the 'high achiever' requirement
           const goodAchievments = profile.achievements.filter(a => !['loseStreak1','loserChallenger1'].includes(a.achievementName));
           const numberOfAchievements = goodAchievments.length;
           const numberAchievementsCompleted = goodAchievments.filter(achievement => achievement.completed === true).length;
           const hasAllAchievements = numberAchievementsCompleted === numberOfAchievements;

           setStats({
               totalWin: numWins,
               totalLoss: numLosses,
               totalRankedWin: numRankedWins,
               totalRankedLose: numRankedLosses,
               streak10: profile.metrics.streakWin > 10,
               streak30: profile.metrics.streakWin > 30,
               hasAllAchievements: hasAllAchievements
           });

            const playerBestSport = () => {
                const { volley, soccer, hoops } = profile.ratings;
                const ratings = [volley,soccer,hoops];
                const highestRating = Math.max(...ratings);
                const index = ratings.indexOf(highestRating);
                return ['volley','soccer','hoops'][index];
           };
           setPlayerBestSport(playerBestSport());

           setDate({
               accountCreation: new Date(profile.memberInfo.dateCreated).toLocaleDateString('en-US').toString(),
               lastOnline: new Date(profile.memberInfo.lastLogin).toLocaleDateString('en-US').toString()
           });

           const r = (a, c) => a + c;
           const powerLevels = Object.keys(profile.outfit)
               .map(slot => profile.outfit[slot])
               // This was my fix for bugReport#201. I suppose there are items missing the stats attribute
               // This should be ensured on the server side.
               // I'm not sure if my fix worked because I can't reproduce the issue,
               // so we will have to look out for duplicate reports after we deploy this fix attempt.
               .map(item => item ? item.stats ? item.stats : {} : {})
               .map(stats => Object.values(stats))
               .map(statArray => statArray.length > 0 ? statArray.reduce(r) : 0);

           // The total power level for each item slot
           setPowerLevel(powerLevels.reduce(r))
       }
    }, [profile]);

    useEffect(() => {
        setOpening(true);
        dispatch(drawerToggle({open: true}));
        let user;
        if (history.location.state) {
            user = { username: history.location.state.username };
        } else {
            user = { username: username};
        }

        masterSocket.send(JSON.stringify({
            'event': 'getPlayerProfile',
            'username': user.username,
        }));
    }, []);


    if (!profile) return null;
    const displayScheme = [
        {
            title: 'Username',
            value: profile.username
        },
        {
            title: 'Power level',
            value: powerLevel
        },
        {
            title: 'Member since',
            value: date.accountCreation === '12/31/1969' ? 'A while' : date.accountCreation
        },
        {
            title: 'Last online',
            value: date.lastOnline === '12/31/1969' ? 'A while' : date.lastOnline
        },
        {
            title: 'Best career division',
            value: <div style={{display:'flex',justifyContent: 'flex-end'}}><img style={{width: '12%'}} src={getDivision(profile.ratings[playerBestSport]).image}/></div>
        }
    ];
    return (
        <animated.div style={contentProps}>
            <Page>
                <Header title="PROFILE" closeRoute={PageTypes.MAIN} />
                <SlimePan style={{display: 'flex', color: palette.base4}}>
                    <BodyDiv className="styleDarkScrollSquare">
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <FeatsUserInfoDiv>
                                    {profile &&
                                    <>
                                        <ProfileEquipped profile={profile} />
                                        <div style={{display: 'flex', alignItems: 'center', flexGrow: 1, position: 'relative', justifyContent: 'center', width: '100%'}}>
                                            <div style={{display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '2em', top: '2em', width: '100%'}}>
                                                <Badges
                                                    profile={profile}
                                                    stats={stats}
                                                />
                                            </div>
                                            <div>
                                                {
                                                    profile.outfit ?
                                                        <Avatar
                                                            equippedPaint={equippedPaint}
                                                            size={'8em'}
                                                            hue={color(profile.outfit.paint.colorHex,'light')}
                                                            outfit={profile.outfit}
                                                            starterColor={starterColor}
                                                            history={history}
                                                        />
                                                        :
                                                        <img
                                                            style={{width: '2em'}}
                                                            src={loader}
                                                            alt="Loading"
                                                        />
                                                }
                                            </div>
                                        </div>
                                        <FeatsUserDetailDiv>
                                            <ul>
                                                {displayScheme.map(metric => (
                                                    <li>
                                                        <FeatsUserDetailTitleText>
                                                            {metric.title}
                                                        </FeatsUserDetailTitleText>
                                                        <FeatsUserDetailValueText>
                                                            {metric.value}
                                                        </FeatsUserDetailValueText>
                                                    </li>
                                                ))}
                                            </ul>
                                        </FeatsUserDetailDiv>
                                    </>
                                    }
                                </FeatsUserInfoDiv>
                            </div>
                            <Metrics
                                dispatch={dispatch}
                                stats={stats}
                                profile={profile}
                            />
                        </div>
                    </BodyDiv>
                </SlimePan>
            </Page>
        </animated.div>
    )
};

export default Feats;