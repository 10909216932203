import {createReducer} from "@reduxjs/toolkit";
import gamepadInitialState from "./gamepadInitialState";

const gamepadReducers = createReducer(gamepadInitialState, {
    GAMEPAD_UPDATE: (state, action) => {
        const { button, pressed } = action.payload;
        state[button] = pressed;
    },
    GAMEPAD_TOGGLE_CONNECTED: (state,action) => {
        state.connected = action.payload;
    },
    GAMEPAD_TOGGLE_NAVIGATION: (state,action) => {
        state.navigating = action.payload;
    },
});

export default gamepadReducers;