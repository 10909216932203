import React from 'react';
import styled from 'styled-components';
import palette from './Palette';

export const STANDARD_BUTTON_WIDTH = '160px';
const ButtonButton = styled.button`
  pointer-events: ${props => props.isDisabled && 'none'};
  
  color: ${palette.base5};
  background-color: ${props => props.isDisabled ? props.color1 : props.color2};
  border-bottom:  ${props => props.isDisabled ? `4px solid ${props.color3}` : `4px solid ${props.color4}`};
  justify-content: center;
  margin: 1px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  .icon { 
    font-size: 20px;
  }
  &:hover, :focus {
    ${props => !props.isDisabled && `margin-top: 2px; border-bottom: 3px solid ${props.color4}`};
  }
  &:active {
    ${props => !props.isDisabled && `margin-top: 4px; border-bottom: 1px solid ${props.color4}`};
  }
  &:focus {
    background-color: ${props => props.color4} !important;
  }
  
`;



export default (props) => {
    let color1,color2,color3, color4;
    if (props.color === 'purple') {
        color1 = palette.wisteria;
        color2 = palette.amethyst;
        color3 = palette.wisteria;
        color4 = palette.wisteria;
    } else if (props.color === 'green') {
        color1 = '#239954';
        color2 = palette.emerald;
        color3 = '#1c7a43';
        color4 = palette.nephritis;
    } else if (props.color === 'red') {
        color1 = palette.pomegranate;
        color2 = palette.alizarin;
        color3 = palette.alizarin;
        color4 = palette.pomegranate;
    } else if (props.color === 'gold') {
        color1 = palette.orange;
        color2 = palette.sunFlower;
        color3 = palette.sunFlower;
        color4 = palette.orange;
    }else {
        color1 = palette.wetAsphalt;
        color2 = palette.peterRiver;
        color3 = palette.midnightBlue;
        color4 = palette.belizeHole;
    }

    return (
        <ButtonButton
            className={props.modalOption ? 'modalOption':''}
            tabIndex={0}
            color={props.color}
            color1={color1} color2={color2} color3={color3} color4={color4}
            isDisabled={props.isDisabled}
            onClick={(e) => props.click ? props.click(e) : null}
            style={props.styles}
        >
            {props.content}
            {props.icon && <div className="icon">{props.icon}</div>}
            {props.title && <div style={props.icon ? {marginLeft: '1em'} : {}}>{props.title}</div>}
        </ButtonButton>
    );
}
