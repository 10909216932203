import React from 'react';
import {BadgesDiv} from "./BadgesStyle";
import badgeAchievements from "./Type/badgeAchievement";
import badgeMod from "./Type/badgeMod";
import badgeHardcore from "./Type/badgeHardcore";
import badgePremium from "./Type/badgePremium";

const Badges = ({ profile, stats }) => {
    const config = { tension: 300, friction: 15 };
    const initialStyles = { opacity: 0, transform: "scale(0.5)" };

    return (
        <BadgesDiv>
            {badgeMod(initialStyles,config, profile, stats)}
            {badgeAchievements(initialStyles,config, profile, stats)}
            {badgePremium(initialStyles,config, profile, stats)}
        </BadgesDiv>
    )
};
export default Badges;

//{badgeHardcore(initialStyles,config, profile, stats)}