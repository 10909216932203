import styled from 'styled-components';
import palette from "../../../styled/Palette";

export const UserBannerDiv = styled.div`
    position: relative;
    overflow: hidden;
    
    font-size: 1.25em;
    height: 4em;
    min-height: 4em;
    padding: 0 .5em;

    display: flex;
    flex-grow: 1;
    align-items: center;
    
    background-color: ${palette.base0};
    
    &:hover, :focus {
        background-color: ${palette.base7};
        svg {
            transform: scale(1.25);
        }
    }
`;

export const UserBannerIconDiv = styled.div`
    color: ${props => props.color};
    width: 3em;
    svg {
        font-size: 1.5em;
    }
`;
export const UserBannerNameDiv = styled.div`
    color: ${props => props.color ? props.color : palette.base2};
    font-size: 1.2em;
    text-align: left;
`;
export const UserBannerOptionsDiv = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    div:focus {
        background-color: rgba(0,0,0,0) !important;
    }
    > div {
        
        margin: 0 .25em !important;
        :not(:hover, :focus) {
            color: gray !important;
        }
    }
`;