import React from 'react';
import styled from 'styled-components';
import palette from "../../styled/Palette";

export const AtlasBannerContainerDiv = styled.div`
    height: 21.5vh;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    font-family: Luckiest Guy;
`;
export const AtlasBannerCurrentSeasonDiv = styled.div`
`;
export const AtlasBannerSeasonDiv = styled.div`
    color: ${palette.base4};
    font-size: 3em;
`;
export const AtlasBannerProgressDiv = styled.div`
    position: relative;
    border: 1px solid ${palette.base4};
    color: ${palette.base4};
    font-size: 3em;
    width: 8em;
    height: .25em;
    border-radius: 2px;
    margin: .2em 0;
`;
export const AtlasBannerProgressBarDiv = styled.div`
    width: ${props => props.progress}%;
    position: absolute;
    background-color: ${palette.base4};
    color: ${palette.base4};
    font-size: 3em;
    height: 100%;
`;
export const AtlasBannerDatesDiv = styled.div`
    color: ${palette.base2};
    font-size: 1.5em;
`;