import {createAction} from "@reduxjs/toolkit";

// Initialize the shop with all items
export const fillShop = createAction('FILL_SHOP');
// Respond to updates such as an item being sold
export const updateShop = createAction('UPDATE_SHOP');
// The unfortunate way we're updating the iconSrc for a particular shop item
export const setShopCanvas = createAction('SET_SHOP_CANVAS');

export const setActiveTab = createAction('SET_ACTIVE_TAB');
