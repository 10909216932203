import {createAction} from "@reduxjs/toolkit";

export const drawerToggle = createAction('DRAWER_TOGGLE');

export const users = createAction('USERS');
export const disconnectReason = createAction('DISCONNECT_REASON');
export const updateLoginToast = createAction('UPDATE_LOGIN_TOAST');
export const updateProfile = createAction('UPDATE_PROFILE');
export const updatePing = createAction('UPDATE_PING');
export const setBackdrop = createAction('BACKDROP');
export const rooms = createAction('ROOMS');
export const updateLoadingStatus = createAction('UPDATE_LOADING_STATUS');
export const updateLoadingState = createAction('UPDATE_LOADING_STATE');
export const updateAssets = createAction('UPDATE_ASSETS');

export const socketSetServerList = createAction('SOCKET_SET_SERVER_LIST');
export const socketUpdateAcceptableServers = createAction('SOCKET_UPDATE_ACCEPTABLE_SERVERS');
export const socketServerRemoveOverride = createAction('SOCKET_SERVER_REMOVE_OVERRIDE');

export const socketSignInPhase = createAction('SOCKET_SIGN_IN_PHASE');

export const socketMasterConnected = createAction('SOCKET_MASTER_CONNECTED');

export const socketCooldown = createAction('SOCKET_COOLDOWN');

