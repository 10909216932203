import React from "react";

const achievements = {
    challenger1: {
        description: 'One total win',
        levels: 4
    },
    challenger2: {
        description: 'Ten total wins',
        levels: 4
    },
    challenger3: {
        description: 'One Hundred total wins',
        levels: 4
    },
    challenger4: {
        description: 'One Thousand total wins',
        levels: 4
    },
    streaker1: {
        description: 'Ten win streak',
        levels: 2
    },
    streaker2: {
        description: 'Thirty win streak',
        levels: 2
    },
    jack1: {
        description: 'Five wins in all game modes',
        levels: 3
    },
    jack2: {
        description: 'Twenty Five wins in all game modes',
        levels: 3
    },
    jack3: {
        description: 'One Hundred wins in all game modes',
        levels: 3
    },
    rare1: {
        description: 'Found one rare face drop',
        levels: 3
    },
    rare2: {
        description: 'Found multiple rare face drops',
        levels: 3
    },
    rare3: {
        description: 'Found all rare face drops',
        levels: 3
    },
    ability1: {
        description: 'Found One ability',
        levels: 4,
    },
    ability2: {
        description: 'Found Five abilities',
        levels: 4,
    },
    ability3: {
        description: 'Found Ten abilities',
        levels: 4,
    },
    ability4: {
        description: 'Found Twenty-Five abilities',
        levels: 4,
    },
};

export default achievements;