import { createSnowballParticles } from '../particles/snowballParticles';
import { createPotionParticles } from '../particles/potionParticles';
import { createReverseBallParticles } from '../particles/reverseBallParticles';
import { createVanishingPowderParticles } from '../particles/vanishingPowderParticles';
import { createFocusJumpParticles } from '../particles/focusJumpParticles';
import { createWindParticles } from '../particles/windParticles';
import { createLevitateParticles } from '../particles/levitateParticles';
import { createSpinnerParticles } from '../particles/spinnerParticles';
import { createGhostParticles } from '../particles/ghostParticles';
import { createOrbParticles } from '../particles/orbParticles';
import { createMusicParticles } from '../particles/musicParticles';
import { createBubblePopParticles } from '../particles/bubblePopParticles';
import { createRockParticles } from '../particles/rockParticles';
import {
    createGrenadeExplosionParticles,
    createGrenadeTickParticles,
} from '../particles/grenadeParticles';
import { createJetPackParticles } from '../particles/jetPackParticles';
import { clipArc } from './arc';
import { imageAssets } from '../../data/images';
import { createVacuumParticles } from '../particles/vacuumParticles';
import { createMagnetParticles } from '../particles/magnetParticles';
import { createAirJumpParticles } from '../particles/airJumpParticles';
import { createSpeedPotionParticles } from '../particles/speedPotionParticles';
import { sandboxCalculateBallTrajectory } from '../../system/physics/physics.js';

function abilityIsActive(player, ability) {
    return player.activeAbility === ability && player.abilities[ability]?.active;
}

let offscreen = new OffscreenCanvas(1920, 900);
let osContext = offscreen.getContext('2d');
let counter = 0;
let calculatedBallPositions = [];
export let RenderAbilityAfterAll = (lobby, context, serverTime, team, teamIndex) => {
    let flashlightOptions = {
        litPlayers: [],
        mostRecentEndTimeFade: null,
        oldestStartTime: null,
        flashlightActive: false,
        lightsOut: false,
        fadeTime: 500,
    };
    lobby.teams.teamA.concat(lobby.teams.teamB).forEach((player) => {
        if (abilityIsActive(player, 'bubble')) {
            let bubble = player.abilities.bubble;
            let bubblePercentage = (serverTime - bubble.startTime) / bubble.blowTime;
            if (bubblePercentage > 1) bubblePercentage = 1;
            let bubbleSize = bubble.bubbleSize * bubblePercentage;
            let bubbleImage = imageAssets.Other.Bubble.base;
            context.drawImage(
                bubbleImage,
                player.position.x - bubbleSize / 2,
                player.position.y - bubbleSize / 2,
                bubbleSize,
                bubbleSize
            );
        }
        if (abilityIsActive(player, 'music')) {
            let bass = imageAssets.Other['Bass Guitar'].base;
            let bassSize = 80;
            context.drawImage(
                bass,
                player.position.x - bassSize - 20,
                player.position.y - bassSize / 2 + 20,
                bassSize * 2,
                bassSize
            );
        }
        if (
            (player.activeAbility === 'flashlight' && player.abilities.flashlight?.active) ||
            (player.abilities.flashlight?.active === false &&
                player.abilities.flashlight?.endTime + flashlightOptions.fadeTime > serverTime)
        ) {
            let flashlight = player.abilities['flashlight'];
            flashlightOptions.flashlightActive = true;
            if (flashlight.active) {
                flashlightOptions.litPlayers.push(player);
                flashlightOptions.lightsOut = true;
            } else if (flashlight.endTime + flashlightOptions.fadeTime > serverTime) {
                flashlightOptions.litPlayers.push(player);
            }
            if (
                flashlight.endTime > flashlightOptions.mostRecentEndTimeFade ||
                (flashlight.endTime && flashlightOptions.mostRecentEndTimeFade === null)
            )
                flashlightOptions.mostRecentEndTimeFade = flashlight.endTime;
            if (
                flashlight.startTime < flashlightOptions.oldestStartTime ||
                (flashlight.startTime && flashlightOptions.oldestStartTime === null)
            )
                flashlightOptions.oldestStartTime = flashlight.startTime;
        }
        if (abilityIsActive(player, 'pause')) {
            let randomValue = Math.floor(Date.now() / 3) % 1200;
            context.lineWidth = 150;
            context.fillStyle = '#000';
            context.beginPath();
            context.moveTo(0, -150 + randomValue);
            context.lineTo(1920, -150 + randomValue);
            context.stroke();

            context.textBaseline = 'middle';
            context.miterLimit = 2;
            context.lineJoin = 'circle';

            context.font = '100px VT323';
            context.fillStyle = '#FFF';
            context.textAlign = 'left';
            context.fillText('PAUSE ||', 100, 100);
            context.textAlign = 'right';
            context.fillText(`00:00:00`, 1920 - 100, 100);
            context.textAlign = 'left';
            let minutes = new Date().getMinutes().toString();
            if (minutes.length < 2) minutes = '0' + minutes;
            context.fillText(`${new Date().getHours() % 12}:${minutes}`, 100, 900 - 200);
            context.fillText('Jan. 01 1970', 100, 900 - 100);
        }
        if (abilityIsActive(player, 'timeWarp')) {
            let timeWarp = player.abilities.timeWarp;
            if (timeWarp.direction === 'forward') {
                let genNumber = (speedNumber, mod) => {
                    let num = (Math.floor(serverTime / speedNumber) % mod).toString();
                    if (num.length < 2) num = '0' + num;
                    return num;
                };
                let hourCounter = genNumber(4000, 60);
                let minCounter = genNumber(250, 60);
                let secCounter = genNumber(20, 99);
                let randomValue = Math.floor(serverTime / 3) % 1200;
                context.lineWidth = 150;
                context.fillStyle = '#000';
                context.beginPath();
                context.moveTo(0, -150 + randomValue);
                context.lineTo(1920, -150 + randomValue);
                context.stroke();
                context.font = '100px VT323';
                context.fillStyle = '#FFF';
                context.textAlign = 'left';
                context.fillText('FF >>', 100, 100);
                context.textAlign = 'right';
                context.fillText(`${hourCounter}:${minCounter}:${secCounter}`, 1920 - 100, 100);
                context.textAlign = 'left';
                let minutes = new Date().getMinutes().toString();
                if (minutes.length < 2) minutes = '0' + minutes;
                context.fillText(`${new Date().getHours() % 12}:${minutes}`, 100, 900 - 200);
                context.fillText('Jan. 01 1970', 100, 900 - 100);
            }
            if (timeWarp.direction === 'backward') {
                let genNumber = (speedNumber, mod) => {
                    let num = Math.abs(
                        mod - (Math.floor(Date.now() / speedNumber) % mod)
                    ).toString();
                    if (num.length < 2) num = '0' + num;
                    return num;
                };
                let hourCounter = genNumber(12000, 60);
                let minCounter = genNumber(4000, 60);
                let secCounter = genNumber(80, 99);
                let randomValue = Math.abs(1200 - (Math.floor(Date.now() / 3) % 1200));
                context.lineWidth = 150;
                context.fillStyle = '#000';
                context.beginPath();
                context.moveTo(0, -150 + randomValue);
                context.lineTo(1920, -150 + randomValue);
                context.stroke();
                context.font = '100px VT323';
                context.fillStyle = '#FFF';
                context.textAlign = 'left';
                context.fillText('REWIND <<', 100, 100);
                context.textAlign = 'right';
                context.fillText(`${hourCounter}:${minCounter}:${secCounter}`, 1920 - 100, 100);
                context.textAlign = 'left';
                let minutes = new Date().getMinutes().toString();
                if (minutes.length < 2) minutes = '0' + minutes;
                context.fillText(`${new Date().getHours() % 12}:${minutes}`, 100, 900 - 200);
                context.fillText('Jan. 01 1970', 100, 900 - 100);
            }
        }
    });
    if (flashlightOptions.flashlightActive) {
        osContext.clearRect(0, 0, 1920, 900);
        osContext.fillStyle = '#000';
        let alpha;
        if (flashlightOptions.lightsOut) {
            alpha = (serverTime - flashlightOptions.oldestStartTime) / 500;
        }
        if (alpha > 1) alpha = 1;

        if (
            flashlightOptions.lightsOut === false &&
            flashlightOptions.mostRecentEndTimeFade + 500 > serverTime
        ) {
            alpha = 1 - (serverTime - flashlightOptions.mostRecentEndTimeFade) / 500;
        }
        alpha *= 0.98;
        osContext.globalAlpha = alpha;
        osContext.fillRect(0, 0, 1920, 900);
        flashlightOptions.litPlayers.forEach((player) => {
            clipArc(osContext, player.position.x, player.position.y, 200, 25);
        });
        context.drawImage(offscreen, 0, 0);
    }
};

export let RenderAbility = (lobby, context, team, teamIndex, currentTime) => {
    lobby.abilityEffects.forEach((abilityEffect, index) => {
        if (abilityEffect.name === 'ghost') {
            createGhostParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'airJump') {
            createAirJumpParticles(
                lobby,
                abilityEffect.team,
                abilityEffect.teamIndex,
                abilityEffect.radius,
                abilityEffect.color
            );
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'speedPotion') {
            createSpeedPotionParticles(
                lobby,
                abilityEffect.team,
                abilityEffect.teamIndex,
                abilityEffect.radius,
                abilityEffect.color
            );
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'magnet') {
            createMagnetParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'music') {
            createMusicParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'vacuum') {
            createVacuumParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'rock') {
            if (!abilityEffect.createdParticles) {
                abilityEffect.createdParticles = true;
                abilityEffect.startTime = Date.now();
                createRockParticles(
                    abilityEffect.position,
                    abilityEffect.width,
                    abilityEffect.height,
                    abilityEffect.raiseTime,
                    abilityEffect.waitTime,
                    abilityEffect.raiseAmount
                );
            } else {
                let startTime = abilityEffect.startTime;
                let totalTime = abilityEffect.totalTime;
                let image = imageAssets.Other.Rock.base;
                let position = abilityEffect.position;
                let raiseTime = abilityEffect.raiseTime;
                let waitTime = abilityEffect.waitTime;
                let amount = abilityEffect.raiseAmount;
                let width = abilityEffect.width;
                let height = abilityEffect.height;
                let x1 = position.x - width / 2;
                let y1 = -(((Date.now() - startTime) / raiseTime) * amount) + 900;
                if (y1 < 900 - height) {
                    y1 = 900 - height;
                }
                if (Date.now() > startTime + raiseTime + waitTime) {
                    let lowerTime = startTime + raiseTime + waitTime;
                    y1 = ((Date.now() - lowerTime) / raiseTime) * amount + 900 - height;
                }
                context.drawImage(image, x1, y1, width, height);
                if (Date.now() > startTime + totalTime) {
                    lobby.abilityEffects.shift();
                }
            }
        }
        if (abilityEffect.name === 'bubblePop') {
            createBubblePopParticles(abilityEffect.position, abilityEffect.size);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'jetPack') {
            createJetPackParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'orb') {
            createOrbParticles(
                lobby,
                abilityEffect.id,
                abilityEffect.team,
                abilityEffect.teamIndex
            );
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'wind') {
            createWindParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'grenadeTick') {
            createGrenadeTickParticles(lobby, abilityEffect.id);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'grenadeExplosion') {
            createGrenadeExplosionParticles(abilityEffect.position);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'vanishingPowder') {
            createVanishingPowderParticles(abilityEffect.position, abilityEffect.radius);
            lobby.abilityEffects.shift();
        }
        if (['consumePotion', 'fadePotion'].includes(abilityEffect.name)) {
            let player = lobby.teams[abilityEffect.team][abilityEffect.teamIndex];
            createPotionParticles(player, abilityEffect.potion, abilityEffect.name);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'reverseBall') {
            createReverseBallParticles(lobby.ball);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'focusJump') {
            createFocusJumpParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'levitate') {
            createLevitateParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'spinner') {
            createSpinnerParticles(lobby, abilityEffect.team, abilityEffect.teamIndex);
            lobby.abilityEffects.shift();
        }
        if (abilityEffect.name === 'dash') {
            let player = lobby.teams[abilityEffect.team][abilityEffect.teamIndex];
            if (currentTime - abilityEffect.time < abilityEffect.fadeInDuration) {
                context.globalAlpha = 1;
            } else if (
                currentTime - abilityEffect.time <
                abilityEffect.fadeInDuration + abilityEffect.fadeOutDuration
            ) {
                let opacity =
                    (currentTime - (abilityEffect.time + abilityEffect.fadeInDuration)) /
                    abilityEffect.fadeOutDuration;
                context.globalAlpha = 1 - opacity;
            } else {
                lobby.abilityEffects.shift();
                context.globalAlpha = 0;
            }
            context.lineWidth = 1;
            context.strokeStyle = '#fff';
            let lineWidth = Math.abs(abilityEffect.startPosition.x - player.position.x);
            let maxLineWidth = 75 + (player.stats.size * 180) / 2;
            let startPositionX = abilityEffect.startPosition.x;
            let direction = 'right';
            if (abilityEffect.startPosition.x > player.position.x) {
                direction = 'left';
            }
            if (lineWidth > maxLineWidth) {
                if (direction === 'left') {
                    startPositionX = player.position.x + maxLineWidth;
                } else {
                    startPositionX = player.position.x - maxLineWidth;
                }
            }

            //top trail
            context.beginPath();
            context.moveTo(startPositionX, player.position.y - (player.stats.size * 100) / 4);
            context.lineTo(player.position.x, player.position.y - (player.stats.size * 100) / 4);
            context.lineWidth = 1;
            context.stroke();
            //middle trail
            context.beginPath();
            context.moveTo(startPositionX + (direction === 'right' ? -25 : 25), player.position.y);
            context.lineTo(player.position.x, player.position.y);
            context.lineWidth = 1;
            context.stroke();
            //bottom trail
            context.beginPath();
            context.moveTo(startPositionX, player.position.y + (player.stats.size * 100) / 4);
            context.lineTo(player.position.x, player.position.y + (player.stats.size * 100) / 4);
            context.stroke();
            context.lineWidth = 1;
            context.globalAlpha = 1;
        }
        if (abilityEffect.name === 'snowball') {
            lobby.abilityEffects.shift();
            createSnowballParticles(abilityEffect.position);
        }
    });
    lobby.teams.teamA.concat(lobby.teams.teamB).forEach((player) => {
        if (abilityIsActive(player, 'web')) {
            context.lineWidth = 8;
            context.strokeStyle = '#fff';
            let webPoint = player.abilities['web'].webPoint;
            context.beginPath();
            context.moveTo(player.position.x, player.position.y);
            context.lineTo(webPoint.x, webPoint.y);
            context.stroke();
        }
        if (abilityIsActive(player, 'hook')) {
            context.lineWidth = 8;
            context.strokeStyle = '#fff';
            context.beginPath();
            let ball = lobby.ball;
            let point = player.abilities['hook'].hookPoint;
            context.moveTo(player.position.x, player.position.y);
            context.lineTo(point.x, point.y);
            context.stroke();
        }
        if (
            player.activeAbility === 'ballPath' &&
            player.team === team &&
            player.teamIndex === teamIndex
        ) {
            counter++;
            if (counter % 6 === 0) {
                let ability = player.abilities[player.activeAbility];
                if (ability) {
                    calculatedBallPositions = sandboxCalculateBallTrajectory(
                        ability.ballPosition,
                        ability.ballVelocity,
                        ability.ballRadius,
                        ability.ceiling,
                        ability.sport,
                        ability.netHeight
                    );
                } else {
                    calculatedBallPositions = [];
                }
            }

            context.fillStyle = '#fff';
            context.globalAlpha = 1;
            calculatedBallPositions.forEach((position, index) => {
                let length = calculatedBallPositions.length;
                if ((length - index) % 3 === 0) {
                    let randomX = Math.random() * 3;
                    let randomY = Math.random() * 3;
                    if (Math.random() > 0.5) randomX *= 1;
                    if (Math.random() > 0.5) randomY *= 1;
                    context.beginPath();
                    context.arc(position.x + randomX, position.y + randomY, 5, 0, 2 * Math.PI);
                    context.fill();
                }
            });
            context.globalAlpha = 1;
        }
    });
};
