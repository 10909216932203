import palette from "../../../styled/Palette";
import Tippy from "@tippyjs/react";
import Tooltip from "../../../styled/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useSpring} from "react-spring";

const badgeAchievements = (initialStyles,config,profile, stats) => {
    const [props2, setSpring2] = useSpring(() => initialStyles);
    function onMount2() {
        setSpring2({
            opacity: 1,
            transform: "scale(1)",
            onRest: () => {},
            config
        });
    }
    function onHide2({ unmount }) {
        setSpring2({
            ...initialStyles,
            onRest: unmount,
            config: { ...config, clamp: true }
        });
    }

    if (stats?.hasAllAchievements) {
        return (
            <div style={{
                color: palette.sunFlower,
                fontSize: '2em'
            }}>
                <Tippy
                    render={attrs => (
                        <Tooltip style={props2} {...attrs}>
                            <div style={{padding: '10px', display: 'flex', flexDirection: 'column'}}>
                                <div>HIGH ACHIEVER</div>
                            </div>
                        </Tooltip>
                    )}
                    animation={true}
                    onMount={onMount2}
                    onHide={onHide2}
                    hideOnClick={false}
                >
                    <div><FontAwesomeIcon icon={['fas', 'medal']} /></div>
                </Tippy>
            </div>
        )
    } else {
        return null;
    }
};

export default badgeAchievements;