import React from 'react';
import styled from 'styled-components';
import palette from "../../styled/Palette";

const AlertDiv = styled.div`
  background-color: ${palette.base7};
  cursor: default;
  color: ${palette.base4};
  padding: .5em 1em;
  font-weight: bold;
  font-size: 1.25em;
  width: 100%;
`;
const FeedbackMount = ({alertValue, styles}) => (
    <AlertDiv
        id="alertReference"
        style={styles}
    >
        {alertValue}
    </AlertDiv>
);

export default FeedbackMount;