import React, {useEffect, useRef, useState} from "react";
import {UserBannerDiv, UserBannerIconDiv, UserBannerNameDiv, UserBannerOptionsDiv} from "../UserBannerStyle";
import Icon from "../../../../data/icon";
import palette from "../../../../styled/Palette";
import {requestRemoveFriend} from "../../../../system/endpoints/master";
import {toggleAllNodes} from "../../../../gamepad/controller";
import {playSound} from "../../../../data/sound";

const InviteRequested = ({player,selected,setSelected,refBanner}) => {
    const refFirstOption = useRef();
    const refSecondOption = useRef();
    const refThirdOption = useRef();
    const [deleting, setDeleting] = useState(false);
    const [options,setOptions] = useState([]);
    const handleDeleting = () => {
        // Show the sub-options for the banner
        setDeleting(true);
    };
    const handleDeselect = () => {
        // Disable the banner as a selectable option
        refBanner.current.setAttribute("tabindex","0");
        // Show the sub-options for the banner
        setSelected(false);
        setDeleting(false);
    };
    const handleSelect = () => {
        // Show the sub-options for the banner
        setSelected(true);
        playSound('itemHover', 1);
    };

    /**
     * Only make the sub-options selectable once a banner is selected
     */
    useEffect(() => {
        if (options.length > 0) {
            toggleAllNodes(false);
            // Make the sub-options selectable
            options.forEach((option,i) => {
                option.setAttribute("tabindex","0");
                if (i === options.length-1) {
                    options[0].focus();
                }
            });
        }
    }, [options]);

    /**
     * Watch the view for changes to update the options focus
     */
    useEffect(() => {
        const temp = [];
        if (refFirstOption.current) temp.push(refFirstOption.current);
        if (refSecondOption.current) temp.push(refSecondOption.current);
        if (refThirdOption.current) temp.push(refThirdOption.current);
        setOptions(temp);
    }, [deleting,selected]);

    /**
     * When no longer selecting the banner, permit access to other banners
     */
    useEffect(() => {
        if (!selected) {
            toggleAllNodes(true);
        }
    }, [selected]);

    return (
        <UserBannerDiv
            key={player.username}
            onClick={() => !selected && handleSelect(refBanner)}
            ref={refBanner}
            selected={selected}
            tabIndex={0}
        >
            {!selected
            ? <>
                    <UserBannerIconDiv color={palette.belizeHole}>
                        {Icon('paper-plane')}
                    </UserBannerIconDiv>
                    <UserBannerNameDiv>
                        {player.username}
                    </UserBannerNameDiv>
                </>
            :
                <>
                    {!deleting ?
                        <>
                            <UserBannerIconDiv color={palette.belizeHole}>
                                {Icon('paper-plane')}
                            </UserBannerIconDiv>
                            <UserBannerNameDiv>
                                {player.username}
                            </UserBannerNameDiv>
                            <UserBannerOptionsDiv>
                                <div
                                    onClick={() => handleDeleting()}
                                    style={{color: palette.pomegranate}}
                                    className={'buttonZoom'}
                                    tabIndex={0}
                                    ref={refFirstOption}
                                >
                                    {Icon('skull')}
                                </div>
                            </UserBannerOptionsDiv>
                        </>
                    :
                        <div style={{display: 'flex', width: '100%'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{color: palette.base4}}>Remove Friend?</div>
                                <UserBannerNameDiv>{player.username}</UserBannerNameDiv>
                            </div>
                            <UserBannerOptionsDiv>
                                <div
                                    onClick={() => handleDeselect()}
                                    ref={refSecondOption}
                                    tabIndex={0}
                                    className={'buttonZoom'}
                                    style={{color: palette.pumpkin}}
                                >
                                    {Icon('undo-alt')}
                                </div>
                                <div
                                    onClick={() => requestRemoveFriend(player.username)}
                                    tabIndex={0}
                                    ref={refThirdOption}
                                    className={'buttonZoom'}
                                    style={{color: palette.pomegranate, marginRight: '.5em'}}
                                >
                                    {Icon('trash-alt')}
                                </div>
                            </UserBannerOptionsDiv>
                        </div>
                    }
                </>
            }

        </UserBannerDiv>
    )
};

export default InviteRequested;