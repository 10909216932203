import styled from 'styled-components';
import palette from '../../../styled/Palette';

export const ItemsDiv = styled.div`
    flex-basis: 0;
    flex-grow: 1;
    height: 100%;
`;

export const ItemsContainerDiv = styled.div`
    width: 100%;
    height: 100%;
`;