import React, {useEffect, useState} from 'react';
import Root from './component/Root'
import './App.scss';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fab,faDiscord } from '@fortawesome/free-brands-svg-icons';
import {
    far,
    faSpinnerThird,
    faCircle
} from 'slimefontawesome/fortawesome-pro-regular-svg-icons'
import {
    fas,
    faReply,
    faScrollOld,
    faBook,
    faSword,
    faSadCry,
    faGrinTongue, faTired, faMeh, faGrinWink,
    faGem, faTimes, faVolumeSlash, faPlay, faUser,
    faFutbol,faBasketballBall,faVolleyballBall,
    faPause, faForward, faCommentAlt, faSack, faTvRetro, faArchway, faTrophyAlt,
    faPowerOff,
    faBoxingGlove, faUserCheck, faUserTimes,
    faSkull, faUserPlus, faUserFriends,
    faUserCrown, faClock, faArrowAltRight,
    faCrown, faHeart, faEye, faRobot,
    faEnvelopeOpenText, faRabbitFast, faFistRaised,
} from 'slimefontawesome/fortawesome-pro-solid-svg-icons';
import {
    fal,
    faSwords,
    faGamepad,
    faCheckCircle
} from 'slimefontawesome/fortawesome-pro-light-svg-icons';
import initGoogleAnalytics from './initGoogleAnalytics';
import {htmlJoystick} from './gamepad/controller';
import {useDispatch, useSelector} from "react-redux";
import {createGlobalStyle } from 'styled-components';
import { isMobileDevice } from "./utility";
import {getOS} from "./data/generic/generic";
import MobileRedirect from "./page/MobileRedirect";

library.add(far, faSpinnerThird, faCircle, faSword, faBook, faScrollOld, faReply, faPowerOff, faFutbol,faBasketballBall,faVolleyballBall,faGrinWink, fab,faDiscord, fas, faGem,faSadCry, faTimes, faVolumeSlash, faPlay, faUser, faPause, faForward, faCommentAlt, fal, faGamepad, faSwords, faSack, faTvRetro, faArchway, faTrophyAlt, faBoxingGlove, faUserCheck, faUserTimes, faSkull, faUserPlus, faUserFriends, faUserCrown, faClock, faArrowAltRight, faCheckCircle, faTired, faMeh, faGrinTongue, faCrown, faHeart, faEye, faRobot, faCircle, faEnvelopeOpenText, faRabbitFast, faFistRaised);

// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch();

const App = ({history}) => {
    const dispatch = useDispatch();
    const isUsingGamepad = useSelector(state => state.gamepad.navigating);
    const [isMobile] = useState(isMobileDevice());

    useEffect(() => {
        // Run analytics tracker
        initGoogleAnalytics(history);
        // Kicks off xinput controller mapping integration
        htmlJoystick(dispatch,history);
    }, []);

    const GlobalStyle = createGlobalStyle`
        *:focus {
            background-color: rgba(0,0,0,.25) !important;
        }
    `;

    if (isMobile) {
        return (
            <MobileRedirect />
        )
    } else {
        return (
            <>
                {isUsingGamepad &&  <GlobalStyle />}
                <Root history={history} />
            </>
        )
    }
};
export default App