import React, {useEffect, useRef, useState} from 'react';
import {masterSocket} from "../../redux/middleware/wsMaster";
import { gameSocket } from '../../redux/middleware/wsMatch';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {devToggleConsole, devToggleRemote} from "../../redux/modules/dev/devActions";
import {devMode} from "../../data/globals";
import {
    ConsoleDiv,
    ConsoleHeaderDiv,
    ConsoleTextAreaDiv,
    ConsoleClearButton,
    ConsoleSendButton,
    ConsoleReceivedDiv,
    ConsoleClosedButton
} from './ServerConsoleStyle';
import palette from "../../styled/Palette";

const ServerConsole = () => {
    const dispatch = useDispatch();
    const dev = useSelector(state => state.dev);
    const [content, setContent] = useState('');
    const [messages, setMessages] = useState([]);
    const [width, setWidth] = useState(1024);
    const [height, setHeight] = useState(512);
    const [messageTypes,setMessageTypes] = useState({});
    const [filter,setFilter] = useState('None');
    const refTextArea = useRef();

    const listenerInstance = () => {
        const message = JSON.parse(event.data);
        if (
            !['ping','updateRooms','publicChatUserData', 'userMetrics'].includes(message.event)
            && !['updatePositions'].includes(message.event)
            ) {
            setMessages(messageCallback => [...messageCallback, JSON.stringify(message)]);
        }
    };

    const filterMessages = (messageName) => {
        setFilter(messageName);
    };

    // When messages learns about a new type, append that to a list
    useEffect(() => {
        messages.forEach(message => {
            const eventName = JSON.parse(message).event;
            if (!Object.keys(messageTypes).includes(eventName)) {
                setMessageTypes({
                    ...messageTypes,
                    [eventName]: message
                });
            }
        });
    }, [messages]);

    useEffect(() => {
        if (dev.remote) {
            if (dev.remote === 'master') {
                masterSocket.addEventListener("message", listenerInstance);
            } else if (dev.remote === 'game') {
                gameSocket.addEventListener("message", listenerInstance);
            }
        }
    }, [dev.remote]);

    useEffect(() => {
        return () => {
            if (dev.remote === 'master') {
                masterSocket.removeEventListener("message", listenerInstance);
            } else if (dev.remote === 'game') {
                gameSocket.removeEventListener("message", listenerInstance);
            }
        }
    }, []);

    if (devMode) {
        if (dev.console) {
            return (
                <ConsoleDiv
                    width={width > 512 ? width : 512}
                    height={height > 256 ? height : 256}
                >
                    <ConsoleHeaderDiv>
                        <div style={{display: 'flex', flexGrow: 1, alignItems: 'center'}}>
                            <div>Server Console</div>
                            <div style={{marginLeft: '2em', color: palette.base4}}>
                                Filter
                                <select onChange={(e) => filterMessages(e.target.value)}>
                                    <option>None</option>
                                    {Object.keys(messageTypes).map(type => {
                                        return (
                                            <option key={type}>
                                                {type}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <button onClick={() => dispatch(devToggleRemote())}>
                            {dev.remote}
                        </button>
                        <input
                            style={{width: '5em'}}
                            placeholder={width}
                            onChange={(e) => setWidth(e.target.value)}
                        />
                        <input
                            style={{width: '5em'}}
                            placeholder={height}
                            onChange={(e) => setHeight(e.target.value)}
                        />
                        <button onClick={() => dispatch(devToggleConsole())}>
                            <FontAwesomeIcon icon={['fas', 'window-maximize']}/>
                        </button>
                    </ConsoleHeaderDiv>
                    <div style={{display: 'flex', flexGrow: 1, overflow: 'hidden'}}>
                        <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                            <ConsoleTextAreaDiv
                                ref={refTextArea}
                                onChange={e => setContent(e.target.value)}
                                className="styleDarkScrollSquare"
                                placeholder={'Message to server..'}
                            />
                            <div style={{display: 'flex'}}>
                                <ConsoleClearButton onClick={() => setMessages([])}>
                                    Clear
                                </ConsoleClearButton>
                                <ConsoleSendButton onClick={() => {
                                    try {
                                        let msg = eval("(" + content + ')');
                                        if (dev.remote === 'master') {
                                            masterSocket.send(JSON.stringify(msg));
                                        } else if (dev.remote === 'game' &&  gameSocket) {
                                            gameSocket.send(JSON.stringify(msg));
                                        }
                                        refTextArea.current.value = '';
                                    }
                                    catch(e){
                                        console.log(e);
                                    }
                                }}>
                                    Send
                                </ConsoleSendButton>
                            </div>
                        </div>
                        <ConsoleReceivedDiv className="styleDarkScrollSquare">
                            {messages.map((message,i) => {
                                if (JSON.parse(message).event === filter || filter === 'None' ) {
                                    return (
                                        <div
                                            key={i}
                                            style={{margin: '1em'}}
                                        >
                                            {message}
                                        </div>
                                    )
                                }
                            })}
                        </ConsoleReceivedDiv>
                    </div>
                </ConsoleDiv>
            )
        } else {
            return (
                <ConsoleClosedButton onClick={() => dispatch(devToggleConsole())}>
                    <FontAwesomeIcon icon={['fas', 'window-maximize']}/>
                </ConsoleClosedButton>
            )
        }
    }
    return null;
};

export default ServerConsole;