import {createReducer} from "@reduxjs/toolkit";
import initialState from "./layoutInitialState";

const layoutReducers = createReducer(initialState, {
    DRAWER_TOGGLE: (state, action) => {
        const { open, view } = action.payload;
        if (typeof open !== 'undefined') state.drawer.open = open;
        if (typeof view !== 'undefined') state.drawer.view = view;
    },
    LAYOUT_TOGGLE_STEAM_DRAWER: (state) => {
        state.steamDrawerOpen = !state.steamDrawerOpen;
    }
});

export default layoutReducers;