
export const loadingTypes = {
    joiningMatch: 'JOINING MATCH',
    somethingWentWrong: 'MATCH CONNECTION FAILED'
};

export default {
    assetsLoaded: false,
    // The backdrop appearance
    backdrop: {
        floor: null
    },
    // Message to display when the disconnect page mounts
    disconnectReason: 'Connection to server was lost',
    // Flag to fade between screens
    loading: {
        state: false,
        status: null
    },
    // The clients ping
    ping: null,
    // Match data from other users games (not the client)
    rooms: [],
    profile: null,

    loginToast: null,
    signInPhase: 'login',

    // Whether the master server has connected
    master: false,

    // The server list that the master has bestowed upon the user
    acceptableServers: [],

    // All cooldowns in the app
    cooldown: {
        servers: false
    }
};