import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import TextUnderlineHover from "../../../styled/TextUnderlineHover";
import {PageTypes} from "../../../system/types";

export let PremiumPrivateMatchData;

const ModalPremiumPrivateMatch = (dispatch,theme,history) => {

    PremiumPrivateMatchData = (
        <div><div style={{padding: '1em'}}>
            Private matches are only available to players who have upgraded to premium. Premium status can be purchased from the&nbsp;
            <TextUnderlineHover
                theme={theme}
                bold={true}
                onClick={() => {
                    history.push(PageTypes.CASH_SHOP);
                    dispatch(modalDisable());
                }}
                style={{flexGrow: 1}}>
                CASH SHOP
            </TextUnderlineHover>
            .
        </div></div>
    );

    dispatch(modalEnable({
        body: 'PremiumPrivateMatch',
        title: "This is a Premium Exclusive"
    }));
};

export default ModalPremiumPrivateMatch;