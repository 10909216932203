import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import {leaveGame} from "../../../redux/middleware/wsMatchThunks";
import {PageTypes} from "../../../system/types";

const ModalMatchForfeit = (dispatch,teams,history) => {

    dispatch(modalEnable({
        accept: dispatch(modalDisable()),
        title: 'Leave match? This will count as a loss.',
        conditional: true,
        userAccept: () => {
            dispatch(leaveGame(teams));
            history.push(PageTypes.MAIN);
            dispatch(modalDisable());
        },
    }))
};

export default ModalMatchForfeit;