
export const badWords = [
    'anal', 'anus', 'arse','ballsack', 'balls', 'bastard', 'bitch', 'blowjob', 'blow job', 'boner',
    'buttplug', 'cock', 'crap', 'cum', 'damn', 'dick', 'dildo', 'fuk', 'fellatio', 'f u c k', 'fudgepacker',
    'fudge packer', 'homo', 'nigga', 'suck', 'piss', 'poop', 'prick', 'sex', 's hit', 'shlt', 'slut', 'whore', 'pedo',
    'pedophile', 'molest', 'asshole', 'tranny', 'transexual', 'virgin', 'poon', 'masturbate', 'fornicate', 'feces', 'butt',
    'nigger', 'faggot', 'fuck', 'smegma', 'clitoris', 'jizz', 'labia', 'cunt', 'vagina', 'penis', 'pussy', 'queer',
    'pube', 'scrotum', 'dyke', 'fag', 'shit', 'flange', 'douche', 'twat', 'muff', 'chink', 'wetback', 'spic', 'spick',
    'beaner', 'kike', 'spook', 'porch monkey', 'nigglet', 'niglet', 'spunk', 'rape', 'buttfuck', 'clit', 'sodomize',
    'raghead', 'jew', 'gay'
];