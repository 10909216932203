
import React, {useEffect, useRef, useState} from "react";
import InviteRequested from './Type/InviteRequested';
import InviteReceived from './Type/InviteReceived';
import PublicUser from './Type/PublicUser';
import FriendUser from './Type/FriendUser';
import {observeMatch} from "../../../redux/middleware/wsMasterThunks";
import {useDispatch, useSelector} from "react-redux";
import {truncate} from "../../../data/generic/generic";

const UserBanner = ({type,history,player,setWhisper}) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(false);
    const refBanner = useRef(false);
    const gamepad = useSelector(state => state.gamepad);

    const handleObserveMatch = (roomId,joinUrl) => {
        dispatch(observeMatch(roomId,joinUrl))
    };

    useEffect(() => {
        if (player.username.length > 12) {
            player = {
                ...player,
                'username': truncate(player.username,12)
            };
        }
    }, []);

    useEffect(() => {
        if (gamepad.button_2) {
            setSelected(false);
            refBanner.current.focus();
        }
    }, [gamepad]);

    switch (type) {
        case 'sent':
            return <InviteRequested
                player={player}
                refBanner={refBanner}
                selected={selected}
                setSelected={setSelected}
            />;
        case 'received':
            return <InviteReceived
                player={player}
                refBanner={refBanner}
                selected={selected}
                setSelected={setSelected}
            />;
        case 'public':
            return <PublicUser
                setWhisper={setWhisper}
                player={player}
                refBanner={refBanner}
                setSelected={setSelected}
                selected={selected}
                history={history}
                handleObserveMatch={handleObserveMatch}
            />;
        case 'online':
        case 'offline':
            return <FriendUser
                setWhisper={setWhisper}
                type={type}
                player={player}
                refBanner={refBanner}
                setSelected={setSelected}
                selected={selected}
                history={history}
                handleObserveMatch={handleObserveMatch}
            />;
        default: return null;
    }
};

export default UserBanner;