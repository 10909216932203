import React, {useEffect, useRef, useState} from 'react';
import Spinner from '../Spinner';
import { useSelector } from 'react-redux';
import {
    LoadingDiv,
    StatusDiv
} from './LoadingStyle';
import {updateLoadingState, updateLoadingStatus} from "../../redux/modules/socket/socketActions";
import {loadingTypes} from "../../redux/modules/socket/socketInitialState";
import {leaveGame} from "../../redux/middleware/wsMatchThunks";
import {toggleUserInput} from "../../data/generic/generic";
import {useIcon} from "../../data/icon";
import palette from "../../styled/Palette";
import {gameSocket} from "../../redux/middleware/wsMatch";
import {PageTypes} from "../../system/types";

// The duration to wait before the timeout action to take place.
const DURATION_TO_TIMEOUT_LOADING_MS = 10000;
// The duration to wait before signaling to the user that the timeout action is taking place.
const DURATION_TO_SIGNAL_TIMEOUT_LOADING_MS = DURATION_TO_TIMEOUT_LOADING_MS - 3000;

/**
 * This components purpose is to cover up the immediate transitions between components.
 * 1) When a match loads there is downtime while connecting
 * 2) The game layer is instantly rendered which is not always done loading
 * 3) For the match context to fadeIn cleanly a separate layer needs to fadeOut from the main screen
 * This overlay appears when joining a match, observing a match, and changing between matches.
 */
const Loading = ({dispatch, history}) => {
    const { state, status } = useSelector(state => state.socket.loading);
    const refState = useRef(state);
    const teams = useSelector(state => state.match.teams);
    const refTimeout = useRef();
    const refTimeout2 = useRef();

    /**
     * This useEffect handles sending the user back to the main screen if they're still on the loading screen
     */
    useEffect(() => {
        // When the loading screen is first enabled
        if (state) {
            // Give the next screen some time to load
            refTimeout.current = setTimeout(() => {
                if (refState.current) {
                    // If it hasn't loaded by now, indicate to the user that something is not right
                    dispatch(updateLoadingStatus(loadingTypes.somethingWentWrong));
                    // Make sure we are disconnected from the match
                    dispatch(leaveGame(teams));
                    history.push(PageTypes.MAIN);
                    toggleUserInput(true);
                }
            }, DURATION_TO_SIGNAL_TIMEOUT_LOADING_MS);
            refTimeout2.current = setTimeout(() => {
               if (refState.current) {
                   // Dismiss the loading overlay
                   dispatch(updateLoadingState(false));
               }
            }, DURATION_TO_TIMEOUT_LOADING_MS);
        } else {
            // Reset the timeout that returns the user to the home screen, they didn't get stuck on the loading screen.
            clearTimeout(refTimeout.current);
            clearTimeout(refTimeout2.current);
        }
    }, [state]);

    return (
        <LoadingDiv isLoading={state}>
            {status === loadingTypes.somethingWentWrong ?
                <div style={{fontSize: '6em', display: 'flex'}}>
                    {useIcon('connection')}
                    <div style={{color: palette.pomegranate}}>
                        {useIcon('exit')}
                    </div>
                </div>
                :
                <Spinner
                    size='120px'
                    display={true}
                />
            }
            <StatusDiv>
                {status}
            </StatusDiv>
        </LoadingDiv>
    );
};

export default Loading;