import changes from "../../system/changes";
import {
    ContentDiv,
    DateDiv,
    DetailDiv,
    SubTitleDiv,
    TitleDiv,
    UpdateContainerLi,
    UpdateRowDiv,
    VersionDiv
} from "./ChangeLogStyle";
import React from "react";

const ChangeLogData = ({handleSelect,activeLog}) => {
    return changes.map((change,staticIndex) => (
        <UpdateContainerLi key={staticIndex}>
            <UpdateRowDiv
                tabIndex={0}
                onClick={() => handleSelect(change.version)}
                className={"transitionSlow"}
            >
                <VersionDiv>Version {change.version}</VersionDiv>
                <TitleDiv>{change.title}</TitleDiv>
                <DateDiv>{change.date}</DateDiv>
            </UpdateRowDiv>
            <ContentDiv show={activeLog === change.version}>
                <DetailDiv>
                    {change.summary}
                </DetailDiv>
                {change.notes &&
                    <>
                        <SubTitleDiv>PATCH NOTES</SubTitleDiv>
                        <DetailDiv>
                            {
                                change.notes.map((note,staticIndex) => (
                                    <div key={staticIndex}>• {note}</div>
                                ))
                            }
                        </DetailDiv>
                    </>
                }
                {change.technical &&
                <>
                    <SubTitleDiv>TECHNICAL CHANGES</SubTitleDiv>
                    <DetailDiv>
                        {change.technical.map((tech, staticIndex) => (
                            <div key={staticIndex}>• {tech}</div>
                        ))}
                    </DetailDiv>
                </>
                }
            </ContentDiv>
        </UpdateContainerLi>
    ));
};

export default ChangeLogData;