export const MODAL_CONTENT_SCHEME = {
    title: null,
    body: null,
    conditional: null,
    doNext: null,
    userApprove: null,
    userReject: null,
    option: null
};

export default {
    content: MODAL_CONTENT_SCHEME
};