import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {clientGenerateAndLoad, loadBackground} from "../../system/background";
import floor from "../../images/floor/floor.png";
import floor2 from "../../images/floor/floor2.png";
import floor3 from "../../images/floor/floor3.png";
import floor4 from "../../images/floor/floor4.png";
import {getRandomInt} from "../../data/format";

const BackgroundManager = ({refCanvasBackground,refCanvasFloor,setSelectedFloor}) => {
    const dispatch = useDispatch();
    const backdrop = useSelector(state => state.socket.backdrop);
    const theme = useSelector(state => state.theme);

    /**
     * Draw the floor
     */
    useEffect(() => {
        if (refCanvasFloor.current) {
            const types = [floor, floor2, floor3, floor4];
            const type = types[backdrop.floor ?? getRandomInt(types.length)];
            setSelectedFloor(type);
        }
    }, [refCanvasFloor.current,backdrop]);

    /**
     * Procedurally generate a background based on the users theme.hexValue color
     */
    useEffect(() => {
        if (theme.hexValue) {
            clientGenerateAndLoad(theme,dispatch);
        }
    }, [theme.hexValue, refCanvasBackground.current]);
    /**
     * Load the generated background on the canvas
     */
    useEffect(() => {
        if (backdrop && refCanvasBackground.current) {
            loadBackground(refCanvasBackground,backdrop);
        }
    }, [backdrop,refCanvasBackground.current]);

    return null;
};

export default BackgroundManager;

