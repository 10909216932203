import {useEffect, useState} from "react";
import {playSound} from "../data/sound";

const sounds = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','!','?','.'];

/**
 * Progresses through the dialogue attempting to simulate regular cadence
 * @param dialogue - The sentence to say
 * @param delay - The amount of time it takes to load the component
 * @returns {string} - The present state of the written string representing the progress
 */
const useTypeWriter = (dialogue, delay = 0) => {
    const [writtenProgress,setWrittenProgress] = useState('');

    // Begin the process of writing each character to the screen
    useEffect(() => {
        if (dialogue) {
            setTimeout(() => {
                setWrittenProgress(dialogue[0]);
            }, delay);
        }
    }, []);

    useEffect(() => {
        if (writtenProgress) {
            setTimeout(() => {
                const done = writtenProgress.length === dialogue.length;
                if (!done) {
                    const theNextLetter = dialogue[writtenProgress.length];
                    let delay = 0;
                    if ([' '].includes(theNextLetter)) {
                        delay = 50;
                    }
                    if (['?','!','.'].includes(theNextLetter)) {
                        delay = 300;
                    }

                    // Append the next letter after appropriate delay time
                    setTimeout(() => {
                        setWrittenProgress(writtenProgress + theNextLetter);
                    }, delay);

                    if (sounds.includes(theNextLetter.toLowerCase())) {
                        playSound(theNextLetter.toLowerCase(), .5);
                    }
                }
            }, 50);
        }
    }, [writtenProgress]);

    return writtenProgress;
};

export default useTypeWriter;
