import styled from 'styled-components';
import palette from "../../../styled/Palette";

export const LootOutfitsDiv = styled.div`
    color: white;
    display: flex;
    align-items: center;
    padding: .5em;
    position: relative;
    background-color: ${palette.base3};
`;

export const LootOutfitSpan = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    
    border-right: 1px solid ${palette.base0};
    border-bottom: 1px solid ${palette.base0};
    border-top: 1px solid ${palette.base0};
    
    color: ${palette.base0};
    background-color: ${palette.midnightBlue};

    font-size: 1em;
    padding: 0 10px;
    cursor: pointer;
    
    &:hover, :focus {
        color: ${props => props.skull ? palette.pomegranate : palette.emerald};
    }
`;
