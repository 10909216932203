import React, { useState, useEffect } from "react";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";
import ItemSlot from '../../../component/Item/ItemSlot';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import palette from '../../../styled/Palette';
import coin from "../../../images/coin.png";
import { playSound } from '../../../data/sound';
import { masterSocket } from "../../../redux/middleware/wsMaster";
import {useDispatch, useSelector} from "react-redux";
import {outfitUpdate, sellItem} from "../../../redux/modules/self/selfActions";
import {
    SellItemDiv,
    TitleDiv,
    ItemNameDiv,
    YieldDiv,
    BodyMount,
    YieldValueDiv,
    ValueDiv,
    LabelDiv,
    ResultDiv,
} from './SellItemStyle';

const SellItem = (props) => {
    const dispatch = useDispatch();
    const { itemSellValue, outfit, } = useSelector(state => state.self);

    let [sellState,setSellState] = useState(0);
    let [selling,setSelling] = useState(false);

    const handleSell = () => {
        playSound('Glass_Rise1',1);
        setSelling(true);
        //Animate background
        const iconRef = document.getElementById('animateIcon');
        iconRef.classList.add('rubberBand');
        //Make sell request to back-end
        masterSocket.send(JSON.stringify({
            'event': 'sellItem',
            'userItemId': props.item.userItemId
        }));
        setTimeout(() => {
            setSellState(1);
        }, 1250);

        // Update items optimistically assuming the server processed the request
        dispatch(sellItem({
            category: props.item.category,
            itemId: props.item.userItemId,
        }));
    };
    const handleCancel = () => {
        props.setSellWindow(false);
        playSound('screenClose',1);
    };
    const getItemValue = () => {
        /**
         * This function needs to remain in parity with the same function on the back-end
         */
        let stats = [];
        //put all user stats in one array to determine the total stat value
        Object.keys(props.item.stats).forEach((key) => stats.push(props.item.stats[key]));
        //get total stat value
        const statTotal = stats.reduce((a, b) => a + b);
        //set min and max values based on stats
        let itemMaxValue = Math.abs(statTotal) * 50;
        //incorporate tier into item value
        if (props.item.tier > 0) itemMaxValue = itemMaxValue * props.item.tier;
        //no value items will yield 100 coin
        if (itemMaxValue === 0) itemMaxValue = 100;
        return itemMaxValue;
    };

    useEffect(() => {
        playSound('screenOpen',1);
    }, []);

    const itemName = props.item.name.toLowerCase() === 'paint' ? props.item.colorName : props.item.name;
    return (
        <SellItemDiv
            className="animatedFast slideInRight"
        >
            {sellState === 0 &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <TitleDiv>SELL</TitleDiv>
                    <ItemNameDiv interfaceColor={props.interfaceColor}>{itemName.toUpperCase()}</ItemNameDiv>
                    <TitleDiv>?</TitleDiv>
                </div>
            }
            {sellState === 1 &&
                <TitleDiv>YOU HAVE EARNED</TitleDiv>
            }
            <YieldDiv>
                {sellState === 0 ?
                    <FontAwesomeIcon
                        id="animateIcon"
                        className="animatedSlower"
                        icon={['fas', 'hand-holding-magic']}
                        style={{
                            marginTop: '.3em',
                            fontSize: '30em',
                            color: palette.base6,
                            position: 'absolute',
                            zIndex: 0,
                        }}
                    />
                :
                    <FontAwesomeIcon
                        className="animated fadeIn animationSpinSlow"
                        icon={['fas', 'badge']}
                        style={{
                            fontSize: '20em',
                            color: palette.base6,
                            position: 'absolute',
                            zIndex: 0,
                        }}
                    />
                }
                {sellState === 0 &&
                    <div style={{display: 'flex',flexDirection:'column'}}>
                        <ItemSlot
                            disabled
                            item={props.item}
                            category={props.category}
                        />
                        <BodyMount>
                            <LabelDiv>POSSIBLE SALE YIELD</LabelDiv>
                            <ValueDiv>
                                <div><img src={coin} alt='Coins'/></div>
                                <YieldValueDiv>
                                    {getItemValue() * .2} - {getItemValue()}
                                </YieldValueDiv>
                            </ValueDiv>
                        </BodyMount>
                    </div>
                }
                {sellState === 1 &&
                    <ResultDiv>
                        <div><img src={coin} alt='Coins'/></div>
                        <YieldValueDiv>{itemSellValue}</YieldValueDiv>
                    </ResultDiv>
                }
            </YieldDiv>
            <div style={{fontSize: '1.5em', zIndex: 1, display: 'flex', justifyContent: 'center'}}>
                {sellState === 0 &&
                    <>
                        <Button
                            click={() => selling ? null: handleCancel()}
                            styles={{width: STANDARD_BUTTON_WIDTH}}
                            title={'Cancel'}
                        />
                        <Button
                            click={() => selling ? null: handleSell()}
                            styles={{width: STANDARD_BUTTON_WIDTH}}
                            title={'Sell'}
                        />
                    </>
                }
                {sellState === 1 &&
                    <Button
                        click={() => handleCancel()}
                        styles={{width: STANDARD_BUTTON_WIDTH}}
                        title={'Continue'}
                    />
                }
            </div>
        </SellItemDiv>
    )
};

export default SellItem;