import {createReducer} from "@reduxjs/toolkit";
import initialState from "./devInitialState";
import {gameSocket} from "../../middleware/wsMatch";

const devReducers = createReducer(initialState, {
    DEV_TOGGLE_CONSOLE: (state) => {
        state.console = !state.console;
    },
    DEV_TOGGLE_REMOTE: (state) => {
        if (state.remote === 'master' && gameSocket) {
            state.remote = 'game';
        } else if (state.remote === 'game') {
            state.remote = 'master'
        }
    },
});

export default devReducers;