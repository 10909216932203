export function playerColorHexToParticleColor(playerColorHex) {
    let particleColorHex = '';
    let colorHex = playerColorHex.substring(1);
    for (let x = 0; x < colorHex.length; x++) {
        particleColorHex += colorHex[x];
        particleColorHex += colorHex[x];
    }
    return particleColorHex;
}

export function hslToRgb(h, s, l) {
    const k = (n) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return { r: 255 * f(0), g: 255 * f(8), b: 255 * f(4) };
}

export function angle(vectorA, vectorB){
    return Math.atan2(vectorB.y - vectorA.y, vectorB.x - vectorA.x);
}