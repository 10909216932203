import React, { useState, useEffect, useRef } from 'react';
import Button from "../../styled/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../component/Modal/Modal';
import { playSound } from '../../data/sound';
import MatchBetDisplay from "../../component/Chat/MatchBetDisplay";
import { leaveGame } from "../../redux/middleware/wsMatchThunks";
import { useSelector, useDispatch } from 'react-redux';
import {match, updateMatchView} from "../../redux/modules/match/matchActions";
import {toggleFullScreen} from "../../data/generic/generic";
import {
    OptionsItems
} from './OptionsStyle';
import RadioInterface from "../../component/RadioInterface";
import LinkURL from "../../component/LinkURL/LinkURL";
import ModalMatchForfeit from "../../component/Modal/Type/ModalMatchForfeit";
import {PageTypes} from "../../system/types";

const MatchOptions = ({bets,history,radioRef,setShowOverlay}) => {
    const dispatch = useDispatch();
    const { team } = useSelector(state => state.self);
    const theme = useSelector(state => state.theme);
    const matchOptionsIsOpen = useSelector(state => state.match.view.options);
    const { teams } = useSelector(state => state.match);

    const refOptionsItems = useRef(null);

    const [viewingRadio,setViewingRadio] = useState(false);
    const userIsObserving = !team;

    // When the user shows the radio the options menu is closed.
    useEffect(() => {
        if (viewingRadio) {
            dispatch(updateMatchView({options: !matchOptionsIsOpen}));
        }
    }, [viewingRadio]);

    const toggleOptions = () => {
        dispatch(updateMatchView({options: !matchOptionsIsOpen}));

        let betWindow = document.getElementById('betWindow');
        if (!matchOptionsIsOpen) {
            refOptionsItems.current.style.display = 'flex';
            if (betWindow) betWindow.style.display = 'none';
            playSound('enable',1);
        } else {
            refOptionsItems.current.style.display = 'none';
            if (betWindow) betWindow.style.display = 'flex';
            playSound('disable',1);
        }
    };

    const handleLeave = () => {
        if (team) {
            ModalMatchForfeit(dispatch,teams,history);
        } else {
            dispatch(leaveGame(teams));
            history.push(PageTypes.MAIN);
        }
    };

    const handleToggleSong = () => {
        radioRef.togglePlaying();
    };

    if (viewingRadio) {
        return (
            <RadioInterface
                source={'match'}
                setViewingRadio={setViewingRadio}
                theme={theme}
                history={history}
            />
        )
    }
    return (
        <>
            <div className="transitionSlow" style={{zIndex: 1, position: 'absolute', right: 0, textAlign: 'right', fontSize: '20px', margin: '5px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <MatchBetDisplay
                                bets={bets}
                            />
                            {userIsObserving &&
                                <Button
                                    click={() => setShowOverlay(true)}
                                    icon={<FontAwesomeIcon icon={['fas', 'usd-circle']}/>}
                                    style={{display: 'flex', flexDirection: 'column', height: '45px', width: '125px'}}
                                />
                            }
                            <Button click={() => toggleOptions()}
                                    icon={<FontAwesomeIcon icon={['fas', 'cog']}/>}
                                    style={{display: 'flex', flexDirection: 'column', height: '45px', width: '125px'}}
                            />
                        </div>
                        <OptionsItems
                             ref={refOptionsItems}
                             className="animated fadeIn"
                             interfaceColorBright={theme.interfaceColorBright}
                        >
                            <LinkURL title={'SHOW RADIO'} click={() => setViewingRadio(true)} />
                            <LinkURL title={'FULL SCREEN'} click={() => toggleFullScreen()} />
                            <LinkURL title={'VIEW RULES'} click={() => history.push({pathname: PageTypes.RULES, state: {goBallot: true}})} />
                            <LinkURL title={'LEAVE MATCH'} click={() => handleLeave()} />
                        </OptionsItems>
                    </div>
                </div>
            </div>
        </>
    )
};

export default MatchOptions;