import React from "react";
import {ModalDiv} from "../../component/Modal/ModalStyle";
import {useDispatch, useSelector} from "react-redux";
import ItemSlot from "../../component/Item/ItemSlot";
import styled from 'styled-components';
import palette from "../../styled/Palette";
import {itemModalDisable} from "../../redux/modules/itemModal/itemModalActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ItemEquipSlots from "./ItemEquipSlots";

const ModalItemContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
`;
const ModalInformationDiv = styled.div`
    margin: .25em 0;
    position: relative;
    height: ${props => props.height}em;
    width: 35em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5em 2em;
    border-radius: 2px;
    background-color: ${palette.base7};
    border: 1px solid ${palette.wetAsphalt};
    svg {
        color: ${palette.wetAsphalt};
        &:hover {
            cursor: pointer;
            color: ${palette.belizeHole};
        }
    }
`;
const ModalItemNameDiv = styled.div`
    color: ${palette.belizeHole};
    font-size: 3em;
    margin-top: .25em;
`;
const ModalItemDescriptionDiv = styled.div`
    color: ${palette.base2};
    margin-top: .75em;
    margin-right: .75em;
    background-color: ${palette.base6};
    padding: .5em 1em;
    border-radius: 2px;
    font-style: italic;
`;
const ModalItemConsumableDiv = styled.div`
    color: ${palette.base2};
    background-color: ${palette.base6};
    padding: .5em 1em;
    border-radius: 2px;
    margin-top: .75em;
    font-style: italic;
    margin-left: .25em;
`;
const ModalItemTypeDiv = styled.div`
    font-weight: bold;
    color: ${props => props.type};
    border: 1px solid ${props => props.type};
    background-color: ${palette.wetAsphalt};
    padding: .5em 1em;
    border-radius: 2px;
    margin-top: .75em;
    font-style: italic;
    margin-right: .5em;
`;
const ModalItemExtendedDiv = styled.div`
    color: ${palette.base2};
    background-color: ${palette.base6};
    padding: 1em;
    border-radius: 2px;
    text-align: left;
    margin: 1em 0;
    flex-grow: 1;
    width: 100%;
`;
const ModalCloseDiv = styled.div`
    color: ${palette.wetAsphalt};
    font-size: 2em;
`;
const ModalDemoDiv = styled.div`
    img {
        border: 1px solid black;
        border-radius: 3px;
        width: 100%;
    }
`;

/**
 * This serves as a modal for viewing extended item details.
 * Not to be confused with the generic modal component used for all other views
 */
const ModalItem = ({history}) => {
    const {item} = useSelector(state => state.modalItem);
    const dispatch = useDispatch();
    const itemTypeBackgroundColor = () => {
        switch (item.type) {
            case 'mana': return palette.belizeHole;
            case 'infinite': return palette.wisteria;
            case 'cooldown': return palette.nephritis;
            case 'one use': return palette.carrot;
        }
    };

    if (item) {
        return (
            <ModalDiv>
                <ModalItemContainerDiv>
                    <ItemSlot
                        slotWidth={20}
                        slotHeight={20}
                        disabled={true}
                        click={() => null}
                        location={history.location.pathname}
                        item={item}
                    />
                    <ModalInformationDiv>
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                            <ItemEquipSlots category={item.category} />
                            <ModalCloseDiv
                                onClick={() => dispatch(itemModalDisable())}
                                className={'buttonZoom'}
                            >
                                <FontAwesomeIcon icon={['fas', 'times']} />
                            </ModalCloseDiv>
                        </div>
                        <ModalItemNameDiv>{item.name}</ModalItemNameDiv>
                        <div style={{display: 'flex'}}>
                            <ModalItemDescriptionDiv>{item.description}</ModalItemDescriptionDiv>
                            {item.type && <ModalItemTypeDiv type={itemTypeBackgroundColor()}>{item.type} type</ModalItemTypeDiv>}
                            {item.consumable && <ModalItemConsumableDiv>Drops {item.consumable}</ModalItemConsumableDiv>}
                        </div>
                        <ModalItemExtendedDiv>{item.extended}</ModalItemExtendedDiv>

                    </ModalInformationDiv>
                </ModalItemContainerDiv>
            </ModalDiv>
        )
    }
    return null;
};

export default ModalItem;

/*
{item.demo &&
                            <ModalDemoDiv>
                                {item.demo}
                            </ModalDemoDiv>
                        }
 */