import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loadRadio, changeCanvasColor, radioMounted } from "../../redux/modules/radio/radioActions";
import { Howler } from "howler";
import {setCookie, userOnChrome} from "../../data/generic/generic";
import isElectron from "is-electron";

const Radio = ({}) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const { song, image, analyser, volume, loading, canvas, paused, mounted } = useSelector(state => state.radio);

    const intervalCanvasVisualizer = useRef(null);
    const [radioCanvas, setRadioCanvas] = useState(null);

    // Stop users on other browsers from crashing.
    const userOnAlternativeBrowser = !userOnChrome() && !isElectron();
    if (userOnAlternativeBrowser) {
        return null;
    }

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                if (song) {
                    song.stop();
                }
                dispatch(loadRadio({loading: false}));
            }, 2000);
        }
    }, [loading]);

    useEffect(() => {
        // Update the radio canvas to reflect any changes to the theme.
        dispatch(changeCanvasColor(theme.interfaceColor));
    }, [image, theme.interfaceColor]);

    useEffect(() => {
        if (song) {
            // Remember the users volume preference level
            setCookie('radioVolumeLevel', `${volume}`, 365);
        }
    }, [volume]);

    useEffect(() => {
        // When the user enables or un-pauses the radio we need to get the reference to the new canvas node
        if (!paused || mounted) {
            setRadioCanvas(document.getElementById('radioButtonCanvas'));
            dispatch(radioMounted(false))
        }
    }, [paused, mounted]);

    useEffect(() => {
        // Keep the data hydrated by watching the analyser changes
        if (analyser) {
            // Connect the analyser after it has been initialized.
            Howler.masterGain.connect(analyser);
            intervalCanvasVisualizer.current = setInterval(() => {
                if (analyser != null) {
                    if (radioCanvas != null) {
                        analyser.fftSize = 2048;
                        var bufferLength = analyser.frequencyBinCount;
                        var dataArray = new Uint8Array(bufferLength);
                        analyser.getByteFrequencyData(dataArray);
                        let spectrum = [];
                        for (var i = 0; i < dataArray.length; i++) {
                            var value = dataArray[i];
                            spectrum.push(value);
                        }
                        let ratio = 2;
                        var peak_frequency = Math.max.apply(null, dataArray);
                        let adjustedFrequency = 1 / ratio + (peak_frequency / 255 / ratio * (ratio-1));
                        let imageSize = 70;
                        let context = radioCanvas.getContext('2d');
                        context.clearRect(0, 0, radioCanvas.width, radioCanvas.height);
                        context.drawImage(
                            canvas,
                            (radioCanvas.width / 2) - (imageSize * adjustedFrequency / 2),
                            (radioCanvas.height / 2) - (imageSize * adjustedFrequency / 2) - 1,
                            imageSize * adjustedFrequency,
                            imageSize * adjustedFrequency
                        );
                    }
                }
            },1000/60);
            return () => {
                clearInterval(intervalCanvasVisualizer.current);
            }
        }
        // listen for changes to canvas to inform the correct theme color update.
    }, [analyser, radioCanvas]);

    return null;
};

export default Radio;
