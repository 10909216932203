import {imageAssets} from "../../../../data/images";

const Cap = (player,context,lerpX,lerpY,lerpAngle,size) => {
    if (player.outfit.cap != null) {
        let image = player.outfit.cap.image;
		if(player.alternativeAssets[player.activeAbility]){
			image = player.alternativeAssets[player.activeAbility].outfit.cap;
		}
        let spriteWidth = imageAssets.Cap[player.outfit.cap.name].width * size;
        let spriteHeight = imageAssets.Cap[player.outfit.cap.name].height * size;
		context.translate(lerpX, lerpY);
		context.rotate(lerpAngle);
		try {
			if (player.direction === 'left') {
				context.scale(-1, 1);
			}
			context.drawImage(image, (-spriteWidth / 2) + (spriteWidth * imageAssets.Cap[player.outfit.cap.name].xMargin), -size * 100 / 2 - Math.ceil(7 * size) - spriteHeight * imageAssets.Cap[player.outfit.cap.name].yMargin, spriteWidth, spriteHeight);
			if (player.direction === 'left') {
				context.scale(-1, 1);
			}
		}
		catch (err) {
			console.log(err);
		}
		context.rotate(-lerpAngle);
		context.translate(-lerpX, -lerpY);
    }
};

export default Cap;