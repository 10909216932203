import React from "react";
import {color} from "../../../data/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import slimeCoin from "../../../images/slimeCoin.png";
import Button, {STANDARD_BUTTON_WIDTH} from "../../../styled/Button";

let IconDroplet = require('react-icons/lib/md/format-color-fill');

const DropPaint = ({currentViewItem, handleReturn}) => {
    const body = (
        <>
            <div style={{
                fontFamily: 'Fondamento',
                fontSize: '3em',
                color: color(currentViewItem.colorHex, 'light')}}
            >
                {currentViewItem.colorName}
            </div>
            <IconDroplet style={{
                backgroundColor: '#2c2c2c',
                padding: '10px',
                borderRadius: '50%',
                fontSize: '120px',
                zIndex: '3',
                margin: '.25em',
                color: color(currentViewItem.colorHex, 'light')}} />
            {currentViewItem.type === 'start' &&
            <div style={{marginBottom: '.75em', color: color(currentViewItem.colorHex, 'light')}}>
                To change your identity, equip an alternative paint color from the Loot screen — <FontAwesomeIcon icon={['fas', 'sack']} /> —
            </div>
            }
        </>
    );

    return (
        <>
            {body}
            <Button
                styles={{width: STANDARD_BUTTON_WIDTH}}
                title="Cool"
                click={() => handleReturn()}
            />
        </>
    )
};

export default DropPaint;