import {imageAssets} from "../../../data/images";

const CriticalShotLerp = (ballCrit,context,ball) => {
    ballCrit.forEach((bc) => {
        let calculatedFrame = Math.floor((Date.now() - bc.time) / bc.animationDuration * bc.totalFrames);
        if (calculatedFrame < bc.totalFrames) {
            let angle = Math.atan2(ball.velocity.y, ball.velocity.x);
            let bcHeight = bc.size * 2.65;
            let bcWidth = bc.size * 2.65;
            context.translate(ball.position.x, ball.position.y);
            context.rotate(angle);
            let x = imageAssets['Other']['Ball Crit'][calculatedFrame].outline;
            context.drawImage(x, -bcHeight / 2, -bcWidth / 2, bcWidth, bcHeight);
            context.rotate(-angle);
            context.translate(-ball.position.x, -ball.position.y);
        }
    });
};

export default CriticalShotLerp;