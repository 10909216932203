import React from 'react';
import {
    RowDiv,
    RuleDiv
} from '../BallotStyle';

const BallotOptionChildren = ({modifierHasVoteOptions, label,theme,modifier,multiSelectColor,handleVote,renderStatus}) => {
    if (!modifierHasVoteOptions) return null;

    const modifierChoices = Object.keys(modifier.allowedTypes);
    return modifierChoices.map((voteType, index) => {
        const childOptionIsActive = modifier.status === modifier.allowedTypes[voteType];

        let voteCounter = 0;
        let votes = [].concat(modifier.votes.teamA).concat(modifier.votes.teamB);
        votes.forEach(vote => {
            if (vote === modifier.allowedTypes[voteType]) {
                voteCounter++;
            }
        });

        if (!childOptionIsActive) {
            // todo: bug when modifierChoices map gets reordered and index no longer represents last item in dom.
            return (
                <RowDiv
                    key={index}
                    bottom={modifierChoices.length-1 === index}
                    outlineColor={multiSelectColor}
                    style={{marginBottom: 0}}
                >
                    <RuleDiv
                        tabIndex={0}
                        color={theme.interfaceColor}
                        onClick={() => handleVote(label, voteType)}
                    >
                        <div style={{display: 'flex', flexGrow: 1, alignItems: 'center',padding: '5px 5px 5px 10px', fontSize: '.8em'}}>
                            {modifier.allowedTypes[voteType]} Ball
                        </div>
                        {renderStatus(label, voteCounter, voteType)}
                    </RuleDiv>
                </RowDiv>
            )
        }
        return null;
    });
};

export default BallotOptionChildren;