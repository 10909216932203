import {createReducer} from "@reduxjs/toolkit";
import socketInitialState from "./shopInitialState";

const shopReducers = createReducer(socketInitialState, {
    FILL_SHOP: (state, action) => {
        //todo: drop consumables attribute from payload on server
        const { serverTime, expiration, shop } = action.payload;
        let update = shop;
        for (let category in shop) {
            update[category].forEach(item => {
                if (item != null) {
                    item.shopExpiration = ((item.expiration - serverTime)) + Date.now();
                    item.category = item.category.toLowerCase();
                }
            });
        }
        state.gear = update.gear;
        state.expiration = expiration;
    },
    UPDATE_SHOP: (state, action) => {
        const message = action.payload;
        state.gear[message.index] = {
            ...state.gear[message.index],
            ...message.item
        };
    },
    SET_SHOP_CANVAS: (state, action) => {
        const {index, canvas} = action.payload;
        state.gear[index].iconSrc = canvas;
    },
    SET_ACTIVE_TAB: (state, action) => {
        state.activeTab = action.payload;
    }
});

export default shopReducers;