import palette from "./Palette";
import styled from 'styled-components';

export const InfoMount2 = styled.div`
    color: ${palette.base4};
    background-color: ${palette.wetAsphalt};
    border: 1px solid ${palette.midnightBlue};
    padding: .5em 1em;
    border-radius: 3px;
    margin: 1em;
`;