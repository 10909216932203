import styled from 'styled-components';
import palette from "../../styled/Palette";
import {FONT_NORMAL} from "../../data/font";

export const DialogueContainerDiv = styled.div`
    ${({ styles }) => styles}
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    flex-direction: column;
    height: 100%;
`;
export const DialogueDiv = styled.div`
    background-color: ${palette.wetAsphalt};
    width: 60em;
    height: 6em;
    border-radius: 4px;
    display: flex;
    position: relative;
`;
export const DialogueWordsDiv = styled.div`
    margin-left: 2em;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DialogueCloseDiv = styled.div`
    color: white;
    font-weight: bold;
    
    position: absolute;
    top: .25em;
    right: .25em;
`;