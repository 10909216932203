import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from '../styled/Palette';

const Spinner = ({display, size}) => {
   return (
       <div style={{
           opacity: display ? 1 : 0,
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center'
       }}>
           <FontAwesomeIcon
               style={{color: palette.base2, position: 'absolute', fontSize: size}}
               icon={['far', 'circle']}
           />
           <FontAwesomeIcon
               className="animationSpinFast"
               style={{color: 'white', fontSize: size}}
               icon={['far', 'spinner-third']} />
       </div>
   )
};

export default Spinner;