import styled from 'styled-components';
import palette from './Palette';

const Tooltip = styled.div`
    padding: 5px 10px;
    border-radius: 3px;
    background-color: ${palette.wetAsphalt};
    color: ${palette.base4};
`;

export default Tooltip;