import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import LineLabel from "../../component/LineLabel/LineLabel";
import palette from "../../styled/Palette";
import ItemSlot from "../../component/Item/ItemSlot";
import {useDispatch, useSelector} from "react-redux";
import {requestChangeLoadout} from "../../system/endpoints/master";
import {nestedSort} from "../../data/generic/generic";
import {getAccessoryByAbility} from "../../data/items";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalAchievements from "../../component/Modal/Type/ModalAchievements";
import ModalLoadout from "../../component/Modal/Type/ModalLoadout";

const LoadoutDiv = styled.div`
    background-color: ${palette.base7};
    padding: 1em 12em 0 12em;
    border-top: 1px solid black;
    background: repeating-linear-gradient(45deg,${palette.base6},${palette.base6} 10px,${palette.base7} 10px,${palette.base7} 20px);
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const emptySlot = {
    colorHex: 'red',
    tier: 0,
    category: 'accessory',
    empty: true
};


const LoadoutSlots = ({loadout,accessorySelected,setAccessorySelected}) => {

    const handleChangeLoadout = (item,index) => {
        const isEmptySlot = typeof(item.slot) !== 'number';

        if (accessorySelected) {
            // The user has selected an item and now needs to select which loadout position to slot it in
            requestChangeLoadout(accessorySelected,index+1);
            setAccessorySelected(null);
        }
        if (!accessorySelected && !isEmptySlot) {
            // The user is making a request to remove the slot item, or if there is no item, do nothing.
            requestChangeLoadout(null,index+1);
        }
    };

    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
          {loadout.map((item,index) => {
              return (
                  <ItemSlot
                      isLoadout={true}
                      click={() => handleChangeLoadout(item,index)}
                      accessorySelected={accessorySelected}
                      slotWidth={8}
                      slotHeight={8}
                      item={item}
                      key={index}
                  />
              )
          })}
      </div>
    )
};

export const determineLoadout = (accessories) => {
    accessories = accessories.map(accessory => {
        return {
            ...accessory,
            ...getAccessoryByAbility(accessory.ability)
        }
    });

    const slottedAccessories = accessories.filter(accessory => accessory.slot);
    // Iterate through number of slots which should exist
    return [...Array(4)].map((s,index) => {
        // Locate the correct item to put in the slot
        const accessoryIndex = slottedAccessories.map((accessory => accessory.slot)).indexOf(index+1);
        // Deliver the appropriate slot, or a stub
        return accessoryIndex !== -1 ? slottedAccessories[accessoryIndex] : emptySlot;
    });
};

const AccessoryLoadout = ({category,accessorySelected,setAccessorySelected,loadout,loadoutQuantity}) => {
    const dispatch = useDispatch();
    if (category !== 'accessory') {
        // Don't show the loadout outside of the accessory screen
        return null;
    }

    return (
        <LoadoutDiv>
            <LoadoutSlots
                accessorySelected={accessorySelected}
                setAccessorySelected={setAccessorySelected}
                loadout={loadout}
            />
            <LineLabel
                key={loadout}
                styles={'LOADOUT'}
                orientation={'horizontal'}
                inverted={true}
            >
                <div>
                    {`LOADOUT ${loadoutQuantity}/4`}
                    <FontAwesomeIcon
                        onClick={() => ModalLoadout(dispatch)}
                        className="buttonZoom"
                        style={{fontSize: '14px', margin: '0 0 0 .5em'}}
                        icon={['fas', 'info-circle']}
                    />
                </div>
            </LineLabel>
        </LoadoutDiv>
    )
};

export default AccessoryLoadout;