import matchScope from "../../../data/matchScope";
import {imageAssets} from "../../../data/images";

const ModeHoops = (context,hoopHeight,hoopSize) => {
    let image = imageAssets.Other.Hoop.base;
    let imageWidth = hoopSize;
    let imageRatio = imageWidth / image.width;
    let imageHeight = image.height * imageRatio;
    let width = 1920;
    context.translate(0, hoopHeight - imageHeight / 2);
    //context.globalCompositeOperation = 'destination-over';
    context.drawImage(imageAssets.Other.Hoop.base, 0, 0, imageWidth, imageHeight);
    context.translate(0, -(hoopHeight - imageHeight / 2));
    context.translate(width, hoopHeight - imageHeight / 2);
    context.scale(-1, 1);
    context.drawImage(imageAssets.Other.Hoop.base, 0, 0, imageWidth, imageHeight);
    context.scale(-1, 1);
    context.translate(-width, -(hoopHeight - imageHeight / 2));
    //ctx.fill();
};

export default ModeHoops;