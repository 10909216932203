import React, {useEffect, useRef, useState} from 'react';
import {
    CardDiv,
    FooterDiv,
} from './CardStyle';
import { masterSocket } from "../../redux/middleware/wsMaster";
import {useSelector} from "react-redux";
import CardBackBody from "./CardBackBody";
import CardBackFooter from "./CardBackFooter";
import {animated, useSpring} from "react-spring";
import {PageTypes} from "../../system/types";

const CardBack = ({index,backgroundColor,item,history,isFlipped,handleUserFlip, tierStyle,tierData}) => {
    const coins = useSelector(state => state.self.coins);
    const [confirmPurchase, setConfirmPurchase] = useState(false);
    const refBuyButton = useRef(null);
    const refCancelButton = useRef(null);

    const contentProps = useSpring({
        top: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        transform: isFlipped ? 'rotateY( 0deg )' : 'rotateY( 180deg )',
        backfaceVisibility: 'hidden',
        onRest: () => {
            if (isFlipped) {
                if (!confirmPurchase) {
                    if (refBuyButton.current) {
                        refBuyButton.current.focus();
                    }
                }
            }
        },
    });

    const handlePurchase = (e, item) => {
        e.stopPropagation();
        setTimeout(() => {
            masterSocket.send(JSON.stringify({
                'event': 'buyItem',
                'category': 'gear',
                'index': index,
                'key': item.key,
            }));
        }, 250)
    };

    /**
     *
     */
    useEffect(() => {
        if (confirmPurchase) {
            if (refCancelButton.current) {
                refCancelButton.current.focus();
            }
        }
    }, [confirmPurchase]);

    return (
        <animated.div style={contentProps}>
            <CardDiv
                flipped={isFlipped}
                style={{fontFamily: 'Montserrat', backgroundColor: backgroundColor || '#2A2A2A'}}
                tier={tierStyle}
            >
                <CardBackBody
                    isFlipped={isFlipped}
                    tierData={tierData}
                    item={item}
                    coins={coins}
                    confirmPurchase={confirmPurchase}
                />
                {history.location.pathname !== PageTypes.PRESENT &&
                    <FooterDiv>
                        <CardBackFooter
                            refCancelButton={refCancelButton}
                            refBuyButton={refBuyButton}
                            handleUserFlip={handleUserFlip}
                            isFlipped={isFlipped}
                            item={item}
                            coins={coins}
                            confirmPurchase={confirmPurchase}
                            setConfirmPurchase={setConfirmPurchase}
                            handlePurchase={handlePurchase}
                            history={history}
                            tierData={tierData}
                        />
                    </FooterDiv>
                }
            </CardDiv>
        </animated.div>
    )
};

export default CardBack;