import React, {useEffect, useState} from "react";
import Button, { STANDARD_BUTTON_WIDTH } from "../../styled/Button";
import TextareaLimited from "../../component/TextArea/TextareaLimited";
import palette from "../../styled/Palette";
import {ISSUE_REPORT_CHARACTER_MAXIMUM,ISSUE_REPORT_CHARACTER_MINIMUM} from "../../data/globals";
import isElectron from "is-electron";
import {
    ErrorFallbackDiv,
    ErrorFallbackMainShortTextDiv,
    ErrorFallbackMainTextDiv,
} from "./ErrorFallbackStyle";
import styled from 'styled-components';
import StackTrace from "stacktrace-js";
import {requestSubmitReport} from "../../system/endpoints/master";
import UseWindowDimensions from "../../hooks/useWindowDimensions";
import {PageTypes} from "../../system/types";

const SorryDiv = styled.div`
    background-color: ${palette.midnightBlue};
    border: 1px solid ${palette.belizeHole};
    padding: 1em;
    border-radius: 4px;
`;
/**
 * This component is triggered by react-error-boundary when an unexpected error occurs.
 * It is also the bug report form that's rendered in the nav route.
 */
const ErrorFallback = ({error, userIssueReport, history}) => {
    const [userReporting,setUserReporting] = useState(false);
    const [reportSent,setReportSent] = useState(false);
    const [characters,setCharacters] = useState(false);
    const [countdown,setCountdown] = useState(0);
    const { width, height } = UseWindowDimensions();
    const viewport = { width, height};

    const handleReload = () => {
        if (isElectron()) {
            // Note: this solution doesn't actually work.
            window.require('electron').remote.getCurrentWindow().reload();
        } else {
            document.location.href="/";
        }
    };

    const handleSendBugReport = () => {
        requestSubmitReport('bug', characters, viewport);
        setReportSent(true);
        setCountdown(3);
        setTimeout(() => {
            history.push(PageTypes.MAIN);
        }, 3000);
    };

    const handleSendErrorStack = () => {
        let deliverStack = function(stackframes) {
            const stackFormatted =  stackframes.map(function(sf) {
                return sf.toString();
            }).join('\n');
            let errorMsg = `${error.name}: ${error.message} - (${stackframes[0].functionName}) ${stackframes[0].fileName}:${stackframes[0].lineNumber}`;

            requestSubmitReport('error', errorMsg, viewport);
        };
        let errback = function(err) {
            console.log('unhandled trace');
            console.log(err.message);
        };
        StackTrace.fromError(error).then(deliverStack).catch(errback);
    };

    /**
     * If the game crashed unexpectedly, immediately deliver the stack trace to the developers.
     */
    useEffect(() => {
        if (!userIssueReport) {
            handleSendErrorStack();
        }
    }, []);

    useEffect(() => {
        if (countdown > 0) {
            setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
    }, [countdown]);

    const tooManyCharacters = characters.length > ISSUE_REPORT_CHARACTER_MAXIMUM;
    const tooFewCharacters = characters.length < ISSUE_REPORT_CHARACTER_MINIMUM;
    const validReport = !tooManyCharacters && !tooFewCharacters;

    return (
        <ErrorFallbackDiv>
            {!userReporting &&
                <>
                    <ErrorFallbackMainShortTextDiv>
                        {userIssueReport ? 'Bug Report' : 'An error has occurred' }
                    </ErrorFallbackMainShortTextDiv>
                    <ErrorFallbackMainTextDiv>
                        {userIssueReport ? 'We wont respond here but we do read every message 🐛' : 'that definitely was not supposed to happen' }
                    </ErrorFallbackMainTextDiv>
                </>
            }
            <div style={{display: 'flex'}}>
                {userReporting &&
                <div style={{width: '100%', height: '10em'}}>
                    {reportSent ?
                        <div>Report received. {userIssueReport ? `Returning to main screen in ${countdown} seconds.` : `The game will reload in ${countdown} seconds.`}</div>
                        :
                        <TextareaLimited
                            setCharacters={setCharacters}
                            limit={ISSUE_REPORT_CHARACTER_MAXIMUM}
                            placeholder={"How can we reproduce the issue you experienced?"}
                        />
                    }
                </div>
                }
            </div>
            {!userIssueReport &&
                <SorryDiv>
                    We are truly sorry to have interrupted your gaming session! Details about this unexpected failure have been promptly delivered to the developers. If you continue to experience this issue over multiple days please submit a bug report. Thank you for your patience.
                </SorryDiv>
            }
            <div style={{display: 'flex', margin: '1em 0'}}>
                {!reportSent &&
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {userReporting &&
                        <>
                            <Button
                                click={() => {
                                    setUserReporting(false);
                                    setCharacters(false);
                                }}
                                styles={{width: STANDARD_BUTTON_WIDTH}}
                                title={'Go Back'}
                            />
                            {validReport ?
                                <Button
                                    click={() => handleSendBugReport()}
                                    styles={{width: STANDARD_BUTTON_WIDTH}}
                                    color={'green'}
                                    title={'Submit Report'}
                                />
                                :
                                <div style={{marginLeft: '.5em', color: palette.peterRiver}}>
                                    {tooManyCharacters && 'Your message must be at most 512 characters'}
                                    {tooFewCharacters && 'Your message must be at least 50 characters'}
                                </div>
                            }
                        </>
                    }
                    {!userReporting &&
                    <>
                        {userIssueReport
                            ?
                            <>
                                <Button
                                    click={() => setUserReporting(true)}
                                    title={'Report Issue'}
                                    color={'green'}
                                    styles={{width: STANDARD_BUTTON_WIDTH}}
                                />
                                <Button
                                    click={() => history.push(PageTypes.MAIN)}
                                    styles={{width: STANDARD_BUTTON_WIDTH}}
                                    title={'Go Back'}
                                />
                            </>
                            :
                            <Button
                                click={() => handleReload()}
                                styles={{width: STANDARD_BUTTON_WIDTH}}
                                title={'Restart Game'}
                            />
                        }
                    </>
                    }

                </div>
                }
            </div>
        </ErrorFallbackDiv>
    )
};

export default ErrorFallback;