import achievements from "../data/achievements";

// The title displayed when a drop happens, based on context
export const dropTitle = (type) => {
    return {
        premium: 'Premium Unlocked!',
        start: 'You Shall Be Known As',
        luck: 'Lucky Drop!',
        firstTicket: `Here's a free ticket to get started!`,
        bet: 'Bet Winner!',
        market: 'You Just Bought',
        mtx: 'You Just Earned',
        unlock: 'Face Unlocked!',
        achievement: 'Achievement Unlocked!',
        doublePurchase: 'You bought on steam and web, enjoy these tickets!'
    }[type];
};

// The title displayed to users when unlocking a particular achievement
export const achievementText = (name,username) => {
    return {
        Kawaii: achievements.jack2.description,
        Generic: achievements.jack3.description,
        Standard: achievements.challenger3.description,
        Mustachio: achievements.challenger4.description,
        Cartoon: achievements.streaker1.description,
        Skeletal: achievements.streaker2.description,
        Baby: 'Congratulations on winning your first match',
        Limesqueeze: '10 Match Lose Streak. Not like this... not like this!',
        Jerk: 'You tried, that\'s what counts.',
        Vintage: 'Wow, now that\'s a rare find. 1% Drop Chance',
        Cyclops: 'No way! This face is a 1% Drop Chance per Win',
        Stitch: `You've got a friend in me, ${username}`,
    }[name];
};

const dropQuantities = {
    potions: 'x3',
    snowball: 'x20 x40 x60',
    grenade: 'x5 x10 x20',
    vanishingPowder: 'x5 x10 x20'
};

export default dropQuantities;