
export const getSound = (soundRequest) => {
    return (
        {
            magnet: {
                noise: 'sounds/magnet.ogg',
                dampener: .5,
            },
            abilityNotReady: {
                noise: 'sounds/abilityNotReady.ogg',
                dampener: .2,
            },
            stealth: {
                noise: 'sounds/stealth.ogg',
                dampener: .4,
            },
            fireworksLaunch: {
                noise: 'sounds/fireworksLaunch.ogg',
                dampener: 1,
            },
            fireworksCrash: {
                noise: 'sounds/fireworksCrash.ogg',
                dampener: 1,
            },
            vacuum: {
                noise: 'sounds/vacuum.ogg',
                dampener: 1,
            },
            rock: {
                noise: 'sounds/rock.ogg',
                dampener: 1,
            },
            growth: {
                noise: 'sounds/growth.ogg',
                dampener: .1,
            },
            bubbleBlow: {
                noise: 'sounds/bubbleBlow.ogg',
                dampener: 1,
            },
            bubblePop: {
                noise: 'sounds/bubblePop.ogg',
                dampener: 1,
            },
            flashlight: {
                noise: 'sounds/flashlight.ogg',
                dampener: .5,
            },
            jetPack: {
                noise: 'sounds/jetPack.ogg',
                dampener: .2,
            },
            pause: {
                noise: 'sounds/pause.ogg',
                dampener: 1,
            },
            wind: {
                noise: 'sounds/wind.ogg',
                dampener: 1,
            },
            rewind: {
                noise: 'sounds/rewind.ogg',
                dampener: 1,
            },
            fastForward: {
                noise: 'sounds/fastForward.ogg',
                dampener: 1,
            },
            fuse: {
                noise: 'sounds/fuse.ogg',
                dampener: 1,
            },
            explosion: {
                noise: 'sounds/explosion.ogg',
                dampener: 1,
            },
            powerDown: {
                noise: 'sounds/powerDown.ogg',
                dampener: 1,
            },
			flip: {
				noise: 'sounds/flip.ogg',
				dampener: 1,
			},
			sand: {
				noise: 'sounds/sand.ogg',
				dampener: 1,
			},
            burn: {
                noise: 'sounds/burn.ogg',
                dampener: .1,
            },
            freeze: {
                noise: 'sounds/freeze.ogg',
                dampener: .7,
            },
            gulp: {
                noise: 'sounds/gulp.ogg',
                dampener: 1,
            },
			snowballCrash: {
				noise: 'sounds/snowballCrash.ogg',
				dampener: 1,
			},
			snowballThrow: {
				noise: 'sounds/snowballThrow.ogg',
				dampener: 1,
			},
            web: {
                noise: 'sounds/web.ogg',
                dampener: 1
            },
			ghost: {
				noise: 'sounds/ghost.ogg',
				dampener: 1,
			},
            explode: {
                noise: 'sounds/bomb.ogg',
                dampener: 1,
            },
            dash: {
                noise: 'sounds/dash.ogg',
                dampener: 1,
            },
            ballCrit: {
                noise: 'sounds/ballCrit.ogg',
                dampener: 1,
            },
            ballHit: {
                noise: 'sounds/ballHit.mp3',
                dampener: 1,
            },
            Birdy_Done5: {
                noise: 'sounds/Birdy_Done5.ogg',
                dampener: 1,
            },
            buttonZoom: {
                noise: 'sounds/buttonZoom.wav',
                dampener: 1,
            },
            bump: {
                noise: 'sounds/bump.ogg',
                dampener: 1,
            },
            blip: {
                noise: 'sounds/blip.wav',
                dampener: 1,
            },
            cashRegister: {
                noise: 'sounds/cashRegister.ogg',
                dampener: 1,
            },
            chatAlert: {
                noise: 'sounds/chatAlert.ogg',
                dampener: 1,
            },
            drop: {
                noise: 'sounds/drop.ogg',
                dampener: 1,
            },
            disable: {
                noise: 'sounds/disable.wav',
                dampener: 1,
            },
            earnCoin: {
                noise: 'sounds/Birdy_Done8.ogg',
                dampener: 1,
            },
            enable: {
                noise: 'sounds/enable.wav',
                dampener: 1,
            },
            foundGame: {
                noise: 'sounds/foundGame.ogg',
                dampener: .1,
            },
            finalCountdown: {
                noise: 'sounds/finalcountdown.ogg',
                dampener: 1,
            },
            goal: {
                noise: 'sounds/goal.ogg',
                dampener: .5,
            },
            groupInvite: {
                noise: 'sounds/groupInvite.ogg',
                dampener: .15,
            },
            groupInviteAccept: {
                noise: 'sounds/groupInviteAccept.ogg',
                dampener: .15,
            },
            hoverItem: {
                noise: 'sounds/mouseHover.wav',
                dampener: 1,
            },
            itemHover: {
                noise: 'sounds/blip.wav',
                dampener: 1,
            },
            jump: {
                noise: 'sounds/waterDrop.wav',
                dampener: 1,
            },
            joinQueue: {
                noise: 'sounds/joinQueue.ogg',
                dampener: 1,
            },
            leftQueue: {
                noise: 'sounds/leftQueue.ogg',
                dampener: 1,
            },
            matchWon: {
                noise: 'sounds/matchWon.ogg',
                dampener: 1,
            },
            matchLost: {
                noise: 'sounds/SpaceBall_Error5.ogg',
                dampener: 1,
            },
            message: {
                noise: 'sounds/message.wav',
                dampener: 1,
            },
            pressButton: {
                noise: 'sounds/select.wav',
                dampener: 1,
            },
            playerJoinFailed: {
                noise: 'sounds/funkyboop.ogg',
                dampener: 1,
            },
            powerShot: {
                noise: 'sounds/powerShot.ogg',
                dampener: 1,
            },
            pop: {
                noise: 'sounds/pop.wav',
                dampener: 1,
            },
            pullSlots: {
                noise: 'sounds/pullSlots.wav',
                dampener: 1,
            },
            RatingBarFillLoss: {
                noise: 'sounds/Space_Error7.ogg',
                dampener: .5,
            },
            RatingBarFillWin: {
                noise: 'sounds/Space_Notification6.ogg',
                dampener: .5,
            },
            receiveChat: {
                noise: '',
                dampener: 1,
            },
            receiveWhisper: {
                noise: '',
                dampener: 1,
            },
            selectItem: {
                noise: 'sounds/equip.wav',
                dampener: 1,
            },
            screenClose: {
                noise: 'sounds/screenClose.ogg',
                dampener: .15,
            },
            screenOpen: {
                noise: 'sounds/screenOpen.ogg',
                dampener: .15,
            },
            SpaceBall_Done5: {
                noise: 'sounds/SpaceBall_Done5.ogg',
                dampener: 1,
            },
            SpaceBall_Error2: {
                noise: 'sounds/SpaceBall_Error2.ogg',
                dampener: 1,
            },
            slotsWin: {
                noise: 'sounds/slotsWin.ogg',
                dampener: 1,
            },
            tick0: {
                noise: 'sounds/tick0.wav',
                dampener: 1,
            },
            tick1: {
                noise: 'sounds/tick1.wav',
                dampener: 1,
            },
            tick2: {
                noise: 'sounds/tick2.wav',
                dampener: 1,
            },
            tick3: {
                noise: 'sounds/tick3.wav',
                dampener: 1,
            },

            coinSound0: {
                noise: 'sounds/coin/coinSound1.ogg',
                dampener: 1,
            },
            coinSound1: {
                noise: 'sounds/coin/coinSound2.ogg',
                dampener: 1,
            },
            coinSound2: {
                noise: 'sounds/coin/coinSound3.ogg',
                dampener: 1,
            },
            coinSound3: {
                noise: 'sounds/coin/coinSound4.ogg',
                dampener: 1,
            },
            coinSound4: {
                noise: 'sounds/coin/coinSound5.ogg',
                dampener: 1,
            },
            coinSound5: {
                noise: 'sounds/coin/coinSound6.ogg',
                dampener: 1,
            },
            coinSound6: {
                noise: 'sounds/coin/coinSound7.ogg',
                dampener: 1,
            },
            coinSound7: {
                noise: 'sounds/coin/coinSound8.ogg',
                dampener: 1,
            },
            coinSound8: {
                noise: 'sounds/coin/coinSound9.ogg',
                dampener: 1,
            },
            coinSound9: {
                noise: 'sounds/coin/coinSound10.ogg',
                dampener: 1,
            },
            coinSound10: {
                noise: 'sounds/coin/coinSound11.ogg',
                dampener: 1,
            },
            coinSound11: {
                noise: 'sounds/coin/coinSound12.ogg',
                dampener: 1,
            },
            coinSound12: {
                noise: 'sounds/coin/coinSound13.ogg',
                dampener: 1,
            },
            coinSound13: {
                noise: 'sounds/coin/coinSound14.ogg',
                dampener: 1,
            },

            Glass_Rise1: {
                noise: 'sounds/Glass_Rise1.ogg',
                dampener: 1,
            },
            Space_Note1: {
                noise: 'sounds/Space_Note1.ogg',
                dampener: 1,
            },
            Space_Note2: {
                noise: 'sounds/Space_Note2.ogg',
                dampener: 1,
            },
            Space_Note3: {
                noise: 'sounds/Space_Note3.ogg',
                dampener: 1,
            },
            Space_Note4: {
                noise: 'sounds/Space_Note4.ogg',
                dampener: 1,
            },
            Space_Note5: {
                noise: 'sounds/Space_Note5.ogg',
                dampener: 1,
            },
            Space_UpNDown2: {
                noise: 'sounds/Space_UpNDown2.ogg',
                dampener: 1,
            },
            Space_Notification15: {
                noise: 'sounds/SpaceBall_Notification15.ogg',
                dampener: 1,
            },
            a: {
                noise: 'sounds/letter/a.ogg',
                dampener: 1
            },
            b: {
                noise: 'sounds/letter/b.ogg',
                dampener: 1
            },
            c: {
                noise: 'sounds/letter/c.ogg',
                dampener: 1
            },
            d: {
                noise: 'sounds/letter/d.ogg',
                dampener: 1
            },
            e: {
                noise: 'sounds/letter/e.ogg',
                dampener: 1
            },
            f: {
                noise: 'sounds/letter/f.ogg',
                dampener: 1
            },
            g: {
                noise: 'sounds/letter/g.ogg',
                dampener: 1
            },
            h: {
                noise: 'sounds/letter/h.ogg',
                dampener: 1
            },
            i: {
                noise: 'sounds/letter/i.ogg',
                dampener: 1
            },
            j: {
                noise: 'sounds/letter/j.ogg',
                dampener: 1
            },
            k: {
                noise: 'sounds/letter/k.ogg',
                dampener: 1
            },
            l: {
                noise: 'sounds/letter/l.ogg',
                dampener: 1
            },
            m: {
                noise: 'sounds/letter/m.ogg',
                dampener: 1
            },
            n: {
                noise: 'sounds/letter/n.ogg',
                dampener: 1
            },
            o: {
                noise: 'sounds/letter/o.ogg',
                dampener: 1
            },
            p: {
                noise: 'sounds/letter/p.ogg',
                dampener: 1
            },
            q: {
                noise: 'sounds/letter/q.ogg',
                dampener: 1
            },
            r: {
                noise: 'sounds/letter/r.ogg',
                dampener: 1
            },
            s: {
                noise: 'sounds/letter/s.ogg',
                dampener: 1
            },
            t: {
                noise: 'sounds/letter/t.ogg',
                dampener: 1
            },
            u: {
                noise: 'sounds/letter/u.ogg',
                dampener: 1
            },
            v: {
                noise: 'sounds/letter/v.ogg',
                dampener: 1
            },
            w: {
                noise: 'sounds/letter/w.ogg',
                dampener: 1
            },
            x: {
                noise: 'sounds/letter/x.ogg',
                dampener: 1
            },
            y: {
                noise: 'sounds/letter/y.ogg',
                dampener: 1
            },
            z: {
                noise: 'sounds/letter/z.ogg',
                dampener: 1
            },
            '!': {
                noise: 'sounds/letter/exclamation.ogg',
                dampener: .1
            },
            '?': {
                noise: 'sounds/letter/question.ogg',
                dampener: .1
            },
            '.': {
                noise: 'sounds/letter/period.ogg',
                dampener: .1
            },
        }[soundRequest]
    )
};

export const bassGuitar = [
    'sounds/bassGuitar/c1.ogg',
    'sounds/bassGuitar/d1.ogg',
    'sounds/bassGuitar/f1.ogg',
    'sounds/bassGuitar/g1.ogg',
    'sounds/bassGuitar/g_sharp1.ogg',
    'sounds/bassGuitar/a1.ogg',
    'sounds/bassGuitar/c2.ogg',
    'sounds/bassGuitar/d2.ogg',
    'sounds/bassGuitar/f2.ogg',
];

export const playSound = (sound, volume) => {
    let audioInstance = new Audio(getSound(sound).noise);
    audioInstance.currentTime = 0;
    audioInstance.volume = parseFloat(.5 * volume * getSound(sound).dampener);
    let playPromise = audioInstance.play();
    if (playPromise !== undefined) {
        playPromise.then(() => {
            // Handling Error
        }).catch(error => {
            // Auto-play was prevented
            console.log(error)
        });
    }
};