import styled from 'styled-components';
import palette from "../../../styled/Palette";

export const InviteInstanceDiv = styled.div`
    align-items: center;
    height: 100%;
    padding: 1em;
    display: flex;
    background-color: ${palette.midnightBlue};
    :nth-of-type(even) {
        background-color: ${palette.wetAsphalt};
    }
`;

export const SquadInviteDiv = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: .25em 0;
    background-color: ${palette.midnightBlue};
`;

export const SquadInviteTextContainerDiv = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: .5em;
`;
export const SquadInviteTextDiv = styled.div`
    margin-top: .5em;
    font-size: .75em;
    color: ${palette.base2};
    font-weight: bold;
`;

export const SquadInviteSubTextDiv = styled.div`
    color: ${palette.base4};
`;
