import {createReducer} from "@reduxjs/toolkit";
import socketInitialState from "./presentInitialState";

const presentReducers = createReducer(socketInitialState, {
    UPDATE_PRESENT: (state, action) => {
        const { presentItems, presentColorSet, outfitId } = action.payload;

        //const items = presentItems.map(item => item.category = item.category.toLowerCase());

        state.presentItems = presentItems;
        state.presentColorSet = presentColorSet;
        state.outfitId = outfitId;
    },
});

export default presentReducers;