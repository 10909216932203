import {createReducer, current} from "@reduxjs/toolkit";
import socketInitialState from "./socketInitialState";
import {nestedSort} from "../../../data/generic/generic";
import {lowestValueObject} from "../../../utility";

/**
 * https://redux-toolkit.js.org/usage/usage-guide
 * redux toolkit is a bit more implicit than a boilerplate redux implementation.
 * 1. The createReducer function uses a switch case under the hood to handle action dispatch requests.
 * 2. This function also leverages the immer library which imitates direct state mutation.
 */
const socketReducers = createReducer(socketInitialState, {
    ROOMS: (state, action) => {
        const {rooms, serverTime} = action.payload;
        for (let roomId in rooms) {
            let room = rooms[roomId];
            if(typeof room.timeLeft === 'number'){
                room.timeLeft = room.timeLeft - (serverTime - room.timeLeftTimestamp);
                room.timeLeftTimestamp = Date.now();
            }
        }
        state.rooms = rooms;
    },
    /**
     * ALL BELOW EVENTS JUST MAP DIRECTLY FROM THE PAYLOAD
     */
    UPDATE_PROFILE: (state, action) => {
        state.profile = action.payload;
    },
    UPDATE_LOGIN_TOAST: (state, action) => {
        state.loginToast = action.payload;
    },
    DISCONNECT_REASON: (state, action) => {
        state.disconnectReason = action.payload;
    },
    UPDATE_PING: (state, action) => {
        state.ping = action.payload;
    },
    BACKDROP: (state, action) => {
        state.backdrop = action.payload;
    },
    UPDATE_LOADING_STATUS: (state, action) => {
        state.loading.status = action.payload;
    },
    UPDATE_LOADING_STATE: (state, action) => {
        state.loading.state = action.payload;
    },
    UPDATE_ASSETS: (state, action) => {
        state.assetsLoaded = action.payload;
    },

    /**
     * Add the server to the server list
     */
    SOCKET_SET_SERVER_LIST: (state, action) => {
        // The new server to add to the server list
        const server = action.payload;
        // The user set values that we want to preserve between updates
        const oldServerEntry = state.acceptableServers.filter(iterator => iterator.serverName === server.serverName)[0];
        // The server list without the stale server entry
        const serverListRemoveOldEntry = state.acceptableServers.filter(iterator => iterator.serverName !== server.serverName);
        // The lowest latency server
        const lowestLatencyServerLatency = lowestValueObject('latency', state.acceptableServers)?.latency || 280;
        // The assessment if this is one of the best possible servers for the user
        const isAcceptable = server.latency <= lowestLatencyServerLatency + 20 || server.latency < 75;

        // The updated server entry
        const newServerEntry = {
            ...server,
            acceptable: isAcceptable,
            override: oldServerEntry?.override
        };

        // The store being updated
        state.acceptableServers = nestedSort('serverName', [...serverListRemoveOldEntry, newServerEntry]);
    },
    SOCKET_UPDATE_ACCEPTABLE_SERVERS: (state, action) => {
        if (typeof (state.acceptableServers[action.payload].override) === 'boolean') {
            state.acceptableServers[action.payload].override = !state.acceptableServers[action.payload].override;
        } else {
            state.acceptableServers[action.payload].override = !state.acceptableServers[action.payload].acceptable;
        }
    },
    SOCKET_SERVER_REMOVE_OVERRIDE: (state, action) => {
        state.acceptableServers[action.payload].override = null;
    },

    SOCKET_SIGN_IN_PHASE: (state, action) => {
        state.signInPhase = action.payload;
    },

    SOCKET_MASTER_CONNECTED: (state) => {
        state.master = true;
    },

    SOCKET_COOLDOWN: (state, action) => {
        state.cooldown[action.payload] = !state.cooldown[action.payload];
    },
});

export default socketReducers;