const Ability = (player, context, team, teamIndex) => {
    let ability  = player.abilities[player.activeAbility];
    let maxOpacity = 1;
    if (player.activeAbility === 'ghost') maxOpacity = 0.6;
    if(ability?.fadeOpacity != null){
        maxOpacity *= ability.fadeOpacity;
    }
    if(player.team === team && player.teamIndex === teamIndex && maxOpacity < .1) maxOpacity = .1;
    if(player.phased){
        maxOpacity = .2;    
    }
    context.globalAlpha = maxOpacity;

};

export default Ability;
