import {TitlePrimaryTextDiv, TitleSecondaryTextDiv} from "../page/Title/TitleStyle";
import React from "react";
import palette from "../styled/Palette";

const SlimeLogo = ({ theme }) => {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <TitlePrimaryTextDiv style={{
                transition: `all 1s linear`,
                color: theme ? `hsl(${theme.interfaceColor % 360},100%,50%)` : palette.wetAsphalt
            }}>
                Slime
            </TitlePrimaryTextDiv>
            <TitleSecondaryTextDiv>
                .LOL
            </TitleSecondaryTextDiv>
        </div>
    )
};

export default SlimeLogo;