
export default {
    // Whether the left navigation panel is rendered
    drawer: {
        open: true,
        view: null,
    },
    steamDrawerOpen: true,
    gamepad: {
        connected: false,
        navigating: false
    }
};