import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import achievements from "../../../data/achievements";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AchievementsModalBodyDiv, InfoDiv} from "../../../page/Feats/FeatsStyle";


const star = <FontAwesomeIcon icon={['fas', 'star']} />;
const modalDetails = {
    'BEST RANK': 'Ranked matches allow you to compete with other players to ascend through five ranks. The ranks are Potato > Kiwi > Blueberry > Plum > Cherry',
    'CHALLENGER': (
        <AchievementsModalBodyDiv>
            <InfoDiv>
                <div>{star}</div>
                <div>{achievements['challenger1'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}</div>
                <div>{achievements['challenger2'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}{star}</div>
                <div>{achievements['challenger3'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}{star}{star}</div>
                <div>{achievements['challenger4'].description}</div>
            </InfoDiv>
        </AchievementsModalBodyDiv>),
    'STREAKER': (
        <AchievementsModalBodyDiv>
            <InfoDiv>
                <div>{star}</div>
                <div>{achievements['streaker1'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}</div>
                <div>{achievements['streaker2'].description}</div>
            </InfoDiv>
        </AchievementsModalBodyDiv>),
    'JACK OF ALL TRADES': (
        <AchievementsModalBodyDiv>
            <InfoDiv>
                <div>{star}</div>
                <div>{achievements['jack1'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}</div>
                <div>{achievements['jack2'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}{star}</div>
                <div>{achievements['jack3'].description}</div>
            </InfoDiv>
        </AchievementsModalBodyDiv>),
    'RARE DROPS': (
        <AchievementsModalBodyDiv>
            <InfoDiv>
                <div>{star}</div>
                <div>{achievements['rare1'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}</div>
                <div>{achievements['rare2'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}{star}</div>
                <div>{achievements['rare3'].description}</div>
            </InfoDiv>
        </AchievementsModalBodyDiv>),
    'CAREER RATIO': (
        <AchievementsModalBodyDiv style={{display: 'flex', margin: '1em'}}>
            <div>
                Ranked Wins
                <hr/>
                Ranked Losses
            </div>
            <div style={{display: 'flex', alignItems: 'center', margin: '0 1em'}}>
                =&nbsp;&nbsp;&nbsp;Career Ratio
            </div>
        </AchievementsModalBodyDiv>
    ),
    'CURRENT RATING': (
        <AchievementsModalBodyDiv style={{display: 'flex', margin: '1em'}}>
            Improve your rating by winning ranked matches. Each match you and your opponent battle to earn or lose rating points. Earn enough rating points and you will advance to a new division.
        </AchievementsModalBodyDiv>
    ),
    'ABILITY': (
        <AchievementsModalBodyDiv style={{display: 'flex', margin: '1em',flexDirection: 'column'}}>
            <InfoDiv>
                <div>{star}</div>
                <div>{achievements['ability1'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}</div>
                <div>{achievements['ability2'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}{star}</div>
                <div>{achievements['ability3'].description}</div>
            </InfoDiv>
            <InfoDiv>
                <div>{star}{star}{star}{star}</div>
                <div>{achievements['ability4'].description}</div>
            </InfoDiv>
        </AchievementsModalBodyDiv>
    )
};

export let AchievementsData;

const ModalAchievements = (dispatch,achievement) => {

    AchievementsData = (modalDetails[achievement]);

    dispatch(modalEnable({
        title: achievement,
        body: 'Achievements'
    }))
};

export default ModalAchievements;