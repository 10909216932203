import styled from 'styled-components';
import palette from '../../../../styled/Palette';
import {shadow} from "../../../../data/globals";

export const BarGraphDiv = styled.div`
    background-color: ${palette.base1};
    display: flex;
    margin: 1px 0 0 1px;
    z-index: 0;
`;
export const BarGraphLabelDiv = styled.div`
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .5em;
    color: ${props => props.green ? palette.emerald : palette.pomegranate};
`;
export const BarGraphLi = styled.li`
    flex-grow: 1;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 1.5em;
`;
export const BarBackgroundDiv = styled.div`
      flex-direction: column;
      flex-grow: .6;
      flex-basis: 0;
      display: flex;
      justify-content: center;
      align-items: center;
`;
export const BarTextDiv = styled.div`
      z-index: 1;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      font-family: Montserrat;
      padding: .75em .5em;
`;