import styled from 'styled-components';
import palette from '../../../styled/Palette';

export const BallotDiv = styled.div`
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const RowDiv = styled.div`
    flex-grow: 1;
    width: 100%;
    text-align: left;
    color: white;
    display: flex;
    background-color: ${palette.base3};
    margin-bottom: 1px;
    align-items: center;
    ${props => props.outlineColor && `
        ${props.top && `border-top: 1px solid ${props.outlineColor}`};
        ${props.bottom && `border-bottom: 1px solid ${props.outlineColor}`};
        border-left: 1px solid ${props.outlineColor};
        border-right: 1px solid ${props.outlineColor};
      }`
}
`;

export const RuleDiv = styled.div`
    height: 3em;
    color: grey;

    padding: ${props => props.mainOption ? '.5em 0 .5em .5em' : 0};
    transition: all .5s ease;
    display: flex;
    flex-grow: 1;
    align-items: center;
    
    &:hover {
        cursor:  ${props => !props.modifierHasVoteOptions && 'pointer'};
        background-color: ${props => !props.modifierHasVoteOptions && palette.base0};
    }
`;

