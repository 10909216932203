import Button from "../../../styled/Button";
import {socketSignInPhase, updateLoginToast} from "../../../redux/modules/socket/socketActions";
import React, {useEffect, useRef, useState} from "react";
import InputStandard from "../../../styled/Input";
import LinkURL from "../../../component/LinkURL/LinkURL";
import {requestSignIn} from "../../../system/endpoints/master";

const FormSignIn = ({dispatch,theme,hideAlert,steamFriendIds}) => {
    const refUsername = useRef(null);
    const refPassword = useRef(null);
    const [formValid,setFormValid] = useState(false);

    const submitForm = () => {
        if (refUsername.current.value && refPassword.current.value) {
            dispatch(updateLoginToast('Authenticating'));
            requestSignIn({
                username: refUsername.current.value,
                password: refPassword.current.value,
                steamFriendIds: steamFriendIds,
            });
        }
    };

    const isValid = () => {
        if (!refUsername.current) return false;
        if (!refPassword.current) return false;
        return refUsername.current.value.length > 0 && refPassword.current.value.length > 0;
    };

    const handleKeyPress = (code) => {
        const pressEnter = code === 'Enter';
        if (pressEnter) {
            submitForm();
        }
    };

    // Generic configuration for handling a listener
    useEffect(() => {
        const keystrokeInstance = (e) => handleKeyPress(e.code);
        window.addEventListener('keydown', keystrokeInstance);
        return () => {
            window.removeEventListener('keydown', keystrokeInstance);
        };
    }, []);

    return (
        <>
            <div>
                <InputStandard
                    tabIndex={0}
                    ref={refUsername}
                    autoComplete="off"
                    placeholder="Username"
                    onKeyUp={() => setFormValid(isValid())}
                />
                <InputStandard
                    tabIndex={0}
                    ref={refPassword}
                    autoComplete="off"
                    placeholder="Password"
                    type="password"
                    onKeyUp={() => setFormValid(isValid())}
                />
                <div style={{display: 'flex', justifyContent: 'flex-end', fontSize: '1.25em', marginTop: '1em'}}>
                    <LinkURL
                        title={'Forgot Password'}
                        click={() => dispatch(socketSignInPhase('reset'))}
                    />
                </div>
            </div>
            <div style={{display: 'flex',marginTop: '2em'}}>
                <Button
                    click={() => submitForm()}
                    isDisabled={!formValid}
                    title={"Submit"}
                    style={{backgroundColor: theme.interfaceColor}}>
                </Button>
                <Button
                    click={() => {
                        hideAlert();
                        dispatch(socketSignInPhase('newUser'));
                        setFormValid(false);
                    }}
                    title={"New User"}
                    type="button"
                    className="buttonNeutral"
                > </Button>
            </div>
        </>
    )
};

export default FormSignIn;