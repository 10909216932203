import {createReducer} from "@reduxjs/toolkit";
import initialState from "./squadInitialState";
import {playSound} from "../../../data/sound";
import {masterSocket} from "../../middleware/wsMaster";

const squadReducers = createReducer(initialState, {
    SQUAD_LEAVE: (state) => {
        state.current = [];
    },
    UPDATE_SQUAD: (state, action) => {
        const { players, pending } = action.payload;
        state.current = players;
        state.pending = pending;
    },
    UPDATE_SQUAD_STATUS: (state, action) => {
        const { pendingSquadInvites } = action.payload;
        state.invites = pendingSquadInvites;
    },
    SQUAD_INVITE_RECEIVED: (state, action) => {
        playSound('groupInvite',1);
        const { squadId, leader } = action.payload;

        state.invites = state.invites.concat({squadId: squadId, leader: leader});
    },
    SQUAD_RESPONSE: (state, action) => {
        // Inform the server of the change
        masterSocket.send(JSON.stringify({
            event: 'squadResponse',
            action: action.payload,
            squadId: state.invites[0].squadId,
        }));

        // Change the client.
        // This poorly ASSUMES the server processed the request successfully.
        // We should ideally wait for a squad_update instead of assuming the change.
        if (action.payload === 'accept') {
            playSound('groupInviteAccept',1);
            // Leave the current squad in order to join the new squad.
            if (state.current.length > 0) {
                playSound('pop',1);
            }
        }
        if (action.payload === 'decline') {
            playSound('pop',1);
            const update = state.invites;
            update.shift();
            // Remove the invite currently being displayed

            state.invites = update;
        }
    },
    SQUAD_REQUEST_LEAVE: () => {
        playSound('pop',1);
        masterSocket.send(JSON.stringify(({
            event: 'leaveSquad',
        })));
    }
});

export default squadReducers;