import {playSound} from "../sound";

export const toggleUserInput = (enable) => {
    let state = enable ? 'remove' : 'add';

    const page = document.getElementsByTagName('html');
    if (page) {
        page[0].classList[state]('noClick');
    }
};

export const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const debounce = (func, wait, immediate) => {
    var timeout;

    return function executedFunction() {
        var context = this;
        var args = arguments;

        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

export const toggleFullScreen = () => {
    // Some of this mozilla specific stuff may already be handled during webpack transpile

    var isInFullScreen = (document.fullscreenElement && true) || (document.webkitFullscreenElement && true) || (document.mozFullScreenElement && true) || (document.msFullscreenElement && true);

    var docElm = document.documentElement;
    if (!isInFullScreen) {
        playSound('enable', 1);
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        }
    } else {
        playSound('disable', 1);
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
};

export const delay = (n) => {
    n = n || 2000;
    return new Promise(done => {
        setTimeout(() => {
            done();
        }, n);
    });
};

export const getBrowser = () => {
    var ua= navigator.userAgent, tem,
        M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);

    return M.join(' ');
};

export const getOS = () => {
    if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) return "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) return "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) return "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) return "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) return "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) return "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac")            != -1) return "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11")            != -1) return "UNIX";
    if (window.navigator.userAgent.indexOf("Linux")          != -1) return "Linux";
    return "Unknown";
};

export const getNext = (currentItem, items) => {
    const currentIndex = items.indexOf(currentItem);
    const nextIndex = (currentIndex + 1) % items.length;
    return items[nextIndex];
};

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
};

export const roman = (value) => {
    switch (value) {
        case 1: return 'I';
        case 2: return 'II';
        case 3: return 'III';
        case 4: return 'IV';
        case 5: return 'V';
        case 6: return 'VI';
        case 7: return 'VII';
        case 8: return 'VIII';
        default:
            return 'I didnt code it this far';
    }
};

export const truncate = (input,length) => input.length > length ? `${input.substring(0, length)}...` : input;

export const userOnChrome = () => {
    //https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
    let isChromium = window.chrome;
    let winNav = window.navigator;
    let vendorName = winNav.vendor;
    let isOpera = typeof window.opr !== "undefined";
    let isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    let isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
        // is Google Chrome on IOS
        return false
    }
    return (isChromium !== null && typeof isChromium !== "undefined" && vendorName === "Google Inc." &&
        isOpera === false && isIEedge === false);
};

// Sort an array of objects based on a number attribute, largest to smallest.
export const nestedSort = (prop, array) => {
    prop = prop.split('.');
    let len = prop.length;
    const arrayClone = [...array];
    arrayClone.sort((a, b) => {
        let i = 0;
        while( i < len ) {
            a = a[prop[i]];
            b = b[prop[i]];
            i++;
        }
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
    });
    return arrayClone;
};
