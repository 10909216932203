import styled from 'styled-components';
import palette from '../../styled/Palette';

export const GlobalChatContainer = styled.div`
    width: 100%;
    padding-left: 10px;
    min-width: 100px;
    background-color: #3D3D3D;
    text-align: left;
    display: flex;
    flex-grow: 1;
    color: white;
`;

export const GlobalChatTime = styled.span`
    color: ${palette.base4};
`;

export const GlobalChatMessageInstance = styled.span`
    color: ${palette.base4};
    font-size: 16px;
    font-family: Montserrat;
`;