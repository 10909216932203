import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { playSound } from '../../../../data/sound';
import palette from '../../../../styled/Palette';
import BallotOptionChildren from './BallotOptionChildren';
import {
    RowDiv,
    RuleDiv,
} from '../BallotStyle';
import { useSelector } from "react-redux";
import { gameSocket } from '../../../../redux/middleware/wsMatch';

const BallotOption = ({modifiers, label, index, teams}) => {
    const theme = useSelector(state => state.theme);
    const { status } = useSelector(state => state.match);
    const { team } = useSelector(state => state.self);

    const modifier = modifiers[label];
    const modifierHasVoteOptions = typeof modifier.allowedTypes !== 'undefined';
    const modifierVotes = modifier.votes.teamA.concat(modifier.votes.teamB).filter(vote => vote === true).length;
    const isBool = modifier.type === 'bool';
    const isEnabled = modifier.status === 'on';
    const multiSelectColor = palette.base2;

    const userVotedCheckmark = (voteSubmitted) => {
        if (voteSubmitted) {
            return (
                <div style={{fontSize: '20px', color: theme.interfaceColorBright}}>
                    <FontAwesomeIcon style={{opacity: voteSubmitted ? 1:0, marginRight: '.5em'}} icon={['fas', 'check']}/>
                </div>
            )
        }
    };

    const handleVote = (key, voteType) => {
        const vote = (modifier, vote) => {
            if (team) {
                gameSocket.send(JSON.stringify({
                    'event': 'vote',
                    'modifier': modifier,
                    'vote': vote
                }));
            }
        };

        if (voteType) {
            vote(key, modifiers[key].allowedTypes[voteType]);
        } else {
            vote(key);
        }
        playSound('itemHover', 1);
    };
    const renderStatus = (key, voteCount, voteType) => {
        const totalPlayers = teams.teamA.length + teams.teamB.length;

        let voteSubmitted = modifiers[key].myVote;
        if (voteType) {
            voteSubmitted = modifiers[key].myVote === modifiers[key].allowedTypes[voteType];
        }

        if (team && status !== 'live') {
            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {userVotedCheckmark(voteSubmitted)}
                    <div style={{marginRight: '10px'}}>
                        {voteCount > 0 && <div>{voteCount} / {totalPlayers}</div>}
                    </div>
                </div>
            )
        } else return null;
    };

    return (
        <div key={index}>
            <RowDiv
                top={true}
                style={{marginBottom: modifierHasVoteOptions ? 0: '1px'}}
                id={label.replace(/\s+/g, '-')}
                outlineColor={modifierHasVoteOptions ? multiSelectColor : 0}
            >
                <RuleDiv
                    tabIndex={0}
                    mainOption={true}
                    modifierHasVoteOptions={modifierHasVoteOptions}
                    onClick={() => isBool ? handleVote(label):null}
                    style={{color: isEnabled || !isBool ? theme.interfaceColorBright : 'grey'}}>
                    <div style={{color: isEnabled || !isBool ? theme.interfaceColorBright : '', marginRight: '1em'}}>
                        <FontAwesomeIcon icon={[isEnabled || !isBool ?'fas':'far', 'diamond']} />
                    </div>
                    <div style={{flexGrow: 1}}>
                        {!isBool && modifier.status} {label}
                    </div>
                    {isBool && renderStatus(label, modifierVotes)}
                </RuleDiv>
            </RowDiv>
            <BallotOptionChildren
                theme={theme}
                modifierHasVoteOptions={modifierHasVoteOptions}
                label={label}
                modifier={modifier}
                multiSelectColor={multiSelectColor}
                handleVote={handleVote}
                renderStatus={renderStatus}
            />
        </div>
    )
};

export default BallotOption;


/*
<div style={{marginRight: '10px'}}>
    {modifiers[key].status === 'on' ? 'DISABLE' : 'ENABLE'}
</div>
 */