import slotTypes, {SlotIcon} from '../../data/slotTypes';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from 'styled-components';
import React from "react";
import palette from "../../styled/Palette";
import {useIcon} from "../../data/icon";

const ItemEquipSlotsDiv = styled.div`
    display: flex;
    padding: .5em;
    align-items: center;
    background-color: ${palette.midnightBlue};
    border-radius: 4px;
    justify-content: center;
`;
const ItemEquipSlotsIconDiv = styled.div`
    padding 0 .25em;
    svg {
       color: ${palette.belizeHole} !important;
    }
`;
const ItemEquipSlotsTextDiv = styled.div`
    color: ${palette.base2};
    font-size: .75em;
    font-family: Montserrat;
    font-weight: bold;
    margin-left: 1em;
`;

const ItemEquipSlots = ({category}) => {
    return (
       <div style={{display: 'flex', alignItems: 'center'}}>
           <ItemEquipSlotsDiv>
               {[...slotTypes,'non'].map((type) => {
                   if (category === type) {
                       return (
                           <ItemEquipSlotsIconDiv key={type}>
                               {useIcon(type)}
                           </ItemEquipSlotsIconDiv>
                       )
                   }
               })}
           </ItemEquipSlotsDiv>
           <ItemEquipSlotsTextDiv>
               {category.toUpperCase()} SLOT ITEM
           </ItemEquipSlotsTextDiv>
       </div>
    )
};

export default ItemEquipSlots;