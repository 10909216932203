import React, {useRef, useEffect, useState} from 'react';
import loader from "../../../images/load2.svg";
import { playSound } from '../../../data/sound';
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import palette from '../../../styled/Palette';
import workerGetPortrait from "../../../webworker/main";
import { imageAssets } from '../../../data/images';
import slotTypes from '../../../../src/data/slotTypes';
import styled from 'styled-components';
import {
    SlotsMount,
    EquiptSlotDiv,
    SlotTag,
    SlotTagMount,
    SlotIcon
} from './EquiptStyle';
import Icon from "../../../data/icon";
import UnequipItem from "./UnequipItem";

const Equipt = ({category,toggleCategory}) => {
    const theme = useSelector(state => state.theme);
    const self = useSelector(state => state.self);

    const [capIcon, setCapIcon] = useState(null);
    const [skinIcon, setSkinIcon] = useState(null);

    useEffect(() => {
        if (self.outfit.cap) {
            workerGetPortrait(null, self.outfit.cap).then(dataUrl => {
                setCapIcon(dataUrl);
            })
        }
        if (self.outfit.skin) {
            // console.log('before',self.outfit);
            workerGetPortrait(null, self.outfit.skin).then(dataUrl => {
                setSkinIcon(dataUrl);
            })
        }
    }, [self.outfit.cap, self.outfit.skin]);

    const getIcon = (type) => {
        const iconNameMap = {
            paint: 'tint',
            skin: 'pencil-paintbrush',
            face: 'grin',
            cap: 'hat-winter',
            accessory: 'puzzle-piece',
        }[type];
        return (
            <SlotIcon color={category === type ? palette.base4 : palette.base6 }>
                <FontAwesomeIcon style={{ fontSize: '2.5em'}}  icon={['far', iconNameMap]} />
            </SlotIcon>
        )
    };

    const renderSlots = () => {
        return slotTypes.map((slot) => {
            const slotType = (slotName) => {
                switch (slotName) {
                    case 'paint': {
                        return (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', backgroundColor: theme.hexValue }}>{getIcon(slotName)}</div>);
                    }
                    case 'cap': {
                        return (<img style={{ zIndex: '3', verticalAlign: 'center', width: '90%' }} src={capIcon || loader} alt="Default paintbrush illustration" />);
                    }
                    case 'skin': {
                        return (<img style={{ zIndex: '3', verticalAlign: 'center', width: '90%' }} src={skinIcon || loader} alt="slime illustration" />);
                    }
                    case 'face': {
                        return (<img style={{transform: 'scale(.4)', zIndex: '3', verticalAlign: 'center' }} src={imageAssets.Face[self.outfit.face.name].idle.src ? imageAssets.Face[self.outfit.face.name].idle.src : loader} alt="Default paintbrush illustration" />);
                    }
                    case 'accessory': {
                        return (<img style={{transform: 'scale(.3)', zIndex: '3', verticalAlign: 'center' }} src={imageAssets.Accessory[self.outfit.accessory.name].base.src ? imageAssets.Accessory[self.outfit.accessory.name].base.src: loader} alt="Default paintbrush illustration" />);
                        //return (<div style={{  transform: 'scale(.6)', zIndex: '3', verticalAlign: 'center', fontSize: '60px', color: 'white'}}><i className="fas fa-comment-alt"> </i></div>);
                    }
                }
            };

            return (
                <EquiptSlotDiv
                    key={slot}
                    currentActive={category === slot}
                    onClick={() => toggleCategory(slot)}
                    tabIndex={0}
                    onMouseEnter={() => playSound('hoverItem', .2)}
                >
                    <UnequipItem
                        self={self}
                        category={slot}
                    />
                    { self.outfit[slot] ? slotType(slot) : getIcon(slot)}
                </EquiptSlotDiv>
            )
        })
    };

    return (
        <>
            <SlotTagMount>
                {slotTypes.map((tag) => (
                    <SlotTag
                        key={tag}
                        onClick={() => toggleCategory(tag)}
                        active={category === tag}>
                        {tag === 'accessory' ? 'EXTRA' : tag.toUpperCase()}
                    </SlotTag>
                ))}
            </SlotTagMount>
            <SlotsMount style={{backgroundColor: palette.wetAsphalt, padding: '1em'}}>
                <div style={{display: 'flex', width: '100%', height: '100%'}}>
                    { renderSlots() }
                </div>
            </SlotsMount>
        </>
    )
};

export default Equipt;