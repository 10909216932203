import React, { useEffect, useState } from 'react';

import Advert from "../../component/Advert";

import { loadAdvert } from '../../advertLoader';
import { leaveGame } from "../../redux/middleware/wsMatchThunks";
import { drawerToggle } from "../../redux/modules/socket/socketActions";
import { useDispatch, useSelector } from 'react-redux';
import RadioInterface from "../../component/RadioInterface";
import Squad from "../../component/Squad/Squad";
import MainBody from "./MainBody/MainBody";
import {
    MainContainerDiv,
} from './MainStyle';
import SteamDrawer from "../../component/SteamDrawer/SteamDrawer";
import isElectron from "is-electron";
import PingWarning from "../../component/PingWarning";
import InformationGamepad from "../../component/Information/InformationGamepad";
import {HomeHeader} from "../../component/Home/HomeHeader";
import ItemSwitchManager from "../../component/ItemSwitchManager";
import {sandbox} from "../../system/physics/physics";
import MainInfoBanner from "./MainInfoBanner";
import {PageTypes} from "../../system/types";
import MockDrop from "../../test/MockDrop";
import MockPresent from "../../test/MockPresent";

const Main = (props) => {
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);
    const squad = useSelector(state => state.squad);
    const { roomId, teams } = useSelector(state => state.match);
    const { username, newItems } = useSelector(state => state.self);
    const inQueue = useSelector(state => state.queue.active);
    const steamDrawerOpen = useSelector(state => state.layout.steamDrawerOpen);

    useEffect(() => {
        dispatch(drawerToggle({open: true}));

        if (!isElectron()) {
            loadAdvert();
        }
        if (roomId !== 'lobby') {
            dispatch(leaveGame(teams));
        }

        //display self screen if user has unclaimed loot
        if (newItems.length > 0 ) {
            if (props.history.location.pathname !== PageTypes.DROP) {
                props.history.push({
                    pathname: PageTypes.DROP,
                    state: { from: props.history.location.pathname }
                });
            }
        }

    },[]);

    return (
        <>
            <MainContainerDiv className="animatedQuick fadeIn">
                <HomeHeader
                    dispatch={dispatch}
                    squad={squad}
                    history={props.history}
                    username={username}
                    steamDrawerOpen={steamDrawerOpen}
                />
                <MainInfoBanner history={props.history}/>
                <MainBody
                    checkMeSquadLead={props.checkMeSquadLead}
                    username={username}
                    theme={theme}
                    inQueue={inQueue}
                    history={props.history}
                />
                <Squad history={props.history}/>
                <InformationGamepad />
                <ItemSwitchManager gameMetadataProvider={sandbox} />
                <PingWarning />
                <SteamDrawer />
            </MainContainerDiv>
            <Advert />
            <RadioInterface
                theme={theme}
                history={props.history}
            />
        </>
    )
};

export default Main;