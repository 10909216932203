import ReactGA from 'react-ga4';


function initializeReactGA(history) {
    ReactGA.initialize('UA-180776786-1');

    // Initialize google analytics page view tracking
    history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    });
}

export default initializeReactGA;