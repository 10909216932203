import {MatchDiv, SlimeContainerDiv, SlimesDiv, VersusDiv, WatchInfoDiv, WatchMatchInfoDiv} from "../WatchStyle";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrinBeamSweat} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from 'react-redux';
import { observeMatch } from '../../../redux/middleware/wsMasterThunks';
import Avatar from "../../../component/Avatar/Avatar";
import {AutoSizer, List} from "react-virtualized";
import {WATCH_MATCH_PANEL_SLOT_SIZE} from "../../../data/globals";
import {color} from "../../../data/format";

const WatchMatchPanel = ({liveRooms,getExpiration,history}) => {
    const dispatch = useDispatch();
    let rooms = useSelector(state => state.socket.rooms);

    const renderTeam = (team) => {
        const slimeSize = () => {
           switch (team.length) {
               case 1: return '50%';
               default: return '100%';
           }
        };

        return team.map(player => {
            return (
                <Avatar
                    key={player.colorName}
                    size={slimeSize()}
                    hue={color(player.outfit.paint.colorHex,'light')}
                    outfit={player.outfit}
                    history={history}
                />
            )
        });
    };
    const ITEMS_COUNT = Object.keys(rooms).length;
    if (liveRooms) {
        return (
            <div style={{height: '100%'}}>
                <AutoSizer>
                    {({ height, width }) => {
                        const itemsPerRow = 1;
                        const rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);

                        return (
                            <List
                                className='List styleDarkScrollSquare'
                                width={width}
                                height={height}
                                rowCount={rowCount}
                                rowHeight={WATCH_MATCH_PANEL_SLOT_SIZE}
                                overscanRowCount={0}
                                tabIndex={null}
                                rowRenderer={
                                    ({ index, key, style }) => {
                                        const items = [];
                                        const fromIndex = index * itemsPerRow;
                                        const toIndex = Math.min(fromIndex + itemsPerRow, ITEMS_COUNT);

                                        for (let i = fromIndex; i < toIndex; i++) {
                                            const key = Object.keys(rooms)[i];
                                            let match = rooms[key];
                                            const {timeLeft,sport,roomId,score,mmr,spectators,port,teams,joinUrl} = match;
                                            let formattedSport = sport[0].toUpperCase() + sport.substr(1);
                                            let formattedAverageRating = mmr.toFixed(0);
                                            items.push(
                                                <MatchDiv
                                                    tabIndex={0}
                                                    key={index}
                                                    onClick={() => {dispatch(observeMatch(roomId,joinUrl))}}
                                                >
                                                    <WatchMatchInfoDiv>
                                                        <div>{formattedSport}</div>
                                                        <div>{score.teamA + ' - ' + score.teamB}</div>
                                                        <div>{formattedAverageRating}</div>
                                                        <div>{spectators}</div>
                                                        <div>{getExpiration(match)}</div>
                                                    </WatchMatchInfoDiv>
                                                    <SlimesDiv>
                                                        <SlimeContainerDiv>
                                                            {renderTeam(teams.teamA)}
                                                        </SlimeContainerDiv>
                                                        <VersusDiv>VS</VersusDiv>
                                                        <SlimeContainerDiv>
                                                            {renderTeam(teams.teamB)}
                                                        </SlimeContainerDiv>
                                                    </SlimesDiv>
                                                </MatchDiv>
                                            )
                                        }

                                        return (
                                            <div
                                                className='Row'
                                                key={key}
                                                style={style}
                                            >
                                                {items}
                                            </div>
                                        )
                                    }
                                }
                            />
                        )
                    }}
                </AutoSizer>
            </div>
        )
    } else {
        return (
            <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '34px',
                    fontWeight: 'bold',
                    flexGrow: '1',
                 }}>
                <div style={{width: '100%'}}>
                    <FontAwesomeIcon style={{fontSize: '120px'}} icon={faGrinBeamSweat}/>
                    <div style={{fontFamily: 'Indie Flower'}}>no one is playing</div>
                </div>
            </div>
        )
    }
};

export default WatchMatchPanel;