import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import TextUnderlineHover from "../../../styled/TextUnderlineHover";

export let PremiumOutfitData;

const ModalPremiumOutfit = (dispatch,theme,history) => {

    PremiumOutfitData = (
        <div>
            <div style={{margin: '1em'}}>
                Creating outfit presets to quickly swap between item combinations is only available to players who have upgraded to premium.
                <br/><br/>
                Premium status can be purchased&nbsp;
                <TextUnderlineHover
                    theme={theme}
                    bold={true}
                    onClick={() => {
                        history.push(PageTypes.LEARN);
                        dispatch(modalDisable());
                    }}
                    style={{flexGrow: 1}}
                >
                    here
                </TextUnderlineHover>
                .
            </div>
        </div>
    );

    dispatch(modalEnable({
        body: 'PremiumOutfit',
        title: "This is a Premium Exclusive"
    }));
};

export default ModalPremiumOutfit;