import React, {useEffect, useState} from 'react';
import {stickFloor, getUserKeystroke, scrollBottom, censor} from '../../system/chat';
import {useDispatch, useSelector} from "react-redux";
import GlobalChatMessage from "./GlobalChatMessage";
import {
    GlobalChatContainer
} from "./GlobalChatStyle";

const GlobalChat = ({chatInputElement}) => {
    const dispatch = useDispatch();
    const chat = useSelector(state => state.chat);
    const self = useSelector(state => state.self);
    const banned = useSelector(state => state.chat.banned);

    useEffect(() => {
        // A higher order function is created to use as a reference to the function instance.
        const keystrokeInstance = (e) => getUserKeystroke(e,banned,chatInputElement);

        scrollBottom();

        if (chatInputElement) {
            window.addEventListener('keyup', keystrokeInstance);
            return () => {
                window.removeEventListener('keyup', keystrokeInstance);
            }
        }
    }, [banned, chatInputElement]);

    useEffect(() => {
        stickFloor();
    }, [chat.public]);
    useEffect(() => {
        scrollBottom();
    }, []);

    return (
        <GlobalChatContainer id="chatWindow">
            <div className="chatStickFloor styleDarkScrollSquare"
                 style={{width: '100%', padding: '5px', wordWrap: 'break-word', height: '100%'}}
            >
                {
                    chat.public.map((message, index) => {
                        return (
                            <GlobalChatMessage
                                key={message.timestamp}
                                dispatch={dispatch}
                                userModerator={self.mod > 0}
                                keyProp={message.timestamp}
                                message={message}
                            />
                        )
                    })
                }
            </div>
        </GlobalChatContainer>
    )
};

export default GlobalChat;