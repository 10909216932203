import Proton from 'proton-engine';
import { protonForeground } from './protonForeground';
import { updatePerFrame } from './protonConfig';
import { getSound } from '../../data/sound';

export function createRockParticles(position, width, height, raiseTime, waitTime, amount) {
    let rockSound = new Audio(getSound('rock').noise);
    let dampener = getSound('rock').dampener;
    rockSound.currentTime = 0;
    rockSound.volume = 1 * dampener;
    rockSound.play();
    let playedSecond = false;

    let startTime = Date.now();
    let rockEmitter = new Proton.Emitter();
    rockEmitter.rate = new Proton.Rate(20, updatePerFrame / 2);
    rockEmitter.addInitialize(new Proton.Mass(1));
    rockEmitter.addInitialize(new Proton.Radius(20, 35));
    rockEmitter.addInitialize(new Proton.Life(1));
    rockEmitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.01));
    rockEmitter.addInitialize(
        new Proton.Velocity(new Proton.Span(-2, 2), new Proton.Span(-2, 2), 'vector')
    );

    // rockEmitter.addInitialize(new Proton.Velocity(Proton.Span(-2,2),-3));
    rockEmitter.addBehaviour(
        new Proton.Color(['a38000', 'b38c00', 'e6b400', 'ffcd1a', '997800', '806400', '665000'])
    );
    rockEmitter.addBehaviour(new Proton.Scale(1, 1));
    rockEmitter.addBehaviour(new Proton.Alpha(1, 0));
    rockEmitter.p.x = position.x;
    rockEmitter.p.y = position.y;
    rockEmitter.damping = 0.04;
    rockEmitter.emit((raiseTime * 2 + waitTime)  / 1000, 10);
    rockEmitter.p.x = 0;
    rockEmitter.p.y = 0;
	let x1 = position.x - width / 2;
	let y1 = 860;
	let recZone = new Proton.RectZone(x1, y1, width, 40);
	rockEmitter.addInitialize(new Proton.Position(recZone));
    let rockInterval = setInterval(() => {
        if (Date.now() - startTime < raiseTime){
		}
		else if (Date.now() - startTime > raiseTime + waitTime) {
            rockEmitter.rate = new Proton.Rate(20, updatePerFrame / 2);
            if(!playedSecond){
                rockSound.currentTime = 0;
                rockSound.volume = 1 * dampener;
                rockSound.play();
                playedSecond = true;
            }
        }
		else{
			rockEmitter.rate = new Proton.Rate(0,1000);
		}
    }, 1000 / 60);
	setTimeout(()=>{
		clearInterval(rockInterval);
	},raiseTime * 2 + waitTime)

    protonForeground.addEmitter(rockEmitter);
}
