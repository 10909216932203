import React, {useState} from "react";
import season from "../../system/season";
import {
    AtlasBannerContainerDiv,
    AtlasBannerCurrentSeasonDiv,
    AtlasBannerDatesDiv,
    AtlasBannerProgressDiv,
    AtlasBannerProgressBarDiv,
    AtlasBannerSeasonDiv
} from "./AtlasBannerStyle";

export const AtlasBanner = () => {
    const [seasonAlias] = useState(season.staticData[season.current].alias[season.iteration]);
    const [seasonMonths] = useState(season.staticData[season.current].months);

    return (
        <AtlasBannerContainerDiv>
            <AtlasBannerCurrentSeasonDiv>
                <AtlasBannerSeasonDiv>
                    {seasonAlias} {season.current}
                </AtlasBannerSeasonDiv>
                <AtlasBannerProgressDiv>
                    <AtlasBannerProgressBarDiv progress={season.percentComplete}> </AtlasBannerProgressBarDiv>
                </AtlasBannerProgressDiv>
                <AtlasBannerDatesDiv>{seasonMonths}</AtlasBannerDatesDiv>
            </AtlasBannerCurrentSeasonDiv>
        </AtlasBannerContainerDiv>
    )
};