import styled from 'styled-components';
import palette from '../../styled/Palette';
import {monoColor} from "../../data/format";

export const CardDiv = styled.div`
    background-color: ${props => props.backgroundColor || palette.base0} !important;
    cursor: ${props => !props.disabled && !props.flipped ? 'pointer': ''};
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 2px solid ${props => props.tier};
    height: 100%;
`;
export const HeaderDiv = styled.div`
    text-decoration: underline;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5em;
    padding: .25em;
    flex-shrink: 0;
    color: ${props => props.color};
    border-top: 1em solid ${props => props.bg || null};
`;
export const FooterDiv = styled.div`
    filter: ${props => props.sold ? 'grayscale(100%)' : ''};
    opacity: ${props => props.sold ? .5 : ''};
    display: flex;
    align-items: center;
    height: 3.5em;
    flex-shrink: 0;
    width: 100%;
    color: ${props => props.color};
    justify-content: space-between;
`;

export const BodyDiv = styled.div`
    filter: ${props => props.sold ? 'grayscale(100%)' : ''};
    opacity: ${props => props.sold ? .5 : ''};
    position: relative;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    font-size: 5em;
    svg {
        width: 50% !important;
        height: 25% !important;
    }
`;
export const CoinsDiv = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.4em;
    color: ${props => props.color};
`;

export const CardFrontSoldStamp = styled.div`
    color: ${palette.base4};
    font-size: 5em;
    transform: rotate(10deg);
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
    
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

export const CardFrontQuantity = styled.div`
    font-size: .75em;
    color: ${palette.base4};
    font-weight: bold;
    font-family: Montserrat;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;
export const card = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: 0 1px 3px 0
    rgba(0,0,0,.2), 0 1px 1px 0
    rgba(0,0,0,.15), 0 2px 1px -1px
    rgba(0,0,0,.13);
    
    transform-style: preserve-3d;
    transition: transform 1s;
    .flipped, .back {
        transform: rotateY( 180deg );
    }
`;

export const CardContainerDiv = styled.div`
        pointer-events: all;
        margin: 5px;
        width: 18em;
        height: 23em;
        position: relative;
    `;