import React, {useState} from "react";
import {MainContainerDiv, NavHeaderDiv} from "../../page/Main/MainStyle";
import Button, {STANDARD_BUTTON_WIDTH} from "../../styled/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserMetrics from "../../page/Main/UserMetrics";
import SquadInvite from "../../page/PublicChat/Squad/SquadInvite";
import {devMode} from "../../data/globals";
import isElectron from "is-electron";
import {layoutToggleSteamDrawer} from "../../redux/modules/layout/layoutActions";
import Icon, {useIcon} from "../../data/icon";
import {useSelector} from "react-redux";
import NewsTicker from "../NewsTicker/NewsTicker";
import {PageTypes} from "../../system/types";

export const HomeHeader = (props) => {
    const [explode, setBoom] = useState(false);
    const self = useSelector(state => state.self);

    //todo: I think that I need a stub to represent the default state of the entire redux store
    // I believe that I need this so that useSelector is functional in storybook

    const ButtonSignIn = () => {
        if (!props.username) {
            return (
                <div style={{display: 'flex', padding: '.25em 0', marginRight: '.25em'}}>
                    <Button
                        title="Sign In"
                        click={() => props.history.push(PageTypes.SIGN_IN)}
                        icon={Icon('door-open')}
                        styles={{width: STANDARD_BUTTON_WIDTH, marginRight: '1px'}}
                    />
                </div>
            )
        }
        return null;
    };
    const ButtonPremium = ({self}) => {
        const userMember = props.username;
        const userPremium = self.premium;

        const userText = () => {
            if (userPremium) {
                return null;
            }
            if (userMember) {
                return null;
            }
            return 'Learn More';
        };

        return (
            <Button
                styles={{justifyContent: 'space-between'}}
                color={'gold'}
                title={userText()}
                icon={useIcon('info')}
                click={() => props.history.push(PageTypes.LEARN)}
            />
        )
    };
    const ButtonAtlas = () => {
        return (
            <Button
                color={'green'}
                title="Game Items"
                click={() => props.history.push(PageTypes.ATLAS)}
                styles={{width: STANDARD_BUTTON_WIDTH, marginRight: '1px'}}
                icon={Icon('book-spells')}
            />
        )
    };
    const ButtonChangeLog = () => {
        return (
            <Button
                history={props.history}
                click={() => props.history.push(PageTypes.CHANGE_LOG)}
                title={'Version 0.11.6'}
                icon={Icon('tag')}
            />
        )
    };
    const ButtonBug = () => {
        return (
            <Button
                color={'red'}
                icon={Icon('bug')}
                click={() => props.history.push({pathname: PageTypes.ERROR_FALLBACK, state: {userIssueReport: true}})}
            />
        )
    };
    const ButtonTestError = () => {
        if (devMode) {
            return (
                <Button
                    color={'purple'}
                    title={'Error'}
                    click={() => setBoom(true)}
                />
            )
        }
        return null;
    };
    const ButtonsElectron = () => {
        if (isElectron()) {
            return (
                <>
                    {props.username &&
                    <Button
                        color={'green'}
                        title={`${props.steamDrawerOpen ? 'Hide' : 'Show'} Friends`}
                        icon={<FontAwesomeIcon style={{margin: '0 .5em 0 .25em', fontSize: '1.5em'}} icon={['fab','steam-square']}/>}
                        click={() => {dispatch(layoutToggleSteamDrawer())}}
                        styles={{width: STANDARD_BUTTON_WIDTH}}
                    />
                    }
                    <Button
                        history={props.history}
                        color={'red'}
                        click={(e)=> quitApplication()}
                        title={'Exit Slime'}
                        icon={<FontAwesomeIcon style={{margin: '0 .5em 0 .25em'}} icon={['fas','power-off']}/>} />
                </>
            )
        }
        return null;
    };
    const quitApplication = () => {
        const app = window.require('electron').remote.app;
        app.exit();
    };

    return (
        <>
            <NavHeaderDiv>
                <ButtonSignIn
                    username={props.username}
                    history={props.history}
                />
                <UserMetrics />
                <SquadInvite
                    squad={props.squad}
                    dispatch={props.dispatch}
                />
                <div style={{flexGrow: 1}}> </div>
                <div style={{display: 'flex', padding: '.25em 0'}}>
                    <ButtonAtlas
                        username={props.username}
                    />
                    <ButtonChangeLog history={props.history}/>
                    <ButtonPremium
                        self={self}
                    />
                    <ButtonBug history={props.history}/>
                    <ButtonsElectron
                        username={props.username}
                        history={props.history}
                        steamDrawerOpen={props.steamDrawerOpen}
                    />
                </div>
            </NavHeaderDiv>
            <NewsTicker />
        </>
    )
};

/*

                <ButtonTestError setBoom={setBoom} />
 */