import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createMemoryHistory } from 'history';
import { Provider } from 'react-redux';
import store from './redux/store';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "./page/ErrorFallback/ErrorFallback";
import TestUserBanner from "./test/TestUserBanner";
export const history = createMemoryHistory();

const render = () => {
    return ReactDOM.render(
        <Provider store={store}>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {}}
            >
                <App history={history}/>
            </ErrorBoundary>
        </Provider>,
        document.getElementById('root')
    );
};
render(App);

/**
 * This handles the scenario where the user accidentally navigates away from the page.
 */
const navigateAway = () => {
    if (document.domain !== "" && document.domain !== "localhost") {
        window.onbeforeunload = function() {
            return "Navigate away from Slime.LOL?";
        };
    }
};
navigateAway();

/**
 * We disabled the service worker to support the ads.txt route
 * Now create-react-app v2 has the service worker disabled by default
 */
//This is how it would be done if CRA didn't handle it.
//import { unregister } from './registerServiceWorker';
//unregister();


/*


const TestApp = () => {
    const [counter,setCounter] = useState(0);

    setTimeout(() => {
        setCounter(counter+1);
    }, 1000);
    return (
        <div style={{width: '100%', height: '100%', backgroundColor: 'green'}}>
            Counter value: {counter} slab
        </div>
    );
};
 */