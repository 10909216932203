import React from "react";
import {modalDisable, modalEnable} from "../../../redux/modules/modal/modalActions";
import TextUnderlineHover from "../../../styled/TextUnderlineHover";
import {LootStatsLi, LootStatsUl} from "../../../page/Loot/LootStats/LootStats";
import palette from "../../../styled/Palette";
import {definitionStats, mapStats} from "../../../data/stats";

export let SlotsTicketsData;

const ModalSlotsTickets = (dispatch) => {

    SlotsTicketsData = (
        <div style={{padding: '1em'}}>Tickets can be found randomly following a match! Premium members have better odds of finding them. You can also purchase tickets from the cash shop, if you're feeling desperate.</div>
    );

    dispatch(modalEnable({
        body: 'SlotsTickets',
        title: "You don't have tickets",
    }));
};

export default ModalSlotsTickets;