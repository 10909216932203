import React from 'react';
import styled from 'styled-components';
import palette from "../../styled/Palette";

export const    ItemDetailMountDiv = styled.div`
    display: flex;
    flex-shrink: 0;
    
    background-color: ${palette.base5};
    
    width: ${props => props.size/2}em;
    height: ${props => props.size/2}em;
    border-radius: 4px;
    
    position: relative;
`;

export const ItemDetailDiv = styled.div`
    display: flex;
    
    margin: .5em;
    
    text-align: left;
    width: 20em;
    
    border-radius: 2px;
`;

export const ItemDetailTextDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
export const ItemDetailContentDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding-left: .5em;
`;
export const ItemDetailTitleDiv = styled.div`
    color: ${palette.belizeHole};
    font-weight: bold;
    font-size: .75em;
`;
export const ItemDetailDescriptionDiv = styled.div`
    color: ${palette.base2};
    margin-top: .5em;
    font-style: italic;
    font-size: .75em;
`;
export const ItemDetailSeasonDiv = styled.div`
    color: ${palette.base4};
    font-weight: bold;
    margin-top: .5em;
    font-size: .75em;
    font-style: italic;
`;