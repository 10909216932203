import React from 'react';
import { playSound } from '../../data/sound';
import {useSelector} from "react-redux";
import styled from 'styled-components';

export const ButtonStandardDiv = styled.div`
    padding: 1px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    &:hover * .glow {
        color: ${props => props.glow};
    }
    &:hover * .icon {
        color: ${props => props.icon};
        transform: scale(1.3);
    }
`;


const ButtonStandard = (props) => {
    const theme = useSelector(state => state.theme);

    return (
        <ButtonStandardDiv
            glow={theme.interfaceColor}
            icon={theme.interfaceColorBright}
        >
            <button
                tabIndex={0}
                style={{
                    ...props.styles,
                    padding: '1em'
                }}
                className="buttonGeneric"
                onMouseEnter={() => {playSound('itemHover', 1)}}
            >
                <div
                    className="glow icon"
                    style={{fontSize: '30px', width: '2em'}}>
                    {props.icon}
                </div>
                <div
                    className="glow"
                    style={{marginLeft: '2em', fontSize: '16px'}}>
                    {props.title}
                </div>
            </button>
        </ButtonStandardDiv>
    )
};
export default ButtonStandard;