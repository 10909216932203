import React, {useEffect, useState} from "react";
import palette from "../../styled/Palette";
import SteamDrawerUser from "./SteamDrawerUser";
import { useSpring, animated, config  } from "react-spring";
import {useSelector} from "react-redux";
import isElectron from "is-electron";
import {
    SteamDrawerDiv,
    SteamDrawerTitleDiv,
    SteamDrawerFriendsDiv
} from "./SteamDrawerStyle";

const SteamDrawer = () => {
    const squad = useSelector(state => state.squad);
    const signedIn = useSelector(state => state.self.username);
    if (!isElectron() || !signedIn) return null;

    const steamDrawerOpen = useSelector(state => state.layout.steamDrawerOpen);
    const friends = useSelector(state => state.steam.friends);
    //const [sortedFriends, setSortedFriends] = useState([]);

    const contentProps = useSpring({
        position: 'absolute',
        height: '100%',
        right: steamDrawerOpen ? '0em' : '-17.9em'
    });

    const friendsPlaying = friends.length > 0;

    const userInSquad = (username) => {
        return squad.current.map(user => user.name).includes(username);
    };

    return (
        <animated.div style={contentProps}>
            <SteamDrawerDiv>
                <div style={{paddingTop: '58px', width: '100%', backgroundColor: palette.emerald}}> </div>
                {friendsPlaying ?
                    <>
                        <SteamDrawerTitleDiv>
                            STEAM FRIENDS<br/>
                            ON SLIME NOW
                        </SteamDrawerTitleDiv>
                        <SteamDrawerFriendsDiv className="styleDarkScrollSquare">
                            {
                                friends.map((user,i) => {
                                    return (
                                        <SteamDrawerUser
                                            key={i}
                                            isInSquad={userInSquad(user.slimeUsername)}
                                            username={user.slimeUsername}
                                            steamName={user.username}
                                            steamPortrait={user.avatar}
                                            inSlime={user.inSlime}
                                        />
                                    )
                                })
                            }
                        </SteamDrawerFriendsDiv>
                    </>
                    :
                    <SteamDrawerTitleDiv>
                        NO STEAM FRIENDS<br/>
                        CURRENTLY PLAYING
                    </SteamDrawerTitleDiv>
                }
            </SteamDrawerDiv>
        </animated.div>
    )
};

export default SteamDrawer;