
const PowerShotLerp = (powerShot,context,teams) => {
    powerShot.forEach(ps => {
        let calculatedFrame = Math.floor((Date.now() - ps.time) / ps.animationDuration * ps.totalFrames);
        if (calculatedFrame < ps.totalFrames) {
			let player = teams[ps.team][ps.teamIndex];
            let frame = player.powerShot[calculatedFrame];
			if(player.alternativeAssets[player.activeAbility]){
				frame = player.alternativeAssets[player.activeAbility].powerShot[calculatedFrame];
			}
            let psHeight = 190;
            let psWidth = 103;
            context.translate(ps.x, ps.y);
            context.rotate(ps.angle);
            let base = frame.base;
            let outline = frame.outline;
            context.drawImage(base, -psWidth / 2, -psHeight / 2, psWidth, psHeight);
            context.drawImage(outline, -psWidth / 2, -psHeight / 2, psWidth, psHeight);
            context.rotate(-ps.angle);
            context.translate(-ps.x, -ps.y);
        }
    });
};

export default PowerShotLerp;