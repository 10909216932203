// The duration of time before the slot begins spinning after the user has clicked spin
export const WAIT_BEFORE_SPIN = 2500;
// The duration of time it takes for the animation to complete before the component is removed
export const WAIT_FOR_WHEEL_FADE_OUT = 1000;
// The amount of cards the wheel contains (must be an even number to function properly)
export const NUMBER_WHEEL_CARDS = 60;
//
export const OFFSET_CARD_INDEX = 9;
// The duration it takes for the wheel to spin
export const WHEEL_SPIN_DURATION_SECONDS = 10; //was 10
//
export const FINAL_CARD = 4;