import {playSound} from "../../data/sound";
import ButtonStandard from "../../component/ButtonStandard/ButtonStandard";
import React, {useEffect, useState} from "react";
import {useIcon} from "../../data/icon";
import {useDispatch} from "react-redux";
import {reloadChat} from "../../redux/modules/chat/chatActions";

export const getCensorLevel = () => {
    const censorLevel = window.localStorage.getItem('chatCensorLevel');
    const localStorageChatCensorLevelOld = !['enabled','disabled'].includes(censorLevel);

    // We used to support 'heavy', 'moderate', 'none'
    if (localStorageChatCensorLevelOld) {
        window.localStorage.setItem('chatCensorLevel', 'enabled');
        return 'enabled';
    }

    return censorLevel;
};

const CensorToggle = () => {
    const dispatch = useDispatch();
    const [chatCensorLevel,setChatCensorLevel] = useState(getCensorLevel());

    const handleChangeCensorLevel = () => {
        const censorLevels = ['enabled','disabled'];
        const currentLevel = censorLevels.indexOf(chatCensorLevel);
        const reachedLastLevel = currentLevel+1 === censorLevels.length;
        const nextLevel = reachedLastLevel ? censorLevels[0] : censorLevels[currentLevel+1];

        // Update local storage to reflect the new censorLevel
        window.localStorage.setItem('chatCensorLevel', nextLevel);
        // Update the component
        setChatCensorLevel(nextLevel);
        // Reload the chat using the new censor level
        dispatch(reloadChat(nextLevel));
    };

    const censorLevel = {
        enabled: {
            icon: useIcon('chatHeavy'),
        },
        disabled: {
            icon: useIcon('chatNone'),
        },
    }[chatCensorLevel];

    return (
        <div
            onClick={() => {
                playSound('enable', 1);
                handleChangeCensorLevel();
            }}
        >
            <ButtonStandard
                title={`Profanity Filter set to ${chatCensorLevel.toUpperCase()}`}
                icon={censorLevel.icon}
            />
        </div>
    )
};

export default CensorToggle;