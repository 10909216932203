import styled from "styled-components";
import palette from "../../styled/Palette";

export const SteamDrawerDiv = styled.div`
    z-index: -1;
    display: flex;
    flex-direction: column;
    background-color: ${palette.base1};
    width: 17.9em;
    height: 100%;
`;

export const SteamDrawerTitleDiv = styled.div`
    font-weight: bold;
    color: ${palette.base4};
    margin: 2em 0 1.5em 0;
    letter-spacing: .1em;
`;


export const SteamDrawerCloseCaret = styled.div`
    font-size: 2em;
    color: ${palette.emerald};
    width: 100%;
    text-align: left;
    padding: 0 .25em;
    cursor: pointer;
`;

export const SteamDrawerFriendsDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: .5em 0;
`;

export const SteamDrawerUserDiv = styled.div`
    opacity: ${props => props.inSlime ? 1 : .15}; 
    display: flex;
    height: 4em;
    align-items: center;
    width: 100%;
    margin: .5em 0em;
    padding: 0 1em;
`;