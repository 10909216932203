import React, {useEffect} from 'react';
import Header from "../../component/Header/Header";
import WatchMatchPanel from "./WatchMatchPanel/WatchMatchPanel";

import {
    WatchDiv,
    WatchHeaderDiv,
    WatchBodyDiv,
    WatchHeaderContentDiv, WatchBodyContentDiv,
} from './WatchStyle';
import { useSelector } from 'react-redux';

import { masterSocket } from "../../redux/middleware/wsMaster";
import Button, {STANDARD_BUTTON_WIDTH} from "../../styled/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FONT_BIG, FONT_NORMAL} from "../../data/font";
import palette from "../../styled/Palette";
import InfoBanner from "../../component/InfoBanner/InfoBanner";
import {PageTypes} from "../../system/types";

/*
 let stubRooms = {
 '803d514a4d2ad14f3b0cdce29e24223a7f47efe5': {
 bets:[],
 localExpiration:  2,
 mmr: 0,
 port: "8003",
 ranked: false,
 roomId: "803d514a4d2ad14f3b0cdce29e24223a7f47efe5",
 score: "0 - 0",
 sport: "volley",
 teamColors: {a: ['flavoursome guitar'], b: 'Jamaican Orchid'},
 teamSizes: {a: 4, b: 4},
 timeLeft: "Starting"
 },
 }; */

const getMatches = () =>{
    masterSocket.send(JSON.stringify({
        'event': 'getActiveMatches',
    }));
};

const Watch = ({history}) => {
    const theme = useSelector(state => state.theme);
    const rooms = useSelector(state => state.socket.rooms);
    const liveRooms = Object.keys(rooms).length > 0;
    const getExpiration = (match) => {
        if (!isNaN(match.timeLeft)) {
            let secondsLeft = Math.floor((match.timeLeft - (Date.now() - match.timeLeftTimestamp)) / 1000);
            if (secondsLeft > 0) {
                const minutes = Math.floor(secondsLeft / 60);
                let seconds = secondsLeft % 60;
                if (seconds < 10) seconds = '0' + seconds;
                return minutes + ':' + seconds;
            } else {
                if(match.score.teamA === match.score.teamB){
                    return 'OVERTIME';
                }
                //todo: This is not a valid response when the match has entered overtime
                return 'OVER';
            }
        }
        else{
            if(match.timeLeft === 'Starting'){
                return 'STARTING';
            }
            return 'OVER';
        }
    };

    useEffect(() => {
        getMatches();
    }, []);

    return (
        <WatchDiv
            interfaceColor={theme.interfaceColor}
            className="fullScreen animatedFast slideInRight">
            <Header
                closeRoute={PageTypes.MAIN}
                title="WATCH"
            />
            <WatchBodyDiv>
                <WatchBodyContentDiv>
                    <InfoBanner
                        title={'Featured Matches'}
                        body={
                            <div>
                                These are the most formidable slime athletes, battling right now. We curate all live matches based on criteria such as <b>average player rating</b> so that you can be sure not to miss the latest and greatest matches in slime history.
                            </div>
                        }
                    />
                    {liveRooms &&
                    <WatchHeaderDiv>
                        <WatchHeaderContentDiv>
                            <div>Mode</div>
                            <div>Score</div>
                            <div>Rating</div>
                            <div>Viewers</div>
                            <div>Status</div>
                        </WatchHeaderContentDiv>
                        <Button
                            history={history}
                            click={() => getMatches()}
                            icon={<FontAwesomeIcon icon={['fas', 'sync']}/>}
                            styles={{width: '4em', justifySelf: 'flex-end'}}
                        />
                    </WatchHeaderDiv>
                    }
                    <WatchMatchPanel
                        liveRooms={liveRooms}
                        getExpiration={getExpiration}
                        history={history}
                    />
                </WatchBodyContentDiv>
            </WatchBodyDiv>
        </WatchDiv>
    )
};

export default Watch;


/*
 <div style={{display: 'flex'}}>
 <div className="swatchMountColorLighter" style={{margin: '0 15px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
 <FontAwesomeIcon style={{fontSize: 'px'}} icon={faFilter}/></div>
 <select defaultValue={"any"}>
 <option value="any">ANY MODE</option>
 <option value="volley">VOLLEY</option>
 <option value="soccer">SOCCER</option>
 <option value="hoops">HOOPS</option>
 </select>
 <select defaultValue={"starting"}>
 <option value="starting">MATCH STARTING</option>
 <option value="ratingHigh">HIGHEST RATED</option>
 <option value="ratingLow">LOWEST RATED</option>
 </select>
 <select defaultValue={"any"}>
 <option value="any">ANY RULE</option>
 <option value="on">ABILITY ON</option>
 <option value="off">ABILITY OFF</option>
 </select>
 <select defaultValue={"any"}>
 <option value="any">ANY SIZE TEAM</option>
 <option value="1">1v1</option>
 <option value="2">2v2</option>
 <option value="3">3v3</option>
 <option value="4">4v4</option>
 </select>
 </div>
 */