import React from "react";
import {useSelector} from "react-redux";
import Icon from "../../data/icon";
import palette from "../../styled/Palette";

const InformationGamepad = () => {
    const gamepad = useSelector(state => state.gamepad);

    if (!gamepad.navigating && gamepad.connected) {
        return (
            <div style={{color: palette.base4, marginTop: '3em'}}>
                Press {Icon('gamepad-alt')} <b>SELECT</b>&nbsp;&nbsp;to navigate
            </div>
        )
    }
    return null;
};

export default InformationGamepad;