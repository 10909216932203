import React, {useState, useRef, useEffect} from 'react';
import { AutoSizer, List } from 'react-virtualized';
import ItemSlot from '../../../component/Item/ItemSlot';
import {
    ItemsDiv,
    ItemsContainerDiv,
} from './ItemsStyle';
import {useDispatch, useSelector} from "react-redux";
import {lootFocusItem, lootUnfocusItem} from "../../../redux/modules/loot/lootActions";
import {FONT_HUGE} from "../../../data/font";

/**
 * Rendered on loot, present, and sell-item screen
 */
const Items = ({history,category, itemsFiltered, sellMode, handleToggleSellWindow,
setLootColorDemo,filtersOn,tooltip,click, slotWidth,slotHeight,filter,rowHeight
}) => {
    const dispatch = useDispatch();
    const [activeFace, setActiveFace] = useState(null);
    const [loading, setLoading] = useState(false);
    const [overlay,setOverlay] = useState(false);
    const focusItem = useSelector(state => state.loot.item);
    const ITEMS_COUNT = itemsFiltered.length;
    const mounted = useRef(false);
    const refList = useRef(false);

    const handleItemEnter = (e,index) => {
        // Have the face smirk if the user hovers the item slot
        if (setActiveFace) {
            setActiveFace(index);
        }
        setOverlay(true);

        //todo: Is only supposed to happen on loot screen
        dispatch(lootFocusItem(itemsFiltered[index]));
    };
    const handleItemLeave = (e,index) => {
        if (setActiveFace) {
            setActiveFace(index);
        }
        setOverlay(false);

        dispatch(lootUnfocusItem());
    };

    const handleSetFace = (index, hovering) => {
        if (hovering) {
            setActiveFace(index);
        } else {
            setActiveFace(null);
            const detail = document.getElementById('detailWindow');
            if (detail) detail.style.display = 'none';
        }
    };

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (category) {
            setLoading(true);
            setTimeout(() => {
                if (mounted.current) {
                    setLoading(false);
                }
            }, 250);
        }
    },[category]);

    return (
        <ItemsDiv>
            {itemsFiltered.length === 0 ?
                <>
                    {loading ? null :
                        (
                        <div
                            style={{color: 'white'}}
                            className="fontLarge"
                        >
                            <div style={{marginTop: '5em'}}>
                                You have not earned any
                            <span style={{fontSize: FONT_HUGE, padding: '0 10px'}}>
                                {category === 'accessory' ? 'accessories' : `${category}s`}
                            </span>
                            {filtersOn &&
                                <span>
                                    with these filters applied
                                </span>
                            }
                            </div>
                        </div>
                        )
                    }
                </>
                :
                <ItemsContainerDiv>
                    <AutoSizer>
                        {({ height, width }) => {
                            const itemsPerRow = Math.floor(5);
                            const rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);

                            return (
                                <List
                                    ref={refList}
                                    className='List styleDarkScrollSquare'
                                    width={width}
                                    height={height}
                                    rowCount={rowCount}
                                    rowHeight={rowHeight}
                                    overscanRowCount={0}
                                    tabIndex={null}
                                    rowRenderer={
                                        ({ index, key, style }) => {
                                            const items = [];
                                            const fromIndex = index * itemsPerRow;
                                            const toIndex = Math.min(fromIndex + itemsPerRow, ITEMS_COUNT);

                                            for (let i = fromIndex; i < toIndex; i++) {
                                                items.push(
                                                    <ItemSlot

                                                        index={i}
                                                        item={itemsFiltered[i]}

                                                        filter={filter}

                                                        overlay={overlay}
                                                        handleItemLeave={handleItemLeave}
                                                        handleItemEnter={handleItemEnter}

                                                        location={history?.location?.pathname}
                                                        slotWidth={slotWidth}
                                                        slotHeight={slotHeight}

                                                        click={click}
                                                        tooltip={tooltip}
                                                        tabIndex={0}
                                                        className='Item'
                                                        setLootColorDemo={setLootColorDemo}
                                                        handleToggleSellWindow={handleToggleSellWindow}
                                                        sellMode={sellMode}
                                                        activeFace={activeFace}
                                                        setActiveFace={handleSetFace}
                                                    />
                                                )
                                            }

                                            return (
                                                <div
                                                    className='Row'
                                                    key={key}
                                                    style={style}
                                                >
                                                    {items}
                                                </div>
                                            )
                                        }
                                    }
                                />
                            )
                        }}
                    </AutoSizer>
                </ItemsContainerDiv>
            }
        </ItemsDiv>
    );
};

export default React.memo(Items);
