import matchScope from "../../../data/matchScope";

const ModeSoccer = (context,teams) => {
    let t1 = teams.teamA.length === 1 ? teams.teamA[0].colorHex : '#777';
    let netHeight = matchScope.netHeight;
    let netY = 900 - netHeight;
    context.beginPath();
    context.lineWidth = "1";
    context.fillStyle = t1;
    context.globalAlpha = .2;
    context.rect(0, netY, 45, netHeight - 4);
    context.fill();

    context.beginPath();
    context.lineWidth = "1";
    context.fillStyle = '#fff';
    context.globalAlpha = 1;
    context.rect(0, netY - 10, 45, 10);
    context.fill();

    let t2 = teams.teamB.length === 1 ? teams.teamB[0].colorHex : '#777';
    context.beginPath();
    context.lineWidth = "1";
    context.fillStyle = t2;
    context.globalAlpha = .2;
    context.rect(1875, netY, 45, netHeight - 4);
    context.fill();

    context.beginPath();
    context.lineWidth = "1";
    context.fillStyle = '#fff';
    context.globalAlpha = 1;
    context.rect(1875, netY - 10, 45, 10);
    context.fill();
};

export default ModeSoccer;