import {FONT_BIG, FONT_NORMAL} from "../../data/font";
import palette from "../../styled/Palette";
import React from "react";
import styled from 'styled-components';

export const InfoBannerDiv = styled.div`
    cursor: ${props => props.onClick ? 'pointer':'default'};
    width: 100%;
    border-radius: 3px;
    font-family: Montserrat;
    text-align: left;
    background-color: ${palette.base6};
    border: 2px solid ${palette.base7};
    padding: 2em;
    margin: 1em 0;
    font-size: 10px;
    color: ${palette.base4};
   
    transition-delay: 2s;
    transition: all .5s;
        
    &:hover {
        ${({ selectable }) => selectable ? `border: 2px solid ${palette.belizeHole};` : ''}
        ${({ selectable }) => selectable ? `background-color: ${palette.midnightBlue};` : ''}
    }
`;

export const InfoBanner = ({title,body,click}) => {
    return (
        <InfoBannerDiv
            onClick={click} selectable={!!click}>
            <div style={{fontSize: FONT_BIG, fontWeight: 'bold', paddingBottom: '.5em'}}>
                {title}
            </div>
            <div style={{fontSize: FONT_NORMAL, color: palette.base2}}>
                {body}
            </div>
        </InfoBannerDiv>
    )
};

export default InfoBanner;