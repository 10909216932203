import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {CloseButtonSpan, HeaderDiv} from './HeaderStyle';
import { playSound } from '../../data/sound';
import {drawerToggle} from "../../redux/modules/socket/socketActions";
import { useDispatch } from 'react-redux';
import Icon, {useIcon} from "../../data/icon";

const Header = (props) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        playSound('screenOpen', 1);
        dispatch(drawerToggle({view: null}));
    };

    return (
        <HeaderDiv>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%'}} >
                <div style={{marginLeft: '10px', fontSize: '16px', textAlign: 'left', padding: '0 5px'}}>
                    {props.title}
                </div>
                <div>
                    {props.closeRoute &&
                    <Link to={props.closeRoute} style={{color: 'white'}}>
                        <CloseButtonSpan tabIndex={0}  onClick={handleClose}>
                            {useIcon('exit')}
                        </CloseButtonSpan>
                    </Link>
                    }
                </div>
            </div>
        </HeaderDiv>
    );
};

export default Header;