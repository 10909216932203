import styled from 'styled-components';
import palette from '../../../styled/Palette';

export const LootItemsFilterDiv = styled.div`
    visibility: ${props => props.shown ? 'visible' : 'hidden'};
    display: ${props => props.shown ? 'flex' : 'none'};
    
    height: 60px;
    background-color: ${palette.base5};
    background-color: ${palette.midnightBlue};
    padding-top: 1em;
    align-items: center;
    padding: 5px;
    border-radius: 0;
    border-bottom: 1px solid black;
    width: 100%;
    
    justify-content: space-between;
`;