
// todo: We just need to stay ahead of the curve in providing alias names for the seasons.
// With four aliases currently provided, we are covered through 2025
const staticData = {
    autumn: {
        months: 'September to November',
        alias: ['Ancient','Astral','Awesome','Auspicious']
    },
    winter: {
        months: 'December to February',
        alias: ['Wicked','Whimsical','Wretched','Wonderful']
    },
    spring: {
        months: 'March to May',
        alias: ['Solid','Super','Stupendous','Sombre']
    },
    summer: {
        months: 'June to August',
        alias: ['Silly','Sandy','Spicy','Sweet']
    },
};
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1;
const monthName = date.toLocaleString('default', { month: 'long' });
const day = date.getDate();
const daysInMonth = new Date(year, month, 0).getDate();
const monthPercentComplete = ((day / daysInMonth) *100).toFixed(0);
const iteration = Math.abs(2022 - year);

const getSeason = () => {
    switch (month) {
        case 9:case 10:case 11:
            return 'autumn';
        case 12:case 1:case 2:
            return 'winter';
        case 3:case 4:case 5:
            return 'spring';
        case 6:case 7:case 8:
            return 'summer';
    }
};
const getPercentComplete = () => {
    const getDays = (m1,m2,m3) => {
        const month1 = new Date(year, m1, 0).getDate();
        const month2 = new Date(year, m2, 0).getDate();
        const month3 = new Date(year, m3, 0).getDate();
        const totalDays = month1 + month2+ month3;
        let progress;

        if ([9,12,3,6].includes(month)) {
            progress = day / totalDays;
        }
        if ([10,1,4,7].includes(month)) {
            progress = (day + month1) / totalDays;
        }
        if ([11,2,5,8].includes(month)) {
            progress = (day + month1 + month2) / totalDays;
        }
        progress = (progress * 100).toFixed(0);

        return progress;
    };
    const determineDuration = (season) => {
        switch (season) {
            case 'autumn': return getDays(9,10,11);
            case 'winter': return getDays(12,1,2);
            case 'spring': return getDays(3,4,5);
            case 'summer': return getDays(6,7,8);
        }
    };
    switch (month) {
        case 9:case 10:case 11:
            return determineDuration('autumn');
        case 12:case 1:case 2:
            return determineDuration('winter');
        case 3:case 4:case 5:
            return determineDuration('spring');
        case 6:case 7:case 8:
            return determineDuration('summer');
    }
};

const current = getSeason();
const percentComplete = getPercentComplete();

export default {
    current,
    iteration,
    percentComplete,
    staticData,
    time: {
        year,
        month,
        day,
        monthName
    },
};