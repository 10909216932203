import styled from 'styled-components';
import React from "react";
import {useSelector} from "react-redux";

const LinkSpan = styled.span`
    
    ${props => props.theme && `color: ${props.theme.interfaceColorBright}`};
    ${props => props.bold && 'font-weight: bold;'}
   
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

const LinkURL = ({title, url, click}) => {
    const theme = useSelector(state => state.theme);
    const openInNewTab = (url) => {
        let win = window.open(url, '_blank');
        win.focus();
    };

    return (
        <LinkSpan
            tabIndex={0}
            theme={theme}
            onClick={() => click ? click() : openInNewTab(url)}
        >
            {title}
        </LinkSpan>
    )
};

export default LinkURL;
